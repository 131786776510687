@import "_setting";
$type: "";
@import "_magnific";
@import "_login";


// FOR SMARTPHONE DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (max-width:$breakpoint_sp) {
	$type: sp;
 @import "_magnific";
 @import "_login";
}

// FOR LARGE DISPLAY SIZE
//-----------------------------------------------------------------
@media print, screen and (min-width:$breakpoint_pc) {
	$type: pc;
 @import "_magnific";
 @import "_login";
}

// FOR TABLET DISPLAY SIZE
//-----------------------------------------------------------------
@media  screen and (max-width:$breakpoint_tb) and (min-width:$breakpoint_sp + 1) {
 $type: tb;
 @import "_magnific";
 @import "_login";
}

// STYLE FOR PRINT
//-----------------------------------------------------------------
@media print {
	$type: print;
}