$c_gray : #737373;
$c_blue : #1560BB;





#mainArea {
	@if $type ==sp {
		width: 90%;
		margin: 0 auto;
	}
}



// breadcrumb ---------------
.breadcrumb {
	nav {
		@if $type==sp {
			padding: 10px 0 20px 0;
		}

		@if $type==pc {
			padding: 20px 0;
		}

		overflow: hidden;
		background: #fff;
		position: relative;
		top:0;
		height: 100%;
		z-index: 1;

		ol {
			float: left;

			li {
				list-style: none;
				float: left;
				padding-right: 20px;
				position: relative;

				a {
					color: #000;
					font-size: 14px;

					&:hover {
						text-decoration: none;
						color: #088E98
					}
				}

				.active {
					color: #088E98
				}

				.breadcrumb-home-a::after {
					content: "";
					display: block;
					width: 4px;
					height: 4px;
					transform: rotate(45deg) translateY(-50%);
					position: absolute;
					top: 50%;
					right: 10px;
					border-top: 1px solid #CFCFCF;
					border-right: 1px solid #CFCFCF;
				}
			}
		}
	}

	h1 {
		position: relative;
		padding-left: 22px;
		line-height: 44px;

		&:after {
			content: "";
			display: block;
			width: 0;
			height: 44px;
			position: absolute;
			top: 0;
			left: 0;
			border-right: 6px solid #088E98;
		}
	}
}

.breadcrumb {
	nav {
		ol {
			li {
				span {
					@if $type =="" {}

					@if $type ==sp {
						font-size: 14px;
					}

					@if $type ==pc {
						font-size: 14px;
					}
				}
			}
		}
	}
}

.section {
	@if $type =="" {}

	@if $type ==sp {}

	@if $type ==pc {
		padding-bottom: 112px;
	}

	@if $type ==tb {
		padding-bottom: 0;
	}
}

.subContent,
.mfp-content {
	@if $type =="" {
		font-feature-settings: "palt";
		word-break: break-all;
	}

	.btnIcon {
		width: 11px;
		height: 11px;
		position: absolute;
		top: calc(50% - 6px);
		left: 15px;
		fill: #707070;
	}


	a {
		@if $type =="" {
			color: $c_blue;
			text-decoration: underline;
		}

		@if $type ==pc {
			&:hover {
				text-decoration: none;
			}
		}

		&.linkArrow {
			@if $type =="" {
				display: inline-block;
				position: relative;
			}

			@if $type ==sp {
				padding-right: 20px;
			}

			@if $type ==pc {
				padding-right: 20px;
			}

			&:after {
				@if $type =="" {
					content: "";
					display: block;
					position: absolute;
					right: 0;
					transform: rotate(-45deg);
				}

				@if $type ==sp {}

				@if $type ==pc {
					width: 8px;
					height: 8px;
					border-right: 2px solid $c_blue;
					border-bottom: 2px solid $c_blue;
					top: 0.55em;
				}
			}
		}
	}

	ul,
	ol {
		@if $type =="" {
			list-style-type: none;
		}
	}

	img {
		@if $type =="" {
			max-width: 100%;
			height: auto;
			height: auto;
			image-rendering: -webkit-optimize-contrast;
		}
	}

	* {
		@if $type =="" {
			box-sizing: border-box;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		@if $type ==sp {
			line-height: 1.5;
		}

		@if $type ==pc {
			line-height: 1.5;
		}
	}

	.tel {
		@if $type ==sp {
			color: $c_base;
			text-decoration: underline;
		}

		@if $type ==pc {
			color: inherit !important;
			text-decoration: none !important;
			pointer-events: none;
		}
	}

	.dInlineBlock {
		@if $type =="" {
			display: inline-block !important;
		}
	}

	sup,
	sub {
		@if $type =="" {
			font-size: .6em;
		}
	}

	@if $type =="" {
		.colorRed {
			color: #A72525;
		}

		.taC {
			text-align: center !important;
		}

		.taL {
			text-align: left !important;
		}

		.taR {
			text-align: right !important;
		}

		.Center {
			margin: 0 auto !important;
		}

		.ovh {
			overflow: hidden !important;
		}

		.mw100 {
			max-width: 100% !important;
			width: auto !important;
		}

		.w100p {
			width: 100% !important;
		}

		.mB0 {
			margin-bottom: 0 !important;
		}

		.mB5 {
			margin-bottom: 5px !important;
		}

		.mB10 {
			margin-bottom: 10px !important;
		}

		.mB20 {
			margin-bottom: 20px !important;
		}

		.mB30 {
			margin-bottom: 30px !important;
		}

		.mB40 {
			margin-bottom: 40px !important;
		}

		.mB50 {
			margin-bottom: 50px !important;
		}

		.mT0 {
			margin-top: 0 !important;
		}

		.mT5 {
			margin-top: 5px !important;
		}

		.mT10 {
			margin-top: 10px !important;
		}

		.mT20 {
			margin-top: 20px !important;
		}

		.mT30 {
			margin-top: 30px !important;
		}

		.mT40 {
			margin-top: 40px !important;
		}

		.mT50 {
			margin-top: 50px !important;
		}

		.mL0 {
			margin-left: 0px !important;
		}

		.mL5 {
			margin-left: 5px !important;
		}

		.mL10 {
			margin-left: 10px !important;
		}

		.mL20 {
			margin-left: 20px !important;
		}

		.mL30 {
			margin-left: 30px !important;
		}

		.mL40 {
			margin-left: 40px !important;
		}

		.mL50 {
			margin-left: 50px !important;
		}

		.mR0 {
			margin-right: 0px !important;
		}

		.mR5 {
			margin-right: 5px !important;
		}

		.mR10 {
			margin-right: 10px !important;
		}

		.mR20 {
			margin-right: 20px !important;
		}

		.mR30 {
			margin-right: 30px !important;
		}

		.mR40 {
			margin-right: 40px !important;
		}

		.mR50 {
			margin-right: 50px !important;
		}

		.w50 {
			width: 50% !important;
		}

		.textKeepAll {
			word-break: keep-all !important;
		}
	}

	@if $type ==sp {
		.mB0 {
			margin-bottom: 0 !important;
		}

		.mB5_sp {
			margin-bottom: 5px !important;
		}

		.mB10_sp {
			margin-bottom: 10px !important;
		}

		.mB20_sp {
			margin-bottom: 20px !important;
		}

		.mB30_sp {
			margin-bottom: 30px !important;
		}

		.mB40_sp {
			margin-bottom: 40px !important;
		}

		.mB50_sp {
			margin-bottom: 50px !important;
		}

		.mT0 {
			margin-top: 0 !important;
		}

		.mT5_sp {
			margin-top: 5px !important;
		}

		.mT10_sp {
			margin-top: 10px !important;
		}

		.mT20_sp {
			margin-top: 20px !important;
		}

		.mT30_sp {
			margin-top: 30px !important;
		}

		.mT40_sp {
			margin-top: 40px !important;
		}

		.mT50_sp {
			margin-top: 50px !important;
		}

		.mL0_sp {
			margin-left: 0px !important;
		}

		.mL5_sp {
			margin-left: 5px !important;
		}

		.mL10_sp {
			margin-left: 10px !important;
		}

		.mL20_sp {
			margin-left: 20px !important;
		}

		.mL30_sp {
			margin-left: 30px !important;
		}

		.mL40_sp {
			margin-left: 40px !important;
		}

		.mL50_sp {
			margin-left: 50px !important;
		}

		.mR0_sp {
			margin-right: 0px !important;
		}

		.mR5_sp {
			margin-right: 5px !important;
		}

		.mR10_sp {
			margin-right: 10px !important;
		}

		.mR20_sp {
			margin-right: 20px !important;
		}

		.mR30_sp {
			margin-right: 30px !important;
		}

		.mR40_sp {
			margin-right: 40px !important;
		}

		.mR50_sp {
			margin-right: 50px !important;
		}

		.size12_sp {
			font-size: 12px !important;
		}

		.size14_sp {
			font-size: 14px !important;
		}

		.size16_sp {
			font-size: 16px !important;
		}

		.size18_sp {
			font-size: 18px !important;
		}

		.size20_sp {
			font-size: 20px !important;
		}

		.w50p_sp {
			display: block;
			width: 50% !important;
			margin: 0 auto;
		}

		.w50 {
			display: block;
			width: 50% !important;
			margin: 0 auto;
		}
	}

	@if $type ==pc {
		.mB0_pc {
			margin-bottom: 0 !important;
		}

		.mB5_pc {
			margin-bottom: 5px !important;
		}

		.mB10_pc {
			margin-bottom: 10px !important;
		}

		.mB20_pc {
			margin-bottom: 20px !important;
		}

		.mB30_pc {
			margin-bottom: 30px !important;
		}

		.mB40_pc {
			margin-bottom: 40px !important;
		}

		.mB50_pc {
			margin-bottom: 50px !important;
		}

		.mT0_pc {
			margin-top: 0 !important;
		}

		.mT5_pc {
			margin-top: 5px !important;
		}

		.mT10_pc {
			margin-top: 10px !important;
		}

		.mT20_pc {
			margin-top: 20px !important;
		}

		.mT30_pc {
			margin-top: 30px !important;
		}

		.mT40_pc {
			margin-top: 40px !important;
		}

		.mT50_pc {
			margin-top: 50px !important;
		}

		.mL0_pc {
			margin-left: 0px !important;
		}

		.mL5_pc {
			margin-left: 5px !important;
		}

		.mL10_pc {
			margin-left: 10px !important;
		}

		.mL20_pc {
			margin-left: 20px !important;
		}

		.mL30_pc {
			margin-left: 30px !important;
		}

		.mL40_pc {
			margin-left: 40px !important;
		}

		.mL50_pc {
			margin-left: 50px !important;
		}

		.mR0_pc {
			margin-right: 0px !important;
		}

		.mR5_pc {
			margin-right: 5px !important;
		}

		.mR10_pc {
			margin-right: 10px !important;
		}

		.mR20_pc {
			margin-right: 20px !important;
		}

		.mR30_pc {
			margin-right: 30px !important;
		}

		.mR40_pc {
			margin-right: 40px !important;
		}

		.mR50_pc {
			margin-right: 50px !important;
		}

		.size12 {
			font-size: 12px !important;
		}

		.size14 {
			font-size: 14px !important;
		}

		.size16 {
			font-size: 16px !important;
		}

		.size18 {
			font-size: 18px !important;
		}

		.size20 {
			font-size: 20px !important;
		}
	}

	*+ul.commonList {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 26px;
		}

		@if $type ==pc {
			margin-top: 40px;
		}
	}

	ul.commonList {
		@if $type =="" {
			line-height: 1.5;
		}

		@if $type ==sp {}

		@if $type ==pc {}

		>li {
			@if $type =="" {
				position: relative;
				box-sizing: border-box;

			}

			@if $type ==sp {
				padding-left: 25px;

				&:not(:first-of-type) {
					margin-top: 15px;
				}
			}

			@if $type ==pc {
				padding-left: 25px;

				&:not(:first-of-type) {
					margin-top: 25px;
				}
			}

			&:before {
				content: "";
				display: inline-block;
				width: 8px;
				height: 8px;
				margin: auto;
				content: '';
				border-radius: 50%;
				background-color: $c_blue;
				position: absolute;
				left: 0;
				top: .55em;
			}
		}
	}

	ol.commonList {
		@if $type =="" {
			counter-reset: number 0;
		}

		>li {
			@if $type =="" {
				position: relative;
			}

			@if $type ==sp {
				padding-left: 25px;

				&:not(:first-of-type) {
					margin-top: 15px;
				}
			}

			@if $type ==pc {
				padding-left: 25px;

				&:not(:first-of-type) {
					margin-top: 25px;
				}
			}

			+li {
				@if $type ==sp {
					margin-top: 7px;
				}

				@if $type ==pc {
					margin-top: 7px;
				}
			}

			&:before {
				@if $type =="" {
					counter-increment: number 1;
					content: counter(number) ".";
					display: flex;
					align-items: center;
					justify-content: center;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
				}

				@if $type ==sp {}

				@if $type ==pc {}
			}
		}
	}

	.modalBody {
		.scrollTable {

			.modTable.thSticky {
				@if $type ==sp {
					min-width: 983px;
				}
			}

			table {
				@if $type ==sp {
					min-width: 983px;
				}

				@if $type ==tb {
					min-width: 983px;
				}
			}
		}
	}

	*+.scrollTable {
		@if $type ==sp {
			margin-top: 12px;
		}

		@if $type ==tb {
			margin-top: 12px;
		}
	}

	.scrollTable {
		@if $type ==sp {
			overflow: auto;
		}

		@if $type ==tb {
			overflow: auto;
		}

		+.commonTable,
		+.sortTable,
		+.modTable {
			@if $type ==sp {
				margin-top: 0;
			}
		}

		.modTable.thSticky {
			@if $type ==sp {
				min-width: 1220px;
			}
		}

		table {
			@if $type ==sp {

				//	min-width: 1220px;
				tr {

					td,
					th {

						&.nowrap {
							white-space: nowrap;
						}
					}
				}
			}

			@if $type ==tb {
				min-width: 1220px;
			}
		}
	}

	table .downBlock {
		@if $type =="" {
			padding-top: 0;
			padding-bottom: 0;
			border-bottom: none;
		}

		@if $type ==sp {
			max-width: calc(100vw - 170px);
			padding-top: 10px;
		}

		@if $type ==pc {
			padding-top: 10px;
		}
	}

	table *+p {
		@if $type ==sp {
			margin-top: 6px;
		}

		@if $type ==pc {
			margin-top: 6px;
		}
	}

	table+table {
		@if $type ==sp {
			margin-top: 30px;
		}

		@if $type ==pc {
			margin-top: 30px;
		}
	}

	.scrolltext {
		@if $type ==sp {
			margin-bottom: 12px;
			font-size: 12px;
			text-align: right;
		}

		@if $type ==tb {
			margin-bottom: 12px;
			font-size: 14px;
			text-align: right;
		}

		@if $type ==pc {
			display: none !important;
		}

		@if $type ==tb {
			display: block !important;
		}
	}

	p+.commonTable {
		@if $type ==sp {
			margin-top: 14px;
		}

		@if $type ==pc {
			margin-top: 14px;
		}
	}

	p+.sortTable {
		@if $type ==sp {
			margin-top: 14px;
		}

		@if $type ==pc {}
	}

	.commonTable+.note {
		@if $type ==sp {
			margin-top: 10px;
		}

		@if $type ==pc {
			margin-top: 10px;
		}
	}

	.commonTable {
		@if $type =="" {
			width: 100%;
			border-right: 1px solid #D2D2D2;
			border-left: 1px solid #D2D2D2;
		}

		@if $type ==sp {
			font-size: 14px;
			border-bottom: 1px solid #D2D2D2;
		}

		@if $type ==pc {}

		@if $type ==tb {
			border-bottom: 1px solid #D2D2D2;
		}

		>thead>tr>th,
		>thead>tr>td,
		>tbody>tr>th,
		>tbody>tr>td,
		>tr>th,
		>tr>td {
			@if $type =="" {
				text-align: left;
			}

			@if $type ==sp {
				padding: 13px 10px;
				border-top: 1px solid #D2D2D2;
			}

			@if $type ==pc {
				padding: 13px 10px;
				border-top: 1px solid #D2D2D2;
				border-bottom: 1px solid #D2D2D2;
			}

			@if $type ==tb {
				&:not(:last-child) {
					border-bottom: none;
				}
			}

		}

		>thead>tr,
		>tbody>tr,
		>tr {
			@if $type ==sp {
				display: flex;
				flex-wrap: wrap;


				>td {
					border-bottom: none;
				}
			}

			@if $type ==pc {
				&.col2 {
					>td {
						width: 38%;
					}
				}
			}

			@if $type ==tb {
				display: flex;
				flex-wrap: wrap;

				>td {
					border-bottom: none;
				}
			}
		}

		>thead>tr>th,
		>tbody>tr>th,
		>tr>th {
			@if $type =="" {
				font-weight: bold;
				background: #F7F6F4;
			}

			@if $type ==sp {
				display: block;
				width: 120px !important;
			}

			@if $type ==pc {
				width: 120px !important;
				font-size: 14px;
			}

			@if $type ==tb {
				display: block;
			}
		}

		>thead>tr>td,
		>tbody>tr>td,
		>tr>td {
			@if $type =="" {}

			@if $type ==sp {
				display: block;
				width: calc(100% - 120px) !important;
			}

			@if $type ==tb {
				display: block;
				width: calc(100% - 120px) !important;
			}
		}
	}

	.sortTable {
		@if $type =="" {
			width: 100%;

			td,
			th {
				&.checkboxOnly {
					.checkboxLabel.textNone {
						margin-right: auto;
						margin-left: auto;
						text-align: center;
					}
				}
			}

			td {

				&.nowrap {
					white-space: nowrap;
				}

			}
		}

		@if $type ==sp {
			font-size: 14px;
			border-bottom: 1px solid #D2D2D2;
		}

		@if $type ==pc {}

		@if $type ==tb {
			border-bottom: 1px solid #D2D2D2;
		}

		>thead>tr>th,
		>thead>tr>td,
		>tbody>tr>th,
		>tbody>tr>td,
		>tr>th,
		>tr>td {
			@if $type =="" {
				text-align: left;
			}

			@if $type ==sp {
				padding: 13px 10px;
				border-top: 1px solid #D2D2D2;
				white-space: nowrap;
			}

			@if $type ==pc {
				padding: 13px 10px;
				border-top: 1px solid #D2D2D2;
				border-bottom: 1px solid #D2D2D2;
			}

			@if $type ==tb {
				&:not(:last-child) {
					border-bottom: none;
				}
			}

		}

		&.tdSmall {

			>thead>tr>td,
			>tbody>tr>td,
			>tr>td {
				font-size: 14px;
			}
		}

		>thead>tr>th,
		>tbody>tr>th,
		>tr>th {
			@if $type =="" {
				font-weight: 400;
				text-align: center;
				line-height: 1.36;

				&.sortNum {
					width: 8.7%;
				}

				&.sortName {
					width: 33.1%;
				}

				&.sortPlace {
					width: 13.6%;
				}

				&.sortBarName {
					width: 27.3%;
				}

				&.sortClass {
					width: 17.1%;
				}
			}

			@if $type ==sp {

				&.sortNum,
				&.sortName,
				&.sortPlace,
				.sortBarName,
				&.sortClass {
					width: auto;
					white-space: nowrap;
				}
			}

			@if $type ==pc {
				font-size: 14px;
			}
		}

		>thead>tr>th:not([scope="col"]),
		>tbody>tr>th:not([scope="col"]),
		>tr>th:not([scope="col"]) {
			@if $type =="" {
				background: #EEEDEC;
				font-weight: bold;

				&.isActive {
					background: #D2D2D2;
				}

				&:not(.isActive):not(.sortNone) {
					color: $c_blue;
					text-decoration: underline;
				}

				&.isActive {
					color: #000;
					text-decoration: none;

					&.isDesc {
						.sortSelect>li {
							&:nth-child(odd) {
								display: none;
							}
						}
					}

					&.isAsc {
						.sortSelect>li {
							&:nth-child(even) {
								display: none;
							}
						}
					}

					.sortSelect {
						>li {
							&:nth-child(odd) {
								border-color: transparent transparent #1560bb transparent !important;
							}

							&:nth-child(even) {
								border-color: #1560bb transparent transparent transparent !important;
							}
						}
					}
				}
			}

			@if $type ==sp {
				padding: 0;
			}

			@if $type ==pc {
				padding: 0;

				&:not(.sortNone) {
					cursor: pointer;
				}
			}

			.sortBtn,
			.sortBtnNone {
				@if $type =="" {}

				@if $type ==sp {
					padding: 20px 10px;
				}

				@if $type ==pc {
					padding: 20px 10px;
				}
			}

			.sortBtn {
				@if $type =="" {
					display: flex;
					align-items: center;
					justify-content: center;
				}

				@if $type ==sp {}

				@if $type ==pc {
					&:not(.isActive) {
						p {
							cursor: pointer;
							transition: .2s opacity;

							&:hover {
								opacity: .7;
							}
						}
					}

					&.isActive {
						.sortSelect {
							>li {
								cursor: pointer;
								transition: .2s opacity;

								&:hover {
									opacity: .7;
								}
							}
						}
					}
				}

				@if $type ==tb {
					height: 100%;
				}

				.sortSelect {
					@if $type =="" {
						display: flex;
						flex-direction: column;
						justify-content: center;
						flex: none;
					}

					@if $type ==sp {
						width: 13px;
						min-height: 23px;
						margin-left: 12px;
					}

					@if $type ==pc {
						width: 13px;
						min-height: 23px;
						margin-left: 12px;
					}

					>li {
						@if $type =="" {
							width: 0;
							height: 0;
							border-style: solid;

							+li {
								margin-top: 3px;
							}
						}

						@if $type ==sp {}

						@if $type ==pc {}

						&:nth-child(odd) {
							@if $type =="" {
								border-color: transparent transparent #737373 transparent;
							}

							@if $type ==sp {
								border-width: 0 6.5px 10px 6.5px;
							}

							@if $type ==pc {
								border-width: 0 6.5px 10px 6.5px;
							}
						}

						&:nth-child(even) {
							@if $type =="" {
								border-color: #737373 transparent transparent transparent;
							}

							@if $type ==sp {
								border-width: 10px 6.5px 0 6.5px;
							}

							@if $type ==pc {
								border-width: 10px 6.5px 0 6.5px;
							}
						}
					}
				}
			}
		}

		>thead>tr:nth-child(odd),
		>tbody>tr:nth-child(odd),
		>tr:nth-child(odd) {
			@if $type =="" {
				background: #fff;
			}

			@if $type ==sp {}

			@if $type ==tb {}
		}

		>thead>tr:nth-child(even),
		>tbody>tr:nth-child(even),
		>tr:nth-child(even) {
			@if $type =="" {
				background: #F9F9F7;
			}

			@if $type ==sp {}

			@if $type ==tb {}
		}



		&.copList {
			@if $type ==sp {
				tr {

					&.sortSelectWrap {

						th {
							//	width: 20%;

							//	&:nth-of-type(1){
							//		width: 10%;
							//	}

							//	&:nth-of-type(2){
							//		width: 50%;
							//	}
						}
					}

					&.subTr {

						td {
							background-color: #F9F9F7;

							&.flexCell {
								display: flex;
								align-items: center;
							}

							span {

								&.tag {
									font-size: 14px;
									background-color: #D2D2D2;
									border-radius: 20px;
									color: #000;
									padding: 3px 5px;
									margin-right: 10px;
									white-space: nowrap;
								}
							}
						}
					}
				}

				td {
					background-color: #fff;

					&.tac {
						text-align: center;
					}

					&.num {
						vertical-align: top;
					}
				}
			}

			@if $type ==pc {
				border: 1px solid #D2D2D2;

				tr {

					&.sortSelectWrap {

						th {
							width: 20%;

							&:nth-of-type(1) {
								width: 10%;
							}

							&:nth-of-type(2) {
								width: 50%;
							}
						}
					}

					&.subTr {

						td {
							background-color: #F9F9F7;
							border-top: none;
							border-bottom: none;

							&.flexCell {
								display: flex;
								align-items: center;
							}

							span {

								&.tag {
									font-size: 14px;
									background-color: #D2D2D2;
									border-radius: 20px;
									color: #000;
									padding: 3px 5px;
									margin-right: 10px;
									white-space: nowrap;
								}
							}
						}

						&+.subTr {

							td {
								border-top: 1px solid #D2D2D2;
							}
						}
					}
				}

				td {
					background-color: #fff;

					&.nowrap {
						white-space: nowrap;
					}

					&.tac {
						text-align: center;
					}

					&.num {
						vertical-align: top;
					}
				}
			}
		}
	}

	table {
		.width3Percent {
			@if $type ==sp {
				width: 3% !important;
			}

			@if $type ==pc {
				width: 3% !important;
			}
		}


		.width4Percent {
			@if $type ==sp {
				width: 4% !important;
			}

			@if $type ==pc {
				width: 4% !important;
			}
		}

		.width5Percent {
			@if $type ==sp {
				width: 5% !important;
			}

			@if $type ==pc {
				width: 5% !important;
			}
		}

		.width6Percent {
			@if $type ==sp {
				width: 6% !important;
			}

			@if $type ==pc {
				width: 6% !important;
			}
		}

		.width7Percent {
			@if $type ==sp {
				width: 7% !important;
			}

			@if $type ==pc {
				width: 7% !important;
			}
		}

		.width8Percent {
			@if $type ==sp {
				width: 8% !important;
			}

			@if $type ==pc {
				width: 8% !important;
			}
		}

		.width9Percent {
			@if $type ==sp {
				width: 9% !important;
			}

			@if $type ==pc {
				width: 9% !important;
			}
		}

		.width10Percent {
			@if $type ==sp {
				width: 10% !important;
			}

			@if $type ==pc {
				width: 10% !important;
			}
		}

		.width11Percent {
			@if $type ==sp {
				width: 11% !important;
			}

			@if $type ==pc {
				width: 11% !important;
			}
		}

		.width12Percent {
			@if $type ==sp {
				width: 12% !important;
			}

			@if $type ==pc {
				width: 12% !important;
			}
		}

		.width13Percent {
			@if $type ==sp {
				width: 13% !important;
			}

			@if $type ==pc {
				width: 13% !important;
			}
		}

		.width14Percent {
			@if $type ==sp {
				width: 14% !important;
			}

			@if $type ==pc {
				width: 14% !important;
			}
		}


		.width16Percent {
			@if $type ==sp {
				width: 16% !important;
			}

			@if $type ==pc {
				width: 16% !important;
			}
		}

		.width18Percent {
			@if $type ==sp {
				width: 18% !important;
			}

			@if $type ==pc {
				width: 18% !important;
			}
		}

		.width20Percent {
			@if $type ==sp {
				width: 20% !important;
			}

			@if $type ==pc {
				width: 20% !important;
			}
		}

		.width130 {
			@if $type ==sp {
				width: 130px !important;
				box-sizing: border-box;
			}

			@if $type ==pc {
				width: 130px !important;
				box-sizing: border-box;
			}
		}
	}

	.separateTable {
		@if $type =="" {
			width: 100%;

		}

		@if $type ==sp {
			font-size: 14px;
			border-bottom: 1px solid #D2D2D2;
		}

		@if $type ==pc {}

		@if $type ==tb {
			border-bottom: 1px solid #D2D2D2;
		}

		>thead>tr>th,
		>thead>tr>td,
		>tbody>tr>th,
		>tbody>tr>td,
		>tr>th,
		>tr>td {
			@if $type =="" {
				text-align: left;
			}

			@if $type ==sp {
				padding: 13px 10px;
				text-align: center;
			}

			@if $type ==pc {
				padding: 13px 10px;
				text-align: center;
			}

			@if $type ==tb {
				text-align: center;
			}

		}

		>thead>tr>th,
		>tbody>tr>th,
		>tr>th {
			@if $type =="" {
				font-weight: 400;
			}

			@if $type ==sp {}

			@if $type ==pc {
				font-size: 14px;
			}
		}



		>thead>tr:nth-child(odd),
		>tbody>tr:nth-child(odd),
		>tr:nth-child(odd) {
			@if $type =="" {
				background: #F7F6F4;
			}

			@if $type ==sp {}

			@if $type ==tb {}
		}

		>thead>tr:nth-child(even),
		>tbody>tr:nth-child(even),
		>tr:nth-child(even) {
			@if $type =="" {
				background: #EEEDEC;
			}

			@if $type ==sp {}

			@if $type ==tb {}
		}
	}

	*+.listBox {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 12px;
		}

		@if $type ==pc {
			margin-top: 12px;
		}
	}

	.listBox {
		@if $type =="" {
			border: 4px solid #E6E6E6;
		}

		@if $type ==sp {}

		@if $type ==pc {}

		>div {
			@if $type ==sp {
				display: flex;
				flex-wrap: wrap;
				font-size: 14px;

				>dt,
				>dd {
					padding-top: 10px;
					padding-bottom: 10px;
				}

				>dt {
					width: 120px;
					margin-left: 15px;
					font-weight: 700;
				}

				>dd {
					width: calc(100% - 150px);
				}
			}
		}

		>div:not(:last-child) {

			>dt {
				@if $type =="" {
					border-bottom: 1px solid #D2D2D2;
				}

				@if $type ==pc {}
			}

			>dd {
				@if $type =="" {
					border-bottom: 1px solid #D2D2D2;
				}

				@if $type ==pc {}
			}
		}

		>div:not(.col3) {
			@if $type =="" {}

			@if $type ==sp {}

			@if $type ==pc {
				display: flex;
			}

			>dt,
			>dd {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {
					padding: 24px 0;
				}
			}

			>dt {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {
					width: 90px;
					margin-left: 30px;
					font-weight: 700;
				}
			}

			>dd {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {
					width: calc(100% - 150px);
				}
			}
		}

		>div.col3 {
			@if $type =="" {
				width: 100%;
			}

			@if $type ==sp {}

			@if $type ==pc {
				display: flex;
			}

			>dt,
			>dd {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {
					padding: 24px 0;
				}
			}

			>*:last-child {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {
					margin-right: 30px;
				}
			}

			>dt {
				@if $type =="" {}

				@if $type ==sp {
					font-weight: 700;
				}

				@if $type ==pc {
					width: 90px;
					margin-left: 30px;
					flex: none;
					font-weight: 700;
				}
			}

			>dd {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {
					width: calc(100% - 90px);
				}
			}
		}


		>.listBlue:not(.col3) {
			@if $type =="" {
				color: $c_blue;
				background: #E6EFFD;
			}

			@if $type ==sp {
				margin-top: 10px;
			}

			@if $type ==pc {
				margin-top: 24px;
			}

			>dt,
			>dd {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {
					padding-top: 10px;
					padding-bottom: 10px;
				}
			}
		}
	}

	*+.note {
		@if $type ==sp {
			margin-top: 11px;
		}

		@if $type ==pc {
			margin-top: 11px;
		}
	}

	.note {
		@if $type =="" {
			text-align: right;
		}

		@if $type ==sp {
			font-size: 13px;
		}

		@if $type ==pc {
			font-size: 13px;
		}
	}

	*+.noteSub {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 0px;
		}

		@if $type ==pc {
			margin-top: 0px;
		}
	}

	.noteSub {
		@if $type =="" {}

		@if $type ==sp {
			font-size: 12px;
		}

		@if $type ==pc {
			font-size: 12px;
		}
	}

	*+p {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 25px;
		}

		@if $type ==pc {
			margin-top: 42px;
		}
	}

	.titleSub {
		@if $type =="" {
			font-weight: bold;
		}
	}

	*+.titleCenter {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 30px;
		}

		@if $type ==pc {
			margin-top: 60px;
		}
	}

	.titleCenter {
		@if $type =="" {
			text-align: center;
		}

		@if $type ==sp {
			font-size: 20px;
		}

		@if $type ==pc {
			font-size: 25px;
		}
	}

	*+.dataNote {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 4px;
		}

		@if $type ==pc {
			margin-top: 4px;
		}
	}

	.dataNote {
		@if $type =="" {}

		@if $type ==sp {}

		@if $type ==pc {}

		span {
			@if $type =="" {}

			@if $type ==sp {
				font-size: 18px;
			}

			@if $type ==pc {
				font-size: 20px;
			}
		}
	}

	*+.colorBlock {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 29px;
		}

		@if $type ==pc {
			margin-top: 44px;
		}
	}

	.colorBlock {
		@if $type =="" {
			background: #F7F6F4;
		}

		@if $type ==sp {
			padding: 27px 15px 27px;
		}

		@if $type ==pc {
			padding: 44px 20px 49px;
		}

		.colorBlockInner {
			@if $type =="" {}

			@if $type ==sp {}

			@if $type ==pc {
				max-width: 930px;
				margin: 0 auto;
			}
		}
	}

	.colorBlockTitle {
		@if $type =="" {
			text-align: center;
		}

		@if $type ==sp {
			margin-bottom: 22px;
			font-size: 18px;
		}

		@if $type ==pc {
			margin-bottom: 33px;
			font-size: 22px;
		}
	}

	.colorBox {
		@if $type =="" {
			background: #F7F6F4;
		}

		@if $type ==sp {}

		@if $type ==pc {}
	}

	.colorBoxHeader {
		@if $type =="" {}

		@if $type ==sp {
			padding: 20px 20px 10px;
		}

		@if $type ==pc {
			padding: 20px 30px;
		}
	}

	.colorBoxBody {
		@if $type =="" {}

		@if $type ==sp {
			padding: 10px 25px 20px;
		}

		@if $type ==pc {
			padding: 14px 57px 54px;
		}
	}

	.colorBoxTitle {
		@if $type =="" {
			color: #000000;
		}

		@if $type ==sp {}

		@if $type ==pc {}
	}

	*:not(.commonDl)+.postContent {
		@if $type =="" {
			border-top: 1px solid #D2D2D2;
		}

		@if $type ==sp {}

		@if $type ==pc {}
	}

	.postContent {
		@if $type =="" {
			border-left: 1px solid #D2D2D2;
			border-right: 1px solid #D2D2D2;
			border-bottom: 1px solid #D2D2D2;
		}

		@if $type ==sp {
			padding: 20px;
		}

		@if $type ==pc {
			padding: 40px;
		}

		*+p {
			@if $type =="" {}

			@if $type ==sp {
				margin-top: 15px;
			}

			@if $type ==pc {
				margin-top: 27px;
			}
		}

	}

	.searchMenu {
		@if $type =="" {}

		@if $type ==sp {}

		@if $type ==pc {
			margin-top: 36px;
		}
	}

	.formList+.btnWrap {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 0;
		}

		@if $type ==pc {}
	}

	*+.btnWrap {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 28px;
		}

		@if $type ==pc {
			margin-top: 43px;
		}
	}

	.btnWrap {
		@if $type =="" {}

		@if $type ==sp {
			text-align: center;

			&#searchCtrlBtns {
				display: flex;
				flex-direction: column;

				.btnClearForm {
					order: 2;
					margin-top: 25px;
					max-width: 260px;
				}

				#buttonSearch {
					order: 1;
				}
			}
		}

		@if $type ==pc {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			&.btnR {
				justify-content: flex-end;
			}

			&.row {
				flex-direction: column;

				> * {
					margin-left: 0;

					+ * {
						margin-top: 10px;
					}
				}
			}
		}

		>* {
			@if $type =="" {}

			@if $type ==sp {
				&+* {
					margin-left: 10px;
				}
			}

			@if $type ==pc {
				&+* {
					margin-left: 15px;
				}
			}
		}

	}

	.btn {
		@if $type =="" {
			display: inline-block;
			border: none;
			background: none;
			border: 1px solid transparent;
			border-radius: 4px;
			text-decoration: none;
			position: relative;
		}

		@if $type ==sp {
			width: 100%;
			padding: 7px 11px;
			margin: 0 auto;

			+.btn {
				margin-top: 15px;
			}
		}

		@if $type ==pc {
			padding: 10px 16px;
			cursor: pointer;
			transition: .5s;

			&:hover {
				opacity: .7;
			}
		}

		&:has(> .btnIcon) {
			@if $type =="" {
			}
			@if $type ==sp {
			}
			@if $type ==pc {
				padding-left: 40px;
			}
		}

		&.btnWhite {
			@if $type =="" {
				color: $c_gray;
				border-color: $c_gray;
				background: #fff;
			}

			@if $type ==sp {}

			@if $type ==pc {}
		}

		&.btnWhiteBorder {
			@if $type =="" {
				color: $c_blue;
				border-color: $c_blue;
				background: #fff;
			}

			@if $type ==sp {}

			@if $type ==pc {}
		}

		&.btnBlue {
			@if $type =="" {
				color: #fff;
				border-color: $c_blue;
				background: $c_blue;
			}

			@if $type ==sp {}

			@if $type ==pc {
				min-width: inherit;
			}
		}

		&.btnGreen {
			@if $type =="" {
				color: #fff;
				border-color: $green;
				background: $green;
			}

			@if $type ==sp {}

			@if $type ==pc {}

			&.btnWhiteBorder {
				@if $type =="" {
					color: $green;
					border-color: $green;
					background: #fff;
				}

				@if $type ==sp {}

				@if $type ==pc {}
			}

			&.btnBack {
				@if $type =="" {
					&:before {
						@if $type =="" {
							border-left: 1px solid $green;
							border-top: 1px solid $green;
						}
					}
				}

				@if $type ==sp {}

				@if $type ==pc {}
			}

			&.btnDownload {
				@if $type =="" {
					color: #000;
					border-color: #B8B8B8;
				}

				@if $type ==sp {}

				@if $type ==pc {}

				&:before {
					@if $type =="" {
						content: "";
						display: inline-block;
						border: none;
						background-image: url(../images/contact_sub/icon_download.svg);
						background-repeat: no-repeat;
						background-size: contain;
						transform: rotate(0);
					}

					@if $type ==sp {
						width: 15px;
						height: 15px;
						margin-right: 13px;
					}

					@if $type ==pc {
						width: 15px;
						height: 15px;
						margin-right: 13px;
					}
				}
			}

			.iconTrash {
				@if $type =="" {
					background-image: url(../images/contact_sub/icon_trash_green.svg);
				}

				@if $type ==sp {}

				@if $type ==pc {}
			}
		}

		&:not(.btnS):not(.btnSS):not(.btnMini):not(.btnFull) {
			@if $type =="" {}

			@if $type ==sp {
				max-width: 100%;
				min-width: 260px;
				height: 50px;
			}

			@if $type ==pc {
				min-width: 220px;
			}
		}

		&.btnFull {
			@if $type =="" {}

			@if $type ==sp {
				min-width: 100%;
			}

			@if $type ==pc {
				min-width: 100%;
			}
		}

		&.btnS {
			@if $type =="" {}

			@if $type ==sp {
				display: inline-block;
				padding-top: 7px;
				padding-bottom: 7px;
			}

			@if $type ==pc {
				min-width: 147px;
				padding-top: 7px;
				padding-bottom: 7px;
			}
		}

		&.btnSS {
			@if $type =="" {}

			@if $type ==sp {
				display: inline-block;
				padding-top: 7px;
				padding-bottom: 7px;
			}

			@if $type ==pc {
				min-width: 68px;
				padding: 1px 8px;
			}
		}

		&.btnMini {
			@if $type =="" {}

			@if $type ==sp {
				display: inline-block;
				padding-top: 7px;
				padding-bottom: 7px;
			}

			@if $type ==pc {
				min-width: 68px;
				padding-top: 7px;
				padding-bottom: 7px;
			}
		}


		&.btnDelete {
			@if $type =="" {
				color: #707070 !important;
				border-color: #707070 !important;
				position: relative;
			}

			@if $type ==sp {}

			@if $type ==pc {
				padding-left: 25px;
			}

			&:before,
			&:after {
				@if $type =="" {
					content: "";
					background: #707070;
					position: absolute;
					top: 0;
					bottom: 0;
				}

				@if $type ==sp {}

				@if $type ==pc {
					width: 13px;
					height: 2px;
					left: 9px;
					top: 0;
					bottom: 0;
					margin: auto;
				}
			}

			&:before {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {
					transform: rotate(-45deg);
				}
			}

			&:after {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {
					transform: rotate(45deg);
				}
			}
		}


		&.btnBack {
			&:before {
				@if $type =="" {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto;
					transform: rotate(-45deg);
					border-left: 1px solid $c_gray;
					border-top: 1px solid $c_gray;
				}

				@if $type ==sp {
					width: 7px;
					height: 7px;
					left: 20px;
				}

				@if $type ==pc {
					width: 7px;
					height: 7px;
					left: 20px;
				}
			}
		}

		&.btnClose {
			@if $type =="" {
				text-align: center;
			}

			@if $type ==sp {
				max-width: 200px;
				padding-right: 40px;
				padding-left: 40px;
			}

			@if $type ==pc {
				min-width: 200px;
				font-size: 16px;
			}

			&:before {
				@if $type =="" {
					content: "×";
					height: 1em;
					line-height: 1;
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto;
				}

				@if $type ==sp {
					left: 15px;
				}

				@if $type ==pc {
					left: 20px;
				}
			}
		}

		&.btnDown {
			@if $type =="" {
				text-align: center;

				&.isOpen {
					&:before {
						transform: rotate(-135deg) translateY(-2px) translateX(-3px);
					}

					&:after {
						opacity: 1;
					}
				}
			}

			@if $type ==sp {
				padding-right: 33px;
			}

			@if $type ==pc {
				padding-right: 33px;
			}

			&:before {
				@if $type =="" {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto;
					transform: rotate(45deg) translateY(-3px);
					transition: .2s transform;
				}

				@if $type ==sp {
					width: 8px;
					height: 8px;
					border-right: 1px solid $c_blue;
					border-bottom: 1px solid $c_blue;
					right: 16px;
				}

				@if $type ==pc {
					width: 8px;
					height: 8px;
					border-right: 1px solid $c_blue;
					border-bottom: 1px solid $c_blue;
					right: 16px;
				}
			}

			&:after {
				@if $type =="" {
					content: "閉じる";
					display: block;
					height: 100%;
					text-align: center;
					background: #fff;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					margin: auto;
					box-sizing: border-box;
					opacity: 0;
					pointer-events: none;
				}

				@if $type ==sp {
					padding-top: 7px;
					padding-bottom: 7px;
					padding-left: 11px;
					border-radius: 4px 0 0 4px;
					width: calc(100% - 33px);
				}

				@if $type ==pc {
					padding-top: 7px;
					padding-bottom: 7px;
					padding-left: 16px;
					border-radius: 4px 0 0 4px;
					width: calc(100% - 33px);
				}

			}

			&.btnGreen {
				@if $type =="" {
					color: #000000;
					border-color: #B8B8B8;

					&.isOpen {
						&:before {
							transform: rotate(-180deg);
						}
					}
				}

				@if $type ==sp {}

				@if $type ==pc {
					min-width: 175px;
				}

				&:before {
					@if $type =="" {
						width: 0;
						height: 0;
						border-style: solid;
						border-color: #088E98 transparent transparent transparent;
						pointer-events: none;
						transform: rotate(0);
					}

					@if $type ==sp {
						border-width: 7px 6.5px 0 6.5px;
					}

					@if $type ==pc {
						border-width: 7px 6.5px 0 6.5px;
					}
				}
			}
		}
	}

	.tag {
		@if $type =="" {
			display: inline-block;
			color: #737373;
			border: 1px solid #B8B8B8;
		}

		@if $type ==sp {
			padding: 5px 16px;
		}

		@if $type ==pc {
			padding: 5px 16px;
		}
	}

	[class^="icon"],
	.modalLink:before {
		@if $type =="" {
			display: inline-block;
			margin-right: 7px;
			vertical-align: middle;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}

		@if $type ==sp {}

		@if $type ==pc {}
	}

	.iconPdf {
		@if $type =="" {
			background-image: url(../images/contact_sub/icon_pdf01.gif);
		}

		@if $type ==sp {
			width: 15px;
			height: 16px;
		}

		@if $type ==pc {
			width: 15px;
			height: 16px;
		}
	}

	.iconAttention {
		@if $type =="" {
			background-image: url(../images/contact_sub/icon_attention_red.png);
		}

		@if $type ==sp {
			width: 18px;
			height: 18px;
		}

		@if $type ==pc {
			width: 18px;
			height: 18px;
		}
	}

	.iconDownload {
		@if $type =="" {
			background-image: url(../images/contact_sub/icon_download.png);
		}

		@if $type ==sp {
			width: 13px;
			height: 13px;
			margin-right: 13px;
		}

		@if $type ==pc {
			width: 13px;
			height: 13px;
			margin-right: 13px;
		}
	}

	.modalLink {
		@if $type =="" {
			display: inline-block;
			position: relative;
		}

		@if $type ==sp {}

		@if $type ==pc {}

		&:before {
			@if $type =="" {
				content: "";
				background-image: url(../images/contact_sub/icon_info.png);
			}

			@if $type ==sp {
				width: 18px;
				height: 18px;
			}

			@if $type ==pc {
				width: 18px;
				height: 18px;
			}
		}
	}

	.searchMenu {
		ul {
			@if $type =="" {
				display: flex;
				border-bottom: 2px solid $c_blue;
			}

			@if $type ==sp {
				justify-content: space-between;
			}

			@if $type ==pc {}

			>li {
				@if $type =="" {
					position: relative;

					&:before {
						content: "";
						width: 100%;
						height: 100%;
						border-width: 1px 1px 0 1px;
						border-style: solid;
						border-color: $c_gray;
						position: absolute;
						bottom: 0;
						left: 0;
						box-sizing: border-box;
						pointer-events: none;
						transition: .2s border, .2s opacity;
					}

					&.isActive {
						color: $c_blue;

						&:before {
							border-width: 2px 2px 0 2px;
							border-color: $c_blue;
						}

						&:after {
							content: "";
							width: calc(100% - 4px);
							height: 2px;
							background: #fff;
							position: absolute;
							bottom: -2px;
							left: 2px;
							box-sizing: border-box;
						}

						>*:before {
							border-color: $c_blue;
						}
					}

					&.searchMenuDisable {
						pointer-events: none;

						&:before {
							z-index: 1;
							border-color: #B8B8B8;
						}

						>* {
							color: #B8B8B8;
							background: #E6E6E6;

							&:before {
								border-color: #B8B8B8;
							}
						}
					}
				}

				@if $type ==sp {
					width: calc(50% - 5px);
					min-height: 36px;

					+li {
						margin-left: 10px;
					}
				}

				@if $type ==pc {
					min-width: 243px;
					min-height: 48px;

					+li {
						margin-left: 10px;
					}

					&:hover {
						&:not(.isActive):before {
							opacity: .7;
						}
					}

				}

				>* {
					@if $type =="" {
						display: flex;
						align-items: center;
						justify-content: center;
						height: 100%;
						position: relative;
					}

					@if $type ==sp {
						padding-right: 25px;
						padding-left: 25px;
						font-size: 14px;
						line-height: 1.4;
					}

					@if $type ==pc {
						font-size: 18px;
					}

					&:before {
						@if $type =="" {
							content: "";
							display: block;
							border-style: solid;
							border-color: $c_gray;
							border-width: 2px 2px 0 0;
							transform: rotate(135deg);
							position: absolute;
							top: 0;
							bottom: 0;
							margin: auto;
						}

						@if $type ==sp {
							width: 6px;
							height: 6px;
							right: 10px;
						}

						@if $type ==pc {
							width: 8px;
							height: 8px;
							right: 14px;
						}
					}
				}

				>a {
					@if $type =="" {
						color: $c_gray;
						text-decoration: none;
						cursor: pointer;
					}

					@if $type ==sp {}

					@if $type ==pc {
						transition: .2s opacity;

						&:hover {
							opacity: .7;
						}
					}
				}
			}

		}
	}

	.attachedList {
		@if $type =="" {}

		@if $type ==sp {}

		@if $type ==pc {}

		>li {
			@if $type =="" {}

			@if $type ==sp {
				+li {
					margin-top: 5px;
				}
			}

			@if $type ==pc {
				+li {
					margin-top: 10px;
				}
			}

			>a {
				@if $type =="" {
					display: inline-block;
					position: relative;
				}

				@if $type ==sp {
					padding-left: 25px;
				}

				@if $type ==pc {
					padding-left: 25px;
				}

				&[href$=pdf],
				&[href$=xls],
				&[href$=xlsx],
				&[href$=doc],
				&[href$=docx],
				&[href$=ppt],
				&[href$=pptx],
				&[href$=txt],
				&[href$=csv],
				&[href$=zip],
				&[href$=jpg],
				&[href$=bmp],
				&[href$=png],
				&[href$=gif],
				&[href$=tif],
				&[href$=svg],
				&[href$=mov],
				&[href$=mp4],
				&[href$=mp3],
				&[href$=wmv] {
					&:after {
						@if $type =="" {
							content: "";
							display: block;
							background-position: center;
							background-size: contain;
							background-repeat: no-repeat;
							position: absolute;
							margin: auto;
						}

						@if $type ==sp {
							width: 18px;
							height: 18px;
							top: 0.2em;
							left: 0;
						}

						@if $type ==pc {
							width: 18px;
							height: 18px;
							top: 0.2em;
							left: 0;
						}
					}
				}

				&[href$=pdf]:after {
					@if $type =="" {
						background-image: url(../images/contact_sub/icon_pdf01.gif);
					}
				}

				&[href$=xls]:after,
				&[href$=xlsx]:after {
					@if $type =="" {
						background-image: url(../images/contact_sub/icon_excel.gif);
					}
				}

				&[href$=doc]:after,
				&[href$=docx]:after {
					@if $type =="" {
						background-image: url(../images/contact_sub/icon_word.gif);
					}
				}

				&[href$=ppt]:after,
				&[href$=pptx]:after {
					@if $type =="" {
						background-image: url(../images/contact_sub/icon_ppt.png);
					}
				}

				&[href$=txt]:after,
				&[href$=csv]:after,
				&[href$=zip]:after {
					@if $type =="" {
						background-image: url(../images/contact_sub/icon_txt.png);
					}
				}

				&[href$=jpg]:after,
				&[href$=png]:after,
				&[href$=gif]:after,
				&[href$=tif]:after,
				&[href$=bmp]:after,
				&[href$=svg]:after {
					@if $type =="" {
						background-image: url(../images/contact_sub/icon_img.png);
					}
				}

				&[href$=mov]:after,
				&[href$=mp4]:after,
				&[href$=mp3]:after,
				&[href$=wmv]:after {
					@if $type =="" {
						background-image: url(../images/contact_sub/icon_mov.png);
					}
				}
			}
		}
	}

	.colorGreen {

		.scrollTable {
			.sortTable {
				@if $type ==sp {
					width: 1000px !important;

					tr {

						td,
						th {
							white-space: initial;
						}
					}
				}
			}
		}

		.btnWrap {
			@if $type =="" {
				.btn.btnWhite {
					border-color: #B8B8B8;
				}
			}

			@if $type ==sp {
				display: flex;
				flex-direction: column;
			}

			@if $type ==pc {}
		}

		.hoverAlpha {
			@if $type =="" {}

			@if $type ==sp {}

			@if $type ==pc {
				transition: .2s opacity;

				&:hover {
					opacity: .8;
				}
			}
		}

		*+.partsSubText {
			@if $type =="" {}

			@if $type ==sp {
				margin-top: 10px;
			}

			@if $type ==pc {
				margin-top: 10px;
			}
		}

		.partsSubText {
			@if $type =="" {
				text-align: right;
			}

			@if $type ==sp {}

			@if $type ==pc {}

			>a {
				@if $type =="" {}

				@if $type ==sp {
					display: inline-block;
				}

				@if $type ==pc {
					margin-left: 24px;
				}

				+a {
					@if $type =="" {}

					@if $type ==sp {
						margin-left: 15px;
					}

					@if $type ==pc {
						margin-left: 24px;
					}

				}
			}
		}

		.searchMenu {
			@if $type =="" {
				position: relative;

				>ul {
					line-height: 1.19;
					border-bottom: 2px solid $green;

					>li {
						&.isActive {
							color: $green;

							&:before {
								border-color: $green;
							}

							>*:before {
								border-color: $green;
							}
						}
					}
				}

			}

			@if $type ==sp {
				>ul {
					flex-direction: column;
					border-bottom: 0;

					>li {
						display: flex;
						align-items: center;
						width: 100%;
						margin-left: 0;

						+li {
							margin-top: 8px;
						}

						>* {
							width: 100%;
							min-height: 42px;
							line-height: 1.19;
						}

						&:not(.isActive) {
							&:before {
								border-width: 1px;
							}

							>a {
								padding-right: 14px;
								text-align: center;
							}
						}

						&.isActive {
							&:before {
								border-width: 2px;
							}

							>a {
								padding-right: 14px;
								text-align: center;
							}
						}
					}
				}

				>.searchMenuBtnArea {
					margin-top: 12px;
				}
			}

			@if $type ==pc {
				display: flex;
				//overflow: auto;

				>ul {
					width: 76.23%;
					//min-width: 1220px;

					>li {
						flex: 1;
						min-width: inherit;
						min-height: 52px;

						>* {
							padding: 4px 34px 4px 14px !important;
							font-size: 16px;
							text-align: center;
						}

						&:not(.isActive) {
							>a {
								padding-right: 14px;
								text-align: center;
							}
						}
					}
				}

				&.notBtn {
					display: block;

					>ul {
						width: 100%;
						padding-right: calc(100% - 76.23%);
					}
				}

				&.notBtnFull {
					display: block;

					>ul {
						width: 100%;
						padding-right: 0;
					}
				}

				>.searchMenuBtnArea {
					width: calc(100% - 76.23%);
					border-bottom: 2px solid $green;

					.btnWrap {
						justify-content: flex-end;
						margin-top: 0;
					}
				}

			}

			@if $type ==tb {
				flex-direction: column;

				>ul {
					width: 100%;

					>li {
						>* {
							br {
								display: none;
							}
						}
					}
				}

				&.notBtn {
					>ul {
						padding-right: 0;
					}
				}


				>.searchMenuBtnArea {
					width: inherit;
					margin-top: 20px;
					border-bottom: none;
				}
			}
		}

		.btn.btnWhite {
			@if $type =="" {
				color: #000000;
			}

			@if $type ==sp {}

			@if $type ==pc {}
		}

		.sortTable {

			>thead>tr:nth-child(even),
			>tbody>tr:nth-child(even),
			>tr:nth-child(even) {
				@if $type =="" {
					background: #fff;
				}
			}
		}

		.sortTable {

			>thead>tr>th:not([scope="col"]),
			>tbody>tr>th:not([scope="col"]),
			>tr>th:not([scope="col"]) {
				@if $type =="" {

					&.isActive {}
				}
			}

			>tbody {
				>tr {
					>th:not([scope="col"]) {
						&.isAsc {
							.sortBtn {
								.sortSelect {
									>li {
										&:nth-child(odd) {
											border-color: transparent transparent $green transparent !important;
										}
									}
								}
							}
						}

						&.isDesc {
							.sortBtn {
								.sortSelect {
									>li {
										&:nth-child(even) {
											border-color: $green transparent transparent transparent !important;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		*+.flexItem {
			@if $type =="" {}

			@if $type ==sp {
				margin-top: 43px;
			}

			@if $type ==pc {
				margin-top: 65px;
			}
		}

		.flexItem {
			@if $type =="" {}

			@if $type ==sp {
				>*+.btnWrap {
					margin-top: 15px;
				}
			}

			@if $type ==pc {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;

				&.flexStart {
					justify-content: flex-start;
				}

			}

			@if $type ==tb {
				flex-direction: column;
				align-items: flex-start;
			}


			>*+* {
				@if $type =="" {
					margin-top: 0;
				}

				@if $type ==sp {}

				@if $type ==pc {
					margin-left: 20px;
				}

				@if $type ==tb {
					margin-top: 8px;
					margin-left: 0;
				}
			}

			>.infoList {
				@if $type =="" {}

				@if $type ==sp {
					flex-direction: column;
					align-items: flex-end;
				}

				@if $type ==pc {
					margin-left: auto;
					margin-top: 0;
				}

				@if $type ==tb {
					flex-direction: column;
					align-items: flex-end;
				}

				>li {
					@if $type =="" {}

					@if $type ==sp {
						+li {
							margin-top: 8px;
						}
					}

					@if $type ==pc {
						margin-top: 8px;
					}

				}

			}

			+.commonDl {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {
					margin-top: 22px;
				}
			}
		}

		*+.separateContent {
			@if $type =="" {}

			@if $type ==sp {
				margin-top: 15px;
			}

			@if $type ==pc {
				margin-top: 27px;
			}
		}

		.separateContent {
			@if $type =="" {
				border-top: 1px solid #D2D2D2;
			}

			@if $type ==sp {
				padding-top: 15px;
			}

			@if $type ==pc {
				padding-top: 15px;
			}
		}

		.inputWrap {
			@if $type =="" {
				display: flex;
				align-items: center;
			}

			@if $type ==sp {
				width: 100%;
				flex-wrap: wrap;
			}

			@if $type ==pc {}

			.inputSearchBtnWrap {
				@if $type =="" {
					display: flex;
					position: relative;
				}

				@if $type ==sp {
					width: 100%;
					margin-top: 4px;
				}

				@if $type ==pc {
					position: relative;
					margin-left: 10px;
				}

				.inputSearchInput {
					@if $type =="" {
						min-height: 42px;
						border: 1px solid #B8B8B8;
					}

					@if $type ==sp {
						width: calc(100% - 147px);
						padding: 7px 8px 7px 9px;
						border-radius: 4px;
					}

					@if $type ==pc {
						min-width: 324px;
						padding: 7px 60px 7px 9px;
						font-size: 14px;
						border-radius: 4px;
					}
				}

				>label {
					@if $type =="" {
						display: flex;
						align-items: center;
						justify-content: center;
						color: #fff;
						background: $green;
						cursor: pointer;

						&.btnLift {
							span:before {
								background: url(../images/contact_sub/icon_reload_white.svg) no-repeat;
								background-size: contain;
							}
						}
					}

					@if $type ==sp {
						width: 140px;
						padding: 8px 4px;
						margin-left: 7px;
						border-radius: 4px;

						&.btnLift {
							span:before {
								width: 14px;
								height: 14px;
							}
						}

					}

					@if $type ==pc {
						width: 126px;
						padding: 8px 4px;
						margin-left: 7px;
						border-radius: 4px;
						transition: .3s opacity;

						&.btnLift {
							span:before {
								width: 14px;
								height: 14px;
							}
						}

						&:hover {
							opacity: .7;
						}
					}
				}

				.inputSearchBtn {
					@if $type =="" {
						display: none;
					}

					@if $type ==sp {}

					@if $type ==pc {}

					+span {
						@if $type =="" {
							display: flex;
							align-items: center;
							justify-content: center;
						}

						@if $type ==sp {
							font-size: 16px;
						}

						@if $type ==pc {
							font-size: 14px;
						}

						&:before {
							@if $type =="" {
								content: "";
								display: block;
								background: url(../images/contact_sub/icon_search.svg) no-repeat;
								background-size: contain;
							}

							@if $type ==sp {
								width: 15px;
								height: 15px;
								margin-right: 8px;
							}

							@if $type ==pc {
								width: 15px;
								height: 15px;
								margin-right: 8px;
							}
						}
					}
				}

			}
		}

		.commonDlCol {
			@if $type =="" {}

			@if $type ==sp {
				>*+* {
					margin-top: 12px;
				}
			}

			@if $type ==pc {
				display: flex;
			}

			@if $type ==tb {
				flex-direction: column;

				>*+* {
					margin-top: 12px;
					margin-left: 0;
				}
			}
		}

		.formList {
			>div {
				>dt {
					@if $type =="" {}

					@if $type ==sp {}

					@if $type ==pc {
						width: 180px;
					}
				}

				>dd {
					@if $type =="" {}

					@if $type ==sp {}

					@if $type ==pc {
						width: calc(100% - 180px);
					}
				}
			}
		}

		.boxMaxTextWrap {
			@if $type =="" {
				position: relative;
			}

			@if $type ==sp {}

			@if $type ==pc {}

			.boxMaxText {
				@if $type =="" {
					display: flex;
					justify-content: center;
					max-height: 5em;
					line-height: 1.357;
					font-feature-settings: inherit;
					position: relative;
					transition: .2s height;
					overflow: hidden;

					&.isOpen {
						max-height: initial;
						padding-bottom: 14px;

						+.boxMaxTextBtn {
							&:before {
								opacity: 0;
							}

							&:after {
								transform: scale(1, -1);
							}
						}
					}
				}

				@if $type ==sp {}

				@if $type ==pc {}



				+.boxMaxTextBtn {
					@if $type =="" {
						display: block;
						width: 100%;
						height: 100%;
						margin-top: -7px;
						position: absolute;
						left: 0;
						bottom: 0;
						cursor: pointer;
					}

					@if $type ==sp {}

					@if $type ==pc {

						&:hover:after {
							opacity: .7;
						}
					}

					&:before {
						@if $type =="" {
							content: "";
							width: 100%;
							height: 1.5em;
							background: rgb(255, 255, 255);
							background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
							position: absolute;
							bottom: 0;
							left: 0;
							transition: .2s opacity;
						}

						@if $type ==sp {}

						@if $type ==pc {}
					}

					&:after {
						@if $type =="" {
							content: "";
							width: 0;
							height: 0;
							border-style: solid;
							border-width: 7px 7px 0 7px;
							border-color: #088e98 transparent transparent transparent;
							position: absolute;
							bottom: 0;
							right: 0;
							left: 0;
							margin: auto;
							transition: .2s transform, .2s opacity;
						}

						@if $type ==sp {}

						@if $type ==pc {}
					}
				}
			}
		}

		.tableStickyWrap {
			@if $type =="" {}

			@if $type ==sp {}

			@if $type ==pc {
				height: 340px;
				overflow-y: scroll;
				margin-top: 17px;

				table {
					margin-top: 0 !important;
				}
			}

			.tableStickyContent {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {
					overflow: clip;
				}
			}

		}

		table.thSticky {
			@if $type =="" {
				position: relative;
			}

			@if $type ==sp {}

			@if $type ==pc {
				border-top: none !important;
			}

			thead {
				tr {
					@if $type =="" {}

					@if $type ==sp {
						border-bottom: 1px solid #d2d2d2;
					}

					@if $type ==pc {}
				}


				th {
					@if $type =="" {}

					@if $type ==sp {}

					@if $type ==pc {
						border-top: none !important;
						border-bottom: none !important;
						position: sticky;
						top: 0;
						left: 0;
						z-index: 2;
					}

					&:before {
						@if $type =="" {
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							border-top: 1px solid #d2d2d2 !important;
							border-bottom: 1px solid #d2d2d2 !important;
							z-index: -1;
							background: #FBFAF9;
							box-sizing: border-box;
						}

						@if $type ==sp {}

						@if $type ==pc {}

					}

				}
			}

			tbody {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {}

				tr:first-child {
					td {
						border-top: none !important;
					}
				}
			}

		}
	}

	*+.infoList {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 11px;
		}

		@if $type ==pc {
			margin-top: 33px;
		}
	}

	.infoList {
		@if $type =="" {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-wrap: wrap;
		}

		@if $type ==sp {}

		@if $type ==pc {}

		>li {
			+li {
				@if $type =="" {}

				@if $type ==sp {
					margin-left: 9px;
				}

				@if $type ==pc {
					margin-left: 9px;
				}
			}
		}
	}

	.downBlock {
		@if $type =="" {
			border-bottom: 1px solid #D2D2D2;
		}

		@if $type ==sp {
			padding-top: 11px;
			padding-bottom: 35px;
		}

		@if $type ==pc {
			padding-top: 33px;
			padding-bottom: 21px;
		}

		.downLead {
			@if $type =="" {}

			@if $type ==sp {}

			@if $type ==pc {
				display: flex;
				align-items: center;
				justify-content: space-between;

				&.flexStart {
					justify-content: flex-start;

					.btnWrap {
						margin: 0 0 0 auto;

						&.filterBtn {
							margin: 0 0 0 20px;
						}
					}
				}
			}

			@if $type ==tb {
				flex-direction: column;
				align-items: flex-start;

				>*+* {
					margin-top: 8px !important;
				}

				.btnWrap {
					margin-left: auto;
				}
			}

			>*+.btnWrap {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {
					margin-top: 0;
				}
			}


		}

		.downBox {
			@if $type =="" {
				display: none;
			}

			@if $type ==sp {
				margin-top: 8px;
			}

			@if $type ==pc {
				margin-top: 8px;
			}

			&2 {
				display: none;
				margin-top: 8px;

				ul {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
					grid-template-rows: 1fr 1fr;
					border-top: 1px solid #707070;
					border-left: 1px solid #707070;

					li {
						border-right: 1px solid #707070;
						border-bottom: 1px solid #707070;
					}

					button {
						width: 100%;
						height: 50px;
						border-radius: 0;
						border: none;
						outline: none;
						cursor: pointer;
						transition: all .3s;
						background-color: #fff;
						position: relative;

						@if $type ==pc {

							&:after {
								content: "";
								display: block;
								width: 8px;
								height: 8px;
								border-top: 1px solid #707070;
								border-right: 1px solid #707070;
								transform: rotate(45deg);
								position: absolute;
								top: calc(50% - 4px);
								right: 15px;
							}

							&:hover {
								background-color: #F7F6F4;
							}
						}
					}
				}
			}
		}
	}


	*+.sortBlock {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 11px;
		}

		@if $type ==pc {
			margin-top: 22px;
		}
	}

	.sortBlock {
		@if $type =="" {
			background: #F9F9F7;
		}

		@if $type ==sp {

			.note {
				background-color: #fff;
			}

			.btnWrap {
				background-color: #fff;
				padding-top: 20px;
				margin: 0;
			}

		}

		@if $type ==pc {

			.note {
				background-color: #fff;
			}


			.btnWrap {
				background-color: #fff;
				padding: 40px 0;
				margin: 0;
			}
		}

		.sortBlockHeader {
			@if $type =="" {}

			@if $type ==sp {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				padding: 23px 15px 23px;
			}

			@if $type ==pc {
				display: flex;
				justify-content: space-between;
				padding: 30px 20px 25px 20px;
			}

			@if $type ==tb {
				flex-wrap: wrap;
			}


			.sortBlockHeaderLead {
				@if $type =="" {}

				@if $type ==sp {
					width: 100%;

					+* {
						margin-top: 20px;
					}
				}

				@if $type ==pc {
					display: flex;
					align-items: center;
				}

				@if $type ==tb {
					width: 100%;
					justify-content: space-between;
				}

				*+* {
					@if $type =="" {}

					@if $type ==sp {
						margin-top: 11px;
					}

					@if $type ==pc {
						margin-left: 33px;
					}
				}

				>*+p {
					@if $type =="" {}

					@if $type ==sp {}

					@if $type ==pc {
						margin-top: 0;
					}
				}

			}

			.paging {
				@if $type ==pc {
					max-width: 930px;
					width: inherit;
				}

				@if $type ==tb {
					width: 100%;
				}
			}
		}

		.sortBlockFooter {
			@if $type =="" {}

			@if $type ==sp {
				padding: 20px 15px;

				.spPageNav {

					.transition {
						display: flex;
						justify-content: space-between;
						gap: 10px;

						a {
							display: flex;
							flex: 1;
							justify-content: center;
							align-items: center;
							background-color: #fff;
							color: #7a7a7a;
							border: 1px solid #7a7a7a;
							border-radius: 4px;
							text-decoration: none;
							height: 50px;

							&.null {
								pointer-events: none;
								opacity: .3;
							}
						}
					}

					.pointing {
						display: flex;
						align-items: flex-start;
						gap: 2;
						margin-top: 15px;

						.mig {
							width: 25%;
							background-color: #7a7a7a;
							color: #fff;
							display: flex;
							justify-content: center;
							align-items: center;
							text-decoration: none;
							height: 50px;

							&.pre {
								border-radius: 5px 0 0 5px;
								margin-right: 1px;
							}

							&.nex {
								border-radius: 0 5px 5px 0;
								margin-left: 1px;
							}

							&.null {
								pointer-events: none;
								opacity: .3;
							}
						}

						.list {
							width: 50%;
							height: 50px;
							background: #7a7a7a;
							position: relative;
							display: flex;
							justify-content: center;
							align-items: center;
							color: #fff;

							&:after {
								content: "";
								display: block;
								width: 0;
								height: 0;
								border-top: 10px solid #fff;
								border-right: 6px solid transparent;
								border-left: 6px solid transparent;
								position: absolute;
								top: calc(50% - 5px);
								right: 10px;
							}

							ul {
								position: absolute;
								top: 50px;
								left: 0;
								width: 100%;
								z-index: 20;
								transform: scaleY(0);
								transition: all .3s;
								transform-origin: left top;
								background-color: #fff;

								li {
									width: 100%;

									a {
										width: 100%;
										height: 50px;
										display: flex;
										justify-content: center;
										align-items: center;
										color: #fff;
										text-decoration: none;
										background-color: #7a7a7a;
										margin-top: 1px;
									}
								}
							}


							&.isOpen {

								&:after {
									transform: rotate(180deg);
								}

								ul {
									transform: scaleY(1);
								}
							}
						}
					}
				}

			}

			@if $type ==pc {
				padding: 30px 20px 25px 20px;
			}

		}
	}

	*+.paging {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 20px;
		}

		@if $type ==tb {
			margin-top: 20px;
		}
	}

	.paging {
		@if $type =="" {
			width: 100%;
		}

		@if $type ==sp {}

		@if $type ==pc {
			display: flex;
			justify-content: center;
		}

		*+* {
			@if $type =="" {}

			@if $type ==sp {}

			@if $type ==pc {
				margin-left: 10px;
			}
		}

		.prev,
		.next {
			@if $type =="" {
				display: block;
				color: #fff;
				border-radius: 4px;
				text-decoration: none;
				border: 1px solid #828282;
				background: #828282;
				position: relative;
			}

			@if $type ==sp {
				display: none;
			}

			@if $type ==pc {
				width: 60px;
				height: 40px;
				line-height: 38px;
				transition: .2s opacity;

				&:hover {
					opacity: .7;
				}
			}

			&:before {
				@if $type =="" {
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					margin: auto;
				}

				@if $type ==sp {}

				@if $type ==pc {}
			}

		}

		.prev:before {
			@if $type =="" {
				border-color: transparent #ffffff transparent transparent;
			}

			@if $type ==sp {
				border-width: 5px 10px 5px 0;
			}

			@if $type ==pc {
				border-width: 5px 10px 5px 0;
			}
		}

		.next:before {
			@if $type =="" {
				border-color: transparent transparent transparent #ffffff;
			}

			@if $type ==sp {
				border-width: 5px 0 5px 10px;
			}

			@if $type ==pc {
				border-width: 5px 0 5px 10px;
			}
		}

		.pageCount {
			@if $type =="" {
				display: flex;
			}

			@if $type ==sp {
				justify-content: center;
				width: 100%;
			}

			@if $type ==pc {
				margin-left: 10px;
			}

			>li {
				@if $type =="" {
					text-align: center;
				}

				@if $type ==sp {
					width: 40px;
					height: 40px;
					line-height: 38px;

					+li {
						margin-left: 10px;
					}
				}

				@if $type ==pc {
					width: 40px;
					height: 40px;
					line-height: 38px;
				}

				a {
					@if $type =="" {
						display: block;
						color: #000000;
						border-radius: 4px;
						text-decoration: none;
						border: 1px solid #D2D2D2;
						background: #fff;

						&.now {
							color: #828282;
							border-color: #828282;
							background: #CCCCCC;
							cursor: inherit;
						}
					}

					@if $type ==sp {}

					@if $type ==pc {
						transition: .2s background, .2s background, .2s color, .2s opacity;

						&:not(.now):hover {
							opacity: .7;
							text-decoration: underline;
						}
					}
				}

				+li {
					@if $type =="" {}

					@if $type ==sp {}

					@if $type ==pc {
						margin-left: 10px;
					}
				}
			}
		}
	}

	.sortBlock {
		.scrolltext {
			@if $type =="" {}

			@if $type ==sp {
				padding: 0 20px;
			}

			@if $type ==tb {
				padding: 0 20px;
			}
		}
	}

	*+.formBlock {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 8px;
		}

		@if $type ==pc {
			margin-top: 8px;
		}
	}

	.formBlock {
		@if $type =="" {
			background: #F7F6F4;
		}

		@if $type ==sp {
			padding: 13px 15px 27px;
		}

		@if $type ==pc {
			padding: 13px 30px 54px;
		}

		.btnWrap {
			@if $type =="" {}

			@if $type ==sp {}

			@if $type ==pc {
				margin-top: 30px;
			}

			@if $type ==tb {
				margin-top: 22px;
			}
		}

		.formList {
			@if $type =="" {}

			@if $type ==sp {
				padding-left: 0;
				padding-right: 0;
			}

			@if $type ==pc {}
		}
	}

	.colorGreen {

		*+.titleBeforeWrap {
			@if $type =="" {}

			@if $type ==sp {
				margin-top: 30px;
			}

			@if $type ==pc {
				margin-top: 36px;
			}
		}

		.titleBeforeWrap {
			@if $type =="" {
				font-weight: bold;
				line-height: 1.2;
				position: relative;
			}

			@if $type ==sp {
				padding-left: 20px;
			}

			@if $type ==pc {
				padding-left: 20px;
			}

			&:first-child {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {
					margin-top: 40px;
				}

			}

			&:before {
				@if $type =="" {
					content: "";
					display: block;
					height: 100%;
					background: $green;
					position: absolute
				}

				@if $type ==sp {
					width: 6px;
					top: 0;
					left: 0;
				}

				@if $type ==pc {
					width: 6px;
					top: 0;
					left: 0;
				}
			}


			.titleSub {
				@if $type =="" {
					display: inline-block;
					color: $green;
					font-weight: 400;
					background: #BEF1E6;
				}

				@if $type ==sp {
					padding: 4px 13px;
					font-size: 14px;
				}

				@if $type ==pc {
					padding: 4px 13px;
					font-size: 16px;
				}
			}

			*+.titleBefore {
				@if $type =="" {}

				@if $type ==sp {
					margin-top: 7px;
				}

				@if $type ==pc {
					margin-top: 7px;
				}
			}

			.titleBefore {
				@if $type =="" {}

				@if $type ==sp {
					font-size: 20px;
				}

				@if $type ==pc {
					font-size: 34px;
				}
			}

		}


		*+.titleBold {
			@if $type =="" {}

			@if $type ==sp {
				margin-top: 27px;
			}

			@if $type ==pc {
				margin-top: 27px;
			}
		}

		.titleBold {
			@if $type =="" {
				font-weight: bold;
			}

			@if $type ==sp {
				font-size: 18px;
			}

			@if $type ==pc {
				font-size: 22px;
			}
		}

		*+.commonDl {
			@if $type =="" {}

			@if $type ==sp {
				margin-top: 30px;
			}

			@if $type ==pc {
				margin-top: 40px;
			}
		}

		.btnWrap.btnR+.commonDl {
			@if $type =="" {}

			@if $type ==sp {
				margin-top: 12px;
			}

			@if $type ==pc {
				margin-top: 12px;
			}
		}

		.commonDl {
			@if $type =="" {
				border: 1px solid #D2D2D2;
			}

			@if $type ==sp {
				+.commonDl {
					margin-top: 15px;
				}
			}

			@if $type ==pc {
				+.commonDl {
					margin-top: 20px;
				}
			}

			+.sortBlock {
				@if $type =="" {}

				@if $type ==sp {
					margin-top: 20px;
				}

				@if $type ==pc {
					margin-top: 40px;
				}
			}

			>div {
				@if $type =="" {
					+div {
						border-top: 1px solid #d2d2d2;
					}
				}

				@if $type ==sp {}

				@if $type ==pc {
					display: flex;

				}

				>dt {
					@if $type =="" {
						background: #F7F6F4;
					}

					@if $type ==sp {
						padding: 8px 15px;
						font-size: 14px;
					}

					@if $type ==pc {
						display: flex;
						align-items: center;
						width: 180px;
						padding: 15px 20px;
						font-size: 14px;
					}
				}

				>dd {
					@if $type =="" {}

					@if $type ==sp {
						padding: 15px 15px;
					}

					@if $type ==pc {
						width: calc(100% - 180px);
						padding: 15px 20px;
					}
				}

				&.blockRequired {
					@if $type =="" {}

					@if $type ==sp {}

					@if $type ==pc {}

					>dt {
						@if $type =="" {
							position: relative;
						}

						@if $type ==sp {
							padding-left: 60px;
						}

						@if $type ==pc {
							padding-left: 65px;
						}

						&:before {
							@if $type =="" {
								content: "必須";
								display: block;
								padding: 2px 5px;
								margin-right: 11px;
								color: #fff;
								font-size: 12px;
								text-align: center;
								line-height: 1;
								background: #A72525;
								position: absolute;
								top: 0;
								bottom: 0;
								margin: auto;
								box-sizing: border-box;
							}

							@if $type ==sp {
								height: 17px;
								left: 15px;

							}

							@if $type ==pc {
								height: 17px;
								left: 20px;

							}
						}
					}

				}
			}
		}

		.sortBlock {
			@if $type =="" {}

			@if $type ==sp {}

			@if $type ==pc {}

			.sortBlockHeader {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {}

				.sortBlockHeaderLead {
					@if $type =="" {}

					@if $type ==sp {}

					@if $type ==pc {}

					>* {
						+* {
							@if $type =="" {}

							@if $type ==sp {}

							@if $type ==pc {
								margin-left: 20px;
							}
						}

						+p {
							@if $type =="" {}

							@if $type ==sp {}

							@if $type ==pc {
								margin-left: 24px;
							}
						}
					}
				}
			}
		}

		.downBlock {
			@if $type =="" {}

			@if $type ==sp {}

			@if $type ==pc {}

			&.borderTop {
				@if $type =="" {
					padding-top: 20px;
					padding-bottom: 0;
					margin-top: 27px;
					border-top: 1px solid #D2D2D2;
					border-bottom: none;
				}

				@if $type ==sp {}

				@if $type ==pc {}

				.downBox {
					@if $type =="" {}

					@if $type ==sp {}

					@if $type ==pc {
						margin-top: 20px;
					}
				}
			}

			&.borderNone {
				@if $type =="" {
					padding-bottom: 0;
					border-top: none;
					border-bottom: none;
				}

				@if $type ==sp {}

				@if $type ==pc {}

				.downBox {
					@if $type =="" {}

					@if $type ==sp {}

					@if $type ==pc {
						margin-top: 20px;
					}
				}
			}
		}

		.iconPlus {
			@if $type =="" {
				display: inline-block;
				position: relative;
				background: url(../images/contact_sub/icon_plus.svg) no-repeat;
				background-size: contain;
			}

			@if $type ==sp {
				width: 18px;
				height: 18px;
			}

			@if $type ==pc {
				width: 18px;
				height: 18px;
			}
		}

		.iconClip {
			@if $type =="" {
				display: inline-block;
				position: relative;
				background: url(../images/contact_sub/icon_paperclip.svg) no-repeat;
				background-size: contain;
			}

			@if $type ==sp {
				width: 18px;
				height: 18px;
			}

			@if $type ==pc {
				width: 18px;
				height: 18px;
			}
		}

		.iconTrash {
			@if $type =="" {
				display: inline-block;
				position: relative;
				background: url(../images/contact_sub/icon_trash.svg) no-repeat;
				background-size: contain;
			}

			@if $type ==sp {
				width: 16px;
				height: 16px;
			}

			@if $type ==pc {
				width: 16px;
				height: 16px;
			}
		}

		.iconReload {
			@if $type =="" {
				display: inline-block;
				vertical-align: baseline;
				position: relative;
				background: url(../images/contact_sub/icon_reload.svg) no-repeat;
				background-size: contain;
			}

			@if $type ==sp {
				width: 16px;
				height: 16px;
			}

			@if $type ==pc {
				width: 16px;
				height: 16px;
			}
		}

		select:focus,
		select:checked,
		select:active,
		input:focus,
		input:checked,
		input:active,
		textarea:focus,
		textarea:checked,
		textarea:active,
		select:focus,
		select:checked,
		select:active,
		input:focus,
		input:checked,
		input:active,
		textarea:focus,
		textarea:checked,
		textarea:active {
			@if $type =="" {
				border-color: $green !important;
				background: #EEFDFE !important;
			}
		}


		.selectWrap {
			&:before {
				@if $type =="" {
					border-color: $green transparent transparent transparent;
				}

				@if $type ==sp {}

				@if $type ==pc {}
			}
		}

		.checkboxLabel {
			input[type="checkbox"]:checked {
				+span {
					@if $type =="" {
						color: #000000;
						box-shadow: 0 0 0 2px $green;
						background: #EEFDFE;
					}

					@if $type ==sp {}

					@if $type ==pc {}

					&:after {
						@if $type =="" {
							border-left: 2px solid $green;
							border-bottom: 2px solid $green;
						}

						@if $type ==sp {}

						@if $type ==pc {}
					}
				}
			}
		}

		.formList {
			>div {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {}

				&.formListDt2 {
					&.variable {
						@if $type =="" {}

						@if $type ==sp {}

						@if $type ==pc {
							flex-wrap: wrap;
						}

						>dt {
							@if $type =="" {}

							@if $type ==sp {}

							@if $type ==pc {
								width: inherit;
								min-width: 180px;
								word-break: keep-all;
							}

							@media print,
							screen and (min-width: 600px) and (max-width: 1230px) {
								+dd {
									margin-top: 10px;
								}
							}
						}

						>dd {
							@if $type =="" {}

							@if $type ==sp {}

							@if $type ==pc {
								flex: 1;
								width: initial;
								max-width: inherit;
								padding-right: 0;
								margin-right: 54px;
							}

							@media print,
							screen and (min-width: 600px) and (max-width: 1230px) {
								width: calc(100% - 180px);
								margin-right: 0;
							}

						}
					}
				}

				>dt {
					@if $type =="" {
						font-weight: 400;
					}

					@if $type ==sp {}

					@if $type ==pc {}

				}
			}
		}

		.textarea {
			@if $type =="" {
				width: 100%;
				border: 1px solid #B8B8B8;
				border-radius: 4px;
				background: #fff;
			}

			@if $type ==sp {}

			@if $type ==pc {
				min-height: 130px;
				padding: 7px 14px;
			}
		}

		.radioLabel {
			input[type="radio"] {
				&:checked {
					+span {
						@if $type =="" {
							color: $green;
							box-shadow: 0 0 0 2px $green;
							background: #EEFDFE;
						}

						@if $type ==sp {}

						@if $type ==pc {}

						&:before {
							@if $type =="" {
								background: $green;
							}

							@if $type ==sp {}

							@if $type ==pc {}
						}
					}
				}
			}
		}
	}

	.formList {
		@if $type =="" {}

		@if $type ==sp {
			>div {
				padding-top: 14px;
				padding-bottom: 14px;
			}
		}

		@if $type ==pc {
			>div {
				padding-top: 22px;
				padding-bottom: 22px;
			}
		}

		>div {
			@if $type =="" {
				border-bottom: 1px solid #E4E4E4;

				+div {
					//	border-top: 1px solid #E4E4E4;
				}
			}

			@if $type ==sp {}

			@if $type ==pc {
				display: flex;
				align-items: center;
			}


			>dt {
				@if $type =="" {
					font-weight: 700;
				}

				@if $type ==sp {}

				@if $type ==pc {
					width: 160px;
					padding: 0 15px 0 0;
					line-height: 1.375;
				}

				span {
					@if $type =="" {
						display: block;
					}

					@if $type ==sp {
						font-size: 12px;
					}

					@if $type ==pc {
						font-size: 12px;
					}
				}
			}

			>dd {
				@if $type =="" {}

				@if $type ==sp {
					margin-top: 8px;
				}

				@if $type ==pc {
					width: calc(100% - 160px);
				}
			}

			&.formListDt2 {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {}

				@if $type ==tb {
					flex-wrap: wrap;

				}

				dd+dt {
					@if $type =="" {}

					@if $type ==sp {
						margin-top: 14px;
					}

					@if $type ==pc {
						width: 80px;
					}

					@if $type ==tb {
						width: 180px;
						margin-top: 10px;
					}
				}

				dd+dt+dd {
					@if $type =="" {}

					@if $type ==sp {}

					@if $type ==pc {}

					@if $type ==tb {
						margin-top: 10px;
					}
				}

				dd {
					@if $type =="" {}

					@if $type ==sp {}

					@if $type ==pc {
						width: calc(55% - 160px);
						max-width: 370px;
						padding-right: 25px;
					}

					@if $type ==tb {
						width: calc(100% - 160px);
						padding-right: 0;
						max-width: inherit;
						padding-right: inherit;
					}
				}
			}
		}
	}

	.input {
		@if $type =="" {
			border: 1px solid #B8B8B8;
		}

		@if $type ==sp {
			padding: 7px 6px;
			border-radius: 4px;
		}

		@if $type ==pc {
			padding: 7px 14px;
			border-radius: 4px;
		}

		&.inputFull {
			@if $type =="" {
				width: 100%;
			}

			@if $type ==sp {}

			@if $type ==pc {}
		}

		&.inputL {
			@if $type =="" {
				width: 100%;
			}

			@if $type ==sp {}

			@if $type ==pc {
				min-width: 350px;
				max-width: 633px;
			}
			@if $type ==tb {
				min-width: inherit;
			}
		}

		&.inputM {
			@if $type =="" {
				width: 100%;
			}

			@if $type ==sp {}

			@if $type ==pc {
				max-width: 418px;
			}
		}

		&.inputS {
			@if $type =="" {}

			@if $type ==sp {
				width: 100px;
			}

			@if $type ==pc {
				width: 85px;
			}
		}
	}

	.rowNote {
		@if $type =="" {
			display: inline-block;
			vertical-align: middle;
		}

		@if $type ==sp {
			margin-left: 5px;
		}

		@if $type ==pc {
			margin-left: 9px;
		}
	}

	.inputSearch {
		@if $type =="" {
			display: inline-block;
			width: 100%;
			position: relative;
		}

		@if $type ==sp {}

		@if $type ==pc {
			width: 100%;
			max-width: 546px;
		}

		&:before {
			@if $type =="" {
				content: "";
				display: block;
				background-image: url(../images/contact_sub/icon_search.png) !important;
				background-repeat: no-repeat;
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
			}

			@if $type ==sp {
				width: 17px;
				height: 17px;
				background-size: 17px auto;
				left: 12px;
			}

			@if $type ==pc {
				width: 22px;
				height: 22px;
				background-size: 22px auto;
				left: 15px;
			}
		}

		.input {
			@if $type =="" {
				width: 100%;
			}

			@if $type ==sp {
				padding-left: 35px;
			}

			@if $type ==pc {
				padding-left: 50px;
			}
		}
	}

	.formRow {
		@if $type =="" {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}

		@if $type ==sp {
			margin-top: -8px;
		}

		@if $type ==pc {
			margin-top: -10px;
		}

		>li {
			@if $type =="" {
				&:last-child {
					margin-right: 0;
				}
			}

			@if $type ==sp {
				margin-right: 8px;
				margin-top: 8px;
			}

			@if $type ==pc {
				margin-right: 8px;
				margin-top: 10px;
			}
		}
	}

	.checkboxLabel {
		@if $type =="" {
			display: block;
			position: relative;
		}

		@if $type ==sp {}

		@if $type ==pc {
			text-align: center;
			cursor: pointer;
		}

		&.textNone {
			@if $type =="" {
				width: 19px;
				height: 19px;
				margin-right: 0;
			}

			@if $type ==sp {}

			@if $type ==pc {}

			input[type="checkbox"] {
				+span {
					@if $type =="" {
						padding: 0;
						box-shadow: none;
						background: transparent;
					}

					@if $type ==sp {}

					@if $type ==pc {}

					&:before {
						@if $type =="" {
							margin: inherit;
							border: 1px solid #737373;
							background: #fff;
							right: 0;
							left: 0;
						}

						@if $type ==sp {}

						@if $type ==pc {}
					}

					&:after {
						@if $type =="" {
							right: 0;
							left: 0;
						}

						@if $type ==sp {}

						@if $type ==pc {}
					}
				}

				&:checked {
					+span {
						@if $type =="" {
							box-shadow: none !important;
						}

						@if $type ==sp {}

						@if $type ==pc {}

						&:before {
							@if $type =="" {
								border-color: $green;
								opacity: 1;

							}

							@if $type ==sp {}

							@if $type ==pc {}
						}

						&:after {
							@if $type =="" {}

							@if $type ==sp {}

							@if $type ==pc {}
						}
					}
				}
			}
		}

		&.error {
			span {
				@if $type =="" {
					background: #FFF0F0 !important;

					&:before {
					}
				}
			}
		}

		input {
			@if $type =="" {
				display: none;
			}
		}

		span {
			@if $type =="" {
				display: block;
				box-shadow: 0 0 0 1px #B8B8B8;
				background: #fff;
				box-sizing: border-box;
				transition: .2s opacity, .2s background, .2s box-shadow;
			}

			@if $type ==sp {
				padding: 6px 14px 6px 41px;
				border-radius: 4px;
			}

			@if $type ==pc {
				padding: 6px 14px 6px 41px;
				border-radius: 4px;

				&:hover {
					opacity: .7;
				}
			}

			&:before,
			&:after {
				@if $type =="" {
					content: "";
					display: block;
					position: absolute;
				}

				@if $type ==sp {}

				@if $type ==pc {}
			}



			&:before {
				@if $type =="" {
					background: #E6E6E6;
					transition: .2s opacity;
				}

				@if $type ==sp {
					width: 17px;
					height: 17px;
					top: 0;
					bottom: 0;
					margin: auto;
					left: 12px;
				}

				@if $type ==pc {
					width: 17px;
					height: 17px;
					top: 0;
					bottom: 0;
					margin: auto;
					left: 12px;
				}
			}

			&:after {
				@if $type =="" {
					background: inherit;
					content: '';
					display: block;
					position: absolute;
					width: 10px;
					height: 5px;
					border-left: 2px solid $c_blue;
					border-bottom: 2px solid $c_blue;
					transform: rotate(-45deg);
					transition: .2s opacity;
					top: 0;
					bottom: 0;
					margin: auto;
					opacity: 0;
				}

				@if $type ==sp {
					left: 16px;
				}

				@if $type ==pc {
					left: 16px;
				}
			}
		}

		input[type="checkbox"]:checked {
			+span {
				@if $type =="" {
					color: $c_blue;
					box-shadow: 0 0 0 2px $c_blue;
					background: #E6EFFD;
				}

				@if $type ==sp {}

				@if $type ==pc {
					&:hover {
						opacity: 1;
					}
				}

				&:after {
					@if $type =="" {
						opacity: 1;
					}
				}

				&:before {
					@if $type =="" {
						opacity: 0;
					}
				}

			}
		}
	}

	.radioLabel {
		@if $type =="" {
			display: block;
			position: relative;
		}

		@if $type ==sp {}

		@if $type ==pc {
			cursor: pointer;
		}

		input {
			@if $type =="" {
				display: none;
			}
		}

		span {
			@if $type =="" {
				display: block;
				box-shadow: 0 0 0 1px #B8B8B8;
				background: #fff;
				box-sizing: border-box;
				transition: .2s opacity, .2s background, .2s box-shadow;
			}

			@if $type ==sp {
				padding: 6px 14px 6px 41px;
				border-radius: 4px;

			}

			@if $type ==pc {
				padding: 6px 14px 6px 41px;
				border-radius: 4px;

				&:hover {
					opacity: .7;
				}
			}

			&:before,
			&:after {
				@if $type =="" {
					content: "";
					display: block;
					position: absolute;
				}

				@if $type ==sp {}

				@if $type ==pc {}
			}

			&:before {
				@if $type =="" {
					background: #E6E6E6;
					transition: .2s;
					border-radius: 50%;
					box-sizing: border-box;
				}

				@if $type ==sp {
					width: 17px;
					height: 17px;
					top: 0;
					bottom: 0;
					margin: auto;
					left: 14px;
				}

				@if $type ==pc {
					width: 17px;
					height: 17px;
					top: 0;
					bottom: 0;
					margin: auto;
					left: 12px;
				}
			}

			&:after {
				@if $type =="" {
					transition: .2s;
					border-radius: 50%;
					border: 1px solid #fff;
					box-sizing: border-box;
					opacity: 0;
				}

				@if $type ==sp {
					width: 15px;
					height: 15px;
					top: 0;
					bottom: 0;
					margin: auto;
					left: 15px;
				}

				@if $type ==pc {
					width: 15px;
					height: 15px;
					top: 0;
					bottom: 0;
					margin: auto;
					left: 13px;
				}
			}
		}

		input[type="radio"]:checked {
			+span {
				@if $type =="" {
					color: $c_blue;
					box-shadow: 0 0 0 2px $c_blue;
					background: #E6EFFD;
				}

				@if $type ==sp {}

				@if $type ==pc {
					&:hover {
						opacity: 1;
					}
				}

				&:before {
					@if $type =="" {
						width: 17px;
						height: 17px;
						background: $c_blue;
					}
				}

				&:after {
					@if $type =="" {
						opacity: 1;
					}
				}

			}
		}

		&.error {
			span {
				@if $type =="" {
					background: #FFF0F0 !important;

					&:before {
					}
				}
			}
		}
	}


	.selectWrap {
		@if $type =="" {
			display: inline-block;
			position: relative;

			&.colorGreen {
				&:before {
					border-color: $green transparent transparent transparent;
				}
			}
		}

		@if $type ==sp {
			width: 100%;

			&.colorGreen {
				select {
					font-size: 14px;
				}
			}
		}

		@if $type ==pc {
			&:not(.selectS) {
				width: 100%;
				max-width: 273px;
			}

			.selectS {
				display: inline-block;
			}

			&.selectM {
				width: 100%;
				max-width: 380px;
			}

			&.selectFull {
				width: 100%;
				max-width: inherit;
			}

			&.colorGreen {
				select {
					font-size: 14px;
				}
			}
		}

		&:before {
			@if $type =="" {
				content: "";
				display: block;
				width: 0;
				height: 0;
				border-style: solid;
				border-color: $c_blue transparent transparent transparent;
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				pointer-events: none;
			}

			@if $type ==sp {
				border-width: 7px 6.5px 0 6.5px;
				right: 12px;
			}

			@if $type ==pc {
				border-width: 7px 6.5px 0 6.5px;
				right: 12px;
			}
		}

		.select {
			@if $type =="" {
				appearance: none;
				-webkit-appearance: none;
				width: 100%;
				border: 1px solid #B8B8B8;
				background: #fff;
			}

			@if $type ==sp {
				padding: 7px 30px 7px 14px;
				border-radius: 4px;
			}

			@if $type ==pc {
				min-height: 41px;
				padding: 7px 30px 7px 14px;
				border-radius: 4px;
			}
		}
	}

	.checkbox {
		@if $type =="" {}

		@if $type ==sp {}

		@if $type ==pc {}
	}

	::placeholder {
		color: #B8B8B8;
	}

	select,
	input,
	textarea {
		@if $type =="" {
			transition: .2s border-color, .2s background;
		}

		@if $type ==sp {
			font-size: 16px;
		}

		&.error {
			background: #FFF0F0 !important;
		}

		&:focus,
		&:checked,
		&:active {
			@if $type =="" {
				border-color: $c_blue !important;
				background: #E6EFFD !important;
				outline: none;
			}

		}

		&:-webkit-autofill {
			@if $type=="" {
				animation-name: onAutoFillStart;
				transition: background-color 50000s ease-in-out 0s;
				box-shadow: transparent !important;
				-webkit-box-shadow: transparent;
			}
		}
	}

	@keyframes onAutoFillStart {
		from {}

		to {}
	}

	.modalContent {
		@if $type =="" {
			background: #fff;
		}

		@if $type ==sp {
			max-width: 1220px;
			padding: 20px 15px;
			margin: 0 auto;
		}

		@if $type ==pc {
			max-width: 1220px;
			padding: 46px 40px 46px;
			margin: 0 auto;
		}
	}

	.modalTitle {
		@if $type =="" {
			display: flex;
			justify-content: center;
			font-weight: bold;
			text-align: center;
		}

		@if $type ==sp {

			margin-bottom: 28px;
			font-size: 20px;
		}

		@if $type ==pc {
			margin-bottom: 42px;
			font-size: 28px;
		}

		>span {
			@if $type =="" {
				position: relative;
			}

			@if $type ==sp {
				display: block;
				padding-bottom: 8px;
			}

			@if $type ==pc {}

			&:before {
				@if $type =="" {
					content: "";
					display: block;
					width: 100%;
					height: 2px;
					background: $c_blue;
					position: absolute;
					bottom: 0;
					right: 0;
					left: 0;
					margin: auto;
				}

				@if $type ==sp {}

				@if $type ==pc {}
			}
		}
	}

	.modalInner {
		@if $type =="" {
			overflow-y: auto;
			overflow-x: hidden;
		}

		@if $type ==sp {
			padding-right: 10px;
			max-height: 323px;
		}

		@if $type ==pc {
			max-width: 986px;
			padding-right: 50px;
			margin: 0 auto;
			max-height: 530px;
		}

		@if $type ==tb {
			padding-right: 15px;
		}

		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
			border-radius: 30px;
			background: #E6E6E6;
			cursor: pointer;
		}

		&::-webkit-scrollbar-thumb {
			width: 5px;
			height: 5px;
			border-radius: 30px;
			background: $c_blue;
			cursor: pointer;
		}
	}

	*+.errorBox {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 30px;
		}

		@if $type ==pc {
			margin-top: 45px;
		}
	}

	.errorBox {
		@if $type =="" {
			color: #A72525;
			background: #FFF0F0;
		}

		@if $type ==sp {
			padding: 10px 20px;
		}

		@if $type ==pc {
			padding: 10px 20px;
		}

		+.commonDl {
			@if $type =="" {}

			@if $type ==sp {
				margin-top: 20px;
			}

			@if $type ==pc {
				margin-top: 20px;
			}
		}

		&.errorBoxStrong {
			@if $type =="" {
			}
			@if $type ==sp {
				padding: 20px 20px;
			}
			@if $type ==pc {
				padding: 26px 20px;
			}
		}
	}

	*+.contentNarrow {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 25px;
		}

		@if $type ==pc {
			margin-top: 45px;
		}
	}

	.contentNarrow {
		@if $type =="" {}

		@if $type ==sp {}

		@if $type ==pc {
			max-width: 1000px;
			margin-inline: auto;
		}
	}

	.radioList {
		@if $type =="" {}

		@if $type ==sp {}

		@if $type ==pc {}

		>li {
			@if $type =="" {}

			@if $type ==sp {}

			@if $type ==pc {}


			+li {
				@if $type =="" {}

				@if $type ==sp {
					margin-top: 16px;
				}

				@if $type ==pc {
					margin-top: 9px;
				}

				@if $type ==pc {
					margin-top: 12px;
				}
			}

			.radiListContent {
				@if $type =="" {}

				@if $type ==sp {
					>*+* {
						margin-top: 4px;
					}
				}

				@if $type ==pc {
					display: flex;
					align-items: flex-start;
				}

				@if $type ==tb {
					flex-direction: column;
				}

				.selectWrap.selectM {
					@if $type =="" {}

					@if $type ==sp {}

					@if $type ==pc {
						max-width: 418px;
					}
				}
			}

			.radioLabelBefore {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {
					width: 135px;
					margin-top: 8px;
					margin-bottom: 8px;
					padding-right: 15px;
				}

				>input[type="radio"] {
					@if $type =="" {
						display: none;

						&:checked {
							+span:after {
								transform: scale(1);
							}
						}
					}

					@if $type ==sp {}

					@if $type ==pc {}

					+span {
						@if $type =="" {
							display: inline-block;
							position: relative;


						}

						@if $type ==sp {
							padding-left: 30px;
						}

						@if $type ==pc {
							padding-left: 35px;
							cursor: pointer;
						}

						&:before,
						&:after {
							@if $type =="" {
								content: "";
								position: absolute;
							}

							@if $type ==sp {}

							@if $type ==pc {}
						}

						&:before {
							@if $type =="" {
								border: 1px solid #707070;
								border-radius: 50%;
								box-sizing: border-box;
							}

							@if $type ==sp {
								width: 20px;
								height: 20px;
								top: 3px;
								left: 0;
							}

							@if $type ==pc {
								width: 24px;
								height: 24px;
								left: 0;
							}
						}

						&:after {
							@if $type =="" {
								border-radius: 50%;
								background: #088E98;
								transform: scale(0);
								transition: .2s transform;
							}

							@if $type ==sp {
								width: 14px;
								height: 14px;
								top: 6px;
								left: 3px;
							}

							@if $type ==pc {
								width: 18px;
								height: 18px;
								top: 3px;
								left: 3px;
							}
						}
					}
				}
			}
		}
	}

	.input:disabled,
	.select:disabled {
		@if $type =="" {
			color: #B8B8B8;
			background: #E6E6E6;
			pointer-events: none;
			opacity: 1;
		}

		@if $type ==sp {}

		@if $type ==pc {}
	}

	.selectWrap.disabled {
		&:before {
			border-color: #B8B8B8 transparent transparent transparent;
		}
	}

	.selectWrap.error {
		&:before {
			border-color: #A72525 transparent transparent transparent !important;
		}

		.select {
			background: #FFF0F0 !important;
		}
	}


	.selectList {
		@if $type =="" {}

		@if $type ==sp {}

		@if $type ==pc {}

		>li {
			@if $type =="" {}

			@if $type ==sp {}

			@if $type ==pc {}

			+li {
				@if $type =="" {}

				@if $type ==sp {
					margin-top: 20px;
				}

				@if $type ==pc {
					margin-top: 20px;
				}
			}

			.selectListTitle {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {}

				+* {
					@if $type =="" {}

					@if $type ==sp {
						margin-top: 5px;
					}

					@if $type ==pc {
						margin-top: 5px;
					}
				}
			}


		}
	}

	*+.btnAdd {
		@if $type =="" {}

		@if $type ==sp {}

		@if $type ==pc {
			margin-left: 24px;
		}
	}

	.btnAdd {
		@if $type =="" {}

		@if $type ==sp {}

		@if $type ==pc {
			display: inline-block;
		}

		>.btn {
			@if $type =="" {
				border-color: #B8B8B8;
			}

			@if $type ==sp {}

			@if $type ==pc {}
		}

	}

	.titleBeforeWrap+.compBox {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 20px;
		}

		@if $type ==pc {
			margin-top: 40px;
		}
	}

	.compBox {
		@if $type =="" {
			background-color: #f7f6f4;
		}

		@if $type ==sp {
			padding: 50px 15px;
		}

		@if $type ==pc {
			padding: 50px 30px;
		}

		.title {
			@if $type =="" {
				background-color: #bef1e6;
				text-align: center;
				font-size: 20px;
				font-weight: 700;
				background-color: transparent;
			}

			@if $type ==sp {}

			@if $type ==pc {}


			a {
				@if $type =="" {
					color: #707070;
					border: 1px solid #707070;
					width: 200px;
					height: 48px;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					border-radius: 5px;
					font-weight: 400;
					font-size: 16px;
					margin: 30px auto 0;
					background-color: #fff;
				}

				@if $type ==sp {}

				@if $type ==pc {
					&:hover {
						text-decoration: none;
					}
				}

			}

			+p {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {
					margin-top: 24px;
				}
			}
		}

		p {
			@if $type =="" {
				text-align: center;
			}

			@if $type ==sp {}

			@if $type ==pc {}
		}

		.custom_hover {
			@if $type =="" {
				width: 200px;
				height: 48px;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				border-radius: 5px;
				color: #707070;
				border: 1px solid #707070;
				text-decoration: none;
				background-color: #fff;
				margin: 20px auto 0;
			}

			@if $type ==sp {}

			@if $type ==pc {}
		}

		&.errorBox {
			@if $type =="" {
				background-color: #fff0f0;
				color: #a72525;
			}

			@if $type ==sp {}

			@if $type ==pc {}
		}
	}

	.btnDrag {
		@if $type =="" {
			background: url(../images/contact_sub/icon_drag.svg) no-repeat;
			background-size: contain;
			cursor: move;
		}

		@if $type ==sp {
			width: 20px;
			height: 15px;
		}

		@if $type ==pc {
			width: 20px;
			height: 15px;
		}

	}
}

.mfp-content {
	@if $type =="" {}

	@if $type ==sp {
		padding: 20px 7px;
	}

	@if $type ==pc {
		padding: 60px;
	}
}

.mfp-close {
	@if $type =="" {
		display: none !important;
	}
}

.mfp-bg {
	@if $type =="" {
		background: rgba(0, 0, 0, .5);
	}

	@if $type ==sp {}

	@if $type ==pc {}

}

.adjustContents {
	@if $type ==pc {
		max-width: 1000px;
		margin: 0 auto;
	}
}


.splitButton {
	@if $type ==sp {
		display: flex;
		gap: 5px;
		margin-top: 20px;

		.btnWrap {
			margin: 0;
			flex: 1;
		}
	}

	@if $type ==pc {
		display: flex;
		margin: 0 0 0 auto;
	}
}

.adjustContents {
	@if $type ==sp {
		p.colorRed:first-of-type {
			margin-top: 20px;
		}
	}
}

input.spNoPlaceholder {
	@if $type ==sp {
		&::placeholder {
			color: transparent;
		}
	}
}


span.placeholder {
	@if $type ==sp {
		display: block;
		font-size: .8em;
	}

	@if $type ==pc {
		display: none;
	}
}

// 現状サイトの404ページの再現のために追加しています
#areaWrapper1 {
	@if $type ==sp {
		overflow: inherit;
		width: 90%;
		margin: 30px auto 0;
	}

	@if $type ==pc {
		max-width: 1220px;
		width: 95%;
		margin: 0 auto 0;
		justify-content: space-between;
		padding-bottom: 100px;
	}

	@media screen and (min-width: 600px) and (max-width: 1000px) {
		padding-bottom: 0;
	}
}

.flexBtn {
	@if $type ==sp {
		margin-top: 30px;
	}

	@if $type ==pc {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-top: 50px;
	}

	@media screen and (min-width: 600px) and (max-width: 1000px) {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-top: 50px;
	}

	a {
		@if $type ==sp {
			display: block;
			padding: .5em 1em;
			background-color: #bef1e6;
			color: #088E98;
			text-decoration: none;
			text-align: center;

			+a {
				margin-top: 1em;
			}
		}

		@if $type ==pc {
			display: block;
			padding: .5em 1em;
			background-color: #bef1e6;
			color: #088E98;
			text-decoration: none;
			margin: 1em .5em;
		}

		@media screen and (min-width: 600px) and (max-width: 1000px) {
			display: block;
			padding: .5em 1em;
			background-color: #bef1e6;
			color: #088E98;
			text-decoration: none;
			margin: 1em .5em;
		}
	}
}

#mainArea {
	@if $type ==sp {}

	@if $type ==pc {}

	#backBtn {
		@if $type ==sp {
			width: 170px;
			height: 40px;
			background-color: #fff;
			border: 1px solid #cfcfcf;
			margin-top: 40px;
		}

		@if $type ==pc {
			width: 170px;
			height: 40px;
			background-color: #fff;
			border: 1px solid #cfcfcf;
			margin-top: 40px;
		}

		@media screen and (min-width: 600px) and (max-width: 1000px) {
			width: 170px;
			height: 40px;
			background-color: #fff;
			border: 1px solid #cfcfcf;
			margin-top: 40px;
		}

		a {
			@if $type ==sp {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #000;
				padding: 0 20px 0 10px;
				font-size: 14px;
				position: relative;
			}

			@if $type ==pc {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #000;
				padding: 0 20px 0 10px;
				font-size: 14px;
				position: relative;
			}

			@media screen and (min-width: 600px) and (max-width: 1000px) {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #000;
				padding: 0 20px 0 10px;
				font-size: 14px;
				position: relative;
			}

			&:before {
				@if $type ==sp {
					content: "";
					display: block;
					width: 8px;
					height: 8px;
					border-bottom: 2px solid #ccc;
					border-left: 2px solid #ccc;
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					position: absolute;
					top: 15px;
					left: 15px;
				}

				@if $type ==pc {
					content: "";
					display: block;
					width: 8px;
					height: 8px;
					border-bottom: 2px solid #ccc;
					border-left: 2px solid #ccc;
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					position: absolute;
					top: 15px;
					left: 15px;
				}

				@media screen and (min-width: 600px) and (max-width: 1000px) {
					content: "";
					display: block;
					width: 8px;
					height: 8px;
					border-bottom: 2px solid #ccc;
					border-left: 2px solid #ccc;
					-webkit-transform: rotate(45deg);
					transform: rotate(45deg);
					position: absolute;
					top: 15px;
					left: 15px;
				}
			}
		}
	}
}

.modal {

	.modalInner {
		&.subContent {
			@if $type =="" {}

			@if $type ==sp {}

			@if $type ==pc {}

			.modalBody {
				.compBox {
					@if $type =="" {}

					@if $type ==sp {}

					@if $type ==pc {
						padding-top: 16px;
						padding-bottom: 40px;
					}

					.title+p {
						@if $type =="" {}

						@if $type ==sp {
							margin-top: 0;
						}

						@if $type ==pc {
							margin-top: 0;
						}

					}
				}
			}


		}
	}
}
//↑ここまでmailinglistのcss流用（mailinglistコンテンツの追加のためコピー）
// -------------------- vars ----------------------- //
$grn: #088e98;

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

.rotate-90 {
	transform: rotate(-90deg);
}

.section.subContent {
	margin-bottom: 100px;
}

// breadcrumb ---------------
.breadcrumb {
	nav {
		@if $type==sp {
			padding: 10px 0 20px 0;
		}

		@if $type==pc {
			padding: 20px 0;
		}

		overflow: hidden;
		background: #fff;
		position: relative;
		top:0;
		height: 100%;
		z-index: 1;
		font-size: 14px;

		ol {
			float: left;

			li {
				list-style: none;
				float: left;
				padding-right: 20px;
				position: relative;

				a {
					color: #000;
					font-size: 14px;

					&:hover {
						text-decoration: none;
						color: #088E98
					}
				}

				.active {
					color: #088E98
				}

				.breadcrumb-home-a::after {
					content: "";
					display: block;
					width: 4px;
					height: 4px;
					transform: rotate(45deg) translateY(-50%);
					position: absolute;
					top: 50%;
					right: 10px;
					border-top: 1px solid #CFCFCF;
					border-right: 1px solid #CFCFCF;
				}
			}
		}
	}

	h1 {
		position: relative;
		padding-left: 22px;
		line-height: 44px;

		&:after {
			content: "";
			display: block;
			width: 0;
			height: 44px;
			position: absolute;
			top: 0;
			left: 0;
			border-right: 6px solid #088E98;
		}
	}
}



h2 {
	font-size: 32px;
	font-weight: 700;
	line-height: 1;
	padding-left: .6em;
	position: relative;

	&:before {
		content: "";
		display: block;
		width: 6px;
		height: 1.2em;
		background-color: $grn;
		position: absolute;
		left: 0;
		top: calc(50% - .6em);
	}
}

h3 {
	font-size: 16px;
	font-weight: 700;
	border-bottom: 2px solid $grn;
	padding-bottom: 10px;
}

.menteMenu {
	display: flex;
	flex-wrap: wrap;
	background-color: #eff9f7;
	margin-top: 40px;
	padding: 40px 10.65%;
	width: 100%;

	a {
		display: block;
		display: flex;
		width: calc(25% - 21px);
		flex-direction: column;
		align-items: center;
		color: #000;
		text-decoration: none;
		background-color: #fff;
		border-radius: 15px;
		box-shadow: 0 0 10px rgba(#cbebe4, 1);
		padding: 32px 0;
		position: relative;
		max-width: 230px;

		&:not(:nth-child(4n)) {
			margin-right: 27px;
		}

		&:nth-child(n + 5) {
			margin-top: 2.8%; 
		}

		&:hover {
			.icon {
				opacity: .8;

				svg,img {
					transform: scale(1.5);
				}
			}
		}

		.blank {
			position: absolute;
			top: 20px;
			right: 20px;

			svg {
				width: 16px;
				height: 16px;
				fill: $grn;
			}
		}

		.icon {
			aspect-ratio: 1 / 1;
			width: 45%;
			border-radius: 50%;
			background-color: $grn;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all .3s;

			svg,img {
				fill: #fff;
				transition: all .3s;
			}

			svg {
				aspect-ratio: 1 / 1;
				width: 30%;
			}

			img {
				width: 40%;
			}
		}

		.title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 30px;
			width: 90%;
			height: 42px;

			.name {
				flex: 1;
				font-size: 16px;
				line-height: 1.3;
				text-align: center;

				span {
					font-size: 13px;
					display: block;
				}
			}

			svg {
				width: 16px;
				height: 16px;
				fill: $grn;
				margin-left: 5px;
				transform: rotate(180deg);
			}
		}
	}
}


#bengoshiTab {
	margin-top: 20px;

	ul {
		list-style: none;
		display: flex;
		gap: 10px;
		border-bottom: 2px solid $grn;
		background-color: #fff;

		li {

			a {
				height: 100%;
				position: relative;
				background-color: #fff;
				display: flex;
				align-items: center;
				text-decoration: none;
				border-top: 1px solid #737373;
				border-right: 1px solid #737373;
				border-left: 1px solid #737373;
				padding: 10px;

				.icon {
					flex: none;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 33px;
					height: 33px;
					border-radius: 50%;
					background-color: #b8b8b8;

					svg {
						width: 15px;
						height: 15px;
						fill: #fff;
					}

					img {
						width: 20px;
						height: 20px;
					}
				}

				.title {
					font-size: 18px;
					font-weight: 500;
					color: #737373;
					text-align: center;
					margin: 0 15px;
				}

				.arrow {
					width: 20px;
					height: 20px;

					svg {
						width: 12px;
						height: 12px;
						fill: #737373;
						transform: rotate(-90deg);
					}
				}

				&.active {
					border-color: $grn;
					border-width: 2px;
					border-bottom: 3px solid #fff;

					&:after {
						content: "";
						display: block;
						width: 100%;
						height: 2px;
						background: #fff;
						position: absolute;
						bottom: -5px;
						left: 0;
					}
				}

				&.active,
				&:hover {
					z-index: 5;

					.icon {
						background-color: $grn;
					}

					.title {
						color: $grn;
					}
				}
			}
		}
	}
}

.ctrlPanel {
	width: 100%;
	padding: 60px 0;
}

#ctrlBtns {
	background-color: #eff9f7;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px 60px;
	gap: 20px;
	margin-top: 20px;

	a {
		text-decoration: none;

		@if $type =="" {
		}
		@if $type ==sp {
		}
		@if $type ==pc {
			padding: 0 60px;
		}
	}

	.item {
		cursor: pointer;
		background-color: #fff;
		border-radius: 15px;
		box-shadow: 0 0 10px rgba(#cbebe4, 1);
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		flex: 1;
		min-height: 88px;
		transition: all .3s;

		&:hover {
			background-color: rgba($grn, .1);
		}

		.icon {
			width: 30px;
			height: 30px;
			position: absolute;
			top: calc(50% - 15px);
			left: 20px;

			svg {
				width: 100%;
				height: 100%;
				fill: $grn;
			}
		}

		.title {
			font-size: 20px;
			color: $grn;
		}

		&.disabled {
			background-color: #e6e6e6;
			pointer-events: none;
			box-shadow: none;

			.icon {
				svg {
					fill: #737373;
				}
			}

			.title {
				color: #737373;
			}
		}
	}
}

.ctrlMess {
	background-color: #eff9f7;
	width: 100%;
	min-height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 40px;

	.title {
		font-size: 18px;
		font-weight: 700;
	}

	.memo {
		font-size: 16px;
		margin-top: 20px;
	}

	&#errer {
		background-color: #fff0f0;

		.title,
		.memo {
			color: #a72525;
		}
	}
}


.loading {
	width: 480px;
	height: 26px;
	border-radius: 13px;
	background-color: #d1eaec;
	position: relative;
	overflow: hidden;
	margin-top: 20px;

	.bar {
		width: 50%;
		background: radial-gradient(circle, rgba(0, 185, 198, 1) 0%, rgba(8, 142, 152, 1) 100%);
		background-size: 200% 100%;
		background-position: -100% 0;
		height: 26px;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 12px;
		animation: barAni 3s infinite linear;
	}
}

@keyframes barAni {
	50% {
		background-position: 0 0;
	}

	100% {
		background-position: -100% 0;
	}
}

.button {
	width: 220px;
	height: 48px;
	border: 1px solid #707070;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	color: #707070;
	font-size: 16px;
	margin-top: 20px;
	transition: all .3s;
	border-radius: 5px;

	.icon {
		width: 16px;
		height: 16px;
		position: absolute;
		top: calc(50% - 10px);
		left: 15px;
		margin: 0;
		display: block;

		svg {
			width: 16px;
			height: 16px;
			fill: #707070;
		}
	}

	&:hover {
		background-color: rgba($grn, .2);
	}
}

.backBtn {
	margin-top: 60px;

	.button {
		width: 280px;
		text-decoration: none;
		color: #707070;
		margin: 0 auto;
	}
}

#log {
	margin-top: 30px;

	.logTable {
		list-style: none;
		margin-top: 20px;

		li {
			border-bottom: 1px dotted #b8b8b8;
			display: flex;
			position: relative;
			font-size: 16px;
			padding-left: 1em;
			padding-bottom: 10px;

			&+li {
				margin-top: 10px;
			}

			&.error {
				color: #a72525;
			}

			&:before {
				content: "";
				display: block;
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background-color: $grn;
				position: absolute;
				top: 10px;
				left: 2px;
			}

			.date {
				width: 340px;
			}

			.matter {
				flex: 1;
			}
		}
	}
}

ul.formRow {
	list-style: none;

	li {
		display: flex;
		gap: 15px;

		&+li {
			margin-top: 20px;
		}
	}

	.kokaiLabel {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 180px;
		height: 42px;
		border-radius: 5px;
		border: 1px solid #b8b8b8;
		background-color: #fff;
		position: relative;
		cursor: pointer;

		&:before {
			content: "";
			display: block;
			width: 17px;
			height: 17px;
			background-color: #e0e0e0;
			position: absolute;
			left: 10px;
			top: calc(50% - 8px);
			border-radius: 50%;
		}

		&.checked {
			background-color: #eefdfe;
			border: 2px solid $grn;
			color: #000 grn;

			&:before {
				width: 10px;
				height: 15px;
				border-right: 3px solid $grn;
				border-bottom: 3px solid $grn;
				transform: rotate(45deg);
				border-radius: 0;
				background-color: transparent;
				top: 6px;
			}
		}
	}
}

.inputCal {
	display: flex;
	width: 183px;
	height: 42px;
	border: 1px solid #b8b8b8;
	border-radius: 5px;
	overflow: hidden;
	align-items: center;
	padding: 0 10px;
	cursor: pointer;

	&.disabled {
		background-color: #e6e6e6;
		cursor: default;

		input {
			background-color: #e6e6e6;
			cursor: default;
		}
	}

	&.error {
		background-color: #fff0f0;

		input {
			background-color: transparent;
		}
	}

	svg {
		width: 20px;
		height: 20px;
		fill: #737373;
	}

	input {
		width: 140px;
		padding: 0 10px;
		cursor: pointer;
	}
}

.timeSelect {
	position: relative;
	z-index: 5;

	.timeBtn {
		display: flex;
		width: 150px;
		height: 42px;
		border: 1px solid #b8b8b8;
		border-radius: 5px;
		overflow: hidden;
		align-items: center;
		padding: 0 10px;
		cursor: pointer;

		&.disabled {
			background-color: #e6e6e6;
			cursor: default;

			input {
				background-color: #e6e6e6;
				cursor: default;
			}
		}

		svg {
			width: 20px;
			height: 20px;
			fill: #737373;
		}

		input {
			width: 100px;
			padding: 0 10px;
			cursor: pointer;
		}
	}
}

.timeSelectArea {
	display: none;

	.timeSelectInner {
		border: 1px solid #d2d2d2;
		background-color: #fff;
		padding: 20px;
		display: flex;
		gap: 20px;
		position: absolute;
		top: 20px;
		left: 0;

		.title {
			text-align: center;
			border-bottom: 1px solid #d2d2d2;
		}
	}

	#hour {

		.H {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
			grid-template-rows: 1fr 1fr;
			background-color: #d2d2d2;
			gap: 1px;
			padding: 1px;
			margin-top: 10px;

			&+.H {
				margin-top: 20px;
			}

			span {
				grid-row: 1 / span 2;
				grid-column: 1;
				line-height: 1;
				width: 2em;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #F7F6F4;
			}

			label {
				background-color: #fff;
				line-height: 1;
				width: 2em;
				aspect-ratio: 1 / 1;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				&.checked {
					background-color: #bef1e6;
				}
			}
		}
	}

	#min {

		.M {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			background-color: #d2d2d2;
			gap: 1px;
			padding: 1px;
			margin-top: 10px;

			label {
				background-color: #fff;
				line-height: 1;
				width: 2em;
				aspect-ratio: 1 / 1;
				display: flex;
				justify-content: center;
				align-items: center;

				&.checked {
					background-color: #bef1e6;
					cursor: pointer;
				}
			}
		}
	}
}

.underBtns {
	margin-top: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;

	button {
		width: 220px;
		height: 48px;
		border-radius: 5px;
		color: #737373;
		border: none;
		outline: none;
		cursor: pointer;

		&.back {
			position: relative;

			svg {
				width: 20px;
				height: 20px;
				fill: #737373;
				position: absolute;
				top: calc(50% - 10px);
				left: 15px;
			}

			&.white {
				min-width: 220px;
				width: auto;
				padding: 0 15px 0 40px;
				background-color: #fff;
				border: 1px solid #737373;
			}
		}

		&.regist {
			background-color: #e6e6e6;

			&.noErrer {
				background-color: $grn;
				color: #fff;
			}
		}
	}
}

.completionArea {
	background-color: #f7f6f4;
	width: 100%;
	padding: 40px;
	text-align: center;
	margin-top: 60px;

	.title {
		font-size: 22px;
		font-weight: 700;
	}

	p {
		margin-top: 40px;
	}
}


.colorGreen {
	.searchMenu+.registErrer {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 30px;
		}

		@if $type ==pc {
			margin-top: 40px;
		}

	}

	.compBox+.registErrer {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 20px;}

		@if $type ==pc {
			margin-top: 20px;
		}
	}

	.registErrer {
		border: none;

		@if $type ==sp {
			padding-top: 30px;
			padding-bottom: 20px;
		}

		@if $type ==pc {
			padding-top: 6px;
			padding-bottom: 20px;
		}

		.title {
			@if $type == sp {
				margin-bottom: 20px;
			} 
			@if $type == pc {
			} 
			
		}
	}

	.errorText {
		margin-right: auto;
		margin-left: auto;
		color: #A72525;
		background: #fff;

		@if $type ==sp {
			width: calc(100% - 40px);
			padding: 20px 30px;
		}

		@if $type ==pc {
			width: calc(100% - 40px);
			padding: 20px 30px;
		}
	}

	* + .errorList {
		@if $type == sp {
			margin-top: 12px;
		} 
		@if $type == pc {
			margin-top: 12px;
		} 
	}
}

.registErrer {
	border: 2px solid #a72525;
	background-color: #fff0f0;
	margin-top: 60px;
	padding-top: 40px;

	.title {
		font-size: 22px;
		font-weight: 700;
		color: #a72525;
		display: flex;
		justify-content: center;
		align-items: center;
		background: inherit !important;

		svg {
			width: 22px;
			height: 22px;
			fill: #a72525;
			margin-right: 10px;
		}
	}

	.errerInfo {
		background-color: #fff;
		display: block;
		margin: 40px;
		padding: 30px;
		color: #a72525;
	}
}


.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
	background-color: #bef1e6 !important;
}

// ------------------ modal ------------------------- //

.modal {
	width: 100%;
	height: 100vh;
	background-color: rgba(#000, .8);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	display: none;

	.modalInner {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		&.subContent {
			@if $type ==sp {}

			@if $type ==pc {}

			.modalBody {
				position: relative;

				@if $type ==sp {
					padding-bottom: 148px !important;
				}

				@if $type ==pc {
					padding-bottom: 128px !important;
				}

				.modBodyIn {
					@if $type ==sp {
						overflow-y: auto;
						max-height: calc(100vh - 355px);
					}

					&:not(.scrollNonePC) {
						@if $type ==pc {
							overflow-y: auto;
							max-height: calc(100vh - 300px);
						}
					}

					*+.modBodyInScrollPC {
						@if $type ==sp {
							margin-top: 30px;
						}

						@if $type ==pc {
							margin-top: 30px;
						}
					}

					.modBodyInScrollPC {
						@if $type ==pc {
							overflow-y: auto;
							max-height: calc(100vh - 380px);
						}
					}
				}

				.modalBtns {
					@if $type ==sp {
						flex-direction: column;
						margin-top: 0;
						position: absolute;
						right: 0;
						left: 0;
						margin: auto;
						bottom: 20px;

						.btn+* {
							margin-top: 0;
						}
					}

					@if $type ==pc {
						position: absolute;
						right: 0;
						left: 0;
						margin: auto;
						bottom: 40px;
					}
				}

				.modTable {
					@if $type =="" {
						overflow: clip;
					}

					@if $type ==sp {
						border-top: none !important;
					}

					@if $type ==pc {}

					tr {

						//th,
						//td {
						//	@if $type ==sp {
						//		display: block !important;
						//		width: 100% !important;
						//	}

						//	@if $type ==pc {}

						//}
					}
				}
			}

			*+.inputWrap {
				@if $type =="" {}

				@if $type ==sp {
					margin-top: 20px;
				}

				@if $type ==pc {
					margin-top: 30px;
				}
			}

			.inputWrap {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {}

				.inputSearchBtnWrap {
					@if $type =="" {}

					@if $type ==sp {}

					@if $type ==pc {
						width: 100%;
						max-width: 464px;
						margin-left: 0;
					}

					.inputSearchInput {
						@if $type =="" {}

						@if $type ==sp {
							width: 100%;
						}

						@if $type ==pc {
							width: 100%;
						}
					}
				}

				+.errorMsg {
					@if $type =="" {}

					@if $type ==sp {
						margin-top: 6px;
					}

					@if $type ==pc {
						margin-top: 6px;
					}
				}
			}

			.modTableNote {
				@if $type =="" {}

				@if $type ==sp {}

				@if $type ==pc {}

				+.modTable {
					@if $type =="" {}

					@if $type ==sp {}

					@if $type ==pc {
						margin-top: 17px !important;
					}

				}
			}
		}

		.modalBody {
			background-color: #fff;

			&.alert {
				width: 580px;
				padding: 30px;

				.icon {
					width: 40px;
					margin: 0 auto;

					svg {
						width: 40px;
						height: 40px;
						fill: #d2d2d2;
					}
				}

				.title {
					font-size: 20px;
					font-weight: 700;
					text-align: center;
				}

				p {
					font-size: 16px;
					text-align: center;
				}

				.loading+p {
					margin-top: 20px;
				}
			}

			&.operation {
				max-width: 1220px;
				width: 95%;
				padding-bottom: 80px;

				.title {
					background-color: #bef1e6;
					text-align: center;
					font-size: 20px;
					font-weight: 700;

					@if $type == sp {
						padding: 10px;
					} 
					@if $type == pc {
						padding: 24px 10px;
					} 
					
				}

				.modBodyIn {
					@if $type ==sp {
						padding: 30px 20px 0;
					}

					@if $type ==pc {
						padding: 30px 50px 0;
					}


					table.modTable {
						border: 1px solid #d2d2d2;
						width: 100%;
						margin-top: 30px;

						&:not(.thSticky) {
							th {
								@if $type ==sp {
									width: 40%;
								}
							}
						}

						th {
							text-align: left;
							background-color: #FBFAF9;
							padding: 10px;
							font-size: 14px;
							border-top: 1px solid #d2d2d2;

		
							@if $type ==pc {
								width: 180px;
							}

							&[scope="row"] {
								width: inherit;
								text-align: center;
							}

						} 

						td {
							padding: 10px;
							border-top: 1px solid #d2d2d2;

							.errorMess {
								display: block;
								font-size: 14px;
								text-align: left;
								color: #a72525;

								+* {
									margin-top: 8px;
								}
							}
						}

						.iconRequired {
							display: inline-block;
							padding: 2px 5px;
							margin-right: 11px;
							color: #fff;
							font-size: 12px;
							text-align: center;
							font-style: normal;
							vertical-align: baseline;
							font-weight: 400;
							line-height: 1;
							background: #A72525;
							box-sizing: border-box;
						}
					}

					p.error {
						background-color: #fff0f0;
						display: block;
						padding: 5px;
						color: #a72525;
					}
				}

				.greenArrow {
					margin-top: 30px;
					width: 100%;

					&:before {
						content: "";
						display: block;
						width: 0;
						height: 0;
						border-top: 25px solid $grn;
						border-right: 35px solid transparent;
						border-left: 35px solid transparent;
						margin: 0 auto;
					}
				}
			}


			.compBox {
				background-color: #f7f6f4;
				padding-top: 50px;
				padding-bottom: 50px;

				.title {
					background-color: transparent;

					a {
						color: #707070;
						border: 1px solid #707070;
						width: 200px;
						height: 48px;
						display: flex;
						justify-content: center;
						align-items: center;
						position: relative;
						border-radius: 5px;
						font-weight: 400;
						font-size: 16px;
						margin: 30px auto 0;
						background-color: #fff;

						&:hover {
							text-decoration: none;
						}
					}
				}

				p {
					text-align: center;
				}

				.custom_hover {
					width: 200px;
					height: 48px;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					border-radius: 5px;
					color: #707070;
					border: 1px solid #707070;
					text-decoration: none;
					background-color: #fff;
					margin: 20px auto 0;
				}

				&.errorBox {
					background-color: #fff0f0;
					color: #a72525;
				}

				table.modTable {

					td {
						background: #fff;
					}
				}
			}

			.modalBtns {
				margin-top: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 15px;

				.btn {
					width: 200px;
					height: 48px;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					border-radius: 5px;
					cursor: pointer;

					&.cancel {
						color: #707070;
						border: 1px solid #707070;
					}

					&.goOn {
						background-color: $grn;
						color: #fff;
					}

					.btnIcon {
						width: 11px;
						height: 11px;
						position: absolute;
						top: calc(50% - 6px);
						left: 15px;
						fill: #707070;
					}
				}
			}
		}

		.searchMenu {
			@if $type ==sp {
				margin-top: 0;
			}

			@if $type ==pc {
				margin-top: 0;
			}

			+.compBox {
				@if $type ==sp {
					margin-top: 30px;
				}

				@if $type ==pc {
					margin-top: 40px;
				}

			}
		}

		#upFileWrap {
			@if $type ==sp {
				display: block;
				padding: 15px 20px;
				background: #F7F6F4;
			}

			@if $type ==pc {
				display: block;
				padding: 33px 40px;
				background: #F7F6F4;
			}

			#inputFile {
				+.errorMsg {
					@if $type ==sp {
						margin-top: 10px;
					}

					@if $type ==pc {
						margin-top: 10px;
					}

				}

				+.btnWrap {
					@if $type ==sp {
						margin-top: 16px;
					}

					@if $type ==pc {
						margin-top: 8px;
					}
				}
			}

			*+.btnWrap {
				@if $type =="" {}

				@if $type ==sp {
					margin-top: 30px;
				}

				@if $type ==pc {
					margin-top: 30px;
				}
			}
		}

	}
}

#targetfile_name {
	@if $type =="" {}

	@if $type ==sp {
		position: relative;
		margin-top: 1em;
	}

	@if $type ==pc {
		position: relative;
		margin-top: 1em;
	}
}


// -------------------------- upload form ------------------------------ //

.formList {
	background-color: #F7F6F4;
	margin-top: 30px;

	.flex {
		display: flex;
		background-color: #fff;
		margin-top: 20px;
		border: 1px solid #d2d2d2;
		padding: 15px;

		&+.flex {
			margin-top: -1px;
		}
	}

	&.teiki {
		background-color: #e6effd;

		#listFileWrap {

			ul {
				li.listHead {
					background: #a8cfff;
				}
			}

			p {
				span.kubun {
					color: #fff;
					background: #1560bb;
					padding: 2px 10px 4px;
					margin-right: 10px;
				}
			}
		}
	}

	&.rinji {
		background-color: #fff2e3;

		#listFileWrap {

			ul {
				li.listHead {
					background: #ffd09a;
				}
			}

			p {
				span.kubun {
					color: #fff;
					background: #e48314;
					padding: 2px 10px 4px;
					margin-right: 10px;
				}
			}
		}
	}

	dt {
		position: relative;

		.clearBtn {
			width: 170px;
			height: 42px;
			border: 1px solid #b8b8b8;
			background-color: #fff;
			position: absolute;
			top: calc(50% - 21px);
			right: 0;
			font-size: 14px;
			color: #707070;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px;
			cursor: pointer;

			svg {
				width: 11px;
				height: 11px;
				fill: #707070;
			}

			&:hover {
				color: #000;

				svg {
					fill: #000;
				}
			}
		}
	}
}

*+#upFileWrap {
	margin-top: 20px;
}

#upFileWrap,
#listFileWrap {
	//	margin: 0 20px 0 0;
}

#inputFile {
	position: relative;
	padding: 170px 0 70px;
	text-align: center;
	height: 310px;
	cursor: pointer;
}

#dropArea {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 90px 0 0;
	border: 2px dashed #b8b8b8;
	color: #b8b8b8;
	font-size: 1.2em;
	background: #fff;
}

#dropArea.dragover {
	border: 5px dashed #088e93;
}

#uploadFile {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	display: block;
	width: 220px;
	height: 48px;
	opacity: 0;
	cursor: pointer;
}

#inputFileWrap {
	position: relative;
	display: inline-block;
	border: 2px solid $grn;
	color: $grn;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
}

#selectFile {
	border-color: #088e93;
	color: #088e93;
	cursor: pointer;

	span {
		cursor: pointer;
	}
}

#listFileWrap {
	position: relative;
	font-size: 0.8em;

	p {
		margin: 15px 0;
	}

	ul {
		list-style: none;
		border: 1px solid #e3e3e3;
		background: #fff;

		li {
			border-bottom: 1px solid #e3e3e3;

			span {
				//	width: 200px;
				padding: 10px;
				display: inline-block;


				&.csvname {
					width: 285px;
				}
			}
		}
	}
}

.btn.btnGreen {
	color: #fff;
	border-color: #088e93;
	background: #088e93;

	&:disabled {
		color: #818181;
		border-color: #e6e6e6;
		background: #e6e6e6;
	}
}

.subContent {
	.modalBody.operation {
		.modBodyIn {
			@if $type == sp {
			} 
			@if $type == pc {
				max-width: 1100px;
				margin-right: auto;
				margin-left: auto;
			}
			@if $type == tb {
				.scrollTable {
					.modTable.thSticky {
						min-width: 1000px;
					}
				}
			} 
		}
	}


	.formList {
		padding: 30px;

		&>div {

			&>dt {
				width: 220px;
			}
		}
	}
}

.errorMsg {
	color: #a72525;
	font-weight: bold;
}

.seisei_date {
	margin-right: 20px;
}

.base_date {
	margin-right: 20px;
}

.errorRed{
	color: #a72525;
}

#overlay {
	z-index: 5;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}

#content {
	z-index: 6;
	width: 540px;
	padding: 30px;
	background: #fff;
	text-align: center;
}

#date-update {
	z-index: 6;
	width: 540px;
	padding: 30px;
	background: #fff;
	text-align: center;
}


#content .iconAttention {
	width: 40px;
	height: 40px;
	filter: grayscale(100%);
	background-color: rgba(255, 255, 255, 0.8);
	background-blend-mode: lighten;
}

.subContent .inputCal.inputSearch:before {
	background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22currentColor%22%20class%3D%22bi%20bi-calendar%22%20viewBox%3D%220%200%2016%2016%22%3E%20%3Cpath%20d%3D%22M3.5%200a.5.5%200%200%201%20.5.5V1h8V.5a.5.5%200%200%201%201%200V1h1a2%202%200%200%201%202%202v11a2%202%200%200%201-2%202H2a2%202%200%200%201-2-2V3a2%202%200%200%201%202-2h1V.5a.5.5%200%200%201%20.5-.5zM1%204v10a1%201%200%200%200%201%201h12a1%201%200%200%200%201-1V4H1z%22%2F%3E%3C%2Fsvg%3E') !important;
	background-color: rgba(255, 255, 255, 0.8);
	background-blend-mode: lighten;
}


#date-update .title {
	font-size: 1.2em;
	font-weight: bold;
}

#date-update .caption {
	font-size: 0.8em;
}

#date-update .step-progress-wrap {
	margin: 30px;
	background-color: #cde8e9;
	border-radius: 100px;
}

#date-update .step-progress {
	display: flex;
	width: 100%;
	height: 15px;
	background-color: #088e93;
	transition: 0.5s;
	border-radius: 100px;
}

.vshow-enter-active,
.vshow-leave-active {
	transition: opacity 0.3s
}

.vshow-enter,
vshow-leave-to {
	opacity: 0;
}

.vld-overlay {
	color: #fff;
	background: rgba(0, 0, 0, 0.6);
	transition: all 0.5s ease-out;
}

[v-cloak] {
	visibility: hidden;
}



.logTable {

	&.gradient {
		-webkit-mask-image: linear-gradient(#fff, rgba(255, 255, 255, 0));
	}

	&.maxHeight {
		max-height: 400px;
	}
}

.d-none {
	display: none;
}

#btnShow {
	display: flex;
	justify-content: center;
	align-items: center;

	.button {
		color: #438E98;
		border: 1px solid #438E98;
	}

	.arrow-down {
		transform: rotate(-90deg);
	}
}

.custom-error {
	text-decoration-line: none !important;
}

/* css button clear file maintenance */
div.message-error {
	position: relative;
}

div.clearBtn {
	width: 170px;
	height: 42px;
	border: 1px solid #b8b8b8;
	background-color: #fff;
	position: absolute;
	top: calc(50% - 21px);
	right: 0;
	font-size: 14px;
	color: #707070;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	cursor: pointer;

	svg {
		width: 11px;
		height: 11px;
		fill: #707070;
	}

	&:hover {
		color: #000;

		svg {
			fill: #000;
		}
	}
}

div#errorMsgZip {

	.errorMsg {
		margin-left: 0px;
	}
}

.color_a72525 {
	color: #a72525;
}

.file-item {
	display: flex;
	align-items: center;
}

img.imgIconFile {
	padding-top: 2px;
}

#listFileWrap {
	.listHead {
		display: grid;
		grid-template-columns: 20% 30% 8% 10% 32%;
	}

	.file-item {
		display: grid;
		grid-template-columns: 20% 30% 8% 10% 32%;
	}
}

.errorList {
	@if $type == "" {
	} 
	@if $type == sp {
	} 
	@if $type == pc {
	} 
	
	> li {
		@if $type == "" {
		} 
		@if $type == sp {
		} 
		@if $type == pc {
		} 
		
		&:before {
			@if $type == sp {
				content: "・";
				color: #A72525;
			} 
			@if $type == pc {
				content: "・";
				color: #A72525;
			} 
		}
	}
}

.contentDisable {
	@if $type =="" {
		color: #B8B8B8;
		text-decoration: none !important;
		pointer-events: none;
	}
	@if $type ==sp {
	}
	@if $type ==pc {
	}

	.checkboxLabel input[type=checkbox],
	.checkboxLabel input[type=checkbox]:checked {
		@if $type =="" {
			color: #B8B8B8 !important;
			background: #EEEDEC !important;
			box-shadow: 0 0 0 2px #B8B8B8 !important;

			& + span {
				color: #B8B8B8 !important;
				background: #EEEDEC !important;
				box-shadow: 0 0 0 2px #B8B8B8 !important;

				&:before {
					opacity: 1 !important;
					background: #E0E0E0 !important;
				}

				&:after {
					opacity: 0 !important;
				}
			}
		}
		@if $type ==sp {
		}
		@if $type ==pc {
		}
	}
}

.calendarList {
	@if $type ==pc {
	  display: flex;
	  align-items: center;
	  font-size: 13px;
  
	  &.row {
		flex-direction: column;
		align-items: flex-start;
  
		>li {
		  +li {
			margin-top: 8px;
			margin-left: 0;
		  }
		}
	  }
  
	  >li {
		+li {
		  margin-left: 14px;
		}
	  }
	}

	.inputSearch.inputCal {
		@if $type =="" {
			display: flex !important;
			padding: 0 10px;
			background: #fff;
		}
		@if $type ==sp {
		}
		@if $type ==pc {
			max-width: 188px;
		}

		&:before {
			@if $type =="" {
				display: none !important;
			}
			@if $type ==sp {
			}
			@if $type ==pc {
			}
		}

		.input {
			@if $type =="" {
				padding: 0 10px;
				border: none;
			}
			@if $type ==sp {
			}
			@if $type ==pc {
			}
			
			&[type="text"],
			&::placeholder {
				@if $type =="" {
					color: #737373;
				}
				@if $type ==sp {
				}
				@if $type ==pc {
					font-size: 16px;
				}
			}

			&:focus,
			&:active {
				@if $type =="" {
					background: transparent !important;
				}
				@if $type ==sp {
				}
				@if $type ==pc {
				}
			}
		}
	}
  }

* + .newsBox {
	@if $type =="" {
	}
	@if $type ==sp {
		margin-top: 25px;
	}
	@if $type ==pc {
		margin-top: 40px;
	}
}

.newsBox {
	@if $type =="" {
		background: #EEFDFE;
	}
	@if $type ==sp {
		padding: 20px;
	}
	@if $type ==pc {
		display: flex;
		align-items: center;
		padding: 28px 42px 21px 0;
	}

	> dt {
		@if $type =="" {
			color: #088E98;
			font-weight: 600;
		}
		@if $type ==sp {
			font-size: 20px;
		}
		@if $type ==pc {
			width: 170px;
			padding: 0 10px;
			font-size: 20px;
			text-align: center;
		}
	}

	> dd {
		@if $type =="" {
		}
		@if $type ==sp {
			margin-top: 8px;
		}
		@if $type ==pc {
		}
	}

	+ * {
		@if $type =="" {
		}
		@if $type ==sp {
			margin-top: 73px;
		}
		@if $type ==pc {
			margin-top: 73px;
		}
	}
}
  
* + .inputArea {
	@if $type =="" {
	}
	@if $type ==sp {
		margin-top: 20px;
	}
	@if $type ==pc {
		margin-top: 20px;
	}
}

.inputArea {
	@if $type =="" {
		background: #F7F6F4;
	}
	@if $type ==sp {
		display: block;
		padding: 15px 20px;
	}
	@if $type ==pc {
		padding: 42px 40px;
	}

	>*:nth-of-type(1) {
		&.colorBlockTitle {
			@if $type =="" {
			}
			@if $type ==sp {
				margin-top: 20px;
			}
			@if $type ==pc {
			}
		}
	}

	* + .commonDlTitle {
		@if $type =="" {
		}
		@if $type ==sp {
			margin-top: 28px;
		}
		@if $type ==pc {
			margin-top: 28px;
		}
	}

	.commonDl + .commonDlTitle {
		@if $type =="" {
		}
		@if $type ==sp {
			margin-top: 45px;
		}
		@if $type ==pc {
			margin-top: 45px;
		}
	}

	.commonDlTitle {
		@if $type =="" {
			font-weight: 600;
		}
		@if $type ==sp {
		}
		@if $type ==pc {
		}

		+ .commonDl {
			@if $type =="" {
			}
			@if $type ==sp {
				margin-top: 12px;
			}
			@if $type ==pc {
				margin-top: 12px;
			}
		}
	}

	.commonDl {
		@if $type =="" {
			background: #ffffff;

			&.error {
				background: #FFF0F0;
			}

			&.dtSky {
				> div {
					> dt {
						background: #EEFDFE !important;
					}
				}
			}
		}
		@if $type ==sp {
		}
		@if $type ==pc {
		}

		> div {
			> dt {
				@if $type =="" {
					background: #EEEDEC !important;
				}
				@if $type ==sp {
				}
				@if $type ==pc {
				}
			}
		}
	}
}


//プログレスバー
.progBar {
	@if $type =="" {
		background-color:#D1EAEC;
		position:relative;
		border-radius:10px;
		overflow: hidden;
	}
	@if $type ==sp {
		width:90px;
    	height:17px;
	}
	@if $type ==pc {
		width:102px;
    	height:17px;
		margin-top: 5px;
	}

	>.bar {
		@if $type =="" {
			position:absolute;
			height:100%;
			background: radial-gradient(circle, #00b9c6 0%, #088e98 100%);
    		background-size: 200% 100%;
    		background-position: -100% 0;
			animation: progress 4s normal;
			animation-fill-mode: forwards;
			border-radius: 13px;
			animation: barAni 3s infinite linear;
		}

		@if $type ==sp {
		}
		@if $type ==pc {
		}
	}
}

.colorGreen {
	.linkGreen {
		@if $type =="" {
			color: $green;
		}
		@if $type ==sp {
		}
		@if $type ==pc {
		}

		i {
			@if $type =="" {
			}
			@if $type ==sp {
				margin-left: 8px;
			}
			@if $type ==pc {
				margin-left: 8px;
			}
		}
	}
}

*+.listBoxContent {
	@if $type =="" {}

	@if $type ==sp {
		margin-top: 10px;
	}

	@if $type ==pc {
		margin-top: 10px;
	}
}

.formlistBoxTitle {
	@if $type =="" {
	}
	@if $type ==sp {
	}
	@if $type ==pc {
		font-size: 14px;
	}

	+ .formlistBox {
		@if $type =="" {
		}
		@if $type ==sp {
			margin-top: 12px;
		}
		@if $type ==pc {
			margin-top: 10px;
		}
	}
}

.formlistBox {
	@if $type =="" {
		border: 1px solid #D2D2D2;
		background: #fff;
	}

	@if $type ==sp {}

	@if $type ==pc {}

	.listBoxContent {
		@if $type =="" {}

		@if $type ==sp {}

		@if $type ==pc {}

		>li {
			@if $type =="" {
				+li {
					border-top: 1px solid #E6E6E6;
				}
			}

			@if $type ==sp {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 11px 15px;
			}

			@if $type ==pc {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 11px 15px;
			}

			>.listBoxFixed {
				@if $type =="" {}

				@if $type ==sp {
					margin-top: 8px;
					flex: none;
					margin-left: auto;
				}

				@if $type ==pc {
					flex: none;
				}
			}
		}
	}
}

.formWrap {
	@if $type =="" {
		display: flex;
		align-items: center;  
	}

	@if $type ==sp {
	}

	@if $type ==pc {
		flex-wrap: wrap;
	}

	.btnWrap {
		@if $type =="" {
		}
		@if $type ==sp {
			margin-top: 0;
			margin-left: 20px;
		}
		@if $type ==pc {
			margin-top: 0;
			margin-left: 28px;
		}
	}

	.btn {
		@if $type =="" {}

		@if $type ==sp {
			margin-top: 0;
		}

		@if $type ==pc {
			margin-top: 0;
		}
	}

	>* + p {
		@if $type =="" {}

	@if $type ==sp {
		margin-top: 0;
	}

	@if $type ==pc {
		margin-top: 0;
	}
	}
}

.formTitle {
	@if $type =="" {
	}
	@if $type ==sp {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: flex-start;
	}
	@if $type ==pc {
		display: flex;
		align-items: center;
	}

	.formTitleSub {
		@if $type =="" {
			flex: none;
			color: #fff;
			line-height: 1;
			font-weight: 600;
			background: $green;
		}
		@if $type ==sp {
			padding: 6px 5px;
			font-size: 12px;
		}
		@if $type ==pc {
			padding: 9px 7px;
			font-size: 14px;
		}
	}

	strong {
		@if $type =="" {
			font-weight: bold;
		}
		@if $type ==sp {
			margin-top: 3px;
			font-size: 18px;
		}
		@if $type ==pc {
			margin-left: 13px;
			font-size: 20px;
		}
	}
}

* + .fileSelectRow {
	@if $type =="" {
	}
	@if $type ==sp {
		margin-top: 10px;
	}
	@if $type ==pc {
		margin-top: 21px;
	}
}

.fileSelectRow {
	@if $type =="" {
		display: flex;
		align-items: center;
		border: 2px dashed #B8B8B8;
		background: #fff;
		cursor: pointer;
	}

	@if $type ==sp {
		padding: 17px 20px;
		flex-direction: column;
	}

	@if $type ==pc {
		padding: 17px 20px;
	}

	.fileSectLead {
		@if $type =="" {
			color: #B8B8B8;
			text-align: center;
			line-height: 1.33;
		}

		@if $type ==sp {
			font-size: 18px;
		}

		@if $type ==pc {
			font-size: 21px;
		}
	}

	* + .btnWrap {
		@if $type =="" {
		}
		@if $type ==sp {
			margin-top: 8px;
		}
		@if $type ==pc {
			margin-top: 0;
			margin-left: 36px;
		}
	}

	.btnWrap {
		@if $type =="" {
			text-align: center;
		}
		@if $type ==sp {
		}
		@if $type ==pc {
		}

		.btn {
			@if $type =="" {
			}
			@if $type ==sp {
			}
			@if $type ==pc {
				&:hover {
					opacity: 1;
				}
			}
		}
	}

	+ .errorMsg {
		@if $type =="" {
		}
		@if $type ==sp {
		}
		@if $type ==pc {
			margin-top: 10px;
		}
	}
}

* +.fileSelect {
	@if $type =="" {
	}

	@if $type ==sp {
		margin-top: 33px;
	}

	@if $type ==pc {
		margin-top: 33px;
	}

}

.fileSelect {
	@if $type =="" {
		display: flex;
		flex-direction: column;
		align-items: center;
		border: 2px dashed #B8B8B8;
		background: #fff;
		cursor: pointer;
	}

	@if $type ==sp {
		padding: 30px 20px;
	}

	@if $type ==pc {
		padding: 111px 20px 70px;
	}

	.fileSectLead {
		@if $type =="" {
			color: #B8B8B8;
			text-align: center;
			line-height: 1.33;
		}

		@if $type ==sp {
			font-size: 18px;
		}

		@if $type ==pc {
			font-size: 21px;
		}
	}

	* + .btnWrap {
		@if $type =="" {
		}
		@if $type ==sp {
			margin-top: 24px;
		}
		@if $type ==pc {
			margin-top: 34px;
		}
	}

	.btnWrap {
		@if $type =="" {
			text-align: center;
		}
		@if $type ==sp {
		}
		@if $type ==pc {
		}

		.btn {
			@if $type =="" {
			}
			@if $type ==sp {
			}
			@if $type ==pc {
				&:hover {
					opacity: 1;
				}
			}
		}
	}

	+ .errorMsg {
		@if $type =="" {
		}
		@if $type ==sp {
		}
		@if $type ==pc {
			margin-top: 10px;
		}
	}
}


.inputSearchBtnIn {
	@if $type =="" {
	}
	@if $type ==sp {
	}
	@if $type ==pc {
	}

	.inputWrap {
		@if $type =="" {
		}
		@if $type ==sp {
		}
		@if $type ==pc {
		}

		.inputSearchBtnWrap {
			@if $type =="" {
			}
			@if $type ==sp {
			}
			@if $type ==pc {
			}
			
			.inputSearchInput {
				@if $type =="" {
				}
				@if $type ==sp {
					padding-right: 60px !important;
				}
				@if $type ==pc {
				}

				+ label {
					@if $type =="" {
						position: absolute;
						right: 0;
						top: 0;
					}
					@if $type ==sp {
						width: 50px;
						height: 42px;
						border-radius: 0 4px 4px 0;
					}
					@if $type ==pc {
						width: 50px;
						height: 42px;
						border-radius: 0 4px 4px 0;
					}
					
					.inputSearchBtn {
						+ span {
							&:before {
								@if $type =="" {
								}
								@if $type ==sp {
									width: 20px;
									height: 20px;
									margin-right: 0;
								}
								@if $type ==pc {
									width: 20px;
									height: 20px;
									margin-right: 0;
								}
							}
						}
					}
				}
			}

			
		}	
	}
}

.quasiTable {
	@if $type =="" {
		background: #fff;
	}
	@if $type ==sp {
	}
	@if $type ==pc {
	}

	.quasiTableHeader {
		@if $type =="" {
			display: flex;
			width: 100%;
			border-top: 1px solid #E6E6E6;
			border-bottom: 1px solid #E6E6E6;
			background: #F7F6F4;
		}
		@if $type ==sp {
		}
		@if $type ==pc {
		}

		.quasiTableTr {
			@if $type =="" {
			}
			@if $type ==sp {
			}
			@if $type ==pc {
				> * {
				}
			}
		}
	}

	.quasiTableBody {
		@if $type =="" {
			border-bottom: 1px solid #E6E6E6;
			
			.quasiTableTr.disabled {
				background: #DFDFDF;
			}
		}
		@if $type ==sp {
		}
		@if $type ==pc {
		}

		.quasiTableTr {
			@if $type =="" {
			}
			@if $type ==sp {
				> * {
					min-height: 60px;
				}
			}
			@if $type ==pc {
				> * {
					min-height: 80px;
				}
			}
		}
	}

	.quasiTableTr {
		@if $type =="" {
			display: flex;
			width: 100%;

			> *:not(.quasiTableCommittee):not(.quasiTableFull) {
				text-align: center;
				justify-content: center;
				flex: none;
			}

			+ .quasiTableTr {
				border-top: 1px solid #E6E6E6;
			}
		}
		@if $type ==sp {

			> * {
				display: flex;
				align-items: center;
				padding: 15px 20px;
			}
		}
		@if $type ==pc {

			> * {
				display: flex;
				align-items: center;
				padding: 15px 20px;
			}
		}

		.quasiTableFull {
			@if $type =="" {
				//width: calc(100% - 163px);
				width: 100%;
			}
			@if $type ==sp {
			}
			@if $type ==pc {
			}
		}

		.quasiTableCommittee {
			@if $type =="" {
				//width: calc(100% - 630px);
				width: 100%;
			}
			@if $type ==sp {
			}
			@if $type ==pc {
			}
		}

		.quasiTableLabel {
			@if $type =="" {
				width: 44px;
			}
			@if $type ==sp {
				padding-right: 0 !important;
				padding-left: 0 !important;
			}
			@if $type ==pc {
				padding-right: 0 !important;
				padding-left: 0 !important;
			}
		}
		
		.quasiTableAdd {
			@if $type =="" {
				width: 161px;
				color: #A72525;
			}
			@if $type ==sp {
			}
			@if $type ==pc {
			}
		}
		
		.quasiTableDate {
			@if $type =="" {
				width: 163px;
			}
			@if $type ==sp {
			}
			@if $type ==pc {
			}
		}
		
		.quasiTableAbolition {
			@if $type =="" {
				width: 163px;
			}
			@if $type ==sp {
			}
			@if $type ==pc {
			}
		}
		
		.quasiTableInfo {
			@if $type =="" {
				width: 163px;
				font-size: 14px;
			}
			@if $type ==sp {
			}
			@if $type ==pc {
			}

			.textInfo {
				@if $type =="" {
					color: #A72525;
				}
				@if $type ==sp {
					font-size: 14px;
				}
				@if $type ==pc {
					font-size: 16px;
				}
			}
		}
	}

	a.quasiTableTr {
		@if $type =="" {
			color: #000000;
			text-decoration: none;
			position: relative;
			cursor: pointer;
		}
		@if $type ==sp {
		}
		@if $type ==pc {
			transition: .2s opacity;

			&:hover {
				opacity: .7;
			}
		}

		&:after {
			@if $type =="" {
				content: "";
				display: block;
				border-right: 2px solid $green;
				border-bottom: 2px solid $green;
				transform: rotate(-45deg);
				border-radius: 0;
				background-color: transparent;
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
			}
			@if $type ==sp {
				width: 10px;
				height: 10px;
				right: 18px;
			}
			@if $type ==pc {
				width: 10px;
				height: 10px;
				right: 18px;
			}
		}
	}

}

.modalInner {
	.quasiTable {
		@if $type =="" {
		}
		@if $type ==sp {
		}
		@if $type ==pc {
		}
	
		.quasiTableHeader {
			@if $type =="" {
			}
			@if $type ==sp {
			}
			@if $type ==pc {
			}
	
			.quasiTableTr {
				@if $type =="" {
				}
				@if $type ==sp {
				}
				@if $type ==pc {
				}
			}
		}
	
		.quasiTableBody {
			@if $type =="" {
			}
			@if $type ==sp {
			}
			@if $type ==pc {
			}
	
			.quasiTableTr {
				@if $type =="" {
				}
				@if $type ==sp {
					> * {
						min-height: 60px;
					}
				}
				@if $type ==pc {
					> * {
						min-height: 68px;
					}
				}
			}
		}
	
		.quasiTableTr {
			@if $type =="" {
			}
			@if $type ==sp {
	
				> * {
					display: flex;
					align-items: center;
					padding: 12px 20px;
				}
			}
			@if $type ==pc {
	
				> * {
					display: flex;
					align-items: center;
					padding: 9px 20px;
				}
			}

			
	
		}
	
	
	}
}

* + .titleSupplement {
	@if $type =="" {
	}
	@if $type ==sp {
		margin-top: 28px;
	}
	@if $type ==pc {
		margin-top: 28px;
	}
}

.titleSupplement {
	@if $type =="" {
		font-weight: 600;
	}
	@if $type ==sp {
	}
	@if $type ==pc {
	}

	+ * {
		@if $type =="" {
		}
		@if $type ==sp {
			margin-top: 16px;
		}
		@if $type ==pc {
			margin-top: 16px;
		}
	}
}

* + .textAreaWrap {
	@if $type =="" {
	}
	@if $type ==sp {
		margin-top: 12px;
	}
	@if $type ==pc {
		margin-top: 16px;
	}
}

.textAreaWrap {
	@if $type =="" {
		background: #F7F6F4;
	}
	@if $type ==sp {
		padding: 25px 20px 17px;
	}
	@if $type ==pc {
		padding: 25px 20px 17px;
	}

	textarea {
		@if $type =="" {
		}
		@if $type ==sp {
			padding: 10px;
		}
		@if $type ==pc {
			padding: 10px;
		}

		&.inputM {
			@if $type =="" {
			}
			@if $type ==sp {
				min-height: 150px !important;
			}
			@if $type ==pc {
				min-height: 150px !important;
			}
		}

		+ p {
			@if $type =="" {
			}
			@if $type ==sp {
				margin-top: 6px;
			}
			@if $type ==pc {
				margin-top: 6px;
			}
		}
	}

	.textAreaResult {
		@if $type =="" {
			background: #fff;
		}
		@if $type ==sp {
			padding: 29px 28px;
		}
		@if $type ==pc {
			padding: 29px 28px;
		}
	}
}

#log {
	.logTable {
		> li {
			&.hideOpen {
				@if $type =="" {
				}
				@if $type ==sp {
				}
				@if $type ==pc {
				}

				
			}
		}

		
	}

	.btnMore {
		@if $type =="" {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			position: relative;
	
			&:not(:has(.iconSearch)).isOpen  {
				&:before {
					transform: rotate(-135deg) translateY(-2px) translateX(-3px);
				}
	
				&:after {
					opacity: 1;
				}
			}
		}
	
		@if $type ==sp {
			padding-right: 33px;
		}
	
		@if $type ==pc {
			padding-right: 33px;
		}
	
		&:not(:has(.iconSearch)) {
			&:before {
				@if $type =="" {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto;
					transform: rotate(45deg) translateY(-3px);
					transition: .2s transform;
				}
	
				@if $type ==sp {
					width: 8px;
					height: 8px;
					border-right: 1px solid $c_blue;
					border-bottom: 1px solid $c_blue;
					right: 16px;
				}
	
				@if $type ==pc {
					width: 8px;
					height: 8px;
					border-right: 1px solid $c_blue;
					border-bottom: 1px solid $c_blue;
					right: 16px;
				}
			}
		}
	
		&:after {
			@if $type =="" {
				content: "閉じる";
				display: block;
				height: 100%;
				text-align: center;
				background: #fff;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				box-sizing: border-box;
				opacity: 0;
				pointer-events: none;
			}
	
			@if $type ==sp {
				padding-top: 7px;
				padding-bottom: 7px;
				padding-left: 11px;
				border-radius: 4px 0 0 4px;
				width: calc(100% - 33px);
				line-height: 34px;
			}
	
			@if $type ==pc {
				padding-top: 7px;
				padding-bottom: 7px;
				padding-left: 16px;
				border-radius: 4px 0 0 4px;
				width: calc(100% - 33px);
				line-height: 29px;
			}
	
		}
	
		&.btnGreen {
			@if $type =="" {
				color: #000000;
				border-color: #B8B8B8;
	
				&.isOpen {
					&:before {
						transform: rotate(-180deg);
					}
				}
			}
	
			@if $type ==sp {}
	
			@if $type ==pc {
				min-width: 175px;
			}
	
			&:not(:has(.iconSearch)):before {
				@if $type =="" {
					width: 0;
					height: 0;
					border-style: solid;
					border-color: #088E98 transparent transparent transparent;
					pointer-events: none;
					transform: rotate(0);
				}
	
				@if $type ==sp {
					border-width: 7px 6.5px 0 6.5px;
				}
	
				@if $type ==pc {
					border-width: 7px 6.5px 0 6.5px;
				}
			}
		}
	}
}


body.bgLogin {
	@if $type =="" {
		background: #BEF1E6;
	}
	@if $type ==sp {
	}
	@if $type ==pc {
	}

	.subContent {
		@if $type =="" {
			background: #fff;
		}

		&:not(.loginBox) {
			@if $type ==sp {
				padding: 40px 0;
				margin: 0 15px;
				margin-bottom: 0;
			}
			@if $type ==pc {
				width: 95%;
				max-width: 1220px;
				padding: 48px 0 105px;
				margin-top: 10px;
				margin-bottom: 0;
				margin-inline: auto;
			}
	
			.inner {
				@if $type =="" {
				}
				@if $type ==sp {
				}
				@if $type ==pc {
					max-width: 960px;
					margin-inline: auto;
				}
			}
		}

		&.loginBox {
			@if $type ==sp {
				padding: 40px 0;
				margin: 40px 15px 0;
				margin-bottom: 0;
			}
			@if $type ==pc {
				width: 95%;
				max-width: 500px;
				padding: 40px 40px 45px;
				margin-top: 10px;
				margin-bottom: 20px;
				margin-inline: auto;
			}
	
			.inner {
				@if $type =="" {
				}
				@if $type ==sp {
				}
				@if $type ==pc {
					width: 100%;
					margin-inline: auto;
				}
			}

			.loginBoxHeader {
				@if $type =="" {
					text-align: center;
				}
				@if $type ==sp {
					margin-bottom: 20px;
				}
				@if $type ==pc {
					margin-bottom: 20px;
				}

				.lHInner {
					#site_logo {
						@if $type =="" {
							text-decoration: none;
						}
						@if $type ==sp {
						}
						@if $type ==pc {
						}

						> a {
							@if $type =="" {
								text-decoration: none;
							}
							@if $type ==sp {
							}
							@if $type ==pc {
							}
						}
	
						.site_logo_text {
							@if $type =="" {
								color: #000000;
								font-weight: bold;
							}
							@if $type ==sp {
								margin-top: 8px;
							}
							@if $type ==pc {
								margin-top: 13px;
								font-size: 20px;
							}
						}
					}
				}

			}
		}
		
	}

}

.loginBoxContent {
	@if $type =="" {
	}
	@if $type ==sp {
	}
	@if $type ==pc {
	}

	+ .loginBoxContent {
		@if $type =="" {
			border-top: 1px solid #D2D2D2;
		}
		@if $type ==sp {
			padding-top: 28px;
			margin-top: 37px;
		}
		@if $type ==pc {
			padding-top: 28px;
			margin-top: 37px;
		}
	}

	.loginBoxInner {
		@if $type =="" {
		}
		@if $type ==sp {
			max-width: 270px;
			margin-inline: auto;
		}
		@if $type ==pc {
			max-width: 270px;
			margin-inline: auto;
		}

		* + * {
			@if $type =="" {
			}
			@if $type ==sp {
				margin-top: 6px;
			}
			@if $type ==pc {
				margin-top: 6px;
			}
		}

		.btnWrap {
			@if $type =="" {
			}
			@if $type ==sp {
				margin-top: 15px;
	
				.btn + .btn {
					margin-top: 10px;
				}
			}
			@if $type ==pc {
				margin-top: 15px;
			}
		}

	}

	.input {
		@if $type =="" {
		}
		@if $type ==sp {
			padding: 11px 14px;
		}
		@if $type ==pc {
			padding: 11px 14px;
		}
	}
	
}

.loginHeader {
	@if $type =="" {
	}
	@if $type ==sp {
	}
	@if $type ==pc {
		padding-top: 40px;
	}

	.lHInner {
		@if $type =="" {
		}
		@if $type ==sp {
			display: flex;
			align-items: center;
			width: 95%;
			min-height: calc(47px + 5vw);
			padding: 2.5vw 0;
			margin-inline: auto;
		}
		@if $type ==pc {
			width: 95%;
			max-width: 1220px;
			margin-inline: auto;
		}

		#site_logo {
			@if $type =="" {
			}
			@if $type ==sp {
			}
			@if $type ==pc {
			}

			a {
				@if $type =="" {
					display: block;
					line-height: 1;
				}
				@if $type ==sp {
					width: 43vw;
					max-width: 277px;
				}
				@if $type ==pc {
					max-width: 241px;
				}

				img {
					@if $type =="" {
						width: 100%;
					}
					@if $type ==sp {
					}
					@if $type ==pc {
					}
				}
			}
		}
	}
}

.loginFooter {
	@if $type =="" {
		margin-inline: auto;
	}
	@if $type ==sp {
	}
	@if $type ==pc {
		width: 95%;
		max-width: 1220px;
		margin-top: 14px;
	}

	p.copy {
		@if $type =="" {
		}
		@if $type ==sp {
		}
		@if $type ==pc {
			max-width: inherit;
			width: 100%;
			padding: 0 10px;
		}
	}

	p.copyError {
		@if $type =="" {
			font-weight: bold;
		}
		@if $type ==sp {
			padding-top: 0;
			padding-bottom: 0;
		}
		@if $type ==pc {
			margin-bottom: 10px;
			font-size: 12px;
		}

		+ * {
			@if $type =="" {
			}
			@if $type ==sp {
				margin-top: 7px;
			}
			@if $type ==pc {
				margin-top: 7px;
			}
		}
	}

	&.small {
		@if $type =="" {
			position: relative;
		}
		@if $type ==sp {
			margin-top: 0;
			padding: 0 0 0 100px;
		}
		@if $type ==pc {
			width: 100%;
			max-width: 500px;
			text-align: center;
		}
		@if $type ==tb {
			margin-top: 0;
		}

		.copyError {
			@if $type =="" {
			}
			@if $type ==sp {
				margin-top: 10px;
				text-align: left;

				+ .copy {
					padding-top: 0;
				}
			}
			@if $type ==pc {
			}
		}

		.copy {
			@if $type =="" {
			}
			@if $type ==sp {
				text-align: left;
			}
			@if $type ==pc {
			}
		}

		.lFImage {
			@if $type =="" {
				position: absolute;
			}
			@if $type ==sp {
				width: 100px;
				padding: 0;
				top: -20px;
				left: 0;
				background: transparent;
				padding: 0;

				img {
					width: 100%;
				}
			}
			@if $type ==pc {
				padding: 0;
				top: -112px;
				left: -55px;
			}
			@if $type ==tb {
				background: transparent;
			}
		}
	}
}

.subContent {
	.titleCenter {
		@if $type =="" {
			font-weight: 400;
		}
		@if $type ==sp {
			font-size: 20px;
		}
		@if $type ==pc {
			font-size: 28px;
		}
	
		+ * {
			@if $type =="" {
			}
			@if $type ==sp {
				margin-top: 40px;
			}
			@if $type ==pc {
				margin-top: 85px;
			}
		}
	}
	
	* + .stepNumWrap {
		@if $type =="" {
		}
		@if $type ==sp {
			margin-top: 40px;
		}
		@if $type ==pc {
			margin-top: 85px;
		}
	}
	
	.stepNumWrap {
		@if $type =="" {
			display: flex;
			align-items: center;
		}
		@if $type ==sp {
		}
		@if $type ==pc {
		}

		+ * {
			@if $type =="" {
			}
			@if $type ==sp {
				margin-top: 9px;
			}
			@if $type ==pc {
				margin-top: 14px;
			}
		}
	
		> * + * {
			@if $type =="" {
			}
			@if $type ==sp {
				margin-top: 0;
			}
			@if $type ==pc {
				margin-top: 0;
			}
		}

		.stepNum {
			@if $type =="" {
				color: #fff;
				line-height: 1;
				background: $green;
			}
			@if $type ==sp {
				padding: 4px 12px;
				font-size: 13px;
			}
			@if $type ==pc {
				padding: 4px 16px;
				font-size: 13px;
			}
		}

		.stepNumTitle {
			@if $type =="" {
				color: $green;
				font-weight: 600;
			}
			@if $type ==sp {
				margin-left: 6px;
				font-size: 16px;
			}
			@if $type ==pc {
				margin-left: 6px;
				font-size: 18px;
			}
		}
	}

	.titleGreen {
		@if $type =="" {
			color: $green;
			font-weight: bold;
		}
		@if $type ==sp {
		}
		@if $type ==pc {
		}
	}

	* + .logoutContent {
		@if $type =="" {
		}
		@if $type ==sp {
		}
		@if $type ==pc {
			margin-top: 45px;
		}
	}

	.infoContent {
		@if $type =="" {
			text-align: center;
		}
		@if $type ==sp {
		}
		@if $type ==pc {
		}

		.infoContentMascot {
			@if $type =="" {
			}
			@if $type ==sp {
			}
			@if $type ==pc {
			}
		}
		
	}

	.infoContentNote {
		@if $type =="" {
			display: flex;
			justify-content: center;
		}
		@if $type ==sp {
		}
		@if $type ==pc {
			margin-top: 11px;
		}

		&:before {
			@if $type =="" {
				content: "";
				flex: none;
				display: block;
				vertical-align: middle;
				margin-right: 5px;
				background: url(../images/contact_sub/icon_logout.svg) no-repeat;
				background-size: contain;
			}
			@if $type ==sp {
				width: 19px;
				height: 19px;
				margin-top: 3px;
			}
			@if $type ==pc {
				width: 19px;
				height: 19px;
				margin-top: 3px;
			}
		}
	}
}