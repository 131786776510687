@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
  * Remove all paddings around the image on small screen
  */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.breadcrumb nav {
  overflow: hidden;
  background: #fff;
  position: relative;
  top: 0;
  height: 100%;
  z-index: 1;
}
.breadcrumb nav ol {
  float: left;
}
.breadcrumb nav ol li {
  list-style: none;
  float: left;
  padding-right: 20px;
  position: relative;
}
.breadcrumb nav ol li a {
  color: #000;
  font-size: 14px;
}
.breadcrumb nav ol li a:hover {
  text-decoration: none;
  color: #088E98;
}
.breadcrumb nav ol li .active {
  color: #088E98;
}
.breadcrumb nav ol li .breadcrumb-home-a::after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  transform: rotate(45deg) translateY(-50%);
  position: absolute;
  top: 50%;
  right: 10px;
  border-top: 1px solid #CFCFCF;
  border-right: 1px solid #CFCFCF;
}
.breadcrumb h1 {
  position: relative;
  padding-left: 22px;
  line-height: 44px;
}
.breadcrumb h1:after {
  content: "";
  display: block;
  width: 0;
  height: 44px;
  position: absolute;
  top: 0;
  left: 0;
  border-right: 6px solid #088E98;
}

.subContent,
.mfp-content {
  font-feature-settings: "palt";
  word-break: break-all;
}
.subContent .btnIcon,
.mfp-content .btnIcon {
  width: 11px;
  height: 11px;
  position: absolute;
  top: calc(50% - 6px);
  left: 15px;
  fill: #707070;
}
.subContent a,
.mfp-content a {
  color: #1560BB;
  text-decoration: underline;
}
.subContent a.linkArrow,
.mfp-content a.linkArrow {
  display: inline-block;
  position: relative;
}
.subContent a.linkArrow:after,
.mfp-content a.linkArrow:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  transform: rotate(-45deg);
}
.subContent ul,
.subContent ol,
.mfp-content ul,
.mfp-content ol {
  list-style-type: none;
}
.subContent img,
.mfp-content img {
  max-width: 100%;
  height: auto;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}
.subContent *,
.mfp-content * {
  box-sizing: border-box;
}
.subContent .dInlineBlock,
.mfp-content .dInlineBlock {
  display: inline-block !important;
}
.subContent sup,
.subContent sub,
.mfp-content sup,
.mfp-content sub {
  font-size: 0.6em;
}
.subContent .colorRed,
.mfp-content .colorRed {
  color: #A72525;
}
.subContent .taC,
.mfp-content .taC {
  text-align: center !important;
}
.subContent .taL,
.mfp-content .taL {
  text-align: left !important;
}
.subContent .taR,
.mfp-content .taR {
  text-align: right !important;
}
.subContent .Center,
.mfp-content .Center {
  margin: 0 auto !important;
}
.subContent .ovh,
.mfp-content .ovh {
  overflow: hidden !important;
}
.subContent .mw100,
.mfp-content .mw100 {
  max-width: 100% !important;
  width: auto !important;
}
.subContent .w100p,
.mfp-content .w100p {
  width: 100% !important;
}
.subContent .mB0,
.mfp-content .mB0 {
  margin-bottom: 0 !important;
}
.subContent .mB5,
.mfp-content .mB5 {
  margin-bottom: 5px !important;
}
.subContent .mB10,
.mfp-content .mB10 {
  margin-bottom: 10px !important;
}
.subContent .mB20,
.mfp-content .mB20 {
  margin-bottom: 20px !important;
}
.subContent .mB30,
.mfp-content .mB30 {
  margin-bottom: 30px !important;
}
.subContent .mB40,
.mfp-content .mB40 {
  margin-bottom: 40px !important;
}
.subContent .mB50,
.mfp-content .mB50 {
  margin-bottom: 50px !important;
}
.subContent .mT0,
.mfp-content .mT0 {
  margin-top: 0 !important;
}
.subContent .mT5,
.mfp-content .mT5 {
  margin-top: 5px !important;
}
.subContent .mT10,
.mfp-content .mT10 {
  margin-top: 10px !important;
}
.subContent .mT20,
.mfp-content .mT20 {
  margin-top: 20px !important;
}
.subContent .mT30,
.mfp-content .mT30 {
  margin-top: 30px !important;
}
.subContent .mT40,
.mfp-content .mT40 {
  margin-top: 40px !important;
}
.subContent .mT50,
.mfp-content .mT50 {
  margin-top: 50px !important;
}
.subContent .mL0,
.mfp-content .mL0 {
  margin-left: 0px !important;
}
.subContent .mL5,
.mfp-content .mL5 {
  margin-left: 5px !important;
}
.subContent .mL10,
.mfp-content .mL10 {
  margin-left: 10px !important;
}
.subContent .mL20,
.mfp-content .mL20 {
  margin-left: 20px !important;
}
.subContent .mL30,
.mfp-content .mL30 {
  margin-left: 30px !important;
}
.subContent .mL40,
.mfp-content .mL40 {
  margin-left: 40px !important;
}
.subContent .mL50,
.mfp-content .mL50 {
  margin-left: 50px !important;
}
.subContent .mR0,
.mfp-content .mR0 {
  margin-right: 0px !important;
}
.subContent .mR5,
.mfp-content .mR5 {
  margin-right: 5px !important;
}
.subContent .mR10,
.mfp-content .mR10 {
  margin-right: 10px !important;
}
.subContent .mR20,
.mfp-content .mR20 {
  margin-right: 20px !important;
}
.subContent .mR30,
.mfp-content .mR30 {
  margin-right: 30px !important;
}
.subContent .mR40,
.mfp-content .mR40 {
  margin-right: 40px !important;
}
.subContent .mR50,
.mfp-content .mR50 {
  margin-right: 50px !important;
}
.subContent .w50,
.mfp-content .w50 {
  width: 50% !important;
}
.subContent .textKeepAll,
.mfp-content .textKeepAll {
  word-break: keep-all !important;
}
.subContent ul.commonList,
.mfp-content ul.commonList {
  line-height: 1.5;
}
.subContent ul.commonList > li,
.mfp-content ul.commonList > li {
  position: relative;
  box-sizing: border-box;
}
.subContent ul.commonList > li:before,
.mfp-content ul.commonList > li:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: auto;
  content: "";
  border-radius: 50%;
  background-color: #1560BB;
  position: absolute;
  left: 0;
  top: 0.55em;
}
.subContent ol.commonList,
.mfp-content ol.commonList {
  counter-reset: number 0;
}
.subContent ol.commonList > li,
.mfp-content ol.commonList > li {
  position: relative;
}
.subContent ol.commonList > li:before,
.mfp-content ol.commonList > li:before {
  counter-increment: number 1;
  content: counter(number) ".";
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.subContent table .downBlock,
.mfp-content table .downBlock {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.subContent .commonTable,
.mfp-content .commonTable {
  width: 100%;
  border-right: 1px solid #D2D2D2;
  border-left: 1px solid #D2D2D2;
}
.subContent .commonTable > thead > tr > th,
.subContent .commonTable > thead > tr > td,
.subContent .commonTable > tbody > tr > th,
.subContent .commonTable > tbody > tr > td,
.subContent .commonTable > tr > th,
.subContent .commonTable > tr > td,
.mfp-content .commonTable > thead > tr > th,
.mfp-content .commonTable > thead > tr > td,
.mfp-content .commonTable > tbody > tr > th,
.mfp-content .commonTable > tbody > tr > td,
.mfp-content .commonTable > tr > th,
.mfp-content .commonTable > tr > td {
  text-align: left;
}
.subContent .commonTable > thead > tr > th,
.subContent .commonTable > tbody > tr > th,
.subContent .commonTable > tr > th,
.mfp-content .commonTable > thead > tr > th,
.mfp-content .commonTable > tbody > tr > th,
.mfp-content .commonTable > tr > th {
  font-weight: bold;
  background: #F7F6F4;
}
.subContent .sortTable,
.mfp-content .sortTable {
  width: 100%;
}
.subContent .sortTable td.checkboxOnly .checkboxLabel.textNone,
.subContent .sortTable th.checkboxOnly .checkboxLabel.textNone,
.mfp-content .sortTable td.checkboxOnly .checkboxLabel.textNone,
.mfp-content .sortTable th.checkboxOnly .checkboxLabel.textNone {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
.subContent .sortTable td.nowrap,
.mfp-content .sortTable td.nowrap {
  white-space: nowrap;
}
.subContent .sortTable > thead > tr > th,
.subContent .sortTable > thead > tr > td,
.subContent .sortTable > tbody > tr > th,
.subContent .sortTable > tbody > tr > td,
.subContent .sortTable > tr > th,
.subContent .sortTable > tr > td,
.mfp-content .sortTable > thead > tr > th,
.mfp-content .sortTable > thead > tr > td,
.mfp-content .sortTable > tbody > tr > th,
.mfp-content .sortTable > tbody > tr > td,
.mfp-content .sortTable > tr > th,
.mfp-content .sortTable > tr > td {
  text-align: left;
}
.subContent .sortTable.tdSmall > thead > tr > td,
.subContent .sortTable.tdSmall > tbody > tr > td,
.subContent .sortTable.tdSmall > tr > td,
.mfp-content .sortTable.tdSmall > thead > tr > td,
.mfp-content .sortTable.tdSmall > tbody > tr > td,
.mfp-content .sortTable.tdSmall > tr > td {
  font-size: 14px;
}
.subContent .sortTable > thead > tr > th,
.subContent .sortTable > tbody > tr > th,
.subContent .sortTable > tr > th,
.mfp-content .sortTable > thead > tr > th,
.mfp-content .sortTable > tbody > tr > th,
.mfp-content .sortTable > tr > th {
  font-weight: 400;
  text-align: center;
  line-height: 1.36;
}
.subContent .sortTable > thead > tr > th.sortNum,
.subContent .sortTable > tbody > tr > th.sortNum,
.subContent .sortTable > tr > th.sortNum,
.mfp-content .sortTable > thead > tr > th.sortNum,
.mfp-content .sortTable > tbody > tr > th.sortNum,
.mfp-content .sortTable > tr > th.sortNum {
  width: 8.7%;
}
.subContent .sortTable > thead > tr > th.sortName,
.subContent .sortTable > tbody > tr > th.sortName,
.subContent .sortTable > tr > th.sortName,
.mfp-content .sortTable > thead > tr > th.sortName,
.mfp-content .sortTable > tbody > tr > th.sortName,
.mfp-content .sortTable > tr > th.sortName {
  width: 33.1%;
}
.subContent .sortTable > thead > tr > th.sortPlace,
.subContent .sortTable > tbody > tr > th.sortPlace,
.subContent .sortTable > tr > th.sortPlace,
.mfp-content .sortTable > thead > tr > th.sortPlace,
.mfp-content .sortTable > tbody > tr > th.sortPlace,
.mfp-content .sortTable > tr > th.sortPlace {
  width: 13.6%;
}
.subContent .sortTable > thead > tr > th.sortBarName,
.subContent .sortTable > tbody > tr > th.sortBarName,
.subContent .sortTable > tr > th.sortBarName,
.mfp-content .sortTable > thead > tr > th.sortBarName,
.mfp-content .sortTable > tbody > tr > th.sortBarName,
.mfp-content .sortTable > tr > th.sortBarName {
  width: 27.3%;
}
.subContent .sortTable > thead > tr > th.sortClass,
.subContent .sortTable > tbody > tr > th.sortClass,
.subContent .sortTable > tr > th.sortClass,
.mfp-content .sortTable > thead > tr > th.sortClass,
.mfp-content .sortTable > tbody > tr > th.sortClass,
.mfp-content .sortTable > tr > th.sortClass {
  width: 17.1%;
}
.subContent .sortTable > thead > tr > th:not([scope=col]),
.subContent .sortTable > tbody > tr > th:not([scope=col]),
.subContent .sortTable > tr > th:not([scope=col]),
.mfp-content .sortTable > thead > tr > th:not([scope=col]),
.mfp-content .sortTable > tbody > tr > th:not([scope=col]),
.mfp-content .sortTable > tr > th:not([scope=col]) {
  background: #EEEDEC;
  font-weight: bold;
}
.subContent .sortTable > thead > tr > th:not([scope=col]).isActive,
.subContent .sortTable > tbody > tr > th:not([scope=col]).isActive,
.subContent .sortTable > tr > th:not([scope=col]).isActive,
.mfp-content .sortTable > thead > tr > th:not([scope=col]).isActive,
.mfp-content .sortTable > tbody > tr > th:not([scope=col]).isActive,
.mfp-content .sortTable > tr > th:not([scope=col]).isActive {
  background: #D2D2D2;
}
.subContent .sortTable > thead > tr > th:not([scope=col]):not(.isActive):not(.sortNone),
.subContent .sortTable > tbody > tr > th:not([scope=col]):not(.isActive):not(.sortNone),
.subContent .sortTable > tr > th:not([scope=col]):not(.isActive):not(.sortNone),
.mfp-content .sortTable > thead > tr > th:not([scope=col]):not(.isActive):not(.sortNone),
.mfp-content .sortTable > tbody > tr > th:not([scope=col]):not(.isActive):not(.sortNone),
.mfp-content .sortTable > tr > th:not([scope=col]):not(.isActive):not(.sortNone) {
  color: #1560BB;
  text-decoration: underline;
}
.subContent .sortTable > thead > tr > th:not([scope=col]).isActive,
.subContent .sortTable > tbody > tr > th:not([scope=col]).isActive,
.subContent .sortTable > tr > th:not([scope=col]).isActive,
.mfp-content .sortTable > thead > tr > th:not([scope=col]).isActive,
.mfp-content .sortTable > tbody > tr > th:not([scope=col]).isActive,
.mfp-content .sortTable > tr > th:not([scope=col]).isActive {
  color: #000;
  text-decoration: none;
}
.subContent .sortTable > thead > tr > th:not([scope=col]).isActive.isDesc .sortSelect > li:nth-child(odd),
.subContent .sortTable > tbody > tr > th:not([scope=col]).isActive.isDesc .sortSelect > li:nth-child(odd),
.subContent .sortTable > tr > th:not([scope=col]).isActive.isDesc .sortSelect > li:nth-child(odd),
.mfp-content .sortTable > thead > tr > th:not([scope=col]).isActive.isDesc .sortSelect > li:nth-child(odd),
.mfp-content .sortTable > tbody > tr > th:not([scope=col]).isActive.isDesc .sortSelect > li:nth-child(odd),
.mfp-content .sortTable > tr > th:not([scope=col]).isActive.isDesc .sortSelect > li:nth-child(odd) {
  display: none;
}
.subContent .sortTable > thead > tr > th:not([scope=col]).isActive.isAsc .sortSelect > li:nth-child(even),
.subContent .sortTable > tbody > tr > th:not([scope=col]).isActive.isAsc .sortSelect > li:nth-child(even),
.subContent .sortTable > tr > th:not([scope=col]).isActive.isAsc .sortSelect > li:nth-child(even),
.mfp-content .sortTable > thead > tr > th:not([scope=col]).isActive.isAsc .sortSelect > li:nth-child(even),
.mfp-content .sortTable > tbody > tr > th:not([scope=col]).isActive.isAsc .sortSelect > li:nth-child(even),
.mfp-content .sortTable > tr > th:not([scope=col]).isActive.isAsc .sortSelect > li:nth-child(even) {
  display: none;
}
.subContent .sortTable > thead > tr > th:not([scope=col]).isActive .sortSelect > li:nth-child(odd),
.subContent .sortTable > tbody > tr > th:not([scope=col]).isActive .sortSelect > li:nth-child(odd),
.subContent .sortTable > tr > th:not([scope=col]).isActive .sortSelect > li:nth-child(odd),
.mfp-content .sortTable > thead > tr > th:not([scope=col]).isActive .sortSelect > li:nth-child(odd),
.mfp-content .sortTable > tbody > tr > th:not([scope=col]).isActive .sortSelect > li:nth-child(odd),
.mfp-content .sortTable > tr > th:not([scope=col]).isActive .sortSelect > li:nth-child(odd) {
  border-color: transparent transparent #1560bb transparent !important;
}
.subContent .sortTable > thead > tr > th:not([scope=col]).isActive .sortSelect > li:nth-child(even),
.subContent .sortTable > tbody > tr > th:not([scope=col]).isActive .sortSelect > li:nth-child(even),
.subContent .sortTable > tr > th:not([scope=col]).isActive .sortSelect > li:nth-child(even),
.mfp-content .sortTable > thead > tr > th:not([scope=col]).isActive .sortSelect > li:nth-child(even),
.mfp-content .sortTable > tbody > tr > th:not([scope=col]).isActive .sortSelect > li:nth-child(even),
.mfp-content .sortTable > tr > th:not([scope=col]).isActive .sortSelect > li:nth-child(even) {
  border-color: #1560bb transparent transparent transparent !important;
}
.subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn,
.subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn,
.subContent .sortTable > tr > th:not([scope=col]) .sortBtn,
.mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn,
.mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn,
.mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect,
.subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect,
.subContent .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect,
.mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect,
.mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect,
.mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: none;
}
.subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect > li,
.subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect > li,
.subContent .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect > li,
.mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect > li,
.mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect > li,
.mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect > li {
  width: 0;
  height: 0;
  border-style: solid;
}
.subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect > li + li,
.subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect > li + li,
.subContent .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect > li + li,
.mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect > li + li,
.mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect > li + li,
.mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect > li + li {
  margin-top: 3px;
}
.subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd),
.subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd),
.subContent .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd),
.mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd),
.mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd),
.mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd) {
  border-color: transparent transparent #737373 transparent;
}
.subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even),
.subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even),
.subContent .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even),
.mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even),
.mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even),
.mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even) {
  border-color: #737373 transparent transparent transparent;
}
.subContent .sortTable > thead > tr:nth-child(odd),
.subContent .sortTable > tbody > tr:nth-child(odd),
.subContent .sortTable > tr:nth-child(odd),
.mfp-content .sortTable > thead > tr:nth-child(odd),
.mfp-content .sortTable > tbody > tr:nth-child(odd),
.mfp-content .sortTable > tr:nth-child(odd) {
  background: #fff;
}
.subContent .sortTable > thead > tr:nth-child(even),
.subContent .sortTable > tbody > tr:nth-child(even),
.subContent .sortTable > tr:nth-child(even),
.mfp-content .sortTable > thead > tr:nth-child(even),
.mfp-content .sortTable > tbody > tr:nth-child(even),
.mfp-content .sortTable > tr:nth-child(even) {
  background: #F9F9F7;
}
.subContent .separateTable,
.mfp-content .separateTable {
  width: 100%;
}
.subContent .separateTable > thead > tr > th,
.subContent .separateTable > thead > tr > td,
.subContent .separateTable > tbody > tr > th,
.subContent .separateTable > tbody > tr > td,
.subContent .separateTable > tr > th,
.subContent .separateTable > tr > td,
.mfp-content .separateTable > thead > tr > th,
.mfp-content .separateTable > thead > tr > td,
.mfp-content .separateTable > tbody > tr > th,
.mfp-content .separateTable > tbody > tr > td,
.mfp-content .separateTable > tr > th,
.mfp-content .separateTable > tr > td {
  text-align: left;
}
.subContent .separateTable > thead > tr > th,
.subContent .separateTable > tbody > tr > th,
.subContent .separateTable > tr > th,
.mfp-content .separateTable > thead > tr > th,
.mfp-content .separateTable > tbody > tr > th,
.mfp-content .separateTable > tr > th {
  font-weight: 400;
}
.subContent .separateTable > thead > tr:nth-child(odd),
.subContent .separateTable > tbody > tr:nth-child(odd),
.subContent .separateTable > tr:nth-child(odd),
.mfp-content .separateTable > thead > tr:nth-child(odd),
.mfp-content .separateTable > tbody > tr:nth-child(odd),
.mfp-content .separateTable > tr:nth-child(odd) {
  background: #F7F6F4;
}
.subContent .separateTable > thead > tr:nth-child(even),
.subContent .separateTable > tbody > tr:nth-child(even),
.subContent .separateTable > tr:nth-child(even),
.mfp-content .separateTable > thead > tr:nth-child(even),
.mfp-content .separateTable > tbody > tr:nth-child(even),
.mfp-content .separateTable > tr:nth-child(even) {
  background: #EEEDEC;
}
.subContent .listBox,
.mfp-content .listBox {
  border: 4px solid #E6E6E6;
}
.subContent .listBox > div:not(:last-child) > dt,
.mfp-content .listBox > div:not(:last-child) > dt {
  border-bottom: 1px solid #D2D2D2;
}
.subContent .listBox > div:not(:last-child) > dd,
.mfp-content .listBox > div:not(:last-child) > dd {
  border-bottom: 1px solid #D2D2D2;
}
.subContent .listBox > div.col3,
.mfp-content .listBox > div.col3 {
  width: 100%;
}
.subContent .listBox > .listBlue:not(.col3),
.mfp-content .listBox > .listBlue:not(.col3) {
  color: #1560BB;
  background: #E6EFFD;
}
.subContent .note,
.mfp-content .note {
  text-align: right;
}
.subContent .titleSub,
.mfp-content .titleSub {
  font-weight: bold;
}
.subContent .titleCenter,
.mfp-content .titleCenter {
  text-align: center;
}
.subContent .colorBlock,
.mfp-content .colorBlock {
  background: #F7F6F4;
}
.subContent .colorBlockTitle,
.mfp-content .colorBlockTitle {
  text-align: center;
}
.subContent .colorBox,
.mfp-content .colorBox {
  background: #F7F6F4;
}
.subContent .colorBoxTitle,
.mfp-content .colorBoxTitle {
  color: #000000;
}
.subContent *:not(.commonDl) + .postContent,
.mfp-content *:not(.commonDl) + .postContent {
  border-top: 1px solid #D2D2D2;
}
.subContent .postContent,
.mfp-content .postContent {
  border-left: 1px solid #D2D2D2;
  border-right: 1px solid #D2D2D2;
  border-bottom: 1px solid #D2D2D2;
}
.subContent .btn,
.mfp-content .btn {
  display: inline-block;
  border: none;
  background: none;
  border: 1px solid transparent;
  border-radius: 4px;
  text-decoration: none;
  position: relative;
}
.subContent .btn.btnWhite,
.mfp-content .btn.btnWhite {
  color: #737373;
  border-color: #737373;
  background: #fff;
}
.subContent .btn.btnWhiteBorder,
.mfp-content .btn.btnWhiteBorder {
  color: #1560BB;
  border-color: #1560BB;
  background: #fff;
}
.subContent .btn.btnBlue,
.mfp-content .btn.btnBlue {
  color: #fff;
  border-color: #1560BB;
  background: #1560BB;
}
.subContent .btn.btnGreen,
.mfp-content .btn.btnGreen {
  color: #fff;
  border-color: #088E98;
  background: #088E98;
}
.subContent .btn.btnGreen.btnWhiteBorder,
.mfp-content .btn.btnGreen.btnWhiteBorder {
  color: #088E98;
  border-color: #088E98;
  background: #fff;
}
.subContent .btn.btnGreen.btnBack:before,
.mfp-content .btn.btnGreen.btnBack:before {
  border-left: 1px solid #088E98;
  border-top: 1px solid #088E98;
}
.subContent .btn.btnGreen.btnDownload,
.mfp-content .btn.btnGreen.btnDownload {
  color: #000;
  border-color: #B8B8B8;
}
.subContent .btn.btnGreen.btnDownload:before,
.mfp-content .btn.btnGreen.btnDownload:before {
  content: "";
  display: inline-block;
  border: none;
  background-image: url(../images/contact_sub/icon_download.svg);
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(0);
}
.subContent .btn.btnGreen .iconTrash,
.mfp-content .btn.btnGreen .iconTrash {
  background-image: url(../images/contact_sub/icon_trash_green.svg);
}
.subContent .btn.btnDelete,
.mfp-content .btn.btnDelete {
  color: #707070 !important;
  border-color: #707070 !important;
  position: relative;
}
.subContent .btn.btnDelete:before, .subContent .btn.btnDelete:after,
.mfp-content .btn.btnDelete:before,
.mfp-content .btn.btnDelete:after {
  content: "";
  background: #707070;
  position: absolute;
  top: 0;
  bottom: 0;
}
.subContent .btn.btnBack:before,
.mfp-content .btn.btnBack:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(-45deg);
  border-left: 1px solid #737373;
  border-top: 1px solid #737373;
}
.subContent .btn.btnClose,
.mfp-content .btn.btnClose {
  text-align: center;
}
.subContent .btn.btnClose:before,
.mfp-content .btn.btnClose:before {
  content: "×";
  height: 1em;
  line-height: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.subContent .btn.btnDown,
.mfp-content .btn.btnDown {
  text-align: center;
}
.subContent .btn.btnDown.isOpen:before,
.mfp-content .btn.btnDown.isOpen:before {
  transform: rotate(-135deg) translateY(-2px) translateX(-3px);
}
.subContent .btn.btnDown.isOpen:after,
.mfp-content .btn.btnDown.isOpen:after {
  opacity: 1;
}
.subContent .btn.btnDown:before,
.mfp-content .btn.btnDown:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(45deg) translateY(-3px);
  transition: 0.2s transform;
}
.subContent .btn.btnDown:after,
.mfp-content .btn.btnDown:after {
  content: "閉じる";
  display: block;
  height: 100%;
  text-align: center;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  box-sizing: border-box;
  opacity: 0;
  pointer-events: none;
}
.subContent .btn.btnDown.btnGreen,
.mfp-content .btn.btnDown.btnGreen {
  color: #000000;
  border-color: #B8B8B8;
}
.subContent .btn.btnDown.btnGreen.isOpen:before,
.mfp-content .btn.btnDown.btnGreen.isOpen:before {
  transform: rotate(-180deg);
}
.subContent .btn.btnDown.btnGreen:before,
.mfp-content .btn.btnDown.btnGreen:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #088E98 transparent transparent transparent;
  pointer-events: none;
  transform: rotate(0);
}
.subContent .tag,
.mfp-content .tag {
  display: inline-block;
  color: #737373;
  border: 1px solid #B8B8B8;
}
.subContent [class^=icon],
.subContent .modalLink:before,
.mfp-content [class^=icon],
.mfp-content .modalLink:before {
  display: inline-block;
  margin-right: 7px;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.subContent .iconPdf,
.mfp-content .iconPdf {
  background-image: url(../images/contact_sub/icon_pdf01.gif);
}
.subContent .iconAttention,
.mfp-content .iconAttention {
  background-image: url(../images/contact_sub/icon_attention_red.png);
}
.subContent .iconDownload,
.mfp-content .iconDownload {
  background-image: url(../images/contact_sub/icon_download.png);
}
.subContent .modalLink,
.mfp-content .modalLink {
  display: inline-block;
  position: relative;
}
.subContent .modalLink:before,
.mfp-content .modalLink:before {
  content: "";
  background-image: url(../images/contact_sub/icon_info.png);
}
.subContent .searchMenu ul,
.mfp-content .searchMenu ul {
  display: flex;
  border-bottom: 2px solid #1560BB;
}
.subContent .searchMenu ul > li,
.mfp-content .searchMenu ul > li {
  position: relative;
}
.subContent .searchMenu ul > li:before,
.mfp-content .searchMenu ul > li:before {
  content: "";
  width: 100%;
  height: 100%;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #737373;
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  pointer-events: none;
  transition: 0.2s border, 0.2s opacity;
}
.subContent .searchMenu ul > li.isActive,
.mfp-content .searchMenu ul > li.isActive {
  color: #1560BB;
}
.subContent .searchMenu ul > li.isActive:before,
.mfp-content .searchMenu ul > li.isActive:before {
  border-width: 2px 2px 0 2px;
  border-color: #1560BB;
}
.subContent .searchMenu ul > li.isActive:after,
.mfp-content .searchMenu ul > li.isActive:after {
  content: "";
  width: calc(100% - 4px);
  height: 2px;
  background: #fff;
  position: absolute;
  bottom: -2px;
  left: 2px;
  box-sizing: border-box;
}
.subContent .searchMenu ul > li.isActive > *:before,
.mfp-content .searchMenu ul > li.isActive > *:before {
  border-color: #1560BB;
}
.subContent .searchMenu ul > li.searchMenuDisable,
.mfp-content .searchMenu ul > li.searchMenuDisable {
  pointer-events: none;
}
.subContent .searchMenu ul > li.searchMenuDisable:before,
.mfp-content .searchMenu ul > li.searchMenuDisable:before {
  z-index: 1;
  border-color: #B8B8B8;
}
.subContent .searchMenu ul > li.searchMenuDisable > *,
.mfp-content .searchMenu ul > li.searchMenuDisable > * {
  color: #B8B8B8;
  background: #E6E6E6;
}
.subContent .searchMenu ul > li.searchMenuDisable > *:before,
.mfp-content .searchMenu ul > li.searchMenuDisable > *:before {
  border-color: #B8B8B8;
}
.subContent .searchMenu ul > li > *,
.mfp-content .searchMenu ul > li > * {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}
.subContent .searchMenu ul > li > *:before,
.mfp-content .searchMenu ul > li > *:before {
  content: "";
  display: block;
  border-style: solid;
  border-color: #737373;
  border-width: 2px 2px 0 0;
  transform: rotate(135deg);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.subContent .searchMenu ul > li > a,
.mfp-content .searchMenu ul > li > a {
  color: #737373;
  text-decoration: none;
  cursor: pointer;
}
.subContent .attachedList > li > a,
.mfp-content .attachedList > li > a {
  display: inline-block;
  position: relative;
}
.subContent .attachedList > li > a[href$=pdf]:after, .subContent .attachedList > li > a[href$=xls]:after, .subContent .attachedList > li > a[href$=xlsx]:after, .subContent .attachedList > li > a[href$=doc]:after, .subContent .attachedList > li > a[href$=docx]:after, .subContent .attachedList > li > a[href$=ppt]:after, .subContent .attachedList > li > a[href$=pptx]:after, .subContent .attachedList > li > a[href$=txt]:after, .subContent .attachedList > li > a[href$=csv]:after, .subContent .attachedList > li > a[href$=zip]:after, .subContent .attachedList > li > a[href$=jpg]:after, .subContent .attachedList > li > a[href$=bmp]:after, .subContent .attachedList > li > a[href$=png]:after, .subContent .attachedList > li > a[href$=gif]:after, .subContent .attachedList > li > a[href$=tif]:after, .subContent .attachedList > li > a[href$=svg]:after, .subContent .attachedList > li > a[href$=mov]:after, .subContent .attachedList > li > a[href$=mp4]:after, .subContent .attachedList > li > a[href$=mp3]:after, .subContent .attachedList > li > a[href$=wmv]:after,
.mfp-content .attachedList > li > a[href$=pdf]:after,
.mfp-content .attachedList > li > a[href$=xls]:after,
.mfp-content .attachedList > li > a[href$=xlsx]:after,
.mfp-content .attachedList > li > a[href$=doc]:after,
.mfp-content .attachedList > li > a[href$=docx]:after,
.mfp-content .attachedList > li > a[href$=ppt]:after,
.mfp-content .attachedList > li > a[href$=pptx]:after,
.mfp-content .attachedList > li > a[href$=txt]:after,
.mfp-content .attachedList > li > a[href$=csv]:after,
.mfp-content .attachedList > li > a[href$=zip]:after,
.mfp-content .attachedList > li > a[href$=jpg]:after,
.mfp-content .attachedList > li > a[href$=bmp]:after,
.mfp-content .attachedList > li > a[href$=png]:after,
.mfp-content .attachedList > li > a[href$=gif]:after,
.mfp-content .attachedList > li > a[href$=tif]:after,
.mfp-content .attachedList > li > a[href$=svg]:after,
.mfp-content .attachedList > li > a[href$=mov]:after,
.mfp-content .attachedList > li > a[href$=mp4]:after,
.mfp-content .attachedList > li > a[href$=mp3]:after,
.mfp-content .attachedList > li > a[href$=wmv]:after {
  content: "";
  display: block;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  margin: auto;
}
.subContent .attachedList > li > a[href$=pdf]:after,
.mfp-content .attachedList > li > a[href$=pdf]:after {
  background-image: url(../images/contact_sub/icon_pdf01.gif);
}
.subContent .attachedList > li > a[href$=xls]:after, .subContent .attachedList > li > a[href$=xlsx]:after,
.mfp-content .attachedList > li > a[href$=xls]:after,
.mfp-content .attachedList > li > a[href$=xlsx]:after {
  background-image: url(../images/contact_sub/icon_excel.gif);
}
.subContent .attachedList > li > a[href$=doc]:after, .subContent .attachedList > li > a[href$=docx]:after,
.mfp-content .attachedList > li > a[href$=doc]:after,
.mfp-content .attachedList > li > a[href$=docx]:after {
  background-image: url(../images/contact_sub/icon_word.gif);
}
.subContent .attachedList > li > a[href$=ppt]:after, .subContent .attachedList > li > a[href$=pptx]:after,
.mfp-content .attachedList > li > a[href$=ppt]:after,
.mfp-content .attachedList > li > a[href$=pptx]:after {
  background-image: url(../images/contact_sub/icon_ppt.png);
}
.subContent .attachedList > li > a[href$=txt]:after, .subContent .attachedList > li > a[href$=csv]:after, .subContent .attachedList > li > a[href$=zip]:after,
.mfp-content .attachedList > li > a[href$=txt]:after,
.mfp-content .attachedList > li > a[href$=csv]:after,
.mfp-content .attachedList > li > a[href$=zip]:after {
  background-image: url(../images/contact_sub/icon_txt.png);
}
.subContent .attachedList > li > a[href$=jpg]:after, .subContent .attachedList > li > a[href$=png]:after, .subContent .attachedList > li > a[href$=gif]:after, .subContent .attachedList > li > a[href$=tif]:after, .subContent .attachedList > li > a[href$=bmp]:after, .subContent .attachedList > li > a[href$=svg]:after,
.mfp-content .attachedList > li > a[href$=jpg]:after,
.mfp-content .attachedList > li > a[href$=png]:after,
.mfp-content .attachedList > li > a[href$=gif]:after,
.mfp-content .attachedList > li > a[href$=tif]:after,
.mfp-content .attachedList > li > a[href$=bmp]:after,
.mfp-content .attachedList > li > a[href$=svg]:after {
  background-image: url(../images/contact_sub/icon_img.png);
}
.subContent .attachedList > li > a[href$=mov]:after, .subContent .attachedList > li > a[href$=mp4]:after, .subContent .attachedList > li > a[href$=mp3]:after, .subContent .attachedList > li > a[href$=wmv]:after,
.mfp-content .attachedList > li > a[href$=mov]:after,
.mfp-content .attachedList > li > a[href$=mp4]:after,
.mfp-content .attachedList > li > a[href$=mp3]:after,
.mfp-content .attachedList > li > a[href$=wmv]:after {
  background-image: url(../images/contact_sub/icon_mov.png);
}
.subContent .colorGreen .btnWrap .btn.btnWhite,
.mfp-content .colorGreen .btnWrap .btn.btnWhite {
  border-color: #B8B8B8;
}
.subContent .colorGreen .partsSubText,
.mfp-content .colorGreen .partsSubText {
  text-align: right;
}
.subContent .colorGreen .searchMenu,
.mfp-content .colorGreen .searchMenu {
  position: relative;
}
.subContent .colorGreen .searchMenu > ul,
.mfp-content .colorGreen .searchMenu > ul {
  line-height: 1.19;
  border-bottom: 2px solid #088E98;
}
.subContent .colorGreen .searchMenu > ul > li.isActive,
.mfp-content .colorGreen .searchMenu > ul > li.isActive {
  color: #088E98;
}
.subContent .colorGreen .searchMenu > ul > li.isActive:before,
.mfp-content .colorGreen .searchMenu > ul > li.isActive:before {
  border-color: #088E98;
}
.subContent .colorGreen .searchMenu > ul > li.isActive > *:before,
.mfp-content .colorGreen .searchMenu > ul > li.isActive > *:before {
  border-color: #088E98;
}
.subContent .colorGreen .btn.btnWhite,
.mfp-content .colorGreen .btn.btnWhite {
  color: #000000;
}
.subContent .colorGreen .sortTable > thead > tr:nth-child(even),
.subContent .colorGreen .sortTable > tbody > tr:nth-child(even),
.subContent .colorGreen .sortTable > tr:nth-child(even),
.mfp-content .colorGreen .sortTable > thead > tr:nth-child(even),
.mfp-content .colorGreen .sortTable > tbody > tr:nth-child(even),
.mfp-content .colorGreen .sortTable > tr:nth-child(even) {
  background: #fff;
}
.subContent .colorGreen .sortTable > tbody > tr > th:not([scope=col]).isAsc .sortBtn .sortSelect > li:nth-child(odd),
.mfp-content .colorGreen .sortTable > tbody > tr > th:not([scope=col]).isAsc .sortBtn .sortSelect > li:nth-child(odd) {
  border-color: transparent transparent #088E98 transparent !important;
}
.subContent .colorGreen .sortTable > tbody > tr > th:not([scope=col]).isDesc .sortBtn .sortSelect > li:nth-child(even),
.mfp-content .colorGreen .sortTable > tbody > tr > th:not([scope=col]).isDesc .sortBtn .sortSelect > li:nth-child(even) {
  border-color: #088E98 transparent transparent transparent !important;
}
.subContent .colorGreen .flexItem > * + *,
.mfp-content .colorGreen .flexItem > * + * {
  margin-top: 0;
}
.subContent .colorGreen .separateContent,
.mfp-content .colorGreen .separateContent {
  border-top: 1px solid #D2D2D2;
}
.subContent .colorGreen .inputWrap,
.mfp-content .colorGreen .inputWrap {
  display: flex;
  align-items: center;
}
.subContent .colorGreen .inputWrap .inputSearchBtnWrap,
.mfp-content .colorGreen .inputWrap .inputSearchBtnWrap {
  display: flex;
  position: relative;
}
.subContent .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchInput,
.mfp-content .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchInput {
  min-height: 42px;
  border: 1px solid #B8B8B8;
}
.subContent .colorGreen .inputWrap .inputSearchBtnWrap > label,
.mfp-content .colorGreen .inputWrap .inputSearchBtnWrap > label {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #088E98;
  cursor: pointer;
}
.subContent .colorGreen .inputWrap .inputSearchBtnWrap > label.btnLift span:before,
.mfp-content .colorGreen .inputWrap .inputSearchBtnWrap > label.btnLift span:before {
  background: url(../images/contact_sub/icon_reload_white.svg) no-repeat;
  background-size: contain;
}
.subContent .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchBtn,
.mfp-content .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchBtn {
  display: none;
}
.subContent .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchBtn + span,
.mfp-content .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchBtn + span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.subContent .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchBtn + span:before,
.mfp-content .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchBtn + span:before {
  content: "";
  display: block;
  background: url(../images/contact_sub/icon_search.svg) no-repeat;
  background-size: contain;
}
.subContent .colorGreen .boxMaxTextWrap,
.mfp-content .colorGreen .boxMaxTextWrap {
  position: relative;
}
.subContent .colorGreen .boxMaxTextWrap .boxMaxText,
.mfp-content .colorGreen .boxMaxTextWrap .boxMaxText {
  display: flex;
  justify-content: center;
  max-height: 5em;
  line-height: 1.357;
  font-feature-settings: inherit;
  position: relative;
  transition: 0.2s height;
  overflow: hidden;
}
.subContent .colorGreen .boxMaxTextWrap .boxMaxText.isOpen,
.mfp-content .colorGreen .boxMaxTextWrap .boxMaxText.isOpen {
  max-height: initial;
  padding-bottom: 14px;
}
.subContent .colorGreen .boxMaxTextWrap .boxMaxText.isOpen + .boxMaxTextBtn:before,
.mfp-content .colorGreen .boxMaxTextWrap .boxMaxText.isOpen + .boxMaxTextBtn:before {
  opacity: 0;
}
.subContent .colorGreen .boxMaxTextWrap .boxMaxText.isOpen + .boxMaxTextBtn:after,
.mfp-content .colorGreen .boxMaxTextWrap .boxMaxText.isOpen + .boxMaxTextBtn:after {
  transform: scale(1, -1);
}
.subContent .colorGreen .boxMaxTextWrap .boxMaxText + .boxMaxTextBtn,
.mfp-content .colorGreen .boxMaxTextWrap .boxMaxText + .boxMaxTextBtn {
  display: block;
  width: 100%;
  height: 100%;
  margin-top: -7px;
  position: absolute;
  left: 0;
  bottom: 0;
  cursor: pointer;
}
.subContent .colorGreen .boxMaxTextWrap .boxMaxText + .boxMaxTextBtn:before,
.mfp-content .colorGreen .boxMaxTextWrap .boxMaxText + .boxMaxTextBtn:before {
  content: "";
  width: 100%;
  height: 1.5em;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.2s opacity;
}
.subContent .colorGreen .boxMaxTextWrap .boxMaxText + .boxMaxTextBtn:after,
.mfp-content .colorGreen .boxMaxTextWrap .boxMaxText + .boxMaxTextBtn:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 0 7px;
  border-color: #088e98 transparent transparent transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  transition: 0.2s transform, 0.2s opacity;
}
.subContent .colorGreen table.thSticky,
.mfp-content .colorGreen table.thSticky {
  position: relative;
}
.subContent .colorGreen table.thSticky thead th:before,
.mfp-content .colorGreen table.thSticky thead th:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 1px solid #d2d2d2 !important;
  border-bottom: 1px solid #d2d2d2 !important;
  z-index: -1;
  background: #FBFAF9;
  box-sizing: border-box;
}
.subContent .colorGreen table.thSticky tbody tr:first-child td,
.mfp-content .colorGreen table.thSticky tbody tr:first-child td {
  border-top: none !important;
}
.subContent .infoList,
.mfp-content .infoList {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.subContent .downBlock,
.mfp-content .downBlock {
  border-bottom: 1px solid #D2D2D2;
}
.subContent .downBlock .downBox,
.mfp-content .downBlock .downBox {
  display: none;
}
.subContent .downBlock .downBox2,
.mfp-content .downBlock .downBox2 {
  display: none;
  margin-top: 8px;
}
.subContent .downBlock .downBox2 ul,
.mfp-content .downBlock .downBox2 ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  border-top: 1px solid #707070;
  border-left: 1px solid #707070;
}
.subContent .downBlock .downBox2 ul li,
.mfp-content .downBlock .downBox2 ul li {
  border-right: 1px solid #707070;
  border-bottom: 1px solid #707070;
}
.subContent .downBlock .downBox2 ul button,
.mfp-content .downBlock .downBox2 ul button {
  width: 100%;
  height: 50px;
  border-radius: 0;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  background-color: #fff;
  position: relative;
}
.subContent .sortBlock,
.mfp-content .sortBlock {
  background: #F9F9F7;
}
.subContent .paging,
.mfp-content .paging {
  width: 100%;
}
.subContent .paging .prev,
.subContent .paging .next,
.mfp-content .paging .prev,
.mfp-content .paging .next {
  display: block;
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  border: 1px solid #828282;
  background: #828282;
  position: relative;
}
.subContent .paging .prev:before,
.subContent .paging .next:before,
.mfp-content .paging .prev:before,
.mfp-content .paging .next:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.subContent .paging .prev:before,
.mfp-content .paging .prev:before {
  border-color: transparent #ffffff transparent transparent;
}
.subContent .paging .next:before,
.mfp-content .paging .next:before {
  border-color: transparent transparent transparent #ffffff;
}
.subContent .paging .pageCount,
.mfp-content .paging .pageCount {
  display: flex;
}
.subContent .paging .pageCount > li,
.mfp-content .paging .pageCount > li {
  text-align: center;
}
.subContent .paging .pageCount > li a,
.mfp-content .paging .pageCount > li a {
  display: block;
  color: #000000;
  border-radius: 4px;
  text-decoration: none;
  border: 1px solid #D2D2D2;
  background: #fff;
}
.subContent .paging .pageCount > li a.now,
.mfp-content .paging .pageCount > li a.now {
  color: #828282;
  border-color: #828282;
  background: #CCCCCC;
  cursor: inherit;
}
.subContent .formBlock,
.mfp-content .formBlock {
  background: #F7F6F4;
}
.subContent .colorGreen .titleBeforeWrap,
.mfp-content .colorGreen .titleBeforeWrap {
  font-weight: bold;
  line-height: 1.2;
  position: relative;
}
.subContent .colorGreen .titleBeforeWrap:before,
.mfp-content .colorGreen .titleBeforeWrap:before {
  content: "";
  display: block;
  height: 100%;
  background: #088E98;
  position: absolute;
}
.subContent .colorGreen .titleBeforeWrap .titleSub,
.mfp-content .colorGreen .titleBeforeWrap .titleSub {
  display: inline-block;
  color: #088E98;
  font-weight: 400;
  background: #BEF1E6;
}
.subContent .colorGreen .titleBold,
.mfp-content .colorGreen .titleBold {
  font-weight: bold;
}
.subContent .colorGreen .commonDl,
.mfp-content .colorGreen .commonDl {
  border: 1px solid #D2D2D2;
}
.subContent .colorGreen .commonDl > div + div,
.mfp-content .colorGreen .commonDl > div + div {
  border-top: 1px solid #d2d2d2;
}
.subContent .colorGreen .commonDl > div > dt,
.mfp-content .colorGreen .commonDl > div > dt {
  background: #F7F6F4;
}
.subContent .colorGreen .commonDl > div.blockRequired > dt,
.mfp-content .colorGreen .commonDl > div.blockRequired > dt {
  position: relative;
}
.subContent .colorGreen .commonDl > div.blockRequired > dt:before,
.mfp-content .colorGreen .commonDl > div.blockRequired > dt:before {
  content: "必須";
  display: block;
  padding: 2px 5px;
  margin-right: 11px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 1;
  background: #A72525;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  box-sizing: border-box;
}
.subContent .colorGreen .downBlock.borderTop,
.mfp-content .colorGreen .downBlock.borderTop {
  padding-top: 20px;
  padding-bottom: 0;
  margin-top: 27px;
  border-top: 1px solid #D2D2D2;
  border-bottom: none;
}
.subContent .colorGreen .downBlock.borderNone,
.mfp-content .colorGreen .downBlock.borderNone {
  padding-bottom: 0;
  border-top: none;
  border-bottom: none;
}
.subContent .colorGreen .iconPlus,
.mfp-content .colorGreen .iconPlus {
  display: inline-block;
  position: relative;
  background: url(../images/contact_sub/icon_plus.svg) no-repeat;
  background-size: contain;
}
.subContent .colorGreen .iconClip,
.mfp-content .colorGreen .iconClip {
  display: inline-block;
  position: relative;
  background: url(../images/contact_sub/icon_paperclip.svg) no-repeat;
  background-size: contain;
}
.subContent .colorGreen .iconTrash,
.mfp-content .colorGreen .iconTrash {
  display: inline-block;
  position: relative;
  background: url(../images/contact_sub/icon_trash.svg) no-repeat;
  background-size: contain;
}
.subContent .colorGreen .iconReload,
.mfp-content .colorGreen .iconReload {
  display: inline-block;
  vertical-align: baseline;
  position: relative;
  background: url(../images/contact_sub/icon_reload.svg) no-repeat;
  background-size: contain;
}
.subContent .colorGreen select:focus,
.subContent .colorGreen select:checked,
.subContent .colorGreen select:active,
.subContent .colorGreen input:focus,
.subContent .colorGreen input:checked,
.subContent .colorGreen input:active,
.subContent .colorGreen textarea:focus,
.subContent .colorGreen textarea:checked,
.subContent .colorGreen textarea:active,
.subContent .colorGreen select:focus,
.subContent .colorGreen select:checked,
.subContent .colorGreen select:active,
.subContent .colorGreen input:focus,
.subContent .colorGreen input:checked,
.subContent .colorGreen input:active,
.subContent .colorGreen textarea:focus,
.subContent .colorGreen textarea:checked,
.subContent .colorGreen textarea:active,
.mfp-content .colorGreen select:focus,
.mfp-content .colorGreen select:checked,
.mfp-content .colorGreen select:active,
.mfp-content .colorGreen input:focus,
.mfp-content .colorGreen input:checked,
.mfp-content .colorGreen input:active,
.mfp-content .colorGreen textarea:focus,
.mfp-content .colorGreen textarea:checked,
.mfp-content .colorGreen textarea:active,
.mfp-content .colorGreen select:focus,
.mfp-content .colorGreen select:checked,
.mfp-content .colorGreen select:active,
.mfp-content .colorGreen input:focus,
.mfp-content .colorGreen input:checked,
.mfp-content .colorGreen input:active,
.mfp-content .colorGreen textarea:focus,
.mfp-content .colorGreen textarea:checked,
.mfp-content .colorGreen textarea:active {
  border-color: #088E98 !important;
  background: #EEFDFE !important;
}
.subContent .colorGreen .selectWrap:before,
.mfp-content .colorGreen .selectWrap:before {
  border-color: #088E98 transparent transparent transparent;
}
.subContent .colorGreen .checkboxLabel input[type=checkbox]:checked + span,
.mfp-content .colorGreen .checkboxLabel input[type=checkbox]:checked + span {
  color: #000000;
  box-shadow: 0 0 0 2px #088E98;
  background: #EEFDFE;
}
.subContent .colorGreen .checkboxLabel input[type=checkbox]:checked + span:after,
.mfp-content .colorGreen .checkboxLabel input[type=checkbox]:checked + span:after {
  border-left: 2px solid #088E98;
  border-bottom: 2px solid #088E98;
}
@media print, screen and (min-width: 600px) and (max-width: 1230px) {
  .subContent .colorGreen .formList > div.formListDt2.variable > dt + dd,
  .mfp-content .colorGreen .formList > div.formListDt2.variable > dt + dd {
    margin-top: 10px;
  }
}
@media print, screen and (min-width: 600px) and (max-width: 1230px) {
  .subContent .colorGreen .formList > div.formListDt2.variable > dd,
  .mfp-content .colorGreen .formList > div.formListDt2.variable > dd {
    width: calc(100% - 180px);
    margin-right: 0;
  }
}
.subContent .colorGreen .formList > div > dt,
.mfp-content .colorGreen .formList > div > dt {
  font-weight: 400;
}
.subContent .colorGreen .textarea,
.mfp-content .colorGreen .textarea {
  width: 100%;
  border: 1px solid #B8B8B8;
  border-radius: 4px;
  background: #fff;
}
.subContent .colorGreen .radioLabel input[type=radio]:checked + span,
.mfp-content .colorGreen .radioLabel input[type=radio]:checked + span {
  color: #088E98;
  box-shadow: 0 0 0 2px #088E98;
  background: #EEFDFE;
}
.subContent .colorGreen .radioLabel input[type=radio]:checked + span:before,
.mfp-content .colorGreen .radioLabel input[type=radio]:checked + span:before {
  background: #088E98;
}
.subContent .formList > div,
.mfp-content .formList > div {
  border-bottom: 1px solid #E4E4E4;
}
.subContent .formList > div > dt,
.mfp-content .formList > div > dt {
  font-weight: 700;
}
.subContent .formList > div > dt span,
.mfp-content .formList > div > dt span {
  display: block;
}
.subContent .input,
.mfp-content .input {
  border: 1px solid #B8B8B8;
}
.subContent .input.inputFull,
.mfp-content .input.inputFull {
  width: 100%;
}
.subContent .input.inputL,
.mfp-content .input.inputL {
  width: 100%;
}
.subContent .input.inputM,
.mfp-content .input.inputM {
  width: 100%;
}
.subContent .rowNote,
.mfp-content .rowNote {
  display: inline-block;
  vertical-align: middle;
}
.subContent .inputSearch,
.mfp-content .inputSearch {
  display: inline-block;
  width: 100%;
  position: relative;
}
.subContent .inputSearch:before,
.mfp-content .inputSearch:before {
  content: "";
  display: block;
  background-image: url(../images/contact_sub/icon_search.png) !important;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.subContent .inputSearch .input,
.mfp-content .inputSearch .input {
  width: 100%;
}
.subContent .formRow,
.mfp-content .formRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.subContent .formRow > li:last-child,
.mfp-content .formRow > li:last-child {
  margin-right: 0;
}
.subContent .checkboxLabel,
.mfp-content .checkboxLabel {
  display: block;
  position: relative;
}
.subContent .checkboxLabel.textNone,
.mfp-content .checkboxLabel.textNone {
  width: 19px;
  height: 19px;
  margin-right: 0;
}
.subContent .checkboxLabel.textNone input[type=checkbox] + span,
.mfp-content .checkboxLabel.textNone input[type=checkbox] + span {
  padding: 0;
  box-shadow: none;
  background: transparent;
}
.subContent .checkboxLabel.textNone input[type=checkbox] + span:before,
.mfp-content .checkboxLabel.textNone input[type=checkbox] + span:before {
  margin: inherit;
  border: 1px solid #737373;
  background: #fff;
  right: 0;
  left: 0;
}
.subContent .checkboxLabel.textNone input[type=checkbox] + span:after,
.mfp-content .checkboxLabel.textNone input[type=checkbox] + span:after {
  right: 0;
  left: 0;
}
.subContent .checkboxLabel.textNone input[type=checkbox]:checked + span,
.mfp-content .checkboxLabel.textNone input[type=checkbox]:checked + span {
  box-shadow: none !important;
}
.subContent .checkboxLabel.textNone input[type=checkbox]:checked + span:before,
.mfp-content .checkboxLabel.textNone input[type=checkbox]:checked + span:before {
  border-color: #088E98;
  opacity: 1;
}
.subContent .checkboxLabel.error span,
.mfp-content .checkboxLabel.error span {
  background: #FFF0F0 !important;
}
.subContent .checkboxLabel input,
.mfp-content .checkboxLabel input {
  display: none;
}
.subContent .checkboxLabel span,
.mfp-content .checkboxLabel span {
  display: block;
  box-shadow: 0 0 0 1px #B8B8B8;
  background: #fff;
  box-sizing: border-box;
  transition: 0.2s opacity, 0.2s background, 0.2s box-shadow;
}
.subContent .checkboxLabel span:before, .subContent .checkboxLabel span:after,
.mfp-content .checkboxLabel span:before,
.mfp-content .checkboxLabel span:after {
  content: "";
  display: block;
  position: absolute;
}
.subContent .checkboxLabel span:before,
.mfp-content .checkboxLabel span:before {
  background: #E6E6E6;
  transition: 0.2s opacity;
}
.subContent .checkboxLabel span:after,
.mfp-content .checkboxLabel span:after {
  background: inherit;
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 5px;
  border-left: 2px solid #1560BB;
  border-bottom: 2px solid #1560BB;
  transform: rotate(-45deg);
  transition: 0.2s opacity;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
}
.subContent .checkboxLabel input[type=checkbox]:checked + span,
.mfp-content .checkboxLabel input[type=checkbox]:checked + span {
  color: #1560BB;
  box-shadow: 0 0 0 2px #1560BB;
  background: #E6EFFD;
}
.subContent .checkboxLabel input[type=checkbox]:checked + span:after,
.mfp-content .checkboxLabel input[type=checkbox]:checked + span:after {
  opacity: 1;
}
.subContent .checkboxLabel input[type=checkbox]:checked + span:before,
.mfp-content .checkboxLabel input[type=checkbox]:checked + span:before {
  opacity: 0;
}
.subContent .radioLabel,
.mfp-content .radioLabel {
  display: block;
  position: relative;
}
.subContent .radioLabel input,
.mfp-content .radioLabel input {
  display: none;
}
.subContent .radioLabel span,
.mfp-content .radioLabel span {
  display: block;
  box-shadow: 0 0 0 1px #B8B8B8;
  background: #fff;
  box-sizing: border-box;
  transition: 0.2s opacity, 0.2s background, 0.2s box-shadow;
}
.subContent .radioLabel span:before, .subContent .radioLabel span:after,
.mfp-content .radioLabel span:before,
.mfp-content .radioLabel span:after {
  content: "";
  display: block;
  position: absolute;
}
.subContent .radioLabel span:before,
.mfp-content .radioLabel span:before {
  background: #E6E6E6;
  transition: 0.2s;
  border-radius: 50%;
  box-sizing: border-box;
}
.subContent .radioLabel span:after,
.mfp-content .radioLabel span:after {
  transition: 0.2s;
  border-radius: 50%;
  border: 1px solid #fff;
  box-sizing: border-box;
  opacity: 0;
}
.subContent .radioLabel input[type=radio]:checked + span,
.mfp-content .radioLabel input[type=radio]:checked + span {
  color: #1560BB;
  box-shadow: 0 0 0 2px #1560BB;
  background: #E6EFFD;
}
.subContent .radioLabel input[type=radio]:checked + span:before,
.mfp-content .radioLabel input[type=radio]:checked + span:before {
  width: 17px;
  height: 17px;
  background: #1560BB;
}
.subContent .radioLabel input[type=radio]:checked + span:after,
.mfp-content .radioLabel input[type=radio]:checked + span:after {
  opacity: 1;
}
.subContent .radioLabel.error span,
.mfp-content .radioLabel.error span {
  background: #FFF0F0 !important;
}
.subContent .selectWrap,
.mfp-content .selectWrap {
  display: inline-block;
  position: relative;
}
.subContent .selectWrap.colorGreen:before,
.mfp-content .selectWrap.colorGreen:before {
  border-color: #088E98 transparent transparent transparent;
}
.subContent .selectWrap:before,
.mfp-content .selectWrap:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #1560BB transparent transparent transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  pointer-events: none;
}
.subContent .selectWrap .select,
.mfp-content .selectWrap .select {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  border: 1px solid #B8B8B8;
  background: #fff;
}
.subContent ::placeholder,
.mfp-content ::placeholder {
  color: #B8B8B8;
}
.subContent select,
.subContent input,
.subContent textarea,
.mfp-content select,
.mfp-content input,
.mfp-content textarea {
  transition: 0.2s border-color, 0.2s background;
}
.subContent select.error,
.subContent input.error,
.subContent textarea.error,
.mfp-content select.error,
.mfp-content input.error,
.mfp-content textarea.error {
  background: #FFF0F0 !important;
}
.subContent select:focus, .subContent select:checked, .subContent select:active,
.subContent input:focus,
.subContent input:checked,
.subContent input:active,
.subContent textarea:focus,
.subContent textarea:checked,
.subContent textarea:active,
.mfp-content select:focus,
.mfp-content select:checked,
.mfp-content select:active,
.mfp-content input:focus,
.mfp-content input:checked,
.mfp-content input:active,
.mfp-content textarea:focus,
.mfp-content textarea:checked,
.mfp-content textarea:active {
  border-color: #1560BB !important;
  background: #E6EFFD !important;
  outline: none;
}
.subContent select:-webkit-autofill,
.subContent input:-webkit-autofill,
.subContent textarea:-webkit-autofill,
.mfp-content select:-webkit-autofill,
.mfp-content input:-webkit-autofill,
.mfp-content textarea:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
  box-shadow: transparent !important;
  -webkit-box-shadow: transparent;
}
@keyframes onAutoFillStart {}
.subContent .modalContent,
.mfp-content .modalContent {
  background: #fff;
}
.subContent .modalTitle,
.mfp-content .modalTitle {
  display: flex;
  justify-content: center;
  font-weight: bold;
  text-align: center;
}
.subContent .modalTitle > span,
.mfp-content .modalTitle > span {
  position: relative;
}
.subContent .modalTitle > span:before,
.mfp-content .modalTitle > span:before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: #1560BB;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.subContent .modalInner,
.mfp-content .modalInner {
  overflow-y: auto;
  overflow-x: hidden;
}
.subContent .modalInner::-webkit-scrollbar,
.mfp-content .modalInner::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 30px;
  background: #E6E6E6;
  cursor: pointer;
}
.subContent .modalInner::-webkit-scrollbar-thumb,
.mfp-content .modalInner::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  border-radius: 30px;
  background: #1560BB;
  cursor: pointer;
}
.subContent .errorBox,
.mfp-content .errorBox {
  color: #A72525;
  background: #FFF0F0;
}
.subContent .radioList > li .radioLabelBefore > input[type=radio],
.mfp-content .radioList > li .radioLabelBefore > input[type=radio] {
  display: none;
}
.subContent .radioList > li .radioLabelBefore > input[type=radio]:checked + span:after,
.mfp-content .radioList > li .radioLabelBefore > input[type=radio]:checked + span:after {
  transform: scale(1);
}
.subContent .radioList > li .radioLabelBefore > input[type=radio] + span,
.mfp-content .radioList > li .radioLabelBefore > input[type=radio] + span {
  display: inline-block;
  position: relative;
}
.subContent .radioList > li .radioLabelBefore > input[type=radio] + span:before, .subContent .radioList > li .radioLabelBefore > input[type=radio] + span:after,
.mfp-content .radioList > li .radioLabelBefore > input[type=radio] + span:before,
.mfp-content .radioList > li .radioLabelBefore > input[type=radio] + span:after {
  content: "";
  position: absolute;
}
.subContent .radioList > li .radioLabelBefore > input[type=radio] + span:before,
.mfp-content .radioList > li .radioLabelBefore > input[type=radio] + span:before {
  border: 1px solid #707070;
  border-radius: 50%;
  box-sizing: border-box;
}
.subContent .radioList > li .radioLabelBefore > input[type=radio] + span:after,
.mfp-content .radioList > li .radioLabelBefore > input[type=radio] + span:after {
  border-radius: 50%;
  background: #088E98;
  transform: scale(0);
  transition: 0.2s transform;
}
.subContent .input:disabled,
.subContent .select:disabled,
.mfp-content .input:disabled,
.mfp-content .select:disabled {
  color: #B8B8B8;
  background: #E6E6E6;
  pointer-events: none;
  opacity: 1;
}
.subContent .selectWrap.disabled:before,
.mfp-content .selectWrap.disabled:before {
  border-color: #B8B8B8 transparent transparent transparent;
}
.subContent .selectWrap.error:before,
.mfp-content .selectWrap.error:before {
  border-color: #A72525 transparent transparent transparent !important;
}
.subContent .selectWrap.error .select,
.mfp-content .selectWrap.error .select {
  background: #FFF0F0 !important;
}
.subContent .btnAdd > .btn,
.mfp-content .btnAdd > .btn {
  border-color: #B8B8B8;
}
.subContent .compBox,
.mfp-content .compBox {
  background-color: #f7f6f4;
}
.subContent .compBox .title,
.mfp-content .compBox .title {
  background-color: #bef1e6;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  background-color: transparent;
}
.subContent .compBox .title a,
.mfp-content .compBox .title a {
  color: #707070;
  border: 1px solid #707070;
  width: 200px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  margin: 30px auto 0;
  background-color: #fff;
}
.subContent .compBox p,
.mfp-content .compBox p {
  text-align: center;
}
.subContent .compBox .custom_hover,
.mfp-content .compBox .custom_hover {
  width: 200px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
  color: #707070;
  border: 1px solid #707070;
  text-decoration: none;
  background-color: #fff;
  margin: 20px auto 0;
}
.subContent .compBox.errorBox,
.mfp-content .compBox.errorBox {
  background-color: #fff0f0;
  color: #a72525;
}
.subContent .btnDrag,
.mfp-content .btnDrag {
  background: url(../images/contact_sub/icon_drag.svg) no-repeat;
  background-size: contain;
  cursor: move;
}

.mfp-close {
  display: none !important;
}

.mfp-bg {
  background: rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  #areaWrapper1 {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .flexBtn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 50px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1000px) {
  .flexBtn a {
    display: block;
    padding: 0.5em 1em;
    background-color: #bef1e6;
    color: #088E98;
    text-decoration: none;
    margin: 1em 0.5em;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  #mainArea #backBtn {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    margin-top: 40px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1000px) {
  #mainArea #backBtn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
    position: relative;
  }
}
@media screen and (min-width: 600px) and (max-width: 1000px) {
  #mainArea #backBtn a:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #ccc;
    border-left: 2px solid #ccc;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 15px;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.rotate-90 {
  transform: rotate(-90deg);
}

.section.subContent {
  margin-bottom: 100px;
}

.breadcrumb nav {
  overflow: hidden;
  background: #fff;
  position: relative;
  top: 0;
  height: 100%;
  z-index: 1;
  font-size: 14px;
}
.breadcrumb nav ol {
  float: left;
}
.breadcrumb nav ol li {
  list-style: none;
  float: left;
  padding-right: 20px;
  position: relative;
}
.breadcrumb nav ol li a {
  color: #000;
  font-size: 14px;
}
.breadcrumb nav ol li a:hover {
  text-decoration: none;
  color: #088E98;
}
.breadcrumb nav ol li .active {
  color: #088E98;
}
.breadcrumb nav ol li .breadcrumb-home-a::after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  transform: rotate(45deg) translateY(-50%);
  position: absolute;
  top: 50%;
  right: 10px;
  border-top: 1px solid #CFCFCF;
  border-right: 1px solid #CFCFCF;
}
.breadcrumb h1 {
  position: relative;
  padding-left: 22px;
  line-height: 44px;
}
.breadcrumb h1:after {
  content: "";
  display: block;
  width: 0;
  height: 44px;
  position: absolute;
  top: 0;
  left: 0;
  border-right: 6px solid #088E98;
}

h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  padding-left: 0.6em;
  position: relative;
}
h2:before {
  content: "";
  display: block;
  width: 6px;
  height: 1.2em;
  background-color: #088e98;
  position: absolute;
  left: 0;
  top: calc(50% - 0.6em);
}

h3 {
  font-size: 16px;
  font-weight: 700;
  border-bottom: 2px solid #088e98;
  padding-bottom: 10px;
}

.menteMenu {
  display: flex;
  flex-wrap: wrap;
  background-color: #eff9f7;
  margin-top: 40px;
  padding: 40px 10.65%;
  width: 100%;
}
.menteMenu a {
  display: block;
  display: flex;
  width: calc(25% - 21px);
  flex-direction: column;
  align-items: center;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 10px #cbebe4;
  padding: 32px 0;
  position: relative;
  max-width: 230px;
}
.menteMenu a:not(:nth-child(4n)) {
  margin-right: 27px;
}
.menteMenu a:nth-child(n+5) {
  margin-top: 2.8%;
}
.menteMenu a:hover .icon {
  opacity: 0.8;
}
.menteMenu a:hover .icon svg, .menteMenu a:hover .icon img {
  transform: scale(1.5);
}
.menteMenu a .blank {
  position: absolute;
  top: 20px;
  right: 20px;
}
.menteMenu a .blank svg {
  width: 16px;
  height: 16px;
  fill: #088e98;
}
.menteMenu a .icon {
  aspect-ratio: 1/1;
  width: 45%;
  border-radius: 50%;
  background-color: #088e98;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.menteMenu a .icon svg, .menteMenu a .icon img {
  fill: #fff;
  transition: all 0.3s;
}
.menteMenu a .icon svg {
  aspect-ratio: 1/1;
  width: 30%;
}
.menteMenu a .icon img {
  width: 40%;
}
.menteMenu a .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  width: 90%;
  height: 42px;
}
.menteMenu a .title .name {
  flex: 1;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
}
.menteMenu a .title .name span {
  font-size: 13px;
  display: block;
}
.menteMenu a .title svg {
  width: 16px;
  height: 16px;
  fill: #088e98;
  margin-left: 5px;
  transform: rotate(180deg);
}

#bengoshiTab {
  margin-top: 20px;
}
#bengoshiTab ul {
  list-style: none;
  display: flex;
  gap: 10px;
  border-bottom: 2px solid #088e98;
  background-color: #fff;
}
#bengoshiTab ul li a {
  height: 100%;
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-top: 1px solid #737373;
  border-right: 1px solid #737373;
  border-left: 1px solid #737373;
  padding: 10px;
}
#bengoshiTab ul li a .icon {
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background-color: #b8b8b8;
}
#bengoshiTab ul li a .icon svg {
  width: 15px;
  height: 15px;
  fill: #fff;
}
#bengoshiTab ul li a .icon img {
  width: 20px;
  height: 20px;
}
#bengoshiTab ul li a .title {
  font-size: 18px;
  font-weight: 500;
  color: #737373;
  text-align: center;
  margin: 0 15px;
}
#bengoshiTab ul li a .arrow {
  width: 20px;
  height: 20px;
}
#bengoshiTab ul li a .arrow svg {
  width: 12px;
  height: 12px;
  fill: #737373;
  transform: rotate(-90deg);
}
#bengoshiTab ul li a.active {
  border-color: #088e98;
  border-width: 2px;
  border-bottom: 3px solid #fff;
}
#bengoshiTab ul li a.active:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: #fff;
  position: absolute;
  bottom: -5px;
  left: 0;
}
#bengoshiTab ul li a.active, #bengoshiTab ul li a:hover {
  z-index: 5;
}
#bengoshiTab ul li a.active .icon, #bengoshiTab ul li a:hover .icon {
  background-color: #088e98;
}
#bengoshiTab ul li a.active .title, #bengoshiTab ul li a:hover .title {
  color: #088e98;
}

.ctrlPanel {
  width: 100%;
  padding: 60px 0;
}

#ctrlBtns {
  background-color: #eff9f7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 60px;
  gap: 20px;
  margin-top: 20px;
}
#ctrlBtns a {
  text-decoration: none;
}
#ctrlBtns .item {
  cursor: pointer;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 10px #cbebe4;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
  min-height: 88px;
  transition: all 0.3s;
}
#ctrlBtns .item:hover {
  background-color: rgba(8, 142, 152, 0.1);
}
#ctrlBtns .item .icon {
  width: 30px;
  height: 30px;
  position: absolute;
  top: calc(50% - 15px);
  left: 20px;
}
#ctrlBtns .item .icon svg {
  width: 100%;
  height: 100%;
  fill: #088e98;
}
#ctrlBtns .item .title {
  font-size: 20px;
  color: #088e98;
}
#ctrlBtns .item.disabled {
  background-color: #e6e6e6;
  pointer-events: none;
  box-shadow: none;
}
#ctrlBtns .item.disabled .icon svg {
  fill: #737373;
}
#ctrlBtns .item.disabled .title {
  color: #737373;
}

.ctrlMess {
  background-color: #eff9f7;
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
}
.ctrlMess .title {
  font-size: 18px;
  font-weight: 700;
}
.ctrlMess .memo {
  font-size: 16px;
  margin-top: 20px;
}
.ctrlMess#errer {
  background-color: #fff0f0;
}
.ctrlMess#errer .title,
.ctrlMess#errer .memo {
  color: #a72525;
}

.loading {
  width: 480px;
  height: 26px;
  border-radius: 13px;
  background-color: #d1eaec;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
}
.loading .bar {
  width: 50%;
  background: radial-gradient(circle, rgb(0, 185, 198) 0%, rgb(8, 142, 152) 100%);
  background-size: 200% 100%;
  background-position: -100% 0;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px;
  animation: barAni 3s infinite linear;
}

@keyframes barAni {
  50% {
    background-position: 0 0;
  }
  100% {
    background-position: -100% 0;
  }
}
.button {
  width: 220px;
  height: 48px;
  border: 1px solid #707070;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  color: #707070;
  font-size: 16px;
  margin-top: 20px;
  transition: all 0.3s;
  border-radius: 5px;
}
.button .icon {
  width: 16px;
  height: 16px;
  position: absolute;
  top: calc(50% - 10px);
  left: 15px;
  margin: 0;
  display: block;
}
.button .icon svg {
  width: 16px;
  height: 16px;
  fill: #707070;
}
.button:hover {
  background-color: rgba(8, 142, 152, 0.2);
}

.backBtn {
  margin-top: 60px;
}
.backBtn .button {
  width: 280px;
  text-decoration: none;
  color: #707070;
  margin: 0 auto;
}

#log {
  margin-top: 30px;
}
#log .logTable {
  list-style: none;
  margin-top: 20px;
}
#log .logTable li {
  border-bottom: 1px dotted #b8b8b8;
  display: flex;
  position: relative;
  font-size: 16px;
  padding-left: 1em;
  padding-bottom: 10px;
}
#log .logTable li + li {
  margin-top: 10px;
}
#log .logTable li.error {
  color: #a72525;
}
#log .logTable li:before {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #088e98;
  position: absolute;
  top: 10px;
  left: 2px;
}
#log .logTable li .date {
  width: 340px;
}
#log .logTable li .matter {
  flex: 1;
}

ul.formRow {
  list-style: none;
}
ul.formRow li {
  display: flex;
  gap: 15px;
}
ul.formRow li + li {
  margin-top: 20px;
}
ul.formRow .kokaiLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 42px;
  border-radius: 5px;
  border: 1px solid #b8b8b8;
  background-color: #fff;
  position: relative;
  cursor: pointer;
}
ul.formRow .kokaiLabel:before {
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  background-color: #e0e0e0;
  position: absolute;
  left: 10px;
  top: calc(50% - 8px);
  border-radius: 50%;
}
ul.formRow .kokaiLabel.checked {
  background-color: #eefdfe;
  border: 2px solid #088e98;
  color: #000 grn;
}
ul.formRow .kokaiLabel.checked:before {
  width: 10px;
  height: 15px;
  border-right: 3px solid #088e98;
  border-bottom: 3px solid #088e98;
  transform: rotate(45deg);
  border-radius: 0;
  background-color: transparent;
  top: 6px;
}

.inputCal {
  display: flex;
  width: 183px;
  height: 42px;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  overflow: hidden;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}
.inputCal.disabled {
  background-color: #e6e6e6;
  cursor: default;
}
.inputCal.disabled input {
  background-color: #e6e6e6;
  cursor: default;
}
.inputCal.error {
  background-color: #fff0f0;
}
.inputCal.error input {
  background-color: transparent;
}
.inputCal svg {
  width: 20px;
  height: 20px;
  fill: #737373;
}
.inputCal input {
  width: 140px;
  padding: 0 10px;
  cursor: pointer;
}

.timeSelect {
  position: relative;
  z-index: 5;
}
.timeSelect .timeBtn {
  display: flex;
  width: 150px;
  height: 42px;
  border: 1px solid #b8b8b8;
  border-radius: 5px;
  overflow: hidden;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}
.timeSelect .timeBtn.disabled {
  background-color: #e6e6e6;
  cursor: default;
}
.timeSelect .timeBtn.disabled input {
  background-color: #e6e6e6;
  cursor: default;
}
.timeSelect .timeBtn svg {
  width: 20px;
  height: 20px;
  fill: #737373;
}
.timeSelect .timeBtn input {
  width: 100px;
  padding: 0 10px;
  cursor: pointer;
}

.timeSelectArea {
  display: none;
}
.timeSelectArea .timeSelectInner {
  border: 1px solid #d2d2d2;
  background-color: #fff;
  padding: 20px;
  display: flex;
  gap: 20px;
  position: absolute;
  top: 20px;
  left: 0;
}
.timeSelectArea .timeSelectInner .title {
  text-align: center;
  border-bottom: 1px solid #d2d2d2;
}
.timeSelectArea #hour .H {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  background-color: #d2d2d2;
  gap: 1px;
  padding: 1px;
  margin-top: 10px;
}
.timeSelectArea #hour .H + .H {
  margin-top: 20px;
}
.timeSelectArea #hour .H span {
  grid-row: 1/span 2;
  grid-column: 1;
  line-height: 1;
  width: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F7F6F4;
}
.timeSelectArea #hour .H label {
  background-color: #fff;
  line-height: 1;
  width: 2em;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.timeSelectArea #hour .H label.checked {
  background-color: #bef1e6;
}
.timeSelectArea #min .M {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background-color: #d2d2d2;
  gap: 1px;
  padding: 1px;
  margin-top: 10px;
}
.timeSelectArea #min .M label {
  background-color: #fff;
  line-height: 1;
  width: 2em;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.timeSelectArea #min .M label.checked {
  background-color: #bef1e6;
  cursor: pointer;
}

.underBtns {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.underBtns button {
  width: 220px;
  height: 48px;
  border-radius: 5px;
  color: #737373;
  border: none;
  outline: none;
  cursor: pointer;
}
.underBtns button.back {
  position: relative;
}
.underBtns button.back svg {
  width: 20px;
  height: 20px;
  fill: #737373;
  position: absolute;
  top: calc(50% - 10px);
  left: 15px;
}
.underBtns button.back.white {
  min-width: 220px;
  width: auto;
  padding: 0 15px 0 40px;
  background-color: #fff;
  border: 1px solid #737373;
}
.underBtns button.regist {
  background-color: #e6e6e6;
}
.underBtns button.regist.noErrer {
  background-color: #088e98;
  color: #fff;
}

.completionArea {
  background-color: #f7f6f4;
  width: 100%;
  padding: 40px;
  text-align: center;
  margin-top: 60px;
}
.completionArea .title {
  font-size: 22px;
  font-weight: 700;
}
.completionArea p {
  margin-top: 40px;
}

.colorGreen .registErrer {
  border: none;
}
.colorGreen .errorText {
  margin-right: auto;
  margin-left: auto;
  color: #A72525;
  background: #fff;
}
.registErrer {
  border: 2px solid #a72525;
  background-color: #fff0f0;
  margin-top: 60px;
  padding-top: 40px;
}
.registErrer .title {
  font-size: 22px;
  font-weight: 700;
  color: #a72525;
  display: flex;
  justify-content: center;
  align-items: center;
  background: inherit !important;
}
.registErrer .title svg {
  width: 22px;
  height: 22px;
  fill: #a72525;
  margin-right: 10px;
}
.registErrer .errerInfo {
  background-color: #fff;
  display: block;
  margin: 40px;
  padding: 30px;
  color: #a72525;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  background-color: #bef1e6 !important;
}

.modal {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: none;
}
.modal .modalInner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal .modalInner.subContent .modalBody {
  position: relative;
}
.modal .modalInner.subContent .modalBody .modTable {
  overflow: clip;
}
.modal .modalInner .modalBody {
  background-color: #fff;
}
.modal .modalInner .modalBody.alert {
  width: 580px;
  padding: 30px;
}
.modal .modalInner .modalBody.alert .icon {
  width: 40px;
  margin: 0 auto;
}
.modal .modalInner .modalBody.alert .icon svg {
  width: 40px;
  height: 40px;
  fill: #d2d2d2;
}
.modal .modalInner .modalBody.alert .title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.modal .modalInner .modalBody.alert p {
  font-size: 16px;
  text-align: center;
}
.modal .modalInner .modalBody.alert .loading + p {
  margin-top: 20px;
}
.modal .modalInner .modalBody.operation {
  max-width: 1220px;
  width: 95%;
  padding-bottom: 80px;
}
.modal .modalInner .modalBody.operation .title {
  background-color: #bef1e6;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}
.modal .modalInner .modalBody.operation .modBodyIn table.modTable {
  border: 1px solid #d2d2d2;
  width: 100%;
  margin-top: 30px;
}
.modal .modalInner .modalBody.operation .modBodyIn table.modTable th {
  text-align: left;
  background-color: #FBFAF9;
  padding: 10px;
  font-size: 14px;
  border-top: 1px solid #d2d2d2;
}
.modal .modalInner .modalBody.operation .modBodyIn table.modTable th[scope=row] {
  width: inherit;
  text-align: center;
}
.modal .modalInner .modalBody.operation .modBodyIn table.modTable td {
  padding: 10px;
  border-top: 1px solid #d2d2d2;
}
.modal .modalInner .modalBody.operation .modBodyIn table.modTable td .errorMess {
  display: block;
  font-size: 14px;
  text-align: left;
  color: #a72525;
}
.modal .modalInner .modalBody.operation .modBodyIn table.modTable td .errorMess + * {
  margin-top: 8px;
}
.modal .modalInner .modalBody.operation .modBodyIn table.modTable .iconRequired {
  display: inline-block;
  padding: 2px 5px;
  margin-right: 11px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  font-style: normal;
  vertical-align: baseline;
  font-weight: 400;
  line-height: 1;
  background: #A72525;
  box-sizing: border-box;
}
.modal .modalInner .modalBody.operation .modBodyIn p.error {
  background-color: #fff0f0;
  display: block;
  padding: 5px;
  color: #a72525;
}
.modal .modalInner .modalBody.operation .greenArrow {
  margin-top: 30px;
  width: 100%;
}
.modal .modalInner .modalBody.operation .greenArrow:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 25px solid #088e98;
  border-right: 35px solid transparent;
  border-left: 35px solid transparent;
  margin: 0 auto;
}
.modal .modalInner .modalBody .compBox {
  background-color: #f7f6f4;
  padding-top: 50px;
  padding-bottom: 50px;
}
.modal .modalInner .modalBody .compBox .title {
  background-color: transparent;
}
.modal .modalInner .modalBody .compBox .title a {
  color: #707070;
  border: 1px solid #707070;
  width: 200px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  margin: 30px auto 0;
  background-color: #fff;
}
.modal .modalInner .modalBody .compBox .title a:hover {
  text-decoration: none;
}
.modal .modalInner .modalBody .compBox p {
  text-align: center;
}
.modal .modalInner .modalBody .compBox .custom_hover {
  width: 200px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
  color: #707070;
  border: 1px solid #707070;
  text-decoration: none;
  background-color: #fff;
  margin: 20px auto 0;
}
.modal .modalInner .modalBody .compBox.errorBox {
  background-color: #fff0f0;
  color: #a72525;
}
.modal .modalInner .modalBody .compBox table.modTable td {
  background: #fff;
}
.modal .modalInner .modalBody .modalBtns {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.modal .modalInner .modalBody .modalBtns .btn {
  width: 200px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
}
.modal .modalInner .modalBody .modalBtns .btn.cancel {
  color: #707070;
  border: 1px solid #707070;
}
.modal .modalInner .modalBody .modalBtns .btn.goOn {
  background-color: #088e98;
  color: #fff;
}
.modal .modalInner .modalBody .modalBtns .btn .btnIcon {
  width: 11px;
  height: 11px;
  position: absolute;
  top: calc(50% - 6px);
  left: 15px;
  fill: #707070;
}
.formList {
  background-color: #F7F6F4;
  margin-top: 30px;
}
.formList .flex {
  display: flex;
  background-color: #fff;
  margin-top: 20px;
  border: 1px solid #d2d2d2;
  padding: 15px;
}
.formList .flex + .flex {
  margin-top: -1px;
}
.formList.teiki {
  background-color: #e6effd;
}
.formList.teiki #listFileWrap ul li.listHead {
  background: #a8cfff;
}
.formList.teiki #listFileWrap p span.kubun {
  color: #fff;
  background: #1560bb;
  padding: 2px 10px 4px;
  margin-right: 10px;
}
.formList.rinji {
  background-color: #fff2e3;
}
.formList.rinji #listFileWrap ul li.listHead {
  background: #ffd09a;
}
.formList.rinji #listFileWrap p span.kubun {
  color: #fff;
  background: #e48314;
  padding: 2px 10px 4px;
  margin-right: 10px;
}
.formList dt {
  position: relative;
}
.formList dt .clearBtn {
  width: 170px;
  height: 42px;
  border: 1px solid #b8b8b8;
  background-color: #fff;
  position: absolute;
  top: calc(50% - 21px);
  right: 0;
  font-size: 14px;
  color: #707070;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.formList dt .clearBtn svg {
  width: 11px;
  height: 11px;
  fill: #707070;
}
.formList dt .clearBtn:hover {
  color: #000;
}
.formList dt .clearBtn:hover svg {
  fill: #000;
}

* + #upFileWrap {
  margin-top: 20px;
}

#inputFile {
  position: relative;
  padding: 170px 0 70px;
  text-align: center;
  height: 310px;
  cursor: pointer;
}

#dropArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 90px 0 0;
  border: 2px dashed #b8b8b8;
  color: #b8b8b8;
  font-size: 1.2em;
  background: #fff;
}

#dropArea.dragover {
  border: 5px dashed #088e93;
}

#uploadFile {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: block;
  width: 220px;
  height: 48px;
  opacity: 0;
  cursor: pointer;
}

#inputFileWrap {
  position: relative;
  display: inline-block;
  border: 2px solid #088e98;
  color: #088e98;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

#selectFile {
  border-color: #088e93;
  color: #088e93;
  cursor: pointer;
}
#selectFile span {
  cursor: pointer;
}

#listFileWrap {
  position: relative;
  font-size: 0.8em;
}
#listFileWrap p {
  margin: 15px 0;
}
#listFileWrap ul {
  list-style: none;
  border: 1px solid #e3e3e3;
  background: #fff;
}
#listFileWrap ul li {
  border-bottom: 1px solid #e3e3e3;
}
#listFileWrap ul li span {
  padding: 10px;
  display: inline-block;
}
#listFileWrap ul li span.csvname {
  width: 285px;
}

.btn.btnGreen {
  color: #fff;
  border-color: #088e93;
  background: #088e93;
}
.btn.btnGreen:disabled {
  color: #818181;
  border-color: #e6e6e6;
  background: #e6e6e6;
}

.subContent .formList {
  padding: 30px;
}
.subContent .formList > div > dt {
  width: 220px;
}

.errorMsg {
  color: #a72525;
  font-weight: bold;
}

.seisei_date {
  margin-right: 20px;
}

.base_date {
  margin-right: 20px;
}

.errorRed {
  color: #a72525;
}

#overlay {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

#content {
  z-index: 6;
  width: 540px;
  padding: 30px;
  background: #fff;
  text-align: center;
}

#date-update {
  z-index: 6;
  width: 540px;
  padding: 30px;
  background: #fff;
  text-align: center;
}

#content .iconAttention {
  width: 40px;
  height: 40px;
  filter: grayscale(100%);
  background-color: rgba(255, 255, 255, 0.8);
  background-blend-mode: lighten;
}

.subContent .inputCal.inputSearch:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22currentColor%22%20class%3D%22bi%20bi-calendar%22%20viewBox%3D%220%200%2016%2016%22%3E%20%3Cpath%20d%3D%22M3.5%200a.5.5%200%200%201%20.5.5V1h8V.5a.5.5%200%200%201%201%200V1h1a2%202%200%200%201%202%202v11a2%202%200%200%201-2%202H2a2%202%200%200%201-2-2V3a2%202%200%200%201%202-2h1V.5a.5.5%200%200%201%20.5-.5zM1%204v10a1%201%200%200%200%201%201h12a1%201%200%200%200%201-1V4H1z%22%2F%3E%3C%2Fsvg%3E") !important;
  background-color: rgba(255, 255, 255, 0.8);
  background-blend-mode: lighten;
}

#date-update .title {
  font-size: 1.2em;
  font-weight: bold;
}

#date-update .caption {
  font-size: 0.8em;
}

#date-update .step-progress-wrap {
  margin: 30px;
  background-color: #cde8e9;
  border-radius: 100px;
}

#date-update .step-progress {
  display: flex;
  width: 100%;
  height: 15px;
  background-color: #088e93;
  transition: 0.5s;
  border-radius: 100px;
}

.vshow-enter-active,
.vshow-leave-active {
  transition: opacity 0.3s;
}

.vshow-enter,
vshow-leave-to {
  opacity: 0;
}

.vld-overlay {
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-out;
}

[v-cloak] {
  visibility: hidden;
}

.logTable.gradient {
  -webkit-mask-image: linear-gradient(#fff, rgba(255, 255, 255, 0));
}
.logTable.maxHeight {
  max-height: 400px;
}

.d-none {
  display: none;
}

#btnShow {
  display: flex;
  justify-content: center;
  align-items: center;
}
#btnShow .button {
  color: #438E98;
  border: 1px solid #438E98;
}
#btnShow .arrow-down {
  transform: rotate(-90deg);
}

.custom-error {
  text-decoration-line: none !important;
}

/* css button clear file maintenance */
div.message-error {
  position: relative;
}

div.clearBtn {
  width: 170px;
  height: 42px;
  border: 1px solid #b8b8b8;
  background-color: #fff;
  position: absolute;
  top: calc(50% - 21px);
  right: 0;
  font-size: 14px;
  color: #707070;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
div.clearBtn svg {
  width: 11px;
  height: 11px;
  fill: #707070;
}
div.clearBtn:hover {
  color: #000;
}
div.clearBtn:hover svg {
  fill: #000;
}

div#errorMsgZip .errorMsg {
  margin-left: 0px;
}

.color_a72525 {
  color: #a72525;
}

.file-item {
  display: flex;
  align-items: center;
}

img.imgIconFile {
  padding-top: 2px;
}

#listFileWrap .listHead {
  display: grid;
  grid-template-columns: 20% 30% 8% 10% 32%;
}
#listFileWrap .file-item {
  display: grid;
  grid-template-columns: 20% 30% 8% 10% 32%;
}

.contentDisable {
  color: #B8B8B8;
  text-decoration: none !important;
  pointer-events: none;
}
.contentDisable .checkboxLabel input[type=checkbox],
.contentDisable .checkboxLabel input[type=checkbox]:checked {
  color: #B8B8B8 !important;
  background: #EEEDEC !important;
  box-shadow: 0 0 0 2px #B8B8B8 !important;
}
.contentDisable .checkboxLabel input[type=checkbox] + span,
.contentDisable .checkboxLabel input[type=checkbox]:checked + span {
  color: #B8B8B8 !important;
  background: #EEEDEC !important;
  box-shadow: 0 0 0 2px #B8B8B8 !important;
}
.contentDisable .checkboxLabel input[type=checkbox] + span:before,
.contentDisable .checkboxLabel input[type=checkbox]:checked + span:before {
  opacity: 1 !important;
  background: #E0E0E0 !important;
}
.contentDisable .checkboxLabel input[type=checkbox] + span:after,
.contentDisable .checkboxLabel input[type=checkbox]:checked + span:after {
  opacity: 0 !important;
}

.calendarList .inputSearch.inputCal {
  display: flex !important;
  padding: 0 10px;
  background: #fff;
}
.calendarList .inputSearch.inputCal:before {
  display: none !important;
}
.calendarList .inputSearch.inputCal .input {
  padding: 0 10px;
  border: none;
}
.calendarList .inputSearch.inputCal .input[type=text], .calendarList .inputSearch.inputCal .input::placeholder {
  color: #737373;
}
.calendarList .inputSearch.inputCal .input:focus, .calendarList .inputSearch.inputCal .input:active {
  background: transparent !important;
}

.newsBox {
  background: #EEFDFE;
}
.newsBox > dt {
  color: #088E98;
  font-weight: 600;
}
.inputArea {
  background: #F7F6F4;
}
.inputArea .commonDlTitle {
  font-weight: 600;
}
.inputArea .commonDl {
  background: #ffffff;
}
.inputArea .commonDl.error {
  background: #FFF0F0;
}
.inputArea .commonDl.dtSky > div > dt {
  background: #EEFDFE !important;
}
.inputArea .commonDl > div > dt {
  background: #EEEDEC !important;
}

.progBar {
  background-color: #D1EAEC;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.progBar > .bar {
  position: absolute;
  height: 100%;
  background: radial-gradient(circle, #00b9c6 0%, #088e98 100%);
  background-size: 200% 100%;
  background-position: -100% 0;
  animation: progress 4s normal;
  animation-fill-mode: forwards;
  border-radius: 13px;
  animation: barAni 3s infinite linear;
}

.colorGreen .linkGreen {
  color: #088E98;
}
.formlistBox {
  border: 1px solid #D2D2D2;
  background: #fff;
}
.formlistBox .listBoxContent > li + li {
  border-top: 1px solid #E6E6E6;
}
.formWrap {
  display: flex;
  align-items: center;
}
.formTitle .formTitleSub {
  flex: none;
  color: #fff;
  line-height: 1;
  font-weight: 600;
  background: #088E98;
}
.formTitle strong {
  font-weight: bold;
}

.fileSelectRow {
  display: flex;
  align-items: center;
  border: 2px dashed #B8B8B8;
  background: #fff;
  cursor: pointer;
}
.fileSelectRow .fileSectLead {
  color: #B8B8B8;
  text-align: center;
  line-height: 1.33;
}
.fileSelectRow .btnWrap {
  text-align: center;
}
.fileSelect {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px dashed #B8B8B8;
  background: #fff;
  cursor: pointer;
}
.fileSelect .fileSectLead {
  color: #B8B8B8;
  text-align: center;
  line-height: 1.33;
}
.fileSelect .btnWrap {
  text-align: center;
}
.inputSearchBtnIn .inputWrap .inputSearchBtnWrap .inputSearchInput + label {
  position: absolute;
  right: 0;
  top: 0;
}
.quasiTable {
  background: #fff;
}
.quasiTable .quasiTableHeader {
  display: flex;
  width: 100%;
  border-top: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
  background: #F7F6F4;
}
.quasiTable .quasiTableBody {
  border-bottom: 1px solid #E6E6E6;
}
.quasiTable .quasiTableBody .quasiTableTr.disabled {
  background: #DFDFDF;
}
.quasiTable .quasiTableTr {
  display: flex;
  width: 100%;
}
.quasiTable .quasiTableTr > *:not(.quasiTableCommittee):not(.quasiTableFull) {
  text-align: center;
  justify-content: center;
  flex: none;
}
.quasiTable .quasiTableTr + .quasiTableTr {
  border-top: 1px solid #E6E6E6;
}
.quasiTable .quasiTableTr .quasiTableFull {
  width: 100%;
}
.quasiTable .quasiTableTr .quasiTableCommittee {
  width: 100%;
}
.quasiTable .quasiTableTr .quasiTableLabel {
  width: 44px;
}
.quasiTable .quasiTableTr .quasiTableAdd {
  width: 161px;
  color: #A72525;
}
.quasiTable .quasiTableTr .quasiTableDate {
  width: 163px;
}
.quasiTable .quasiTableTr .quasiTableAbolition {
  width: 163px;
}
.quasiTable .quasiTableTr .quasiTableInfo {
  width: 163px;
  font-size: 14px;
}
.quasiTable .quasiTableTr .quasiTableInfo .textInfo {
  color: #A72525;
}
.quasiTable a.quasiTableTr {
  color: #000000;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}
.quasiTable a.quasiTableTr:after {
  content: "";
  display: block;
  border-right: 2px solid #088E98;
  border-bottom: 2px solid #088E98;
  transform: rotate(-45deg);
  border-radius: 0;
  background-color: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.titleSupplement {
  font-weight: 600;
}
.textAreaWrap {
  background: #F7F6F4;
}
.textAreaWrap .textAreaResult {
  background: #fff;
}

#log .btnMore {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}
#log .btnMore:not(:has(.iconSearch)).isOpen:before {
  transform: rotate(-135deg) translateY(-2px) translateX(-3px);
}
#log .btnMore:not(:has(.iconSearch)).isOpen:after {
  opacity: 1;
}
#log .btnMore:not(:has(.iconSearch)):before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(45deg) translateY(-3px);
  transition: 0.2s transform;
}
#log .btnMore:after {
  content: "閉じる";
  display: block;
  height: 100%;
  text-align: center;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  box-sizing: border-box;
  opacity: 0;
  pointer-events: none;
}
#log .btnMore.btnGreen {
  color: #000000;
  border-color: #B8B8B8;
}
#log .btnMore.btnGreen.isOpen:before {
  transform: rotate(-180deg);
}
#log .btnMore.btnGreen:not(:has(.iconSearch)):before {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #088E98 transparent transparent transparent;
  pointer-events: none;
  transform: rotate(0);
}

body.bgLogin {
  background: #BEF1E6;
}
body.bgLogin .subContent {
  background: #fff;
}
body.bgLogin .subContent.loginBox .loginBoxHeader {
  text-align: center;
}
body.bgLogin .subContent.loginBox .loginBoxHeader .lHInner #site_logo {
  text-decoration: none;
}
body.bgLogin .subContent.loginBox .loginBoxHeader .lHInner #site_logo > a {
  text-decoration: none;
}
body.bgLogin .subContent.loginBox .loginBoxHeader .lHInner #site_logo .site_logo_text {
  color: #000000;
  font-weight: bold;
}

.loginBoxContent + .loginBoxContent {
  border-top: 1px solid #D2D2D2;
}
.loginHeader .lHInner #site_logo a {
  display: block;
  line-height: 1;
}
.loginHeader .lHInner #site_logo a img {
  width: 100%;
}

.loginFooter {
  margin-inline: auto;
}
.loginFooter p.copyError {
  font-weight: bold;
}
.loginFooter.small {
  position: relative;
}
.loginFooter.small .lFImage {
  position: absolute;
}

.subContent .titleCenter {
  font-weight: 400;
}
.subContent .stepNumWrap {
  display: flex;
  align-items: center;
}
.subContent .stepNumWrap .stepNum {
  color: #fff;
  line-height: 1;
  background: #088E98;
}
.subContent .stepNumWrap .stepNumTitle {
  color: #088E98;
  font-weight: 600;
}
.subContent .titleGreen {
  color: #088E98;
  font-weight: bold;
}
.subContent .infoContent {
  text-align: center;
}
.subContent .infoContentNote {
  display: flex;
  justify-content: center;
}
.subContent .infoContentNote:before {
  content: "";
  flex: none;
  display: block;
  vertical-align: middle;
  margin-right: 5px;
  background: url(../images/contact_sub/icon_logout.svg) no-repeat;
  background-size: contain;
}

@media screen and (max-width: 599px) {
  /* Magnific Popup CSS */
  .mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8;
  }
  .mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
  }
  .mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box;
  }
  .mfp-container:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .mfp-align-top .mfp-container:before {
    display: none;
  }
  .mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
  }
  .mfp-inline-holder .mfp-content,
  .mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
  }
  .mfp-ajax-cur {
    cursor: progress;
  }
  .mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
  }
  .mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
  }
  .mfp-auto-cursor .mfp-content {
    cursor: auto;
  }
  .mfp-close,
  .mfp-arrow,
  .mfp-preloader,
  .mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  .mfp-loading.mfp-figure {
    display: none;
  }
  .mfp-hide {
    display: none !important;
  }
  .mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
  }
  .mfp-preloader a {
    color: #CCC;
  }
  .mfp-preloader a:hover {
    color: #FFF;
  }
  .mfp-s-ready .mfp-preloader {
    display: none;
  }
  .mfp-s-error .mfp-content {
    display: none;
  }
  button.mfp-close,
  button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation;
  }
  button::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  .mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
  }
  .mfp-close:hover,
  .mfp-close:focus {
    opacity: 1;
  }
  .mfp-close-btn-in .mfp-close {
    color: #333;
  }
  .mfp-image-holder .mfp-close,
  .mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
  }
  .mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
  }
  .mfp-arrow {
    position: absolute;
    opacity: 0.65;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent;
  }
  .mfp-arrow:active {
    margin-top: -54px;
  }
  .mfp-arrow:hover,
  .mfp-arrow:focus {
    opacity: 1;
  }
  .mfp-arrow:before,
  .mfp-arrow:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
  }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
  }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7;
  }
  .mfp-arrow-left {
    left: 0;
  }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px;
  }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F;
  }
  .mfp-arrow-right {
    right: 0;
  }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px;
  }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F;
  }
  .mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
  }
  .mfp-iframe-holder .mfp-close {
    top: -40px;
  }
  .mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
  }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
  }
  /* Main image in popup */
  img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
  }
  /* The shadow behind the image */
  .mfp-figure {
    line-height: 0;
  }
  .mfp-figure:after {
    content: "";
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444;
  }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px;
  }
  .mfp-figure figure {
    margin: 0;
  }
  .mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
  }
  .mfp-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px;
  }
  .mfp-image-holder .mfp-content {
    max-width: 100%;
  }
  .mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
  }
  /* css button clear file maintenance */
}
@media screen and (max-width: 599px) and (max-width: 800px) and (orientation: landscape), screen and (max-width: 599px) and (max-height: 300px) {
  /**
  * Remove all paddings around the image on small screen
  */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media screen and (max-width: 599px) and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media screen and (max-width: 599px) {
  #mainArea {
    width: 90%;
    margin: 0 auto;
  }
  .breadcrumb nav {
    padding: 10px 0 20px 0;
    overflow: hidden;
    background: #fff;
    position: relative;
    top: 0;
    height: 100%;
    z-index: 1;
  }
  .breadcrumb nav ol {
    float: left;
  }
  .breadcrumb nav ol li {
    list-style: none;
    float: left;
    padding-right: 20px;
    position: relative;
  }
  .breadcrumb nav ol li a {
    color: #000;
    font-size: 14px;
  }
  .breadcrumb nav ol li a:hover {
    text-decoration: none;
    color: #088E98;
  }
  .breadcrumb nav ol li .active {
    color: #088E98;
  }
  .breadcrumb nav ol li .breadcrumb-home-a::after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    transform: rotate(45deg) translateY(-50%);
    position: absolute;
    top: 50%;
    right: 10px;
    border-top: 1px solid #CFCFCF;
    border-right: 1px solid #CFCFCF;
  }
  .breadcrumb h1 {
    position: relative;
    padding-left: 22px;
    line-height: 44px;
  }
  .breadcrumb h1:after {
    content: "";
    display: block;
    width: 0;
    height: 44px;
    position: absolute;
    top: 0;
    left: 0;
    border-right: 6px solid #088E98;
  }
  .breadcrumb nav ol li span {
    font-size: 14px;
  }
  .subContent .btnIcon,
  .mfp-content .btnIcon {
    width: 11px;
    height: 11px;
    position: absolute;
    top: calc(50% - 6px);
    left: 15px;
    fill: #707070;
  }
  .subContent a.linkArrow,
  .mfp-content a.linkArrow {
    padding-right: 20px;
  }
  .subContent h1,
  .subContent h2,
  .subContent h3,
  .subContent h4,
  .subContent h5,
  .mfp-content h1,
  .mfp-content h2,
  .mfp-content h3,
  .mfp-content h4,
  .mfp-content h5 {
    line-height: 1.5;
  }
  .subContent .tel,
  .mfp-content .tel {
    color: #fff6e6;
    text-decoration: underline;
  }
  .subContent .mB0,
  .mfp-content .mB0 {
    margin-bottom: 0 !important;
  }
  .subContent .mB5_sp,
  .mfp-content .mB5_sp {
    margin-bottom: 5px !important;
  }
  .subContent .mB10_sp,
  .mfp-content .mB10_sp {
    margin-bottom: 10px !important;
  }
  .subContent .mB20_sp,
  .mfp-content .mB20_sp {
    margin-bottom: 20px !important;
  }
  .subContent .mB30_sp,
  .mfp-content .mB30_sp {
    margin-bottom: 30px !important;
  }
  .subContent .mB40_sp,
  .mfp-content .mB40_sp {
    margin-bottom: 40px !important;
  }
  .subContent .mB50_sp,
  .mfp-content .mB50_sp {
    margin-bottom: 50px !important;
  }
  .subContent .mT0,
  .mfp-content .mT0 {
    margin-top: 0 !important;
  }
  .subContent .mT5_sp,
  .mfp-content .mT5_sp {
    margin-top: 5px !important;
  }
  .subContent .mT10_sp,
  .mfp-content .mT10_sp {
    margin-top: 10px !important;
  }
  .subContent .mT20_sp,
  .mfp-content .mT20_sp {
    margin-top: 20px !important;
  }
  .subContent .mT30_sp,
  .mfp-content .mT30_sp {
    margin-top: 30px !important;
  }
  .subContent .mT40_sp,
  .mfp-content .mT40_sp {
    margin-top: 40px !important;
  }
  .subContent .mT50_sp,
  .mfp-content .mT50_sp {
    margin-top: 50px !important;
  }
  .subContent .mL0_sp,
  .mfp-content .mL0_sp {
    margin-left: 0px !important;
  }
  .subContent .mL5_sp,
  .mfp-content .mL5_sp {
    margin-left: 5px !important;
  }
  .subContent .mL10_sp,
  .mfp-content .mL10_sp {
    margin-left: 10px !important;
  }
  .subContent .mL20_sp,
  .mfp-content .mL20_sp {
    margin-left: 20px !important;
  }
  .subContent .mL30_sp,
  .mfp-content .mL30_sp {
    margin-left: 30px !important;
  }
  .subContent .mL40_sp,
  .mfp-content .mL40_sp {
    margin-left: 40px !important;
  }
  .subContent .mL50_sp,
  .mfp-content .mL50_sp {
    margin-left: 50px !important;
  }
  .subContent .mR0_sp,
  .mfp-content .mR0_sp {
    margin-right: 0px !important;
  }
  .subContent .mR5_sp,
  .mfp-content .mR5_sp {
    margin-right: 5px !important;
  }
  .subContent .mR10_sp,
  .mfp-content .mR10_sp {
    margin-right: 10px !important;
  }
  .subContent .mR20_sp,
  .mfp-content .mR20_sp {
    margin-right: 20px !important;
  }
  .subContent .mR30_sp,
  .mfp-content .mR30_sp {
    margin-right: 30px !important;
  }
  .subContent .mR40_sp,
  .mfp-content .mR40_sp {
    margin-right: 40px !important;
  }
  .subContent .mR50_sp,
  .mfp-content .mR50_sp {
    margin-right: 50px !important;
  }
  .subContent .size12_sp,
  .mfp-content .size12_sp {
    font-size: 12px !important;
  }
  .subContent .size14_sp,
  .mfp-content .size14_sp {
    font-size: 14px !important;
  }
  .subContent .size16_sp,
  .mfp-content .size16_sp {
    font-size: 16px !important;
  }
  .subContent .size18_sp,
  .mfp-content .size18_sp {
    font-size: 18px !important;
  }
  .subContent .size20_sp,
  .mfp-content .size20_sp {
    font-size: 20px !important;
  }
  .subContent .w50p_sp,
  .mfp-content .w50p_sp {
    display: block;
    width: 50% !important;
    margin: 0 auto;
  }
  .subContent .w50,
  .mfp-content .w50 {
    display: block;
    width: 50% !important;
    margin: 0 auto;
  }
  .subContent * + ul.commonList,
  .mfp-content * + ul.commonList {
    margin-top: 26px;
  }
  .subContent ul.commonList > li,
  .mfp-content ul.commonList > li {
    padding-left: 25px;
  }
  .subContent ul.commonList > li:not(:first-of-type),
  .mfp-content ul.commonList > li:not(:first-of-type) {
    margin-top: 15px;
  }
  .subContent ul.commonList > li:before,
  .mfp-content ul.commonList > li:before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: auto;
    content: "";
    border-radius: 50%;
    background-color: #1560BB;
    position: absolute;
    left: 0;
    top: 0.55em;
  }
  .subContent ol.commonList > li,
  .mfp-content ol.commonList > li {
    padding-left: 25px;
  }
  .subContent ol.commonList > li:not(:first-of-type),
  .mfp-content ol.commonList > li:not(:first-of-type) {
    margin-top: 15px;
  }
  .subContent ol.commonList > li + li,
  .mfp-content ol.commonList > li + li {
    margin-top: 7px;
  }
  .subContent .modalBody .scrollTable .modTable.thSticky,
  .mfp-content .modalBody .scrollTable .modTable.thSticky {
    min-width: 983px;
  }
  .subContent .modalBody .scrollTable table,
  .mfp-content .modalBody .scrollTable table {
    min-width: 983px;
  }
  .subContent * + .scrollTable,
  .mfp-content * + .scrollTable {
    margin-top: 12px;
  }
  .subContent .scrollTable,
  .mfp-content .scrollTable {
    overflow: auto;
  }
  .subContent .scrollTable + .commonTable,
  .subContent .scrollTable + .sortTable,
  .subContent .scrollTable + .modTable,
  .mfp-content .scrollTable + .commonTable,
  .mfp-content .scrollTable + .sortTable,
  .mfp-content .scrollTable + .modTable {
    margin-top: 0;
  }
  .subContent .scrollTable .modTable.thSticky,
  .mfp-content .scrollTable .modTable.thSticky {
    min-width: 1220px;
  }
  .subContent .scrollTable table tr td.nowrap,
  .subContent .scrollTable table tr th.nowrap,
  .mfp-content .scrollTable table tr td.nowrap,
  .mfp-content .scrollTable table tr th.nowrap {
    white-space: nowrap;
  }
  .subContent table .downBlock,
  .mfp-content table .downBlock {
    max-width: calc(100vw - 170px);
    padding-top: 10px;
  }
  .subContent table * + p,
  .mfp-content table * + p {
    margin-top: 6px;
  }
  .subContent table + table,
  .mfp-content table + table {
    margin-top: 30px;
  }
  .subContent .scrolltext,
  .mfp-content .scrolltext {
    margin-bottom: 12px;
    font-size: 12px;
    text-align: right;
  }
  .subContent p + .commonTable,
  .mfp-content p + .commonTable {
    margin-top: 14px;
  }
  .subContent p + .sortTable,
  .mfp-content p + .sortTable {
    margin-top: 14px;
  }
  .subContent .commonTable + .note,
  .mfp-content .commonTable + .note {
    margin-top: 10px;
  }
  .subContent .commonTable,
  .mfp-content .commonTable {
    font-size: 14px;
    border-bottom: 1px solid #D2D2D2;
  }
  .subContent .commonTable > thead > tr > th,
  .subContent .commonTable > thead > tr > td,
  .subContent .commonTable > tbody > tr > th,
  .subContent .commonTable > tbody > tr > td,
  .subContent .commonTable > tr > th,
  .subContent .commonTable > tr > td,
  .mfp-content .commonTable > thead > tr > th,
  .mfp-content .commonTable > thead > tr > td,
  .mfp-content .commonTable > tbody > tr > th,
  .mfp-content .commonTable > tbody > tr > td,
  .mfp-content .commonTable > tr > th,
  .mfp-content .commonTable > tr > td {
    padding: 13px 10px;
    border-top: 1px solid #D2D2D2;
  }
  .subContent .commonTable > thead > tr,
  .subContent .commonTable > tbody > tr,
  .subContent .commonTable > tr,
  .mfp-content .commonTable > thead > tr,
  .mfp-content .commonTable > tbody > tr,
  .mfp-content .commonTable > tr {
    display: flex;
    flex-wrap: wrap;
  }
  .subContent .commonTable > thead > tr > td,
  .subContent .commonTable > tbody > tr > td,
  .subContent .commonTable > tr > td,
  .mfp-content .commonTable > thead > tr > td,
  .mfp-content .commonTable > tbody > tr > td,
  .mfp-content .commonTable > tr > td {
    border-bottom: none;
  }
  .subContent .commonTable > thead > tr > th,
  .subContent .commonTable > tbody > tr > th,
  .subContent .commonTable > tr > th,
  .mfp-content .commonTable > thead > tr > th,
  .mfp-content .commonTable > tbody > tr > th,
  .mfp-content .commonTable > tr > th {
    display: block;
    width: 120px !important;
  }
  .subContent .commonTable > thead > tr > td,
  .subContent .commonTable > tbody > tr > td,
  .subContent .commonTable > tr > td,
  .mfp-content .commonTable > thead > tr > td,
  .mfp-content .commonTable > tbody > tr > td,
  .mfp-content .commonTable > tr > td {
    display: block;
    width: calc(100% - 120px) !important;
  }
  .subContent .sortTable,
  .mfp-content .sortTable {
    font-size: 14px;
    border-bottom: 1px solid #D2D2D2;
  }
  .subContent .sortTable > thead > tr > th,
  .subContent .sortTable > thead > tr > td,
  .subContent .sortTable > tbody > tr > th,
  .subContent .sortTable > tbody > tr > td,
  .subContent .sortTable > tr > th,
  .subContent .sortTable > tr > td,
  .mfp-content .sortTable > thead > tr > th,
  .mfp-content .sortTable > thead > tr > td,
  .mfp-content .sortTable > tbody > tr > th,
  .mfp-content .sortTable > tbody > tr > td,
  .mfp-content .sortTable > tr > th,
  .mfp-content .sortTable > tr > td {
    padding: 13px 10px;
    border-top: 1px solid #D2D2D2;
    white-space: nowrap;
  }
  .subContent .sortTable.tdSmall > thead > tr > td,
  .subContent .sortTable.tdSmall > tbody > tr > td,
  .subContent .sortTable.tdSmall > tr > td,
  .mfp-content .sortTable.tdSmall > thead > tr > td,
  .mfp-content .sortTable.tdSmall > tbody > tr > td,
  .mfp-content .sortTable.tdSmall > tr > td {
    font-size: 14px;
  }
  .subContent .sortTable > thead > tr > th.sortNum, .subContent .sortTable > thead > tr > th.sortName, .subContent .sortTable > thead > tr > th.sortPlace,
  .subContent .sortTable > thead > tr > th .sortBarName, .subContent .sortTable > thead > tr > th.sortClass,
  .subContent .sortTable > tbody > tr > th.sortNum,
  .subContent .sortTable > tbody > tr > th.sortName,
  .subContent .sortTable > tbody > tr > th.sortPlace,
  .subContent .sortTable > tbody > tr > th .sortBarName,
  .subContent .sortTable > tbody > tr > th.sortClass,
  .subContent .sortTable > tr > th.sortNum,
  .subContent .sortTable > tr > th.sortName,
  .subContent .sortTable > tr > th.sortPlace,
  .subContent .sortTable > tr > th .sortBarName,
  .subContent .sortTable > tr > th.sortClass,
  .mfp-content .sortTable > thead > tr > th.sortNum,
  .mfp-content .sortTable > thead > tr > th.sortName,
  .mfp-content .sortTable > thead > tr > th.sortPlace,
  .mfp-content .sortTable > thead > tr > th .sortBarName,
  .mfp-content .sortTable > thead > tr > th.sortClass,
  .mfp-content .sortTable > tbody > tr > th.sortNum,
  .mfp-content .sortTable > tbody > tr > th.sortName,
  .mfp-content .sortTable > tbody > tr > th.sortPlace,
  .mfp-content .sortTable > tbody > tr > th .sortBarName,
  .mfp-content .sortTable > tbody > tr > th.sortClass,
  .mfp-content .sortTable > tr > th.sortNum,
  .mfp-content .sortTable > tr > th.sortName,
  .mfp-content .sortTable > tr > th.sortPlace,
  .mfp-content .sortTable > tr > th .sortBarName,
  .mfp-content .sortTable > tr > th.sortClass {
    width: auto;
    white-space: nowrap;
  }
  .subContent .sortTable > thead > tr > th:not([scope=col]),
  .subContent .sortTable > tbody > tr > th:not([scope=col]),
  .subContent .sortTable > tr > th:not([scope=col]),
  .mfp-content .sortTable > thead > tr > th:not([scope=col]),
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]),
  .mfp-content .sortTable > tr > th:not([scope=col]) {
    padding: 0;
  }
  .subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn,
  .subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtnNone,
  .subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn,
  .subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtnNone,
  .subContent .sortTable > tr > th:not([scope=col]) .sortBtn,
  .subContent .sortTable > tr > th:not([scope=col]) .sortBtnNone,
  .mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn,
  .mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtnNone,
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn,
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtnNone,
  .mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn,
  .mfp-content .sortTable > tr > th:not([scope=col]) .sortBtnNone {
    padding: 20px 10px;
  }
  .subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect,
  .subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect,
  .subContent .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect,
  .mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect,
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect,
  .mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect {
    width: 13px;
    min-height: 23px;
    margin-left: 12px;
  }
  .subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd),
  .subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd),
  .subContent .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd),
  .mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd),
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd),
  .mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd) {
    border-width: 0 6.5px 10px 6.5px;
  }
  .subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even),
  .subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even),
  .subContent .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even),
  .mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even),
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even),
  .mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even) {
    border-width: 10px 6.5px 0 6.5px;
  }
  .subContent .sortTable.copList tr.subTr td,
  .mfp-content .sortTable.copList tr.subTr td {
    background-color: #F9F9F7;
  }
  .subContent .sortTable.copList tr.subTr td.flexCell,
  .mfp-content .sortTable.copList tr.subTr td.flexCell {
    display: flex;
    align-items: center;
  }
  .subContent .sortTable.copList tr.subTr td span.tag,
  .mfp-content .sortTable.copList tr.subTr td span.tag {
    font-size: 14px;
    background-color: #D2D2D2;
    border-radius: 20px;
    color: #000;
    padding: 3px 5px;
    margin-right: 10px;
    white-space: nowrap;
  }
  .subContent .sortTable.copList td,
  .mfp-content .sortTable.copList td {
    background-color: #fff;
  }
  .subContent .sortTable.copList td.tac,
  .mfp-content .sortTable.copList td.tac {
    text-align: center;
  }
  .subContent .sortTable.copList td.num,
  .mfp-content .sortTable.copList td.num {
    vertical-align: top;
  }
  .subContent table .width3Percent,
  .mfp-content table .width3Percent {
    width: 3% !important;
  }
  .subContent table .width4Percent,
  .mfp-content table .width4Percent {
    width: 4% !important;
  }
  .subContent table .width5Percent,
  .mfp-content table .width5Percent {
    width: 5% !important;
  }
  .subContent table .width6Percent,
  .mfp-content table .width6Percent {
    width: 6% !important;
  }
  .subContent table .width7Percent,
  .mfp-content table .width7Percent {
    width: 7% !important;
  }
  .subContent table .width8Percent,
  .mfp-content table .width8Percent {
    width: 8% !important;
  }
  .subContent table .width9Percent,
  .mfp-content table .width9Percent {
    width: 9% !important;
  }
  .subContent table .width10Percent,
  .mfp-content table .width10Percent {
    width: 10% !important;
  }
  .subContent table .width11Percent,
  .mfp-content table .width11Percent {
    width: 11% !important;
  }
  .subContent table .width12Percent,
  .mfp-content table .width12Percent {
    width: 12% !important;
  }
  .subContent table .width13Percent,
  .mfp-content table .width13Percent {
    width: 13% !important;
  }
  .subContent table .width14Percent,
  .mfp-content table .width14Percent {
    width: 14% !important;
  }
  .subContent table .width16Percent,
  .mfp-content table .width16Percent {
    width: 16% !important;
  }
  .subContent table .width18Percent,
  .mfp-content table .width18Percent {
    width: 18% !important;
  }
  .subContent table .width20Percent,
  .mfp-content table .width20Percent {
    width: 20% !important;
  }
  .subContent table .width130,
  .mfp-content table .width130 {
    width: 130px !important;
    box-sizing: border-box;
  }
  .subContent .separateTable,
  .mfp-content .separateTable {
    font-size: 14px;
    border-bottom: 1px solid #D2D2D2;
  }
  .subContent .separateTable > thead > tr > th,
  .subContent .separateTable > thead > tr > td,
  .subContent .separateTable > tbody > tr > th,
  .subContent .separateTable > tbody > tr > td,
  .subContent .separateTable > tr > th,
  .subContent .separateTable > tr > td,
  .mfp-content .separateTable > thead > tr > th,
  .mfp-content .separateTable > thead > tr > td,
  .mfp-content .separateTable > tbody > tr > th,
  .mfp-content .separateTable > tbody > tr > td,
  .mfp-content .separateTable > tr > th,
  .mfp-content .separateTable > tr > td {
    padding: 13px 10px;
    text-align: center;
  }
  .subContent * + .listBox,
  .mfp-content * + .listBox {
    margin-top: 12px;
  }
  .subContent .listBox > div,
  .mfp-content .listBox > div {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
  }
  .subContent .listBox > div > dt,
  .subContent .listBox > div > dd,
  .mfp-content .listBox > div > dt,
  .mfp-content .listBox > div > dd {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .subContent .listBox > div > dt,
  .mfp-content .listBox > div > dt {
    width: 120px;
    margin-left: 15px;
    font-weight: 700;
  }
  .subContent .listBox > div > dd,
  .mfp-content .listBox > div > dd {
    width: calc(100% - 150px);
  }
  .subContent .listBox > div.col3 > dt,
  .mfp-content .listBox > div.col3 > dt {
    font-weight: 700;
  }
  .subContent .listBox > .listBlue:not(.col3),
  .mfp-content .listBox > .listBlue:not(.col3) {
    margin-top: 10px;
  }
  .subContent * + .note,
  .mfp-content * + .note {
    margin-top: 11px;
  }
  .subContent .note,
  .mfp-content .note {
    font-size: 13px;
  }
  .subContent * + .noteSub,
  .mfp-content * + .noteSub {
    margin-top: 0px;
  }
  .subContent .noteSub,
  .mfp-content .noteSub {
    font-size: 12px;
  }
  .subContent * + p,
  .mfp-content * + p {
    margin-top: 25px;
  }
  .subContent * + .titleCenter,
  .mfp-content * + .titleCenter {
    margin-top: 30px;
  }
  .subContent .titleCenter,
  .mfp-content .titleCenter {
    font-size: 20px;
  }
  .subContent * + .dataNote,
  .mfp-content * + .dataNote {
    margin-top: 4px;
  }
  .subContent .dataNote span,
  .mfp-content .dataNote span {
    font-size: 18px;
  }
  .subContent * + .colorBlock,
  .mfp-content * + .colorBlock {
    margin-top: 29px;
  }
  .subContent .colorBlock,
  .mfp-content .colorBlock {
    padding: 27px 15px 27px;
  }
  .subContent .colorBlockTitle,
  .mfp-content .colorBlockTitle {
    margin-bottom: 22px;
    font-size: 18px;
  }
  .subContent .colorBoxHeader,
  .mfp-content .colorBoxHeader {
    padding: 20px 20px 10px;
  }
  .subContent .colorBoxBody,
  .mfp-content .colorBoxBody {
    padding: 10px 25px 20px;
  }
  .subContent .postContent,
  .mfp-content .postContent {
    padding: 20px;
  }
  .subContent .postContent * + p,
  .mfp-content .postContent * + p {
    margin-top: 15px;
  }
  .subContent .formList + .btnWrap,
  .mfp-content .formList + .btnWrap {
    margin-top: 0;
  }
  .subContent * + .btnWrap,
  .mfp-content * + .btnWrap {
    margin-top: 28px;
  }
  .subContent .btnWrap,
  .mfp-content .btnWrap {
    text-align: center;
  }
  .subContent .btnWrap#searchCtrlBtns,
  .mfp-content .btnWrap#searchCtrlBtns {
    display: flex;
    flex-direction: column;
  }
  .subContent .btnWrap#searchCtrlBtns .btnClearForm,
  .mfp-content .btnWrap#searchCtrlBtns .btnClearForm {
    order: 2;
    margin-top: 25px;
    max-width: 260px;
  }
  .subContent .btnWrap#searchCtrlBtns #buttonSearch,
  .mfp-content .btnWrap#searchCtrlBtns #buttonSearch {
    order: 1;
  }
  .subContent .btnWrap > * + *,
  .mfp-content .btnWrap > * + * {
    margin-left: 10px;
  }
  .subContent .btn,
  .mfp-content .btn {
    width: 100%;
    padding: 7px 11px;
    margin: 0 auto;
  }
  .subContent .btn + .btn,
  .mfp-content .btn + .btn {
    margin-top: 15px;
  }
  .subContent .btn.btnGreen.btnDownload:before,
  .mfp-content .btn.btnGreen.btnDownload:before {
    width: 15px;
    height: 15px;
    margin-right: 13px;
  }
  .subContent .btn:not(.btnS):not(.btnSS):not(.btnMini):not(.btnFull),
  .mfp-content .btn:not(.btnS):not(.btnSS):not(.btnMini):not(.btnFull) {
    max-width: 100%;
    min-width: 260px;
    height: 50px;
  }
  .subContent .btn.btnFull,
  .mfp-content .btn.btnFull {
    min-width: 100%;
  }
  .subContent .btn.btnS,
  .mfp-content .btn.btnS {
    display: inline-block;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .subContent .btn.btnSS,
  .mfp-content .btn.btnSS {
    display: inline-block;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .subContent .btn.btnMini,
  .mfp-content .btn.btnMini {
    display: inline-block;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .subContent .btn.btnBack:before,
  .mfp-content .btn.btnBack:before {
    width: 7px;
    height: 7px;
    left: 20px;
  }
  .subContent .btn.btnClose,
  .mfp-content .btn.btnClose {
    max-width: 200px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .subContent .btn.btnClose:before,
  .mfp-content .btn.btnClose:before {
    left: 15px;
  }
  .subContent .btn.btnDown,
  .mfp-content .btn.btnDown {
    padding-right: 33px;
  }
  .subContent .btn.btnDown:before,
  .mfp-content .btn.btnDown:before {
    width: 8px;
    height: 8px;
    border-right: 1px solid #1560BB;
    border-bottom: 1px solid #1560BB;
    right: 16px;
  }
  .subContent .btn.btnDown:after,
  .mfp-content .btn.btnDown:after {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 11px;
    border-radius: 4px 0 0 4px;
    width: calc(100% - 33px);
  }
  .subContent .btn.btnDown.btnGreen:before,
  .mfp-content .btn.btnDown.btnGreen:before {
    border-width: 7px 6.5px 0 6.5px;
  }
  .subContent .tag,
  .mfp-content .tag {
    padding: 5px 16px;
  }
  .subContent .iconPdf,
  .mfp-content .iconPdf {
    width: 15px;
    height: 16px;
  }
  .subContent .iconAttention,
  .mfp-content .iconAttention {
    width: 18px;
    height: 18px;
  }
  .subContent .iconDownload,
  .mfp-content .iconDownload {
    width: 13px;
    height: 13px;
    margin-right: 13px;
  }
  .subContent .modalLink:before,
  .mfp-content .modalLink:before {
    width: 18px;
    height: 18px;
  }
  .subContent .searchMenu ul,
  .mfp-content .searchMenu ul {
    justify-content: space-between;
  }
  .subContent .searchMenu ul > li,
  .mfp-content .searchMenu ul > li {
    width: calc(50% - 5px);
    min-height: 36px;
  }
  .subContent .searchMenu ul > li + li,
  .mfp-content .searchMenu ul > li + li {
    margin-left: 10px;
  }
  .subContent .searchMenu ul > li > *,
  .mfp-content .searchMenu ul > li > * {
    padding-right: 25px;
    padding-left: 25px;
    font-size: 14px;
    line-height: 1.4;
  }
  .subContent .searchMenu ul > li > *:before,
  .mfp-content .searchMenu ul > li > *:before {
    width: 6px;
    height: 6px;
    right: 10px;
  }
  .subContent .attachedList > li + li,
  .mfp-content .attachedList > li + li {
    margin-top: 5px;
  }
  .subContent .attachedList > li > a,
  .mfp-content .attachedList > li > a {
    padding-left: 25px;
  }
  .subContent .attachedList > li > a[href$=pdf]:after, .subContent .attachedList > li > a[href$=xls]:after, .subContent .attachedList > li > a[href$=xlsx]:after, .subContent .attachedList > li > a[href$=doc]:after, .subContent .attachedList > li > a[href$=docx]:after, .subContent .attachedList > li > a[href$=ppt]:after, .subContent .attachedList > li > a[href$=pptx]:after, .subContent .attachedList > li > a[href$=txt]:after, .subContent .attachedList > li > a[href$=csv]:after, .subContent .attachedList > li > a[href$=zip]:after, .subContent .attachedList > li > a[href$=jpg]:after, .subContent .attachedList > li > a[href$=bmp]:after, .subContent .attachedList > li > a[href$=png]:after, .subContent .attachedList > li > a[href$=gif]:after, .subContent .attachedList > li > a[href$=tif]:after, .subContent .attachedList > li > a[href$=svg]:after, .subContent .attachedList > li > a[href$=mov]:after, .subContent .attachedList > li > a[href$=mp4]:after, .subContent .attachedList > li > a[href$=mp3]:after, .subContent .attachedList > li > a[href$=wmv]:after,
  .mfp-content .attachedList > li > a[href$=pdf]:after,
  .mfp-content .attachedList > li > a[href$=xls]:after,
  .mfp-content .attachedList > li > a[href$=xlsx]:after,
  .mfp-content .attachedList > li > a[href$=doc]:after,
  .mfp-content .attachedList > li > a[href$=docx]:after,
  .mfp-content .attachedList > li > a[href$=ppt]:after,
  .mfp-content .attachedList > li > a[href$=pptx]:after,
  .mfp-content .attachedList > li > a[href$=txt]:after,
  .mfp-content .attachedList > li > a[href$=csv]:after,
  .mfp-content .attachedList > li > a[href$=zip]:after,
  .mfp-content .attachedList > li > a[href$=jpg]:after,
  .mfp-content .attachedList > li > a[href$=bmp]:after,
  .mfp-content .attachedList > li > a[href$=png]:after,
  .mfp-content .attachedList > li > a[href$=gif]:after,
  .mfp-content .attachedList > li > a[href$=tif]:after,
  .mfp-content .attachedList > li > a[href$=svg]:after,
  .mfp-content .attachedList > li > a[href$=mov]:after,
  .mfp-content .attachedList > li > a[href$=mp4]:after,
  .mfp-content .attachedList > li > a[href$=mp3]:after,
  .mfp-content .attachedList > li > a[href$=wmv]:after {
    width: 18px;
    height: 18px;
    top: 0.2em;
    left: 0;
  }
  .subContent .colorGreen .scrollTable .sortTable,
  .mfp-content .colorGreen .scrollTable .sortTable {
    width: 1000px !important;
  }
  .subContent .colorGreen .scrollTable .sortTable tr td,
  .subContent .colorGreen .scrollTable .sortTable tr th,
  .mfp-content .colorGreen .scrollTable .sortTable tr td,
  .mfp-content .colorGreen .scrollTable .sortTable tr th {
    white-space: initial;
  }
  .subContent .colorGreen .btnWrap,
  .mfp-content .colorGreen .btnWrap {
    display: flex;
    flex-direction: column;
  }
  .subContent .colorGreen * + .partsSubText,
  .mfp-content .colorGreen * + .partsSubText {
    margin-top: 10px;
  }
  .subContent .colorGreen .partsSubText > a,
  .mfp-content .colorGreen .partsSubText > a {
    display: inline-block;
  }
  .subContent .colorGreen .partsSubText > a + a,
  .mfp-content .colorGreen .partsSubText > a + a {
    margin-left: 15px;
  }
  .subContent .colorGreen .searchMenu > ul,
  .mfp-content .colorGreen .searchMenu > ul {
    flex-direction: column;
    border-bottom: 0;
  }
  .subContent .colorGreen .searchMenu > ul > li,
  .mfp-content .colorGreen .searchMenu > ul > li {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 0;
  }
  .subContent .colorGreen .searchMenu > ul > li + li,
  .mfp-content .colorGreen .searchMenu > ul > li + li {
    margin-top: 8px;
  }
  .subContent .colorGreen .searchMenu > ul > li > *,
  .mfp-content .colorGreen .searchMenu > ul > li > * {
    width: 100%;
    min-height: 42px;
    line-height: 1.19;
  }
  .subContent .colorGreen .searchMenu > ul > li:not(.isActive):before,
  .mfp-content .colorGreen .searchMenu > ul > li:not(.isActive):before {
    border-width: 1px;
  }
  .subContent .colorGreen .searchMenu > ul > li:not(.isActive) > a,
  .mfp-content .colorGreen .searchMenu > ul > li:not(.isActive) > a {
    padding-right: 14px;
    text-align: center;
  }
  .subContent .colorGreen .searchMenu > ul > li.isActive:before,
  .mfp-content .colorGreen .searchMenu > ul > li.isActive:before {
    border-width: 2px;
  }
  .subContent .colorGreen .searchMenu > ul > li.isActive > a,
  .mfp-content .colorGreen .searchMenu > ul > li.isActive > a {
    padding-right: 14px;
    text-align: center;
  }
  .subContent .colorGreen .searchMenu > .searchMenuBtnArea,
  .mfp-content .colorGreen .searchMenu > .searchMenuBtnArea {
    margin-top: 12px;
  }
  .subContent .colorGreen .sortTable > tbody > tr > th:not([scope=col]).isAsc .sortBtn .sortSelect > li:nth-child(odd),
  .mfp-content .colorGreen .sortTable > tbody > tr > th:not([scope=col]).isAsc .sortBtn .sortSelect > li:nth-child(odd) {
    border-color: transparent transparent #088E98 transparent !important;
  }
  .subContent .colorGreen .sortTable > tbody > tr > th:not([scope=col]).isDesc .sortBtn .sortSelect > li:nth-child(even),
  .mfp-content .colorGreen .sortTable > tbody > tr > th:not([scope=col]).isDesc .sortBtn .sortSelect > li:nth-child(even) {
    border-color: #088E98 transparent transparent transparent !important;
  }
  .subContent .colorGreen * + .flexItem,
  .mfp-content .colorGreen * + .flexItem {
    margin-top: 43px;
  }
  .subContent .colorGreen .flexItem > * + .btnWrap,
  .mfp-content .colorGreen .flexItem > * + .btnWrap {
    margin-top: 15px;
  }
  .subContent .colorGreen .flexItem > .infoList,
  .mfp-content .colorGreen .flexItem > .infoList {
    flex-direction: column;
    align-items: flex-end;
  }
  .subContent .colorGreen .flexItem > .infoList > li + li,
  .mfp-content .colorGreen .flexItem > .infoList > li + li {
    margin-top: 8px;
  }
  .subContent .colorGreen * + .separateContent,
  .mfp-content .colorGreen * + .separateContent {
    margin-top: 15px;
  }
  .subContent .colorGreen .separateContent,
  .mfp-content .colorGreen .separateContent {
    padding-top: 15px;
  }
  .subContent .colorGreen .inputWrap,
  .mfp-content .colorGreen .inputWrap {
    width: 100%;
    flex-wrap: wrap;
  }
  .subContent .colorGreen .inputWrap .inputSearchBtnWrap,
  .mfp-content .colorGreen .inputWrap .inputSearchBtnWrap {
    width: 100%;
    margin-top: 4px;
  }
  .subContent .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchInput,
  .mfp-content .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchInput {
    width: calc(100% - 147px);
    padding: 7px 8px 7px 9px;
    border-radius: 4px;
  }
  .subContent .colorGreen .inputWrap .inputSearchBtnWrap > label,
  .mfp-content .colorGreen .inputWrap .inputSearchBtnWrap > label {
    width: 140px;
    padding: 8px 4px;
    margin-left: 7px;
    border-radius: 4px;
  }
  .subContent .colorGreen .inputWrap .inputSearchBtnWrap > label.btnLift span:before,
  .mfp-content .colorGreen .inputWrap .inputSearchBtnWrap > label.btnLift span:before {
    width: 14px;
    height: 14px;
  }
  .subContent .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchBtn + span,
  .mfp-content .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchBtn + span {
    font-size: 16px;
  }
  .subContent .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchBtn + span:before,
  .mfp-content .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchBtn + span:before {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }
  .subContent .colorGreen .commonDlCol > * + *,
  .mfp-content .colorGreen .commonDlCol > * + * {
    margin-top: 12px;
  }
  .subContent .colorGreen table.thSticky thead tr,
  .mfp-content .colorGreen table.thSticky thead tr {
    border-bottom: 1px solid #d2d2d2;
  }
  .subContent .colorGreen table.thSticky tbody tr:first-child td,
  .mfp-content .colorGreen table.thSticky tbody tr:first-child td {
    border-top: none !important;
  }
  .subContent * + .infoList,
  .mfp-content * + .infoList {
    margin-top: 11px;
  }
  .subContent .infoList > li + li,
  .mfp-content .infoList > li + li {
    margin-left: 9px;
  }
  .subContent .downBlock,
  .mfp-content .downBlock {
    padding-top: 11px;
    padding-bottom: 35px;
  }
  .subContent .downBlock .downBox,
  .mfp-content .downBlock .downBox {
    margin-top: 8px;
  }
  .subContent .downBlock .downBox2,
  .mfp-content .downBlock .downBox2 {
    display: none;
    margin-top: 8px;
  }
  .subContent .downBlock .downBox2 ul,
  .mfp-content .downBlock .downBox2 ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    border-top: 1px solid #707070;
    border-left: 1px solid #707070;
  }
  .subContent .downBlock .downBox2 ul li,
  .mfp-content .downBlock .downBox2 ul li {
    border-right: 1px solid #707070;
    border-bottom: 1px solid #707070;
  }
  .subContent .downBlock .downBox2 ul button,
  .mfp-content .downBlock .downBox2 ul button {
    width: 100%;
    height: 50px;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    background-color: #fff;
    position: relative;
  }
  .subContent * + .sortBlock,
  .mfp-content * + .sortBlock {
    margin-top: 11px;
  }
  .subContent .sortBlock .note,
  .mfp-content .sortBlock .note {
    background-color: #fff;
  }
  .subContent .sortBlock .btnWrap,
  .mfp-content .sortBlock .btnWrap {
    background-color: #fff;
    padding-top: 20px;
    margin: 0;
  }
  .subContent .sortBlock .sortBlockHeader,
  .mfp-content .sortBlock .sortBlockHeader {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 23px 15px 23px;
  }
  .subContent .sortBlock .sortBlockHeader .sortBlockHeaderLead,
  .mfp-content .sortBlock .sortBlockHeader .sortBlockHeaderLead {
    width: 100%;
  }
  .subContent .sortBlock .sortBlockHeader .sortBlockHeaderLead + *,
  .mfp-content .sortBlock .sortBlockHeader .sortBlockHeaderLead + * {
    margin-top: 20px;
  }
  .subContent .sortBlock .sortBlockHeader .sortBlockHeaderLead * + *,
  .mfp-content .sortBlock .sortBlockHeader .sortBlockHeaderLead * + * {
    margin-top: 11px;
  }
  .subContent .sortBlock .sortBlockFooter,
  .mfp-content .sortBlock .sortBlockFooter {
    padding: 20px 15px;
  }
  .subContent .sortBlock .sortBlockFooter .spPageNav .transition,
  .mfp-content .sortBlock .sortBlockFooter .spPageNav .transition {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .subContent .sortBlock .sortBlockFooter .spPageNav .transition a,
  .mfp-content .sortBlock .sortBlockFooter .spPageNav .transition a {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #7a7a7a;
    border: 1px solid #7a7a7a;
    border-radius: 4px;
    text-decoration: none;
    height: 50px;
  }
  .subContent .sortBlock .sortBlockFooter .spPageNav .transition a.null,
  .mfp-content .sortBlock .sortBlockFooter .spPageNav .transition a.null {
    pointer-events: none;
    opacity: 0.3;
  }
  .subContent .sortBlock .sortBlockFooter .spPageNav .pointing,
  .mfp-content .sortBlock .sortBlockFooter .spPageNav .pointing {
    display: flex;
    align-items: flex-start;
    gap: 2;
    margin-top: 15px;
  }
  .subContent .sortBlock .sortBlockFooter .spPageNav .pointing .mig,
  .mfp-content .sortBlock .sortBlockFooter .spPageNav .pointing .mig {
    width: 25%;
    background-color: #7a7a7a;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    height: 50px;
  }
  .subContent .sortBlock .sortBlockFooter .spPageNav .pointing .mig.pre,
  .mfp-content .sortBlock .sortBlockFooter .spPageNav .pointing .mig.pre {
    border-radius: 5px 0 0 5px;
    margin-right: 1px;
  }
  .subContent .sortBlock .sortBlockFooter .spPageNav .pointing .mig.nex,
  .mfp-content .sortBlock .sortBlockFooter .spPageNav .pointing .mig.nex {
    border-radius: 0 5px 5px 0;
    margin-left: 1px;
  }
  .subContent .sortBlock .sortBlockFooter .spPageNav .pointing .mig.null,
  .mfp-content .sortBlock .sortBlockFooter .spPageNav .pointing .mig.null {
    pointer-events: none;
    opacity: 0.3;
  }
  .subContent .sortBlock .sortBlockFooter .spPageNav .pointing .list,
  .mfp-content .sortBlock .sortBlockFooter .spPageNav .pointing .list {
    width: 50%;
    height: 50px;
    background: #7a7a7a;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .subContent .sortBlock .sortBlockFooter .spPageNav .pointing .list:after,
  .mfp-content .sortBlock .sortBlockFooter .spPageNav .pointing .list:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 10px solid #fff;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    position: absolute;
    top: calc(50% - 5px);
    right: 10px;
  }
  .subContent .sortBlock .sortBlockFooter .spPageNav .pointing .list ul,
  .mfp-content .sortBlock .sortBlockFooter .spPageNav .pointing .list ul {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    z-index: 20;
    transform: scaleY(0);
    transition: all 0.3s;
    transform-origin: left top;
    background-color: #fff;
  }
  .subContent .sortBlock .sortBlockFooter .spPageNav .pointing .list ul li,
  .mfp-content .sortBlock .sortBlockFooter .spPageNav .pointing .list ul li {
    width: 100%;
  }
  .subContent .sortBlock .sortBlockFooter .spPageNav .pointing .list ul li a,
  .mfp-content .sortBlock .sortBlockFooter .spPageNav .pointing .list ul li a {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    background-color: #7a7a7a;
    margin-top: 1px;
  }
  .subContent .sortBlock .sortBlockFooter .spPageNav .pointing .list.isOpen:after,
  .mfp-content .sortBlock .sortBlockFooter .spPageNav .pointing .list.isOpen:after {
    transform: rotate(180deg);
  }
  .subContent .sortBlock .sortBlockFooter .spPageNav .pointing .list.isOpen ul,
  .mfp-content .sortBlock .sortBlockFooter .spPageNav .pointing .list.isOpen ul {
    transform: scaleY(1);
  }
  .subContent * + .paging,
  .mfp-content * + .paging {
    margin-top: 20px;
  }
  .subContent .paging .prev,
  .subContent .paging .next,
  .mfp-content .paging .prev,
  .mfp-content .paging .next {
    display: none;
  }
  .subContent .paging .prev:before,
  .mfp-content .paging .prev:before {
    border-width: 5px 10px 5px 0;
  }
  .subContent .paging .next:before,
  .mfp-content .paging .next:before {
    border-width: 5px 0 5px 10px;
  }
  .subContent .paging .pageCount,
  .mfp-content .paging .pageCount {
    justify-content: center;
    width: 100%;
  }
  .subContent .paging .pageCount > li,
  .mfp-content .paging .pageCount > li {
    width: 40px;
    height: 40px;
    line-height: 38px;
  }
  .subContent .paging .pageCount > li + li,
  .mfp-content .paging .pageCount > li + li {
    margin-left: 10px;
  }
  .subContent .sortBlock .scrolltext,
  .mfp-content .sortBlock .scrolltext {
    padding: 0 20px;
  }
  .subContent * + .formBlock,
  .mfp-content * + .formBlock {
    margin-top: 8px;
  }
  .subContent .formBlock,
  .mfp-content .formBlock {
    padding: 13px 15px 27px;
  }
  .subContent .formBlock .formList,
  .mfp-content .formBlock .formList {
    padding-left: 0;
    padding-right: 0;
  }
  .subContent .colorGreen * + .titleBeforeWrap,
  .mfp-content .colorGreen * + .titleBeforeWrap {
    margin-top: 30px;
  }
  .subContent .colorGreen .titleBeforeWrap,
  .mfp-content .colorGreen .titleBeforeWrap {
    padding-left: 20px;
  }
  .subContent .colorGreen .titleBeforeWrap:before,
  .mfp-content .colorGreen .titleBeforeWrap:before {
    width: 6px;
    top: 0;
    left: 0;
  }
  .subContent .colorGreen .titleBeforeWrap .titleSub,
  .mfp-content .colorGreen .titleBeforeWrap .titleSub {
    padding: 4px 13px;
    font-size: 14px;
  }
  .subContent .colorGreen .titleBeforeWrap * + .titleBefore,
  .mfp-content .colorGreen .titleBeforeWrap * + .titleBefore {
    margin-top: 7px;
  }
  .subContent .colorGreen .titleBeforeWrap .titleBefore,
  .mfp-content .colorGreen .titleBeforeWrap .titleBefore {
    font-size: 20px;
  }
  .subContent .colorGreen * + .titleBold,
  .mfp-content .colorGreen * + .titleBold {
    margin-top: 27px;
  }
  .subContent .colorGreen .titleBold,
  .mfp-content .colorGreen .titleBold {
    font-size: 18px;
  }
  .subContent .colorGreen * + .commonDl,
  .mfp-content .colorGreen * + .commonDl {
    margin-top: 30px;
  }
  .subContent .colorGreen .btnWrap.btnR + .commonDl,
  .mfp-content .colorGreen .btnWrap.btnR + .commonDl {
    margin-top: 12px;
  }
  .subContent .colorGreen .commonDl + .commonDl,
  .mfp-content .colorGreen .commonDl + .commonDl {
    margin-top: 15px;
  }
  .subContent .colorGreen .commonDl + .sortBlock,
  .mfp-content .colorGreen .commonDl + .sortBlock {
    margin-top: 20px;
  }
  .subContent .colorGreen .commonDl > div > dt,
  .mfp-content .colorGreen .commonDl > div > dt {
    padding: 8px 15px;
    font-size: 14px;
  }
  .subContent .colorGreen .commonDl > div > dd,
  .mfp-content .colorGreen .commonDl > div > dd {
    padding: 15px 15px;
  }
  .subContent .colorGreen .commonDl > div.blockRequired > dt,
  .mfp-content .colorGreen .commonDl > div.blockRequired > dt {
    padding-left: 60px;
  }
  .subContent .colorGreen .commonDl > div.blockRequired > dt:before,
  .mfp-content .colorGreen .commonDl > div.blockRequired > dt:before {
    height: 17px;
    left: 15px;
  }
  .subContent .colorGreen .iconPlus,
  .mfp-content .colorGreen .iconPlus {
    width: 18px;
    height: 18px;
  }
  .subContent .colorGreen .iconClip,
  .mfp-content .colorGreen .iconClip {
    width: 18px;
    height: 18px;
  }
  .subContent .colorGreen .iconTrash,
  .mfp-content .colorGreen .iconTrash {
    width: 16px;
    height: 16px;
  }
  .subContent .colorGreen .iconReload,
  .mfp-content .colorGreen .iconReload {
    width: 16px;
    height: 16px;
  }
}
@media screen and (max-width: 599px) and (min-width: 600px) and (max-width: 1230px) {
  .subContent .colorGreen .formList > div.formListDt2.variable > dt + dd,
  .mfp-content .colorGreen .formList > div.formListDt2.variable > dt + dd {
    margin-top: 10px;
  }
}
@media screen and (max-width: 599px) and (min-width: 600px) and (max-width: 1230px) {
  .subContent .colorGreen .formList > div.formListDt2.variable > dd,
  .mfp-content .colorGreen .formList > div.formListDt2.variable > dd {
    width: calc(100% - 180px);
    margin-right: 0;
  }
}
@media screen and (max-width: 599px) {
  .subContent .formList > div,
  .mfp-content .formList > div {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .subContent .formList > div > dt span,
  .mfp-content .formList > div > dt span {
    font-size: 12px;
  }
  .subContent .formList > div > dd,
  .mfp-content .formList > div > dd {
    margin-top: 8px;
  }
  .subContent .formList > div.formListDt2 dd + dt,
  .mfp-content .formList > div.formListDt2 dd + dt {
    margin-top: 14px;
  }
  .subContent .input,
  .mfp-content .input {
    padding: 7px 6px;
    border-radius: 4px;
  }
  .subContent .input.inputS,
  .mfp-content .input.inputS {
    width: 100px;
  }
  .subContent .rowNote,
  .mfp-content .rowNote {
    margin-left: 5px;
  }
  .subContent .inputSearch:before,
  .mfp-content .inputSearch:before {
    width: 17px;
    height: 17px;
    background-size: 17px auto;
    left: 12px;
  }
  .subContent .inputSearch .input,
  .mfp-content .inputSearch .input {
    padding-left: 35px;
  }
  .subContent .formRow,
  .mfp-content .formRow {
    margin-top: -8px;
  }
  .subContent .formRow > li,
  .mfp-content .formRow > li {
    margin-right: 8px;
    margin-top: 8px;
  }
  .subContent .checkboxLabel span,
  .mfp-content .checkboxLabel span {
    padding: 6px 14px 6px 41px;
    border-radius: 4px;
  }
  .subContent .checkboxLabel span:before,
  .mfp-content .checkboxLabel span:before {
    width: 17px;
    height: 17px;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 12px;
  }
  .subContent .checkboxLabel span:after,
  .mfp-content .checkboxLabel span:after {
    left: 16px;
  }
  .subContent .radioLabel span,
  .mfp-content .radioLabel span {
    padding: 6px 14px 6px 41px;
    border-radius: 4px;
  }
  .subContent .radioLabel span:before,
  .mfp-content .radioLabel span:before {
    width: 17px;
    height: 17px;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 14px;
  }
  .subContent .radioLabel span:after,
  .mfp-content .radioLabel span:after {
    width: 15px;
    height: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 15px;
  }
  .subContent .selectWrap,
  .mfp-content .selectWrap {
    width: 100%;
  }
  .subContent .selectWrap.colorGreen select,
  .mfp-content .selectWrap.colorGreen select {
    font-size: 14px;
  }
  .subContent .selectWrap:before,
  .mfp-content .selectWrap:before {
    border-width: 7px 6.5px 0 6.5px;
    right: 12px;
  }
  .subContent .selectWrap .select,
  .mfp-content .selectWrap .select {
    padding: 7px 30px 7px 14px;
    border-radius: 4px;
  }
  .subContent ::placeholder,
  .mfp-content ::placeholder {
    color: #B8B8B8;
  }
  .subContent select,
  .subContent input,
  .subContent textarea,
  .mfp-content select,
  .mfp-content input,
  .mfp-content textarea {
    font-size: 16px;
  }
  .subContent select.error,
  .subContent input.error,
  .subContent textarea.error,
  .mfp-content select.error,
  .mfp-content input.error,
  .mfp-content textarea.error {
    background: #FFF0F0 !important;
  }
  @keyframes onAutoFillStart {}
  .subContent .modalContent,
  .mfp-content .modalContent {
    max-width: 1220px;
    padding: 20px 15px;
    margin: 0 auto;
  }
  .subContent .modalTitle,
  .mfp-content .modalTitle {
    margin-bottom: 28px;
    font-size: 20px;
  }
  .subContent .modalTitle > span,
  .mfp-content .modalTitle > span {
    display: block;
    padding-bottom: 8px;
  }
  .subContent .modalInner,
  .mfp-content .modalInner {
    padding-right: 10px;
    max-height: 323px;
  }
  .subContent .modalInner::-webkit-scrollbar,
  .mfp-content .modalInner::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 30px;
    background: #E6E6E6;
    cursor: pointer;
  }
  .subContent .modalInner::-webkit-scrollbar-thumb,
  .mfp-content .modalInner::-webkit-scrollbar-thumb {
    width: 5px;
    height: 5px;
    border-radius: 30px;
    background: #1560BB;
    cursor: pointer;
  }
  .subContent * + .errorBox,
  .mfp-content * + .errorBox {
    margin-top: 30px;
  }
  .subContent .errorBox,
  .mfp-content .errorBox {
    padding: 10px 20px;
  }
  .subContent .errorBox + .commonDl,
  .mfp-content .errorBox + .commonDl {
    margin-top: 20px;
  }
  .subContent .errorBox.errorBoxStrong,
  .mfp-content .errorBox.errorBoxStrong {
    padding: 20px 20px;
  }
  .subContent * + .contentNarrow,
  .mfp-content * + .contentNarrow {
    margin-top: 25px;
  }
  .subContent .radioList > li + li,
  .mfp-content .radioList > li + li {
    margin-top: 16px;
  }
  .subContent .radioList > li .radiListContent > * + *,
  .mfp-content .radioList > li .radiListContent > * + * {
    margin-top: 4px;
  }
  .subContent .radioList > li .radioLabelBefore > input[type=radio] + span,
  .mfp-content .radioList > li .radioLabelBefore > input[type=radio] + span {
    padding-left: 30px;
  }
  .subContent .radioList > li .radioLabelBefore > input[type=radio] + span:before,
  .mfp-content .radioList > li .radioLabelBefore > input[type=radio] + span:before {
    width: 20px;
    height: 20px;
    top: 3px;
    left: 0;
  }
  .subContent .radioList > li .radioLabelBefore > input[type=radio] + span:after,
  .mfp-content .radioList > li .radioLabelBefore > input[type=radio] + span:after {
    width: 14px;
    height: 14px;
    top: 6px;
    left: 3px;
  }
  .subContent .selectWrap.disabled:before,
  .mfp-content .selectWrap.disabled:before {
    border-color: #B8B8B8 transparent transparent transparent;
  }
  .subContent .selectWrap.error:before,
  .mfp-content .selectWrap.error:before {
    border-color: #A72525 transparent transparent transparent !important;
  }
  .subContent .selectWrap.error .select,
  .mfp-content .selectWrap.error .select {
    background: #FFF0F0 !important;
  }
  .subContent .selectList > li + li,
  .mfp-content .selectList > li + li {
    margin-top: 20px;
  }
  .subContent .selectList > li .selectListTitle + *,
  .mfp-content .selectList > li .selectListTitle + * {
    margin-top: 5px;
  }
  .subContent .titleBeforeWrap + .compBox,
  .mfp-content .titleBeforeWrap + .compBox {
    margin-top: 20px;
  }
  .subContent .compBox,
  .mfp-content .compBox {
    padding: 50px 15px;
  }
  .subContent .btnDrag,
  .mfp-content .btnDrag {
    width: 20px;
    height: 15px;
  }
  .mfp-content {
    padding: 20px 7px;
  }
  .splitButton {
    display: flex;
    gap: 5px;
    margin-top: 20px;
  }
  .splitButton .btnWrap {
    margin: 0;
    flex: 1;
  }
  .adjustContents p.colorRed:first-of-type {
    margin-top: 20px;
  }
  input.spNoPlaceholder::placeholder {
    color: transparent;
  }
  span.placeholder {
    display: block;
    font-size: 0.8em;
  }
  #areaWrapper1 {
    overflow: inherit;
    width: 90%;
    margin: 30px auto 0;
  }
}
@media screen and (max-width: 599px) and (min-width: 600px) and (max-width: 1000px) {
  #areaWrapper1 {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 599px) {
  .flexBtn {
    margin-top: 30px;
  }
}
@media screen and (max-width: 599px) and (min-width: 600px) and (max-width: 1000px) {
  .flexBtn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 50px;
  }
}
@media screen and (max-width: 599px) {
  .flexBtn a {
    display: block;
    padding: 0.5em 1em;
    background-color: #bef1e6;
    color: #088E98;
    text-decoration: none;
    text-align: center;
  }
  .flexBtn a + a {
    margin-top: 1em;
  }
}
@media screen and (max-width: 599px) and (min-width: 600px) and (max-width: 1000px) {
  .flexBtn a {
    display: block;
    padding: 0.5em 1em;
    background-color: #bef1e6;
    color: #088E98;
    text-decoration: none;
    margin: 1em 0.5em;
  }
}
@media screen and (max-width: 599px) {
  #mainArea #backBtn {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    margin-top: 40px;
  }
}
@media screen and (max-width: 599px) and (min-width: 600px) and (max-width: 1000px) {
  #mainArea #backBtn {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    margin-top: 40px;
  }
}
@media screen and (max-width: 599px) {
  #mainArea #backBtn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
    position: relative;
  }
}
@media screen and (max-width: 599px) and (min-width: 600px) and (max-width: 1000px) {
  #mainArea #backBtn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
    position: relative;
  }
}
@media screen and (max-width: 599px) {
  #mainArea #backBtn a:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #ccc;
    border-left: 2px solid #ccc;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 15px;
  }
}
@media screen and (max-width: 599px) and (min-width: 600px) and (max-width: 1000px) {
  #mainArea #backBtn a:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #ccc;
    border-left: 2px solid #ccc;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 15px;
  }
}
@media screen and (max-width: 599px) {
  .modal .modalInner.subContent .modalBody .compBox .title + p {
    margin-top: 0;
  }
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .rotate-90 {
    transform: rotate(-90deg);
  }
  .section.subContent {
    margin-bottom: 100px;
  }
  .breadcrumb nav {
    padding: 10px 0 20px 0;
    overflow: hidden;
    background: #fff;
    position: relative;
    top: 0;
    height: 100%;
    z-index: 1;
    font-size: 14px;
  }
  .breadcrumb nav ol {
    float: left;
  }
  .breadcrumb nav ol li {
    list-style: none;
    float: left;
    padding-right: 20px;
    position: relative;
  }
  .breadcrumb nav ol li a {
    color: #000;
    font-size: 14px;
  }
  .breadcrumb nav ol li a:hover {
    text-decoration: none;
    color: #088E98;
  }
  .breadcrumb nav ol li .active {
    color: #088E98;
  }
  .breadcrumb nav ol li .breadcrumb-home-a::after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    transform: rotate(45deg) translateY(-50%);
    position: absolute;
    top: 50%;
    right: 10px;
    border-top: 1px solid #CFCFCF;
    border-right: 1px solid #CFCFCF;
  }
  .breadcrumb h1 {
    position: relative;
    padding-left: 22px;
    line-height: 44px;
  }
  .breadcrumb h1:after {
    content: "";
    display: block;
    width: 0;
    height: 44px;
    position: absolute;
    top: 0;
    left: 0;
    border-right: 6px solid #088E98;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 1;
    padding-left: 0.6em;
    position: relative;
  }
  h2:before {
    content: "";
    display: block;
    width: 6px;
    height: 1.2em;
    background-color: #088e98;
    position: absolute;
    left: 0;
    top: calc(50% - 0.6em);
  }
  h3 {
    font-size: 16px;
    font-weight: 700;
    border-bottom: 2px solid #088e98;
    padding-bottom: 10px;
  }
  .menteMenu {
    display: flex;
    flex-wrap: wrap;
    background-color: #eff9f7;
    margin-top: 40px;
    padding: 40px 10.65%;
    width: 100%;
  }
  .menteMenu a {
    display: block;
    display: flex;
    width: calc(25% - 21px);
    flex-direction: column;
    align-items: center;
    color: #000;
    text-decoration: none;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px #cbebe4;
    padding: 32px 0;
    position: relative;
    max-width: 230px;
  }
  .menteMenu a:not(:nth-child(4n)) {
    margin-right: 27px;
  }
  .menteMenu a:nth-child(n+5) {
    margin-top: 2.8%;
  }
  .menteMenu a:hover .icon {
    opacity: 0.8;
  }
  .menteMenu a:hover .icon svg, .menteMenu a:hover .icon img {
    transform: scale(1.5);
  }
  .menteMenu a .blank {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .menteMenu a .blank svg {
    width: 16px;
    height: 16px;
    fill: #088e98;
  }
  .menteMenu a .icon {
    aspect-ratio: 1/1;
    width: 45%;
    border-radius: 50%;
    background-color: #088e98;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
  }
  .menteMenu a .icon svg, .menteMenu a .icon img {
    fill: #fff;
    transition: all 0.3s;
  }
  .menteMenu a .icon svg {
    aspect-ratio: 1/1;
    width: 30%;
  }
  .menteMenu a .icon img {
    width: 40%;
  }
  .menteMenu a .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    width: 90%;
    height: 42px;
  }
  .menteMenu a .title .name {
    flex: 1;
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
  }
  .menteMenu a .title .name span {
    font-size: 13px;
    display: block;
  }
  .menteMenu a .title svg {
    width: 16px;
    height: 16px;
    fill: #088e98;
    margin-left: 5px;
    transform: rotate(180deg);
  }
  #bengoshiTab {
    margin-top: 20px;
  }
  #bengoshiTab ul {
    list-style: none;
    display: flex;
    gap: 10px;
    border-bottom: 2px solid #088e98;
    background-color: #fff;
  }
  #bengoshiTab ul li a {
    height: 100%;
    position: relative;
    background-color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    border-top: 1px solid #737373;
    border-right: 1px solid #737373;
    border-left: 1px solid #737373;
    padding: 10px;
  }
  #bengoshiTab ul li a .icon {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: #b8b8b8;
  }
  #bengoshiTab ul li a .icon svg {
    width: 15px;
    height: 15px;
    fill: #fff;
  }
  #bengoshiTab ul li a .icon img {
    width: 20px;
    height: 20px;
  }
  #bengoshiTab ul li a .title {
    font-size: 18px;
    font-weight: 500;
    color: #737373;
    text-align: center;
    margin: 0 15px;
  }
  #bengoshiTab ul li a .arrow {
    width: 20px;
    height: 20px;
  }
  #bengoshiTab ul li a .arrow svg {
    width: 12px;
    height: 12px;
    fill: #737373;
    transform: rotate(-90deg);
  }
  #bengoshiTab ul li a.active {
    border-color: #088e98;
    border-width: 2px;
    border-bottom: 3px solid #fff;
  }
  #bengoshiTab ul li a.active:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    position: absolute;
    bottom: -5px;
    left: 0;
  }
  #bengoshiTab ul li a.active, #bengoshiTab ul li a:hover {
    z-index: 5;
  }
  #bengoshiTab ul li a.active .icon, #bengoshiTab ul li a:hover .icon {
    background-color: #088e98;
  }
  #bengoshiTab ul li a.active .title, #bengoshiTab ul li a:hover .title {
    color: #088e98;
  }
  .ctrlPanel {
    width: 100%;
    padding: 60px 0;
  }
  #ctrlBtns {
    background-color: #eff9f7;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 60px;
    gap: 20px;
    margin-top: 20px;
  }
  #ctrlBtns a {
    text-decoration: none;
  }
  #ctrlBtns .item {
    cursor: pointer;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px #cbebe4;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex: 1;
    min-height: 88px;
    transition: all 0.3s;
  }
  #ctrlBtns .item:hover {
    background-color: rgba(8, 142, 152, 0.1);
  }
  #ctrlBtns .item .icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: calc(50% - 15px);
    left: 20px;
  }
  #ctrlBtns .item .icon svg {
    width: 100%;
    height: 100%;
    fill: #088e98;
  }
  #ctrlBtns .item .title {
    font-size: 20px;
    color: #088e98;
  }
  #ctrlBtns .item.disabled {
    background-color: #e6e6e6;
    pointer-events: none;
    box-shadow: none;
  }
  #ctrlBtns .item.disabled .icon svg {
    fill: #737373;
  }
  #ctrlBtns .item.disabled .title {
    color: #737373;
  }
  .ctrlMess {
    background-color: #eff9f7;
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
  }
  .ctrlMess .title {
    font-size: 18px;
    font-weight: 700;
  }
  .ctrlMess .memo {
    font-size: 16px;
    margin-top: 20px;
  }
  .ctrlMess#errer {
    background-color: #fff0f0;
  }
  .ctrlMess#errer .title,
  .ctrlMess#errer .memo {
    color: #a72525;
  }
  .loading {
    width: 480px;
    height: 26px;
    border-radius: 13px;
    background-color: #d1eaec;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
  }
  .loading .bar {
    width: 50%;
    background: radial-gradient(circle, rgb(0, 185, 198) 0%, rgb(8, 142, 152) 100%);
    background-size: 200% 100%;
    background-position: -100% 0;
    height: 26px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
    animation: barAni 3s infinite linear;
  }
  @keyframes barAni {
    50% {
      background-position: 0 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
  .button {
    width: 220px;
    height: 48px;
    border: 1px solid #707070;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    color: #707070;
    font-size: 16px;
    margin-top: 20px;
    transition: all 0.3s;
    border-radius: 5px;
  }
  .button .icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: calc(50% - 10px);
    left: 15px;
    margin: 0;
    display: block;
  }
  .button .icon svg {
    width: 16px;
    height: 16px;
    fill: #707070;
  }
  .button:hover {
    background-color: rgba(8, 142, 152, 0.2);
  }
  .backBtn {
    margin-top: 60px;
  }
  .backBtn .button {
    width: 280px;
    text-decoration: none;
    color: #707070;
    margin: 0 auto;
  }
  #log {
    margin-top: 30px;
  }
  #log .logTable {
    list-style: none;
    margin-top: 20px;
  }
  #log .logTable li {
    border-bottom: 1px dotted #b8b8b8;
    display: flex;
    position: relative;
    font-size: 16px;
    padding-left: 1em;
    padding-bottom: 10px;
  }
  #log .logTable li + li {
    margin-top: 10px;
  }
  #log .logTable li.error {
    color: #a72525;
  }
  #log .logTable li:before {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #088e98;
    position: absolute;
    top: 10px;
    left: 2px;
  }
  #log .logTable li .date {
    width: 340px;
  }
  #log .logTable li .matter {
    flex: 1;
  }
  ul.formRow {
    list-style: none;
  }
  ul.formRow li {
    display: flex;
    gap: 15px;
  }
  ul.formRow li + li {
    margin-top: 20px;
  }
  ul.formRow .kokaiLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 42px;
    border-radius: 5px;
    border: 1px solid #b8b8b8;
    background-color: #fff;
    position: relative;
    cursor: pointer;
  }
  ul.formRow .kokaiLabel:before {
    content: "";
    display: block;
    width: 17px;
    height: 17px;
    background-color: #e0e0e0;
    position: absolute;
    left: 10px;
    top: calc(50% - 8px);
    border-radius: 50%;
  }
  ul.formRow .kokaiLabel.checked {
    background-color: #eefdfe;
    border: 2px solid #088e98;
    color: #000 grn;
  }
  ul.formRow .kokaiLabel.checked:before {
    width: 10px;
    height: 15px;
    border-right: 3px solid #088e98;
    border-bottom: 3px solid #088e98;
    transform: rotate(45deg);
    border-radius: 0;
    background-color: transparent;
    top: 6px;
  }
  .inputCal {
    display: flex;
    width: 183px;
    height: 42px;
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    overflow: hidden;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
  }
  .inputCal.disabled {
    background-color: #e6e6e6;
    cursor: default;
  }
  .inputCal.disabled input {
    background-color: #e6e6e6;
    cursor: default;
  }
  .inputCal.error {
    background-color: #fff0f0;
  }
  .inputCal.error input {
    background-color: transparent;
  }
  .inputCal svg {
    width: 20px;
    height: 20px;
    fill: #737373;
  }
  .inputCal input {
    width: 140px;
    padding: 0 10px;
    cursor: pointer;
  }
  .timeSelect {
    position: relative;
    z-index: 5;
  }
  .timeSelect .timeBtn {
    display: flex;
    width: 150px;
    height: 42px;
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    overflow: hidden;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
  }
  .timeSelect .timeBtn.disabled {
    background-color: #e6e6e6;
    cursor: default;
  }
  .timeSelect .timeBtn.disabled input {
    background-color: #e6e6e6;
    cursor: default;
  }
  .timeSelect .timeBtn svg {
    width: 20px;
    height: 20px;
    fill: #737373;
  }
  .timeSelect .timeBtn input {
    width: 100px;
    padding: 0 10px;
    cursor: pointer;
  }
  .timeSelectArea {
    display: none;
  }
  .timeSelectArea .timeSelectInner {
    border: 1px solid #d2d2d2;
    background-color: #fff;
    padding: 20px;
    display: flex;
    gap: 20px;
    position: absolute;
    top: 20px;
    left: 0;
  }
  .timeSelectArea .timeSelectInner .title {
    text-align: center;
    border-bottom: 1px solid #d2d2d2;
  }
  .timeSelectArea #hour .H {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    background-color: #d2d2d2;
    gap: 1px;
    padding: 1px;
    margin-top: 10px;
  }
  .timeSelectArea #hour .H + .H {
    margin-top: 20px;
  }
  .timeSelectArea #hour .H span {
    grid-row: 1/span 2;
    grid-column: 1;
    line-height: 1;
    width: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F7F6F4;
  }
  .timeSelectArea #hour .H label {
    background-color: #fff;
    line-height: 1;
    width: 2em;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .timeSelectArea #hour .H label.checked {
    background-color: #bef1e6;
  }
  .timeSelectArea #min .M {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    background-color: #d2d2d2;
    gap: 1px;
    padding: 1px;
    margin-top: 10px;
  }
  .timeSelectArea #min .M label {
    background-color: #fff;
    line-height: 1;
    width: 2em;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .timeSelectArea #min .M label.checked {
    background-color: #bef1e6;
    cursor: pointer;
  }
  .underBtns {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .underBtns button {
    width: 220px;
    height: 48px;
    border-radius: 5px;
    color: #737373;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .underBtns button.back {
    position: relative;
  }
  .underBtns button.back svg {
    width: 20px;
    height: 20px;
    fill: #737373;
    position: absolute;
    top: calc(50% - 10px);
    left: 15px;
  }
  .underBtns button.back.white {
    min-width: 220px;
    width: auto;
    padding: 0 15px 0 40px;
    background-color: #fff;
    border: 1px solid #737373;
  }
  .underBtns button.regist {
    background-color: #e6e6e6;
  }
  .underBtns button.regist.noErrer {
    background-color: #088e98;
    color: #fff;
  }
  .completionArea {
    background-color: #f7f6f4;
    width: 100%;
    padding: 40px;
    text-align: center;
    margin-top: 60px;
  }
  .completionArea .title {
    font-size: 22px;
    font-weight: 700;
  }
  .completionArea p {
    margin-top: 40px;
  }
  .colorGreen .searchMenu + .registErrer {
    margin-top: 30px;
  }
  .colorGreen .compBox + .registErrer {
    margin-top: 20px;
  }
  .colorGreen .registErrer {
    border: none;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .colorGreen .registErrer .title {
    margin-bottom: 20px;
  }
  .colorGreen .errorText {
    margin-right: auto;
    margin-left: auto;
    color: #A72525;
    background: #fff;
    width: calc(100% - 40px);
    padding: 20px 30px;
  }
  .colorGreen * + .errorList {
    margin-top: 12px;
  }
  .registErrer {
    border: 2px solid #a72525;
    background-color: #fff0f0;
    margin-top: 60px;
    padding-top: 40px;
  }
  .registErrer .title {
    font-size: 22px;
    font-weight: 700;
    color: #a72525;
    display: flex;
    justify-content: center;
    align-items: center;
    background: inherit !important;
  }
  .registErrer .title svg {
    width: 22px;
    height: 22px;
    fill: #a72525;
    margin-right: 10px;
  }
  .registErrer .errerInfo {
    background-color: #fff;
    display: block;
    margin: 40px;
    padding: 30px;
    color: #a72525;
  }
  .ui-state-highlight,
  .ui-widget-content .ui-state-highlight,
  .ui-widget-header .ui-state-highlight {
    background-color: #bef1e6 !important;
  }
  .modal {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
  }
  .modal .modalInner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal .modalInner.subContent .modalBody {
    position: relative;
    padding-bottom: 148px !important;
  }
  .modal .modalInner.subContent .modalBody .modBodyIn {
    overflow-y: auto;
    max-height: calc(100vh - 355px);
  }
  .modal .modalInner.subContent .modalBody .modBodyIn * + .modBodyInScrollPC {
    margin-top: 30px;
  }
  .modal .modalInner.subContent .modalBody .modalBtns {
    flex-direction: column;
    margin-top: 0;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    bottom: 20px;
  }
  .modal .modalInner.subContent .modalBody .modalBtns .btn + * {
    margin-top: 0;
  }
  .modal .modalInner.subContent .modalBody .modTable {
    border-top: none !important;
  }
  .modal .modalInner.subContent * + .inputWrap {
    margin-top: 20px;
  }
  .modal .modalInner.subContent .inputWrap .inputSearchBtnWrap .inputSearchInput {
    width: 100%;
  }
  .modal .modalInner.subContent .inputWrap + .errorMsg {
    margin-top: 6px;
  }
  .modal .modalInner .modalBody {
    background-color: #fff;
  }
  .modal .modalInner .modalBody.alert {
    width: 580px;
    padding: 30px;
  }
  .modal .modalInner .modalBody.alert .icon {
    width: 40px;
    margin: 0 auto;
  }
  .modal .modalInner .modalBody.alert .icon svg {
    width: 40px;
    height: 40px;
    fill: #d2d2d2;
  }
  .modal .modalInner .modalBody.alert .title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }
  .modal .modalInner .modalBody.alert p {
    font-size: 16px;
    text-align: center;
  }
  .modal .modalInner .modalBody.alert .loading + p {
    margin-top: 20px;
  }
  .modal .modalInner .modalBody.operation {
    max-width: 1220px;
    width: 95%;
    padding-bottom: 80px;
  }
  .modal .modalInner .modalBody.operation .title {
    background-color: #bef1e6;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    padding: 10px;
  }
  .modal .modalInner .modalBody.operation .modBodyIn {
    padding: 30px 20px 0;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable {
    border: 1px solid #d2d2d2;
    width: 100%;
    margin-top: 30px;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable:not(.thSticky) th {
    width: 40%;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable th {
    text-align: left;
    background-color: #FBFAF9;
    padding: 10px;
    font-size: 14px;
    border-top: 1px solid #d2d2d2;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable th[scope=row] {
    width: inherit;
    text-align: center;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable td {
    padding: 10px;
    border-top: 1px solid #d2d2d2;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable td .errorMess {
    display: block;
    font-size: 14px;
    text-align: left;
    color: #a72525;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable td .errorMess + * {
    margin-top: 8px;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable .iconRequired {
    display: inline-block;
    padding: 2px 5px;
    margin-right: 11px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    font-style: normal;
    vertical-align: baseline;
    font-weight: 400;
    line-height: 1;
    background: #A72525;
    box-sizing: border-box;
  }
  .modal .modalInner .modalBody.operation .modBodyIn p.error {
    background-color: #fff0f0;
    display: block;
    padding: 5px;
    color: #a72525;
  }
  .modal .modalInner .modalBody.operation .greenArrow {
    margin-top: 30px;
    width: 100%;
  }
  .modal .modalInner .modalBody.operation .greenArrow:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 25px solid #088e98;
    border-right: 35px solid transparent;
    border-left: 35px solid transparent;
    margin: 0 auto;
  }
  .modal .modalInner .modalBody .compBox {
    background-color: #f7f6f4;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .modal .modalInner .modalBody .compBox .title {
    background-color: transparent;
  }
  .modal .modalInner .modalBody .compBox .title a {
    color: #707070;
    border: 1px solid #707070;
    width: 200px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    margin: 30px auto 0;
    background-color: #fff;
  }
  .modal .modalInner .modalBody .compBox .title a:hover {
    text-decoration: none;
  }
  .modal .modalInner .modalBody .compBox p {
    text-align: center;
  }
  .modal .modalInner .modalBody .compBox .custom_hover {
    width: 200px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    color: #707070;
    border: 1px solid #707070;
    text-decoration: none;
    background-color: #fff;
    margin: 20px auto 0;
  }
  .modal .modalInner .modalBody .compBox.errorBox {
    background-color: #fff0f0;
    color: #a72525;
  }
  .modal .modalInner .modalBody .compBox table.modTable td {
    background: #fff;
  }
  .modal .modalInner .modalBody .modalBtns {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .modal .modalInner .modalBody .modalBtns .btn {
    width: 200px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    cursor: pointer;
  }
  .modal .modalInner .modalBody .modalBtns .btn.cancel {
    color: #707070;
    border: 1px solid #707070;
  }
  .modal .modalInner .modalBody .modalBtns .btn.goOn {
    background-color: #088e98;
    color: #fff;
  }
  .modal .modalInner .modalBody .modalBtns .btn .btnIcon {
    width: 11px;
    height: 11px;
    position: absolute;
    top: calc(50% - 6px);
    left: 15px;
    fill: #707070;
  }
  .modal .modalInner .searchMenu {
    margin-top: 0;
  }
  .modal .modalInner .searchMenu + .compBox {
    margin-top: 30px;
  }
  .modal .modalInner #upFileWrap {
    display: block;
    padding: 15px 20px;
    background: #F7F6F4;
  }
  .modal .modalInner #upFileWrap #inputFile + .errorMsg {
    margin-top: 10px;
  }
  .modal .modalInner #upFileWrap #inputFile + .btnWrap {
    margin-top: 16px;
  }
  .modal .modalInner #upFileWrap * + .btnWrap {
    margin-top: 30px;
  }
  #targetfile_name {
    position: relative;
    margin-top: 1em;
  }
  .formList {
    background-color: #F7F6F4;
    margin-top: 30px;
  }
  .formList .flex {
    display: flex;
    background-color: #fff;
    margin-top: 20px;
    border: 1px solid #d2d2d2;
    padding: 15px;
  }
  .formList .flex + .flex {
    margin-top: -1px;
  }
  .formList.teiki {
    background-color: #e6effd;
  }
  .formList.teiki #listFileWrap ul li.listHead {
    background: #a8cfff;
  }
  .formList.teiki #listFileWrap p span.kubun {
    color: #fff;
    background: #1560bb;
    padding: 2px 10px 4px;
    margin-right: 10px;
  }
  .formList.rinji {
    background-color: #fff2e3;
  }
  .formList.rinji #listFileWrap ul li.listHead {
    background: #ffd09a;
  }
  .formList.rinji #listFileWrap p span.kubun {
    color: #fff;
    background: #e48314;
    padding: 2px 10px 4px;
    margin-right: 10px;
  }
  .formList dt {
    position: relative;
  }
  .formList dt .clearBtn {
    width: 170px;
    height: 42px;
    border: 1px solid #b8b8b8;
    background-color: #fff;
    position: absolute;
    top: calc(50% - 21px);
    right: 0;
    font-size: 14px;
    color: #707070;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  .formList dt .clearBtn svg {
    width: 11px;
    height: 11px;
    fill: #707070;
  }
  .formList dt .clearBtn:hover {
    color: #000;
  }
  .formList dt .clearBtn:hover svg {
    fill: #000;
  }
  * + #upFileWrap {
    margin-top: 20px;
  }
  #inputFile {
    position: relative;
    padding: 170px 0 70px;
    text-align: center;
    height: 310px;
    cursor: pointer;
  }
  #dropArea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 90px 0 0;
    border: 2px dashed #b8b8b8;
    color: #b8b8b8;
    font-size: 1.2em;
    background: #fff;
  }
  #dropArea.dragover {
    border: 5px dashed #088e93;
  }
  #uploadFile {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: block;
    width: 220px;
    height: 48px;
    opacity: 0;
    cursor: pointer;
  }
  #inputFileWrap {
    position: relative;
    display: inline-block;
    border: 2px solid #088e98;
    color: #088e98;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  #selectFile {
    border-color: #088e93;
    color: #088e93;
    cursor: pointer;
  }
  #selectFile span {
    cursor: pointer;
  }
  #listFileWrap {
    position: relative;
    font-size: 0.8em;
  }
  #listFileWrap p {
    margin: 15px 0;
  }
  #listFileWrap ul {
    list-style: none;
    border: 1px solid #e3e3e3;
    background: #fff;
  }
  #listFileWrap ul li {
    border-bottom: 1px solid #e3e3e3;
  }
  #listFileWrap ul li span {
    padding: 10px;
    display: inline-block;
  }
  #listFileWrap ul li span.csvname {
    width: 285px;
  }
  .btn.btnGreen {
    color: #fff;
    border-color: #088e93;
    background: #088e93;
  }
  .btn.btnGreen:disabled {
    color: #818181;
    border-color: #e6e6e6;
    background: #e6e6e6;
  }
  .subContent .formList {
    padding: 30px;
  }
  .subContent .formList > div > dt {
    width: 220px;
  }
  .errorMsg {
    color: #a72525;
    font-weight: bold;
  }
  .seisei_date {
    margin-right: 20px;
  }
  .base_date {
    margin-right: 20px;
  }
  .errorRed {
    color: #a72525;
  }
  #overlay {
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #content {
    z-index: 6;
    width: 540px;
    padding: 30px;
    background: #fff;
    text-align: center;
  }
  #date-update {
    z-index: 6;
    width: 540px;
    padding: 30px;
    background: #fff;
    text-align: center;
  }
  #content .iconAttention {
    width: 40px;
    height: 40px;
    filter: grayscale(100%);
    background-color: rgba(255, 255, 255, 0.8);
    background-blend-mode: lighten;
  }
  .subContent .inputCal.inputSearch:before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22currentColor%22%20class%3D%22bi%20bi-calendar%22%20viewBox%3D%220%200%2016%2016%22%3E%20%3Cpath%20d%3D%22M3.5%200a.5.5%200%200%201%20.5.5V1h8V.5a.5.5%200%200%201%201%200V1h1a2%202%200%200%201%202%202v11a2%202%200%200%201-2%202H2a2%202%200%200%201-2-2V3a2%202%200%200%201%202-2h1V.5a.5.5%200%200%201%20.5-.5zM1%204v10a1%201%200%200%200%201%201h12a1%201%200%200%200%201-1V4H1z%22%2F%3E%3C%2Fsvg%3E") !important;
    background-color: rgba(255, 255, 255, 0.8);
    background-blend-mode: lighten;
  }
  #date-update .title {
    font-size: 1.2em;
    font-weight: bold;
  }
  #date-update .caption {
    font-size: 0.8em;
  }
  #date-update .step-progress-wrap {
    margin: 30px;
    background-color: #cde8e9;
    border-radius: 100px;
  }
  #date-update .step-progress {
    display: flex;
    width: 100%;
    height: 15px;
    background-color: #088e93;
    transition: 0.5s;
    border-radius: 100px;
  }
  .vshow-enter-active,
  .vshow-leave-active {
    transition: opacity 0.3s;
  }
  .vshow-enter,
  vshow-leave-to {
    opacity: 0;
  }
  .vld-overlay {
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-out;
  }
  [v-cloak] {
    visibility: hidden;
  }
  .logTable.gradient {
    -webkit-mask-image: linear-gradient(#fff, rgba(255, 255, 255, 0));
  }
  .logTable.maxHeight {
    max-height: 400px;
  }
  .d-none {
    display: none;
  }
  #btnShow {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #btnShow .button {
    color: #438E98;
    border: 1px solid #438E98;
  }
  #btnShow .arrow-down {
    transform: rotate(-90deg);
  }
  .custom-error {
    text-decoration-line: none !important;
  }
  div.message-error {
    position: relative;
  }
  div.clearBtn {
    width: 170px;
    height: 42px;
    border: 1px solid #b8b8b8;
    background-color: #fff;
    position: absolute;
    top: calc(50% - 21px);
    right: 0;
    font-size: 14px;
    color: #707070;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  div.clearBtn svg {
    width: 11px;
    height: 11px;
    fill: #707070;
  }
  div.clearBtn:hover {
    color: #000;
  }
  div.clearBtn:hover svg {
    fill: #000;
  }
  div#errorMsgZip .errorMsg {
    margin-left: 0px;
  }
  .color_a72525 {
    color: #a72525;
  }
  .file-item {
    display: flex;
    align-items: center;
  }
  img.imgIconFile {
    padding-top: 2px;
  }
  #listFileWrap .listHead {
    display: grid;
    grid-template-columns: 20% 30% 8% 10% 32%;
  }
  #listFileWrap .file-item {
    display: grid;
    grid-template-columns: 20% 30% 8% 10% 32%;
  }
  .errorList > li:before {
    content: "・";
    color: #A72525;
  }
  * + .newsBox {
    margin-top: 25px;
  }
  .newsBox {
    padding: 20px;
  }
  .newsBox > dt {
    font-size: 20px;
  }
  .newsBox > dd {
    margin-top: 8px;
  }
  .newsBox + * {
    margin-top: 73px;
  }
  * + .inputArea {
    margin-top: 20px;
  }
  .inputArea {
    display: block;
    padding: 15px 20px;
  }
  .inputArea > *:nth-of-type(1).colorBlockTitle {
    margin-top: 20px;
  }
  .inputArea * + .commonDlTitle {
    margin-top: 28px;
  }
  .inputArea .commonDl + .commonDlTitle {
    margin-top: 45px;
  }
  .inputArea .commonDlTitle + .commonDl {
    margin-top: 12px;
  }
  .progBar {
    width: 90px;
    height: 17px;
  }
  .colorGreen .linkGreen i {
    margin-left: 8px;
  }
  * + .listBoxContent {
    margin-top: 10px;
  }
  .formlistBoxTitle + .formlistBox {
    margin-top: 12px;
  }
  .formlistBox .listBoxContent > li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 11px 15px;
  }
  .formlistBox .listBoxContent > li > .listBoxFixed {
    margin-top: 8px;
    flex: none;
    margin-left: auto;
  }
  .formWrap .btnWrap {
    margin-top: 0;
    margin-left: 20px;
  }
  .formWrap .btn {
    margin-top: 0;
  }
  .formWrap > * + p {
    margin-top: 0;
  }
  .formTitle {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .formTitle .formTitleSub {
    padding: 6px 5px;
    font-size: 12px;
  }
  .formTitle strong {
    margin-top: 3px;
    font-size: 18px;
  }
  * + .fileSelectRow {
    margin-top: 10px;
  }
  .fileSelectRow {
    padding: 17px 20px;
    flex-direction: column;
  }
  .fileSelectRow .fileSectLead {
    font-size: 18px;
  }
  .fileSelectRow * + .btnWrap {
    margin-top: 8px;
  }
  * + .fileSelect {
    margin-top: 33px;
  }
  .fileSelect {
    padding: 30px 20px;
  }
  .fileSelect .fileSectLead {
    font-size: 18px;
  }
  .fileSelect * + .btnWrap {
    margin-top: 24px;
  }
  .inputSearchBtnIn .inputWrap .inputSearchBtnWrap .inputSearchInput {
    padding-right: 60px !important;
  }
  .inputSearchBtnIn .inputWrap .inputSearchBtnWrap .inputSearchInput + label {
    width: 50px;
    height: 42px;
    border-radius: 0 4px 4px 0;
  }
  .inputSearchBtnIn .inputWrap .inputSearchBtnWrap .inputSearchInput + label .inputSearchBtn + span:before {
    width: 20px;
    height: 20px;
    margin-right: 0;
  }
  .quasiTable .quasiTableBody .quasiTableTr > * {
    min-height: 60px;
  }
  .quasiTable .quasiTableTr > * {
    display: flex;
    align-items: center;
    padding: 15px 20px;
  }
  .quasiTable .quasiTableTr .quasiTableLabel {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .quasiTable .quasiTableTr .quasiTableInfo .textInfo {
    font-size: 14px;
  }
  .quasiTable a.quasiTableTr:after {
    width: 10px;
    height: 10px;
    right: 18px;
  }
  .modalInner .quasiTable .quasiTableBody .quasiTableTr > * {
    min-height: 60px;
  }
  .modalInner .quasiTable .quasiTableTr > * {
    display: flex;
    align-items: center;
    padding: 12px 20px;
  }
  * + .titleSupplement {
    margin-top: 28px;
  }
  .titleSupplement + * {
    margin-top: 16px;
  }
  * + .textAreaWrap {
    margin-top: 12px;
  }
  .textAreaWrap {
    padding: 25px 20px 17px;
  }
  .textAreaWrap textarea {
    padding: 10px;
  }
  .textAreaWrap textarea.inputM {
    min-height: 150px !important;
  }
  .textAreaWrap textarea + p {
    margin-top: 6px;
  }
  .textAreaWrap .textAreaResult {
    padding: 29px 28px;
  }
  #log .btnMore {
    padding-right: 33px;
  }
  #log .btnMore:not(:has(.iconSearch)):before {
    width: 8px;
    height: 8px;
    border-right: 1px solid #1560BB;
    border-bottom: 1px solid #1560BB;
    right: 16px;
  }
  #log .btnMore:after {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 11px;
    border-radius: 4px 0 0 4px;
    width: calc(100% - 33px);
    line-height: 34px;
  }
  #log .btnMore.btnGreen:not(:has(.iconSearch)):before {
    border-width: 7px 6.5px 0 6.5px;
  }
  body.bgLogin .subContent:not(.loginBox) {
    padding: 40px 0;
    margin: 0 15px;
    margin-bottom: 0;
  }
  body.bgLogin .subContent.loginBox {
    padding: 40px 0;
    margin: 40px 15px 0;
    margin-bottom: 0;
  }
  body.bgLogin .subContent.loginBox .loginBoxHeader {
    margin-bottom: 20px;
  }
  body.bgLogin .subContent.loginBox .loginBoxHeader .lHInner #site_logo .site_logo_text {
    margin-top: 8px;
  }
  .loginBoxContent + .loginBoxContent {
    padding-top: 28px;
    margin-top: 37px;
  }
  .loginBoxContent .loginBoxInner {
    max-width: 270px;
    margin-inline: auto;
  }
  .loginBoxContent .loginBoxInner * + * {
    margin-top: 6px;
  }
  .loginBoxContent .loginBoxInner .btnWrap {
    margin-top: 15px;
  }
  .loginBoxContent .loginBoxInner .btnWrap .btn + .btn {
    margin-top: 10px;
  }
  .loginBoxContent .input {
    padding: 11px 14px;
  }
  .loginHeader .lHInner {
    display: flex;
    align-items: center;
    width: 95%;
    min-height: calc(47px + 5vw);
    padding: 2.5vw 0;
    margin-inline: auto;
  }
  .loginHeader .lHInner #site_logo a {
    width: 43vw;
    max-width: 277px;
  }
  .loginFooter p.copyError {
    padding-top: 0;
    padding-bottom: 0;
  }
  .loginFooter p.copyError + * {
    margin-top: 7px;
  }
  .loginFooter.small {
    margin-top: 0;
    padding: 0 0 0 100px;
  }
  .loginFooter.small .copyError {
    margin-top: 10px;
    text-align: left;
  }
  .loginFooter.small .copyError + .copy {
    padding-top: 0;
  }
  .loginFooter.small .copy {
    text-align: left;
  }
  .loginFooter.small .lFImage {
    width: 100px;
    padding: 0;
    top: -20px;
    left: 0;
    background: transparent;
    padding: 0;
  }
  .loginFooter.small .lFImage img {
    width: 100%;
  }
  .subContent .titleCenter {
    font-size: 20px;
  }
  .subContent .titleCenter + * {
    margin-top: 40px;
  }
  .subContent * + .stepNumWrap {
    margin-top: 40px;
  }
  .subContent .stepNumWrap + * {
    margin-top: 9px;
  }
  .subContent .stepNumWrap > * + * {
    margin-top: 0;
  }
  .subContent .stepNumWrap .stepNum {
    padding: 4px 12px;
    font-size: 13px;
  }
  .subContent .stepNumWrap .stepNumTitle {
    margin-left: 6px;
    font-size: 16px;
  }
  .subContent .infoContentNote:before {
    width: 19px;
    height: 19px;
    margin-top: 3px;
  }
}
@media print, screen and (min-width: 600px) {
  /* Magnific Popup CSS */
  .mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8;
  }
  .mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
  }
  .mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box;
  }
  .mfp-container:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .mfp-align-top .mfp-container:before {
    display: none;
  }
  .mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
  }
  .mfp-inline-holder .mfp-content,
  .mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
  }
  .mfp-ajax-cur {
    cursor: progress;
  }
  .mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
  }
  .mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
  }
  .mfp-auto-cursor .mfp-content {
    cursor: auto;
  }
  .mfp-close,
  .mfp-arrow,
  .mfp-preloader,
  .mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  .mfp-loading.mfp-figure {
    display: none;
  }
  .mfp-hide {
    display: none !important;
  }
  .mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
  }
  .mfp-preloader a {
    color: #CCC;
  }
  .mfp-preloader a:hover {
    color: #FFF;
  }
  .mfp-s-ready .mfp-preloader {
    display: none;
  }
  .mfp-s-error .mfp-content {
    display: none;
  }
  button.mfp-close,
  button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation;
  }
  button::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  .mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
  }
  .mfp-close:hover,
  .mfp-close:focus {
    opacity: 1;
  }
  .mfp-close-btn-in .mfp-close {
    color: #333;
  }
  .mfp-image-holder .mfp-close,
  .mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
  }
  .mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
  }
  .mfp-arrow {
    position: absolute;
    opacity: 0.65;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent;
  }
  .mfp-arrow:active {
    margin-top: -54px;
  }
  .mfp-arrow:hover,
  .mfp-arrow:focus {
    opacity: 1;
  }
  .mfp-arrow:before,
  .mfp-arrow:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
  }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
  }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7;
  }
  .mfp-arrow-left {
    left: 0;
  }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px;
  }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F;
  }
  .mfp-arrow-right {
    right: 0;
  }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px;
  }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F;
  }
  .mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
  }
  .mfp-iframe-holder .mfp-close {
    top: -40px;
  }
  .mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
  }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
  }
  /* Main image in popup */
  img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
  }
  /* The shadow behind the image */
  .mfp-figure {
    line-height: 0;
  }
  .mfp-figure:after {
    content: "";
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444;
  }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px;
  }
  .mfp-figure figure {
    margin: 0;
  }
  .mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
  }
  .mfp-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px;
  }
  .mfp-image-holder .mfp-content {
    max-width: 100%;
  }
  .mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
  }
  /* css button clear file maintenance */
}
@media screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape), screen and (min-width: 600px) and (max-height: 300px) {
  /**
  * Remove all paddings around the image on small screen
  */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media print and (max-width: 900px), screen and (min-width: 600px) and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media print, screen and (min-width: 600px) {
  .breadcrumb nav {
    padding: 20px 0;
    overflow: hidden;
    background: #fff;
    position: relative;
    top: 0;
    height: 100%;
    z-index: 1;
  }
  .breadcrumb nav ol {
    float: left;
  }
  .breadcrumb nav ol li {
    list-style: none;
    float: left;
    padding-right: 20px;
    position: relative;
  }
  .breadcrumb nav ol li a {
    color: #000;
    font-size: 14px;
  }
  .breadcrumb nav ol li a:hover {
    text-decoration: none;
    color: #088E98;
  }
  .breadcrumb nav ol li .active {
    color: #088E98;
  }
  .breadcrumb nav ol li .breadcrumb-home-a::after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    transform: rotate(45deg) translateY(-50%);
    position: absolute;
    top: 50%;
    right: 10px;
    border-top: 1px solid #CFCFCF;
    border-right: 1px solid #CFCFCF;
  }
  .breadcrumb h1 {
    position: relative;
    padding-left: 22px;
    line-height: 44px;
  }
  .breadcrumb h1:after {
    content: "";
    display: block;
    width: 0;
    height: 44px;
    position: absolute;
    top: 0;
    left: 0;
    border-right: 6px solid #088E98;
  }
  .breadcrumb nav ol li span {
    font-size: 14px;
  }
  .section {
    padding-bottom: 112px;
  }
  .subContent .btnIcon,
  .mfp-content .btnIcon {
    width: 11px;
    height: 11px;
    position: absolute;
    top: calc(50% - 6px);
    left: 15px;
    fill: #707070;
  }
  .subContent a:hover,
  .mfp-content a:hover {
    text-decoration: none;
  }
  .subContent a.linkArrow,
  .mfp-content a.linkArrow {
    padding-right: 20px;
  }
  .subContent a.linkArrow:after,
  .mfp-content a.linkArrow:after {
    width: 8px;
    height: 8px;
    border-right: 2px solid #1560BB;
    border-bottom: 2px solid #1560BB;
    top: 0.55em;
  }
  .subContent h1,
  .subContent h2,
  .subContent h3,
  .subContent h4,
  .subContent h5,
  .mfp-content h1,
  .mfp-content h2,
  .mfp-content h3,
  .mfp-content h4,
  .mfp-content h5 {
    line-height: 1.5;
  }
  .subContent .tel,
  .mfp-content .tel {
    color: inherit !important;
    text-decoration: none !important;
    pointer-events: none;
  }
  .subContent .mB0_pc,
  .mfp-content .mB0_pc {
    margin-bottom: 0 !important;
  }
  .subContent .mB5_pc,
  .mfp-content .mB5_pc {
    margin-bottom: 5px !important;
  }
  .subContent .mB10_pc,
  .mfp-content .mB10_pc {
    margin-bottom: 10px !important;
  }
  .subContent .mB20_pc,
  .mfp-content .mB20_pc {
    margin-bottom: 20px !important;
  }
  .subContent .mB30_pc,
  .mfp-content .mB30_pc {
    margin-bottom: 30px !important;
  }
  .subContent .mB40_pc,
  .mfp-content .mB40_pc {
    margin-bottom: 40px !important;
  }
  .subContent .mB50_pc,
  .mfp-content .mB50_pc {
    margin-bottom: 50px !important;
  }
  .subContent .mT0_pc,
  .mfp-content .mT0_pc {
    margin-top: 0 !important;
  }
  .subContent .mT5_pc,
  .mfp-content .mT5_pc {
    margin-top: 5px !important;
  }
  .subContent .mT10_pc,
  .mfp-content .mT10_pc {
    margin-top: 10px !important;
  }
  .subContent .mT20_pc,
  .mfp-content .mT20_pc {
    margin-top: 20px !important;
  }
  .subContent .mT30_pc,
  .mfp-content .mT30_pc {
    margin-top: 30px !important;
  }
  .subContent .mT40_pc,
  .mfp-content .mT40_pc {
    margin-top: 40px !important;
  }
  .subContent .mT50_pc,
  .mfp-content .mT50_pc {
    margin-top: 50px !important;
  }
  .subContent .mL0_pc,
  .mfp-content .mL0_pc {
    margin-left: 0px !important;
  }
  .subContent .mL5_pc,
  .mfp-content .mL5_pc {
    margin-left: 5px !important;
  }
  .subContent .mL10_pc,
  .mfp-content .mL10_pc {
    margin-left: 10px !important;
  }
  .subContent .mL20_pc,
  .mfp-content .mL20_pc {
    margin-left: 20px !important;
  }
  .subContent .mL30_pc,
  .mfp-content .mL30_pc {
    margin-left: 30px !important;
  }
  .subContent .mL40_pc,
  .mfp-content .mL40_pc {
    margin-left: 40px !important;
  }
  .subContent .mL50_pc,
  .mfp-content .mL50_pc {
    margin-left: 50px !important;
  }
  .subContent .mR0_pc,
  .mfp-content .mR0_pc {
    margin-right: 0px !important;
  }
  .subContent .mR5_pc,
  .mfp-content .mR5_pc {
    margin-right: 5px !important;
  }
  .subContent .mR10_pc,
  .mfp-content .mR10_pc {
    margin-right: 10px !important;
  }
  .subContent .mR20_pc,
  .mfp-content .mR20_pc {
    margin-right: 20px !important;
  }
  .subContent .mR30_pc,
  .mfp-content .mR30_pc {
    margin-right: 30px !important;
  }
  .subContent .mR40_pc,
  .mfp-content .mR40_pc {
    margin-right: 40px !important;
  }
  .subContent .mR50_pc,
  .mfp-content .mR50_pc {
    margin-right: 50px !important;
  }
  .subContent .size12,
  .mfp-content .size12 {
    font-size: 12px !important;
  }
  .subContent .size14,
  .mfp-content .size14 {
    font-size: 14px !important;
  }
  .subContent .size16,
  .mfp-content .size16 {
    font-size: 16px !important;
  }
  .subContent .size18,
  .mfp-content .size18 {
    font-size: 18px !important;
  }
  .subContent .size20,
  .mfp-content .size20 {
    font-size: 20px !important;
  }
  .subContent * + ul.commonList,
  .mfp-content * + ul.commonList {
    margin-top: 40px;
  }
  .subContent ul.commonList > li,
  .mfp-content ul.commonList > li {
    padding-left: 25px;
  }
  .subContent ul.commonList > li:not(:first-of-type),
  .mfp-content ul.commonList > li:not(:first-of-type) {
    margin-top: 25px;
  }
  .subContent ul.commonList > li:before,
  .mfp-content ul.commonList > li:before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: auto;
    content: "";
    border-radius: 50%;
    background-color: #1560BB;
    position: absolute;
    left: 0;
    top: 0.55em;
  }
  .subContent ol.commonList > li,
  .mfp-content ol.commonList > li {
    padding-left: 25px;
  }
  .subContent ol.commonList > li:not(:first-of-type),
  .mfp-content ol.commonList > li:not(:first-of-type) {
    margin-top: 25px;
  }
  .subContent ol.commonList > li + li,
  .mfp-content ol.commonList > li + li {
    margin-top: 7px;
  }
  .subContent table .downBlock,
  .mfp-content table .downBlock {
    padding-top: 10px;
  }
  .subContent table * + p,
  .mfp-content table * + p {
    margin-top: 6px;
  }
  .subContent table + table,
  .mfp-content table + table {
    margin-top: 30px;
  }
  .subContent .scrolltext,
  .mfp-content .scrolltext {
    display: none !important;
  }
  .subContent p + .commonTable,
  .mfp-content p + .commonTable {
    margin-top: 14px;
  }
  .subContent .commonTable + .note,
  .mfp-content .commonTable + .note {
    margin-top: 10px;
  }
  .subContent .commonTable > thead > tr > th,
  .subContent .commonTable > thead > tr > td,
  .subContent .commonTable > tbody > tr > th,
  .subContent .commonTable > tbody > tr > td,
  .subContent .commonTable > tr > th,
  .subContent .commonTable > tr > td,
  .mfp-content .commonTable > thead > tr > th,
  .mfp-content .commonTable > thead > tr > td,
  .mfp-content .commonTable > tbody > tr > th,
  .mfp-content .commonTable > tbody > tr > td,
  .mfp-content .commonTable > tr > th,
  .mfp-content .commonTable > tr > td {
    padding: 13px 10px;
    border-top: 1px solid #D2D2D2;
    border-bottom: 1px solid #D2D2D2;
  }
  .subContent .commonTable > thead > tr.col2 > td,
  .subContent .commonTable > tbody > tr.col2 > td,
  .subContent .commonTable > tr.col2 > td,
  .mfp-content .commonTable > thead > tr.col2 > td,
  .mfp-content .commonTable > tbody > tr.col2 > td,
  .mfp-content .commonTable > tr.col2 > td {
    width: 38%;
  }
  .subContent .commonTable > thead > tr > th,
  .subContent .commonTable > tbody > tr > th,
  .subContent .commonTable > tr > th,
  .mfp-content .commonTable > thead > tr > th,
  .mfp-content .commonTable > tbody > tr > th,
  .mfp-content .commonTable > tr > th {
    width: 120px !important;
    font-size: 14px;
  }
  .subContent .sortTable > thead > tr > th,
  .subContent .sortTable > thead > tr > td,
  .subContent .sortTable > tbody > tr > th,
  .subContent .sortTable > tbody > tr > td,
  .subContent .sortTable > tr > th,
  .subContent .sortTable > tr > td,
  .mfp-content .sortTable > thead > tr > th,
  .mfp-content .sortTable > thead > tr > td,
  .mfp-content .sortTable > tbody > tr > th,
  .mfp-content .sortTable > tbody > tr > td,
  .mfp-content .sortTable > tr > th,
  .mfp-content .sortTable > tr > td {
    padding: 13px 10px;
    border-top: 1px solid #D2D2D2;
    border-bottom: 1px solid #D2D2D2;
  }
  .subContent .sortTable.tdSmall > thead > tr > td,
  .subContent .sortTable.tdSmall > tbody > tr > td,
  .subContent .sortTable.tdSmall > tr > td,
  .mfp-content .sortTable.tdSmall > thead > tr > td,
  .mfp-content .sortTable.tdSmall > tbody > tr > td,
  .mfp-content .sortTable.tdSmall > tr > td {
    font-size: 14px;
  }
  .subContent .sortTable > thead > tr > th,
  .subContent .sortTable > tbody > tr > th,
  .subContent .sortTable > tr > th,
  .mfp-content .sortTable > thead > tr > th,
  .mfp-content .sortTable > tbody > tr > th,
  .mfp-content .sortTable > tr > th {
    font-size: 14px;
  }
  .subContent .sortTable > thead > tr > th:not([scope=col]),
  .subContent .sortTable > tbody > tr > th:not([scope=col]),
  .subContent .sortTable > tr > th:not([scope=col]),
  .mfp-content .sortTable > thead > tr > th:not([scope=col]),
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]),
  .mfp-content .sortTable > tr > th:not([scope=col]) {
    padding: 0;
  }
  .subContent .sortTable > thead > tr > th:not([scope=col]):not(.sortNone),
  .subContent .sortTable > tbody > tr > th:not([scope=col]):not(.sortNone),
  .subContent .sortTable > tr > th:not([scope=col]):not(.sortNone),
  .mfp-content .sortTable > thead > tr > th:not([scope=col]):not(.sortNone),
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]):not(.sortNone),
  .mfp-content .sortTable > tr > th:not([scope=col]):not(.sortNone) {
    cursor: pointer;
  }
  .subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn,
  .subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtnNone,
  .subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn,
  .subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtnNone,
  .subContent .sortTable > tr > th:not([scope=col]) .sortBtn,
  .subContent .sortTable > tr > th:not([scope=col]) .sortBtnNone,
  .mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn,
  .mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtnNone,
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn,
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtnNone,
  .mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn,
  .mfp-content .sortTable > tr > th:not([scope=col]) .sortBtnNone {
    padding: 20px 10px;
  }
  .subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn:not(.isActive) p,
  .subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn:not(.isActive) p,
  .subContent .sortTable > tr > th:not([scope=col]) .sortBtn:not(.isActive) p,
  .mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn:not(.isActive) p,
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn:not(.isActive) p,
  .mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn:not(.isActive) p {
    cursor: pointer;
    transition: 0.2s opacity;
  }
  .subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn:not(.isActive) p:hover,
  .subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn:not(.isActive) p:hover,
  .subContent .sortTable > tr > th:not([scope=col]) .sortBtn:not(.isActive) p:hover,
  .mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn:not(.isActive) p:hover,
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn:not(.isActive) p:hover,
  .mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn:not(.isActive) p:hover {
    opacity: 0.7;
  }
  .subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn.isActive .sortSelect > li,
  .subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn.isActive .sortSelect > li,
  .subContent .sortTable > tr > th:not([scope=col]) .sortBtn.isActive .sortSelect > li,
  .mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn.isActive .sortSelect > li,
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn.isActive .sortSelect > li,
  .mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn.isActive .sortSelect > li {
    cursor: pointer;
    transition: 0.2s opacity;
  }
  .subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn.isActive .sortSelect > li:hover,
  .subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn.isActive .sortSelect > li:hover,
  .subContent .sortTable > tr > th:not([scope=col]) .sortBtn.isActive .sortSelect > li:hover,
  .mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn.isActive .sortSelect > li:hover,
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn.isActive .sortSelect > li:hover,
  .mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn.isActive .sortSelect > li:hover {
    opacity: 0.7;
  }
  .subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect,
  .subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect,
  .subContent .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect,
  .mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect,
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect,
  .mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect {
    width: 13px;
    min-height: 23px;
    margin-left: 12px;
  }
  .subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd),
  .subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd),
  .subContent .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd),
  .mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd),
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd),
  .mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(odd) {
    border-width: 0 6.5px 10px 6.5px;
  }
  .subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even),
  .subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even),
  .subContent .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even),
  .mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even),
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even),
  .mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn .sortSelect > li:nth-child(even) {
    border-width: 10px 6.5px 0 6.5px;
  }
  .subContent .sortTable.copList,
  .mfp-content .sortTable.copList {
    border: 1px solid #D2D2D2;
  }
  .subContent .sortTable.copList tr.sortSelectWrap th,
  .mfp-content .sortTable.copList tr.sortSelectWrap th {
    width: 20%;
  }
  .subContent .sortTable.copList tr.sortSelectWrap th:nth-of-type(1),
  .mfp-content .sortTable.copList tr.sortSelectWrap th:nth-of-type(1) {
    width: 10%;
  }
  .subContent .sortTable.copList tr.sortSelectWrap th:nth-of-type(2),
  .mfp-content .sortTable.copList tr.sortSelectWrap th:nth-of-type(2) {
    width: 50%;
  }
  .subContent .sortTable.copList tr.subTr td,
  .mfp-content .sortTable.copList tr.subTr td {
    background-color: #F9F9F7;
    border-top: none;
    border-bottom: none;
  }
  .subContent .sortTable.copList tr.subTr td.flexCell,
  .mfp-content .sortTable.copList tr.subTr td.flexCell {
    display: flex;
    align-items: center;
  }
  .subContent .sortTable.copList tr.subTr td span.tag,
  .mfp-content .sortTable.copList tr.subTr td span.tag {
    font-size: 14px;
    background-color: #D2D2D2;
    border-radius: 20px;
    color: #000;
    padding: 3px 5px;
    margin-right: 10px;
    white-space: nowrap;
  }
  .subContent .sortTable.copList tr.subTr + .subTr td,
  .mfp-content .sortTable.copList tr.subTr + .subTr td {
    border-top: 1px solid #D2D2D2;
  }
  .subContent .sortTable.copList td,
  .mfp-content .sortTable.copList td {
    background-color: #fff;
  }
  .subContent .sortTable.copList td.nowrap,
  .mfp-content .sortTable.copList td.nowrap {
    white-space: nowrap;
  }
  .subContent .sortTable.copList td.tac,
  .mfp-content .sortTable.copList td.tac {
    text-align: center;
  }
  .subContent .sortTable.copList td.num,
  .mfp-content .sortTable.copList td.num {
    vertical-align: top;
  }
  .subContent table .width3Percent,
  .mfp-content table .width3Percent {
    width: 3% !important;
  }
  .subContent table .width4Percent,
  .mfp-content table .width4Percent {
    width: 4% !important;
  }
  .subContent table .width5Percent,
  .mfp-content table .width5Percent {
    width: 5% !important;
  }
  .subContent table .width6Percent,
  .mfp-content table .width6Percent {
    width: 6% !important;
  }
  .subContent table .width7Percent,
  .mfp-content table .width7Percent {
    width: 7% !important;
  }
  .subContent table .width8Percent,
  .mfp-content table .width8Percent {
    width: 8% !important;
  }
  .subContent table .width9Percent,
  .mfp-content table .width9Percent {
    width: 9% !important;
  }
  .subContent table .width10Percent,
  .mfp-content table .width10Percent {
    width: 10% !important;
  }
  .subContent table .width11Percent,
  .mfp-content table .width11Percent {
    width: 11% !important;
  }
  .subContent table .width12Percent,
  .mfp-content table .width12Percent {
    width: 12% !important;
  }
  .subContent table .width13Percent,
  .mfp-content table .width13Percent {
    width: 13% !important;
  }
  .subContent table .width14Percent,
  .mfp-content table .width14Percent {
    width: 14% !important;
  }
  .subContent table .width16Percent,
  .mfp-content table .width16Percent {
    width: 16% !important;
  }
  .subContent table .width18Percent,
  .mfp-content table .width18Percent {
    width: 18% !important;
  }
  .subContent table .width20Percent,
  .mfp-content table .width20Percent {
    width: 20% !important;
  }
  .subContent table .width130,
  .mfp-content table .width130 {
    width: 130px !important;
    box-sizing: border-box;
  }
  .subContent .separateTable > thead > tr > th,
  .subContent .separateTable > thead > tr > td,
  .subContent .separateTable > tbody > tr > th,
  .subContent .separateTable > tbody > tr > td,
  .subContent .separateTable > tr > th,
  .subContent .separateTable > tr > td,
  .mfp-content .separateTable > thead > tr > th,
  .mfp-content .separateTable > thead > tr > td,
  .mfp-content .separateTable > tbody > tr > th,
  .mfp-content .separateTable > tbody > tr > td,
  .mfp-content .separateTable > tr > th,
  .mfp-content .separateTable > tr > td {
    padding: 13px 10px;
    text-align: center;
  }
  .subContent .separateTable > thead > tr > th,
  .subContent .separateTable > tbody > tr > th,
  .subContent .separateTable > tr > th,
  .mfp-content .separateTable > thead > tr > th,
  .mfp-content .separateTable > tbody > tr > th,
  .mfp-content .separateTable > tr > th {
    font-size: 14px;
  }
  .subContent * + .listBox,
  .mfp-content * + .listBox {
    margin-top: 12px;
  }
  .subContent .listBox > div:not(.col3),
  .mfp-content .listBox > div:not(.col3) {
    display: flex;
  }
  .subContent .listBox > div:not(.col3) > dt,
  .subContent .listBox > div:not(.col3) > dd,
  .mfp-content .listBox > div:not(.col3) > dt,
  .mfp-content .listBox > div:not(.col3) > dd {
    padding: 24px 0;
  }
  .subContent .listBox > div:not(.col3) > dt,
  .mfp-content .listBox > div:not(.col3) > dt {
    width: 90px;
    margin-left: 30px;
    font-weight: 700;
  }
  .subContent .listBox > div:not(.col3) > dd,
  .mfp-content .listBox > div:not(.col3) > dd {
    width: calc(100% - 150px);
  }
  .subContent .listBox > div.col3,
  .mfp-content .listBox > div.col3 {
    display: flex;
  }
  .subContent .listBox > div.col3 > dt,
  .subContent .listBox > div.col3 > dd,
  .mfp-content .listBox > div.col3 > dt,
  .mfp-content .listBox > div.col3 > dd {
    padding: 24px 0;
  }
  .subContent .listBox > div.col3 > *:last-child,
  .mfp-content .listBox > div.col3 > *:last-child {
    margin-right: 30px;
  }
  .subContent .listBox > div.col3 > dt,
  .mfp-content .listBox > div.col3 > dt {
    width: 90px;
    margin-left: 30px;
    flex: none;
    font-weight: 700;
  }
  .subContent .listBox > div.col3 > dd,
  .mfp-content .listBox > div.col3 > dd {
    width: calc(100% - 90px);
  }
  .subContent .listBox > .listBlue:not(.col3),
  .mfp-content .listBox > .listBlue:not(.col3) {
    margin-top: 24px;
  }
  .subContent .listBox > .listBlue:not(.col3) > dt,
  .subContent .listBox > .listBlue:not(.col3) > dd,
  .mfp-content .listBox > .listBlue:not(.col3) > dt,
  .mfp-content .listBox > .listBlue:not(.col3) > dd {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .subContent * + .note,
  .mfp-content * + .note {
    margin-top: 11px;
  }
  .subContent .note,
  .mfp-content .note {
    font-size: 13px;
  }
  .subContent * + .noteSub,
  .mfp-content * + .noteSub {
    margin-top: 0px;
  }
  .subContent .noteSub,
  .mfp-content .noteSub {
    font-size: 12px;
  }
  .subContent * + p,
  .mfp-content * + p {
    margin-top: 42px;
  }
  .subContent * + .titleCenter,
  .mfp-content * + .titleCenter {
    margin-top: 60px;
  }
  .subContent .titleCenter,
  .mfp-content .titleCenter {
    font-size: 25px;
  }
  .subContent * + .dataNote,
  .mfp-content * + .dataNote {
    margin-top: 4px;
  }
  .subContent .dataNote span,
  .mfp-content .dataNote span {
    font-size: 20px;
  }
  .subContent * + .colorBlock,
  .mfp-content * + .colorBlock {
    margin-top: 44px;
  }
  .subContent .colorBlock,
  .mfp-content .colorBlock {
    padding: 44px 20px 49px;
  }
  .subContent .colorBlock .colorBlockInner,
  .mfp-content .colorBlock .colorBlockInner {
    max-width: 930px;
    margin: 0 auto;
  }
  .subContent .colorBlockTitle,
  .mfp-content .colorBlockTitle {
    margin-bottom: 33px;
    font-size: 22px;
  }
  .subContent .colorBoxHeader,
  .mfp-content .colorBoxHeader {
    padding: 20px 30px;
  }
  .subContent .colorBoxBody,
  .mfp-content .colorBoxBody {
    padding: 14px 57px 54px;
  }
  .subContent .postContent,
  .mfp-content .postContent {
    padding: 40px;
  }
  .subContent .postContent * + p,
  .mfp-content .postContent * + p {
    margin-top: 27px;
  }
  .subContent .searchMenu,
  .mfp-content .searchMenu {
    margin-top: 36px;
  }
  .subContent * + .btnWrap,
  .mfp-content * + .btnWrap {
    margin-top: 43px;
  }
  .subContent .btnWrap,
  .mfp-content .btnWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .subContent .btnWrap.btnR,
  .mfp-content .btnWrap.btnR {
    justify-content: flex-end;
  }
  .subContent .btnWrap.row,
  .mfp-content .btnWrap.row {
    flex-direction: column;
  }
  .subContent .btnWrap.row > *,
  .mfp-content .btnWrap.row > * {
    margin-left: 0;
  }
  .subContent .btnWrap.row > * + *,
  .mfp-content .btnWrap.row > * + * {
    margin-top: 10px;
  }
  .subContent .btnWrap > * + *,
  .mfp-content .btnWrap > * + * {
    margin-left: 15px;
  }
  .subContent .btn,
  .mfp-content .btn {
    padding: 10px 16px;
    cursor: pointer;
    transition: 0.5s;
  }
  .subContent .btn:hover,
  .mfp-content .btn:hover {
    opacity: 0.7;
  }
  .subContent .btn:has(> .btnIcon),
  .mfp-content .btn:has(> .btnIcon) {
    padding-left: 40px;
  }
  .subContent .btn.btnBlue,
  .mfp-content .btn.btnBlue {
    min-width: inherit;
  }
  .subContent .btn.btnGreen.btnDownload:before,
  .mfp-content .btn.btnGreen.btnDownload:before {
    width: 15px;
    height: 15px;
    margin-right: 13px;
  }
  .subContent .btn:not(.btnS):not(.btnSS):not(.btnMini):not(.btnFull),
  .mfp-content .btn:not(.btnS):not(.btnSS):not(.btnMini):not(.btnFull) {
    min-width: 220px;
  }
  .subContent .btn.btnFull,
  .mfp-content .btn.btnFull {
    min-width: 100%;
  }
  .subContent .btn.btnS,
  .mfp-content .btn.btnS {
    min-width: 147px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .subContent .btn.btnSS,
  .mfp-content .btn.btnSS {
    min-width: 68px;
    padding: 1px 8px;
  }
  .subContent .btn.btnMini,
  .mfp-content .btn.btnMini {
    min-width: 68px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .subContent .btn.btnDelete,
  .mfp-content .btn.btnDelete {
    padding-left: 25px;
  }
  .subContent .btn.btnDelete:before, .subContent .btn.btnDelete:after,
  .mfp-content .btn.btnDelete:before,
  .mfp-content .btn.btnDelete:after {
    width: 13px;
    height: 2px;
    left: 9px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .subContent .btn.btnDelete:before,
  .mfp-content .btn.btnDelete:before {
    transform: rotate(-45deg);
  }
  .subContent .btn.btnDelete:after,
  .mfp-content .btn.btnDelete:after {
    transform: rotate(45deg);
  }
  .subContent .btn.btnBack:before,
  .mfp-content .btn.btnBack:before {
    width: 7px;
    height: 7px;
    left: 20px;
  }
  .subContent .btn.btnClose,
  .mfp-content .btn.btnClose {
    min-width: 200px;
    font-size: 16px;
  }
  .subContent .btn.btnClose:before,
  .mfp-content .btn.btnClose:before {
    left: 20px;
  }
  .subContent .btn.btnDown,
  .mfp-content .btn.btnDown {
    padding-right: 33px;
  }
  .subContent .btn.btnDown:before,
  .mfp-content .btn.btnDown:before {
    width: 8px;
    height: 8px;
    border-right: 1px solid #1560BB;
    border-bottom: 1px solid #1560BB;
    right: 16px;
  }
  .subContent .btn.btnDown:after,
  .mfp-content .btn.btnDown:after {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 16px;
    border-radius: 4px 0 0 4px;
    width: calc(100% - 33px);
  }
  .subContent .btn.btnDown.btnGreen,
  .mfp-content .btn.btnDown.btnGreen {
    min-width: 175px;
  }
  .subContent .btn.btnDown.btnGreen:before,
  .mfp-content .btn.btnDown.btnGreen:before {
    border-width: 7px 6.5px 0 6.5px;
  }
  .subContent .tag,
  .mfp-content .tag {
    padding: 5px 16px;
  }
  .subContent .iconPdf,
  .mfp-content .iconPdf {
    width: 15px;
    height: 16px;
  }
  .subContent .iconAttention,
  .mfp-content .iconAttention {
    width: 18px;
    height: 18px;
  }
  .subContent .iconDownload,
  .mfp-content .iconDownload {
    width: 13px;
    height: 13px;
    margin-right: 13px;
  }
  .subContent .modalLink:before,
  .mfp-content .modalLink:before {
    width: 18px;
    height: 18px;
  }
  .subContent .searchMenu ul > li,
  .mfp-content .searchMenu ul > li {
    min-width: 243px;
    min-height: 48px;
  }
  .subContent .searchMenu ul > li + li,
  .mfp-content .searchMenu ul > li + li {
    margin-left: 10px;
  }
  .subContent .searchMenu ul > li:hover:not(.isActive):before,
  .mfp-content .searchMenu ul > li:hover:not(.isActive):before {
    opacity: 0.7;
  }
  .subContent .searchMenu ul > li > *,
  .mfp-content .searchMenu ul > li > * {
    font-size: 18px;
  }
  .subContent .searchMenu ul > li > *:before,
  .mfp-content .searchMenu ul > li > *:before {
    width: 8px;
    height: 8px;
    right: 14px;
  }
  .subContent .searchMenu ul > li > a,
  .mfp-content .searchMenu ul > li > a {
    transition: 0.2s opacity;
  }
  .subContent .searchMenu ul > li > a:hover,
  .mfp-content .searchMenu ul > li > a:hover {
    opacity: 0.7;
  }
  .subContent .attachedList > li + li,
  .mfp-content .attachedList > li + li {
    margin-top: 10px;
  }
  .subContent .attachedList > li > a,
  .mfp-content .attachedList > li > a {
    padding-left: 25px;
  }
  .subContent .attachedList > li > a[href$=pdf]:after, .subContent .attachedList > li > a[href$=xls]:after, .subContent .attachedList > li > a[href$=xlsx]:after, .subContent .attachedList > li > a[href$=doc]:after, .subContent .attachedList > li > a[href$=docx]:after, .subContent .attachedList > li > a[href$=ppt]:after, .subContent .attachedList > li > a[href$=pptx]:after, .subContent .attachedList > li > a[href$=txt]:after, .subContent .attachedList > li > a[href$=csv]:after, .subContent .attachedList > li > a[href$=zip]:after, .subContent .attachedList > li > a[href$=jpg]:after, .subContent .attachedList > li > a[href$=bmp]:after, .subContent .attachedList > li > a[href$=png]:after, .subContent .attachedList > li > a[href$=gif]:after, .subContent .attachedList > li > a[href$=tif]:after, .subContent .attachedList > li > a[href$=svg]:after, .subContent .attachedList > li > a[href$=mov]:after, .subContent .attachedList > li > a[href$=mp4]:after, .subContent .attachedList > li > a[href$=mp3]:after, .subContent .attachedList > li > a[href$=wmv]:after,
  .mfp-content .attachedList > li > a[href$=pdf]:after,
  .mfp-content .attachedList > li > a[href$=xls]:after,
  .mfp-content .attachedList > li > a[href$=xlsx]:after,
  .mfp-content .attachedList > li > a[href$=doc]:after,
  .mfp-content .attachedList > li > a[href$=docx]:after,
  .mfp-content .attachedList > li > a[href$=ppt]:after,
  .mfp-content .attachedList > li > a[href$=pptx]:after,
  .mfp-content .attachedList > li > a[href$=txt]:after,
  .mfp-content .attachedList > li > a[href$=csv]:after,
  .mfp-content .attachedList > li > a[href$=zip]:after,
  .mfp-content .attachedList > li > a[href$=jpg]:after,
  .mfp-content .attachedList > li > a[href$=bmp]:after,
  .mfp-content .attachedList > li > a[href$=png]:after,
  .mfp-content .attachedList > li > a[href$=gif]:after,
  .mfp-content .attachedList > li > a[href$=tif]:after,
  .mfp-content .attachedList > li > a[href$=svg]:after,
  .mfp-content .attachedList > li > a[href$=mov]:after,
  .mfp-content .attachedList > li > a[href$=mp4]:after,
  .mfp-content .attachedList > li > a[href$=mp3]:after,
  .mfp-content .attachedList > li > a[href$=wmv]:after {
    width: 18px;
    height: 18px;
    top: 0.2em;
    left: 0;
  }
  .subContent .colorGreen .hoverAlpha,
  .mfp-content .colorGreen .hoverAlpha {
    transition: 0.2s opacity;
  }
  .subContent .colorGreen .hoverAlpha:hover,
  .mfp-content .colorGreen .hoverAlpha:hover {
    opacity: 0.8;
  }
  .subContent .colorGreen * + .partsSubText,
  .mfp-content .colorGreen * + .partsSubText {
    margin-top: 10px;
  }
  .subContent .colorGreen .partsSubText > a,
  .mfp-content .colorGreen .partsSubText > a {
    margin-left: 24px;
  }
  .subContent .colorGreen .partsSubText > a + a,
  .mfp-content .colorGreen .partsSubText > a + a {
    margin-left: 24px;
  }
  .subContent .colorGreen .searchMenu,
  .mfp-content .colorGreen .searchMenu {
    display: flex;
  }
  .subContent .colorGreen .searchMenu > ul,
  .mfp-content .colorGreen .searchMenu > ul {
    width: 76.23%;
  }
  .subContent .colorGreen .searchMenu > ul > li,
  .mfp-content .colorGreen .searchMenu > ul > li {
    flex: 1;
    min-width: inherit;
    min-height: 52px;
  }
  .subContent .colorGreen .searchMenu > ul > li > *,
  .mfp-content .colorGreen .searchMenu > ul > li > * {
    padding: 4px 34px 4px 14px !important;
    font-size: 16px;
    text-align: center;
  }
  .subContent .colorGreen .searchMenu > ul > li:not(.isActive) > a,
  .mfp-content .colorGreen .searchMenu > ul > li:not(.isActive) > a {
    padding-right: 14px;
    text-align: center;
  }
  .subContent .colorGreen .searchMenu.notBtn,
  .mfp-content .colorGreen .searchMenu.notBtn {
    display: block;
  }
  .subContent .colorGreen .searchMenu.notBtn > ul,
  .mfp-content .colorGreen .searchMenu.notBtn > ul {
    width: 100%;
    padding-right: 23.77%;
  }
  .subContent .colorGreen .searchMenu.notBtnFull,
  .mfp-content .colorGreen .searchMenu.notBtnFull {
    display: block;
  }
  .subContent .colorGreen .searchMenu.notBtnFull > ul,
  .mfp-content .colorGreen .searchMenu.notBtnFull > ul {
    width: 100%;
    padding-right: 0;
  }
  .subContent .colorGreen .searchMenu > .searchMenuBtnArea,
  .mfp-content .colorGreen .searchMenu > .searchMenuBtnArea {
    width: 23.77%;
    border-bottom: 2px solid #088E98;
  }
  .subContent .colorGreen .searchMenu > .searchMenuBtnArea .btnWrap,
  .mfp-content .colorGreen .searchMenu > .searchMenuBtnArea .btnWrap {
    justify-content: flex-end;
    margin-top: 0;
  }
  .subContent .colorGreen .sortTable > tbody > tr > th:not([scope=col]).isAsc .sortBtn .sortSelect > li:nth-child(odd),
  .mfp-content .colorGreen .sortTable > tbody > tr > th:not([scope=col]).isAsc .sortBtn .sortSelect > li:nth-child(odd) {
    border-color: transparent transparent #088E98 transparent !important;
  }
  .subContent .colorGreen .sortTable > tbody > tr > th:not([scope=col]).isDesc .sortBtn .sortSelect > li:nth-child(even),
  .mfp-content .colorGreen .sortTable > tbody > tr > th:not([scope=col]).isDesc .sortBtn .sortSelect > li:nth-child(even) {
    border-color: #088E98 transparent transparent transparent !important;
  }
  .subContent .colorGreen * + .flexItem,
  .mfp-content .colorGreen * + .flexItem {
    margin-top: 65px;
  }
  .subContent .colorGreen .flexItem,
  .mfp-content .colorGreen .flexItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .subContent .colorGreen .flexItem.flexStart,
  .mfp-content .colorGreen .flexItem.flexStart {
    justify-content: flex-start;
  }
  .subContent .colorGreen .flexItem > * + *,
  .mfp-content .colorGreen .flexItem > * + * {
    margin-left: 20px;
  }
  .subContent .colorGreen .flexItem > .infoList,
  .mfp-content .colorGreen .flexItem > .infoList {
    margin-left: auto;
    margin-top: 0;
  }
  .subContent .colorGreen .flexItem > .infoList > li,
  .mfp-content .colorGreen .flexItem > .infoList > li {
    margin-top: 8px;
  }
  .subContent .colorGreen .flexItem + .commonDl,
  .mfp-content .colorGreen .flexItem + .commonDl {
    margin-top: 22px;
  }
  .subContent .colorGreen * + .separateContent,
  .mfp-content .colorGreen * + .separateContent {
    margin-top: 27px;
  }
  .subContent .colorGreen .separateContent,
  .mfp-content .colorGreen .separateContent {
    padding-top: 15px;
  }
  .subContent .colorGreen .inputWrap .inputSearchBtnWrap,
  .mfp-content .colorGreen .inputWrap .inputSearchBtnWrap {
    position: relative;
    margin-left: 10px;
  }
  .subContent .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchInput,
  .mfp-content .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchInput {
    min-width: 324px;
    padding: 7px 60px 7px 9px;
    font-size: 14px;
    border-radius: 4px;
  }
  .subContent .colorGreen .inputWrap .inputSearchBtnWrap > label,
  .mfp-content .colorGreen .inputWrap .inputSearchBtnWrap > label {
    width: 126px;
    padding: 8px 4px;
    margin-left: 7px;
    border-radius: 4px;
    transition: 0.3s opacity;
  }
  .subContent .colorGreen .inputWrap .inputSearchBtnWrap > label.btnLift span:before,
  .mfp-content .colorGreen .inputWrap .inputSearchBtnWrap > label.btnLift span:before {
    width: 14px;
    height: 14px;
  }
  .subContent .colorGreen .inputWrap .inputSearchBtnWrap > label:hover,
  .mfp-content .colorGreen .inputWrap .inputSearchBtnWrap > label:hover {
    opacity: 0.7;
  }
  .subContent .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchBtn + span,
  .mfp-content .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchBtn + span {
    font-size: 14px;
  }
  .subContent .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchBtn + span:before,
  .mfp-content .colorGreen .inputWrap .inputSearchBtnWrap .inputSearchBtn + span:before {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }
  .subContent .colorGreen .commonDlCol,
  .mfp-content .colorGreen .commonDlCol {
    display: flex;
  }
  .subContent .colorGreen .formList > div > dt,
  .mfp-content .colorGreen .formList > div > dt {
    width: 180px;
  }
  .subContent .colorGreen .formList > div > dd,
  .mfp-content .colorGreen .formList > div > dd {
    width: calc(100% - 180px);
  }
  .subContent .colorGreen .boxMaxTextWrap .boxMaxText + .boxMaxTextBtn:hover:after,
  .mfp-content .colorGreen .boxMaxTextWrap .boxMaxText + .boxMaxTextBtn:hover:after {
    opacity: 0.7;
  }
  .subContent .colorGreen .tableStickyWrap,
  .mfp-content .colorGreen .tableStickyWrap {
    height: 340px;
    overflow-y: scroll;
    margin-top: 17px;
  }
  .subContent .colorGreen .tableStickyWrap table,
  .mfp-content .colorGreen .tableStickyWrap table {
    margin-top: 0 !important;
  }
  .subContent .colorGreen .tableStickyWrap .tableStickyContent,
  .mfp-content .colorGreen .tableStickyWrap .tableStickyContent {
    overflow: clip;
  }
  .subContent .colorGreen table.thSticky,
  .mfp-content .colorGreen table.thSticky {
    border-top: none !important;
  }
  .subContent .colorGreen table.thSticky thead th,
  .mfp-content .colorGreen table.thSticky thead th {
    border-top: none !important;
    border-bottom: none !important;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .subContent .colorGreen table.thSticky tbody tr:first-child td,
  .mfp-content .colorGreen table.thSticky tbody tr:first-child td {
    border-top: none !important;
  }
  .subContent * + .infoList,
  .mfp-content * + .infoList {
    margin-top: 33px;
  }
  .subContent .infoList > li + li,
  .mfp-content .infoList > li + li {
    margin-left: 9px;
  }
  .subContent .downBlock,
  .mfp-content .downBlock {
    padding-top: 33px;
    padding-bottom: 21px;
  }
  .subContent .downBlock .downLead,
  .mfp-content .downBlock .downLead {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .subContent .downBlock .downLead.flexStart,
  .mfp-content .downBlock .downLead.flexStart {
    justify-content: flex-start;
  }
  .subContent .downBlock .downLead.flexStart .btnWrap,
  .mfp-content .downBlock .downLead.flexStart .btnWrap {
    margin: 0 0 0 auto;
  }
  .subContent .downBlock .downLead.flexStart .btnWrap.filterBtn,
  .mfp-content .downBlock .downLead.flexStart .btnWrap.filterBtn {
    margin: 0 0 0 20px;
  }
  .subContent .downBlock .downLead > * + .btnWrap,
  .mfp-content .downBlock .downLead > * + .btnWrap {
    margin-top: 0;
  }
  .subContent .downBlock .downBox,
  .mfp-content .downBlock .downBox {
    margin-top: 8px;
  }
  .subContent .downBlock .downBox2,
  .mfp-content .downBlock .downBox2 {
    display: none;
    margin-top: 8px;
  }
  .subContent .downBlock .downBox2 ul,
  .mfp-content .downBlock .downBox2 ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    border-top: 1px solid #707070;
    border-left: 1px solid #707070;
  }
  .subContent .downBlock .downBox2 ul li,
  .mfp-content .downBlock .downBox2 ul li {
    border-right: 1px solid #707070;
    border-bottom: 1px solid #707070;
  }
  .subContent .downBlock .downBox2 ul button,
  .mfp-content .downBlock .downBox2 ul button {
    width: 100%;
    height: 50px;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    background-color: #fff;
    position: relative;
  }
  .subContent .downBlock .downBox2 ul button:after,
  .mfp-content .downBlock .downBox2 ul button:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-top: 1px solid #707070;
    border-right: 1px solid #707070;
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 4px);
    right: 15px;
  }
  .subContent .downBlock .downBox2 ul button:hover,
  .mfp-content .downBlock .downBox2 ul button:hover {
    background-color: #F7F6F4;
  }
  .subContent * + .sortBlock,
  .mfp-content * + .sortBlock {
    margin-top: 22px;
  }
  .subContent .sortBlock .note,
  .mfp-content .sortBlock .note {
    background-color: #fff;
  }
  .subContent .sortBlock .btnWrap,
  .mfp-content .sortBlock .btnWrap {
    background-color: #fff;
    padding: 40px 0;
    margin: 0;
  }
  .subContent .sortBlock .sortBlockHeader,
  .mfp-content .sortBlock .sortBlockHeader {
    display: flex;
    justify-content: space-between;
    padding: 30px 20px 25px 20px;
  }
  .subContent .sortBlock .sortBlockHeader .sortBlockHeaderLead,
  .mfp-content .sortBlock .sortBlockHeader .sortBlockHeaderLead {
    display: flex;
    align-items: center;
  }
  .subContent .sortBlock .sortBlockHeader .sortBlockHeaderLead * + *,
  .mfp-content .sortBlock .sortBlockHeader .sortBlockHeaderLead * + * {
    margin-left: 33px;
  }
  .subContent .sortBlock .sortBlockHeader .sortBlockHeaderLead > * + p,
  .mfp-content .sortBlock .sortBlockHeader .sortBlockHeaderLead > * + p {
    margin-top: 0;
  }
  .subContent .sortBlock .sortBlockHeader .paging,
  .mfp-content .sortBlock .sortBlockHeader .paging {
    max-width: 930px;
    width: inherit;
  }
  .subContent .sortBlock .sortBlockFooter,
  .mfp-content .sortBlock .sortBlockFooter {
    padding: 30px 20px 25px 20px;
  }
  .subContent .paging,
  .mfp-content .paging {
    display: flex;
    justify-content: center;
  }
  .subContent .paging * + *,
  .mfp-content .paging * + * {
    margin-left: 10px;
  }
  .subContent .paging .prev,
  .subContent .paging .next,
  .mfp-content .paging .prev,
  .mfp-content .paging .next {
    width: 60px;
    height: 40px;
    line-height: 38px;
    transition: 0.2s opacity;
  }
  .subContent .paging .prev:hover,
  .subContent .paging .next:hover,
  .mfp-content .paging .prev:hover,
  .mfp-content .paging .next:hover {
    opacity: 0.7;
  }
  .subContent .paging .prev:before,
  .mfp-content .paging .prev:before {
    border-width: 5px 10px 5px 0;
  }
  .subContent .paging .next:before,
  .mfp-content .paging .next:before {
    border-width: 5px 0 5px 10px;
  }
  .subContent .paging .pageCount,
  .mfp-content .paging .pageCount {
    margin-left: 10px;
  }
  .subContent .paging .pageCount > li,
  .mfp-content .paging .pageCount > li {
    width: 40px;
    height: 40px;
    line-height: 38px;
  }
  .subContent .paging .pageCount > li a,
  .mfp-content .paging .pageCount > li a {
    transition: 0.2s background, 0.2s background, 0.2s color, 0.2s opacity;
  }
  .subContent .paging .pageCount > li a:not(.now):hover,
  .mfp-content .paging .pageCount > li a:not(.now):hover {
    opacity: 0.7;
    text-decoration: underline;
  }
  .subContent .paging .pageCount > li + li,
  .mfp-content .paging .pageCount > li + li {
    margin-left: 10px;
  }
  .subContent * + .formBlock,
  .mfp-content * + .formBlock {
    margin-top: 8px;
  }
  .subContent .formBlock,
  .mfp-content .formBlock {
    padding: 13px 30px 54px;
  }
  .subContent .formBlock .btnWrap,
  .mfp-content .formBlock .btnWrap {
    margin-top: 30px;
  }
  .subContent .colorGreen * + .titleBeforeWrap,
  .mfp-content .colorGreen * + .titleBeforeWrap {
    margin-top: 36px;
  }
  .subContent .colorGreen .titleBeforeWrap,
  .mfp-content .colorGreen .titleBeforeWrap {
    padding-left: 20px;
  }
  .subContent .colorGreen .titleBeforeWrap:first-child,
  .mfp-content .colorGreen .titleBeforeWrap:first-child {
    margin-top: 40px;
  }
  .subContent .colorGreen .titleBeforeWrap:before,
  .mfp-content .colorGreen .titleBeforeWrap:before {
    width: 6px;
    top: 0;
    left: 0;
  }
  .subContent .colorGreen .titleBeforeWrap .titleSub,
  .mfp-content .colorGreen .titleBeforeWrap .titleSub {
    padding: 4px 13px;
    font-size: 16px;
  }
  .subContent .colorGreen .titleBeforeWrap * + .titleBefore,
  .mfp-content .colorGreen .titleBeforeWrap * + .titleBefore {
    margin-top: 7px;
  }
  .subContent .colorGreen .titleBeforeWrap .titleBefore,
  .mfp-content .colorGreen .titleBeforeWrap .titleBefore {
    font-size: 34px;
  }
  .subContent .colorGreen * + .titleBold,
  .mfp-content .colorGreen * + .titleBold {
    margin-top: 27px;
  }
  .subContent .colorGreen .titleBold,
  .mfp-content .colorGreen .titleBold {
    font-size: 22px;
  }
  .subContent .colorGreen * + .commonDl,
  .mfp-content .colorGreen * + .commonDl {
    margin-top: 40px;
  }
  .subContent .colorGreen .btnWrap.btnR + .commonDl,
  .mfp-content .colorGreen .btnWrap.btnR + .commonDl {
    margin-top: 12px;
  }
  .subContent .colorGreen .commonDl + .commonDl,
  .mfp-content .colorGreen .commonDl + .commonDl {
    margin-top: 20px;
  }
  .subContent .colorGreen .commonDl + .sortBlock,
  .mfp-content .colorGreen .commonDl + .sortBlock {
    margin-top: 40px;
  }
  .subContent .colorGreen .commonDl > div,
  .mfp-content .colorGreen .commonDl > div {
    display: flex;
  }
  .subContent .colorGreen .commonDl > div > dt,
  .mfp-content .colorGreen .commonDl > div > dt {
    display: flex;
    align-items: center;
    width: 180px;
    padding: 15px 20px;
    font-size: 14px;
  }
  .subContent .colorGreen .commonDl > div > dd,
  .mfp-content .colorGreen .commonDl > div > dd {
    width: calc(100% - 180px);
    padding: 15px 20px;
  }
  .subContent .colorGreen .commonDl > div.blockRequired > dt,
  .mfp-content .colorGreen .commonDl > div.blockRequired > dt {
    padding-left: 65px;
  }
  .subContent .colorGreen .commonDl > div.blockRequired > dt:before,
  .mfp-content .colorGreen .commonDl > div.blockRequired > dt:before {
    height: 17px;
    left: 20px;
  }
  .subContent .colorGreen .sortBlock .sortBlockHeader .sortBlockHeaderLead > * + *,
  .mfp-content .colorGreen .sortBlock .sortBlockHeader .sortBlockHeaderLead > * + * {
    margin-left: 20px;
  }
  .subContent .colorGreen .sortBlock .sortBlockHeader .sortBlockHeaderLead > * + p,
  .mfp-content .colorGreen .sortBlock .sortBlockHeader .sortBlockHeaderLead > * + p {
    margin-left: 24px;
  }
  .subContent .colorGreen .downBlock.borderTop .downBox,
  .mfp-content .colorGreen .downBlock.borderTop .downBox {
    margin-top: 20px;
  }
  .subContent .colorGreen .downBlock.borderNone .downBox,
  .mfp-content .colorGreen .downBlock.borderNone .downBox {
    margin-top: 20px;
  }
  .subContent .colorGreen .iconPlus,
  .mfp-content .colorGreen .iconPlus {
    width: 18px;
    height: 18px;
  }
  .subContent .colorGreen .iconClip,
  .mfp-content .colorGreen .iconClip {
    width: 18px;
    height: 18px;
  }
  .subContent .colorGreen .iconTrash,
  .mfp-content .colorGreen .iconTrash {
    width: 16px;
    height: 16px;
  }
  .subContent .colorGreen .iconReload,
  .mfp-content .colorGreen .iconReload {
    width: 16px;
    height: 16px;
  }
  .subContent .colorGreen .formList > div.formListDt2.variable,
  .mfp-content .colorGreen .formList > div.formListDt2.variable {
    flex-wrap: wrap;
  }
  .subContent .colorGreen .formList > div.formListDt2.variable > dt,
  .mfp-content .colorGreen .formList > div.formListDt2.variable > dt {
    width: inherit;
    min-width: 180px;
    word-break: keep-all;
  }
}
@media print, screen and (min-width: 600px) and (min-width: 600px) and (max-width: 1230px) {
  .subContent .colorGreen .formList > div.formListDt2.variable > dt + dd,
  .mfp-content .colorGreen .formList > div.formListDt2.variable > dt + dd {
    margin-top: 10px;
  }
}
@media print, screen and (min-width: 600px) {
  .subContent .colorGreen .formList > div.formListDt2.variable > dd,
  .mfp-content .colorGreen .formList > div.formListDt2.variable > dd {
    flex: 1;
    width: initial;
    max-width: inherit;
    padding-right: 0;
    margin-right: 54px;
  }
}
@media print, screen and (min-width: 600px) and (min-width: 600px) and (max-width: 1230px) {
  .subContent .colorGreen .formList > div.formListDt2.variable > dd,
  .mfp-content .colorGreen .formList > div.formListDt2.variable > dd {
    width: calc(100% - 180px);
    margin-right: 0;
  }
}
@media print, screen and (min-width: 600px) {
  .subContent .colorGreen .textarea,
  .mfp-content .colorGreen .textarea {
    min-height: 130px;
    padding: 7px 14px;
  }
  .subContent .formList > div,
  .mfp-content .formList > div {
    padding-top: 22px;
    padding-bottom: 22px;
  }
  .subContent .formList > div,
  .mfp-content .formList > div {
    display: flex;
    align-items: center;
  }
  .subContent .formList > div > dt,
  .mfp-content .formList > div > dt {
    width: 160px;
    padding: 0 15px 0 0;
    line-height: 1.375;
  }
  .subContent .formList > div > dt span,
  .mfp-content .formList > div > dt span {
    font-size: 12px;
  }
  .subContent .formList > div > dd,
  .mfp-content .formList > div > dd {
    width: calc(100% - 160px);
  }
  .subContent .formList > div.formListDt2 dd + dt,
  .mfp-content .formList > div.formListDt2 dd + dt {
    width: 80px;
  }
  .subContent .formList > div.formListDt2 dd,
  .mfp-content .formList > div.formListDt2 dd {
    width: calc(55% - 160px);
    max-width: 370px;
    padding-right: 25px;
  }
  .subContent .input,
  .mfp-content .input {
    padding: 7px 14px;
    border-radius: 4px;
  }
  .subContent .input.inputL,
  .mfp-content .input.inputL {
    min-width: 350px;
    max-width: 633px;
  }
  .subContent .input.inputM,
  .mfp-content .input.inputM {
    max-width: 418px;
  }
  .subContent .input.inputS,
  .mfp-content .input.inputS {
    width: 85px;
  }
  .subContent .rowNote,
  .mfp-content .rowNote {
    margin-left: 9px;
  }
  .subContent .inputSearch,
  .mfp-content .inputSearch {
    width: 100%;
    max-width: 546px;
  }
  .subContent .inputSearch:before,
  .mfp-content .inputSearch:before {
    width: 22px;
    height: 22px;
    background-size: 22px auto;
    left: 15px;
  }
  .subContent .inputSearch .input,
  .mfp-content .inputSearch .input {
    padding-left: 50px;
  }
  .subContent .formRow,
  .mfp-content .formRow {
    margin-top: -10px;
  }
  .subContent .formRow > li,
  .mfp-content .formRow > li {
    margin-right: 8px;
    margin-top: 10px;
  }
  .subContent .checkboxLabel,
  .mfp-content .checkboxLabel {
    text-align: center;
    cursor: pointer;
  }
  .subContent .checkboxLabel span,
  .mfp-content .checkboxLabel span {
    padding: 6px 14px 6px 41px;
    border-radius: 4px;
  }
  .subContent .checkboxLabel span:hover,
  .mfp-content .checkboxLabel span:hover {
    opacity: 0.7;
  }
  .subContent .checkboxLabel span:before,
  .mfp-content .checkboxLabel span:before {
    width: 17px;
    height: 17px;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 12px;
  }
  .subContent .checkboxLabel span:after,
  .mfp-content .checkboxLabel span:after {
    left: 16px;
  }
  .subContent .checkboxLabel input[type=checkbox]:checked + span:hover,
  .mfp-content .checkboxLabel input[type=checkbox]:checked + span:hover {
    opacity: 1;
  }
  .subContent .radioLabel,
  .mfp-content .radioLabel {
    cursor: pointer;
  }
  .subContent .radioLabel span,
  .mfp-content .radioLabel span {
    padding: 6px 14px 6px 41px;
    border-radius: 4px;
  }
  .subContent .radioLabel span:hover,
  .mfp-content .radioLabel span:hover {
    opacity: 0.7;
  }
  .subContent .radioLabel span:before,
  .mfp-content .radioLabel span:before {
    width: 17px;
    height: 17px;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 12px;
  }
  .subContent .radioLabel span:after,
  .mfp-content .radioLabel span:after {
    width: 15px;
    height: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 13px;
  }
  .subContent .radioLabel input[type=radio]:checked + span:hover,
  .mfp-content .radioLabel input[type=radio]:checked + span:hover {
    opacity: 1;
  }
  .subContent .selectWrap:not(.selectS),
  .mfp-content .selectWrap:not(.selectS) {
    width: 100%;
    max-width: 273px;
  }
  .subContent .selectWrap .selectS,
  .mfp-content .selectWrap .selectS {
    display: inline-block;
  }
  .subContent .selectWrap.selectM,
  .mfp-content .selectWrap.selectM {
    width: 100%;
    max-width: 380px;
  }
  .subContent .selectWrap.selectFull,
  .mfp-content .selectWrap.selectFull {
    width: 100%;
    max-width: inherit;
  }
  .subContent .selectWrap.colorGreen select,
  .mfp-content .selectWrap.colorGreen select {
    font-size: 14px;
  }
  .subContent .selectWrap:before,
  .mfp-content .selectWrap:before {
    border-width: 7px 6.5px 0 6.5px;
    right: 12px;
  }
  .subContent .selectWrap .select,
  .mfp-content .selectWrap .select {
    min-height: 41px;
    padding: 7px 30px 7px 14px;
    border-radius: 4px;
  }
  .subContent ::placeholder,
  .mfp-content ::placeholder {
    color: #B8B8B8;
  }
  .subContent select.error,
  .subContent input.error,
  .subContent textarea.error,
  .mfp-content select.error,
  .mfp-content input.error,
  .mfp-content textarea.error {
    background: #FFF0F0 !important;
  }
  @keyframes onAutoFillStart {}
  .subContent .modalContent,
  .mfp-content .modalContent {
    max-width: 1220px;
    padding: 46px 40px 46px;
    margin: 0 auto;
  }
  .subContent .modalTitle,
  .mfp-content .modalTitle {
    margin-bottom: 42px;
    font-size: 28px;
  }
  .subContent .modalInner,
  .mfp-content .modalInner {
    max-width: 986px;
    padding-right: 50px;
    margin: 0 auto;
    max-height: 530px;
  }
  .subContent .modalInner::-webkit-scrollbar,
  .mfp-content .modalInner::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 30px;
    background: #E6E6E6;
    cursor: pointer;
  }
  .subContent .modalInner::-webkit-scrollbar-thumb,
  .mfp-content .modalInner::-webkit-scrollbar-thumb {
    width: 5px;
    height: 5px;
    border-radius: 30px;
    background: #1560BB;
    cursor: pointer;
  }
  .subContent * + .errorBox,
  .mfp-content * + .errorBox {
    margin-top: 45px;
  }
  .subContent .errorBox,
  .mfp-content .errorBox {
    padding: 10px 20px;
  }
  .subContent .errorBox + .commonDl,
  .mfp-content .errorBox + .commonDl {
    margin-top: 20px;
  }
  .subContent .errorBox.errorBoxStrong,
  .mfp-content .errorBox.errorBoxStrong {
    padding: 26px 20px;
  }
  .subContent * + .contentNarrow,
  .mfp-content * + .contentNarrow {
    margin-top: 45px;
  }
  .subContent .contentNarrow,
  .mfp-content .contentNarrow {
    max-width: 1000px;
    margin-inline: auto;
  }
  .subContent .radioList > li + li,
  .mfp-content .radioList > li + li {
    margin-top: 9px;
    margin-top: 12px;
  }
  .subContent .radioList > li .radiListContent,
  .mfp-content .radioList > li .radiListContent {
    display: flex;
    align-items: flex-start;
  }
  .subContent .radioList > li .radiListContent .selectWrap.selectM,
  .mfp-content .radioList > li .radiListContent .selectWrap.selectM {
    max-width: 418px;
  }
  .subContent .radioList > li .radioLabelBefore,
  .mfp-content .radioList > li .radioLabelBefore {
    width: 135px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding-right: 15px;
  }
  .subContent .radioList > li .radioLabelBefore > input[type=radio] + span,
  .mfp-content .radioList > li .radioLabelBefore > input[type=radio] + span {
    padding-left: 35px;
    cursor: pointer;
  }
  .subContent .radioList > li .radioLabelBefore > input[type=radio] + span:before,
  .mfp-content .radioList > li .radioLabelBefore > input[type=radio] + span:before {
    width: 24px;
    height: 24px;
    left: 0;
  }
  .subContent .radioList > li .radioLabelBefore > input[type=radio] + span:after,
  .mfp-content .radioList > li .radioLabelBefore > input[type=radio] + span:after {
    width: 18px;
    height: 18px;
    top: 3px;
    left: 3px;
  }
  .subContent .selectWrap.disabled:before,
  .mfp-content .selectWrap.disabled:before {
    border-color: #B8B8B8 transparent transparent transparent;
  }
  .subContent .selectWrap.error:before,
  .mfp-content .selectWrap.error:before {
    border-color: #A72525 transparent transparent transparent !important;
  }
  .subContent .selectWrap.error .select,
  .mfp-content .selectWrap.error .select {
    background: #FFF0F0 !important;
  }
  .subContent .selectList > li + li,
  .mfp-content .selectList > li + li {
    margin-top: 20px;
  }
  .subContent .selectList > li .selectListTitle + *,
  .mfp-content .selectList > li .selectListTitle + * {
    margin-top: 5px;
  }
  .subContent * + .btnAdd,
  .mfp-content * + .btnAdd {
    margin-left: 24px;
  }
  .subContent .btnAdd,
  .mfp-content .btnAdd {
    display: inline-block;
  }
  .subContent .titleBeforeWrap + .compBox,
  .mfp-content .titleBeforeWrap + .compBox {
    margin-top: 40px;
  }
  .subContent .compBox,
  .mfp-content .compBox {
    padding: 50px 30px;
  }
  .subContent .compBox .title a:hover,
  .mfp-content .compBox .title a:hover {
    text-decoration: none;
  }
  .subContent .compBox .title + p,
  .mfp-content .compBox .title + p {
    margin-top: 24px;
  }
  .subContent .btnDrag,
  .mfp-content .btnDrag {
    width: 20px;
    height: 15px;
  }
  .mfp-content {
    padding: 60px;
  }
  .adjustContents {
    max-width: 1000px;
    margin: 0 auto;
  }
  .splitButton {
    display: flex;
    margin: 0 0 0 auto;
  }
  span.placeholder {
    display: none;
  }
  #areaWrapper1 {
    max-width: 1220px;
    width: 95%;
    margin: 0 auto 0;
    justify-content: space-between;
    padding-bottom: 100px;
  }
}
@media screen and (min-width: 600px) and (min-width: 600px) and (max-width: 1000px) {
  #areaWrapper1 {
    padding-bottom: 0;
  }
}
@media print, screen and (min-width: 600px) {
  .flexBtn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 50px;
  }
}
@media screen and (min-width: 600px) and (min-width: 600px) and (max-width: 1000px) {
  .flexBtn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 50px;
  }
}
@media print, screen and (min-width: 600px) {
  .flexBtn a {
    display: block;
    padding: 0.5em 1em;
    background-color: #bef1e6;
    color: #088E98;
    text-decoration: none;
    margin: 1em 0.5em;
  }
}
@media screen and (min-width: 600px) and (min-width: 600px) and (max-width: 1000px) {
  .flexBtn a {
    display: block;
    padding: 0.5em 1em;
    background-color: #bef1e6;
    color: #088E98;
    text-decoration: none;
    margin: 1em 0.5em;
  }
}
@media print, screen and (min-width: 600px) {
  #mainArea #backBtn {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    margin-top: 40px;
  }
}
@media screen and (min-width: 600px) and (min-width: 600px) and (max-width: 1000px) {
  #mainArea #backBtn {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    margin-top: 40px;
  }
}
@media print, screen and (min-width: 600px) {
  #mainArea #backBtn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
    position: relative;
  }
}
@media screen and (min-width: 600px) and (min-width: 600px) and (max-width: 1000px) {
  #mainArea #backBtn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
    position: relative;
  }
}
@media print, screen and (min-width: 600px) {
  #mainArea #backBtn a:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #ccc;
    border-left: 2px solid #ccc;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 15px;
  }
}
@media screen and (min-width: 600px) and (min-width: 600px) and (max-width: 1000px) {
  #mainArea #backBtn a:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #ccc;
    border-left: 2px solid #ccc;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 15px;
  }
}
@media print, screen and (min-width: 600px) {
  .modal .modalInner.subContent .modalBody .compBox {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  .modal .modalInner.subContent .modalBody .compBox .title + p {
    margin-top: 0;
  }
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .rotate-90 {
    transform: rotate(-90deg);
  }
  .section.subContent {
    margin-bottom: 100px;
  }
  .breadcrumb nav {
    padding: 20px 0;
    overflow: hidden;
    background: #fff;
    position: relative;
    top: 0;
    height: 100%;
    z-index: 1;
    font-size: 14px;
  }
  .breadcrumb nav ol {
    float: left;
  }
  .breadcrumb nav ol li {
    list-style: none;
    float: left;
    padding-right: 20px;
    position: relative;
  }
  .breadcrumb nav ol li a {
    color: #000;
    font-size: 14px;
  }
  .breadcrumb nav ol li a:hover {
    text-decoration: none;
    color: #088E98;
  }
  .breadcrumb nav ol li .active {
    color: #088E98;
  }
  .breadcrumb nav ol li .breadcrumb-home-a::after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    transform: rotate(45deg) translateY(-50%);
    position: absolute;
    top: 50%;
    right: 10px;
    border-top: 1px solid #CFCFCF;
    border-right: 1px solid #CFCFCF;
  }
  .breadcrumb h1 {
    position: relative;
    padding-left: 22px;
    line-height: 44px;
  }
  .breadcrumb h1:after {
    content: "";
    display: block;
    width: 0;
    height: 44px;
    position: absolute;
    top: 0;
    left: 0;
    border-right: 6px solid #088E98;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 1;
    padding-left: 0.6em;
    position: relative;
  }
  h2:before {
    content: "";
    display: block;
    width: 6px;
    height: 1.2em;
    background-color: #088e98;
    position: absolute;
    left: 0;
    top: calc(50% - 0.6em);
  }
  h3 {
    font-size: 16px;
    font-weight: 700;
    border-bottom: 2px solid #088e98;
    padding-bottom: 10px;
  }
  .menteMenu {
    display: flex;
    flex-wrap: wrap;
    background-color: #eff9f7;
    margin-top: 40px;
    padding: 40px 10.65%;
    width: 100%;
  }
  .menteMenu a {
    display: block;
    display: flex;
    width: calc(25% - 21px);
    flex-direction: column;
    align-items: center;
    color: #000;
    text-decoration: none;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px #cbebe4;
    padding: 32px 0;
    position: relative;
    max-width: 230px;
  }
  .menteMenu a:not(:nth-child(4n)) {
    margin-right: 27px;
  }
  .menteMenu a:nth-child(n+5) {
    margin-top: 2.8%;
  }
  .menteMenu a:hover .icon {
    opacity: 0.8;
  }
  .menteMenu a:hover .icon svg, .menteMenu a:hover .icon img {
    transform: scale(1.5);
  }
  .menteMenu a .blank {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .menteMenu a .blank svg {
    width: 16px;
    height: 16px;
    fill: #088e98;
  }
  .menteMenu a .icon {
    aspect-ratio: 1/1;
    width: 45%;
    border-radius: 50%;
    background-color: #088e98;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
  }
  .menteMenu a .icon svg, .menteMenu a .icon img {
    fill: #fff;
    transition: all 0.3s;
  }
  .menteMenu a .icon svg {
    aspect-ratio: 1/1;
    width: 30%;
  }
  .menteMenu a .icon img {
    width: 40%;
  }
  .menteMenu a .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    width: 90%;
    height: 42px;
  }
  .menteMenu a .title .name {
    flex: 1;
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
  }
  .menteMenu a .title .name span {
    font-size: 13px;
    display: block;
  }
  .menteMenu a .title svg {
    width: 16px;
    height: 16px;
    fill: #088e98;
    margin-left: 5px;
    transform: rotate(180deg);
  }
  #bengoshiTab {
    margin-top: 20px;
  }
  #bengoshiTab ul {
    list-style: none;
    display: flex;
    gap: 10px;
    border-bottom: 2px solid #088e98;
    background-color: #fff;
  }
  #bengoshiTab ul li a {
    height: 100%;
    position: relative;
    background-color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    border-top: 1px solid #737373;
    border-right: 1px solid #737373;
    border-left: 1px solid #737373;
    padding: 10px;
  }
  #bengoshiTab ul li a .icon {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: #b8b8b8;
  }
  #bengoshiTab ul li a .icon svg {
    width: 15px;
    height: 15px;
    fill: #fff;
  }
  #bengoshiTab ul li a .icon img {
    width: 20px;
    height: 20px;
  }
  #bengoshiTab ul li a .title {
    font-size: 18px;
    font-weight: 500;
    color: #737373;
    text-align: center;
    margin: 0 15px;
  }
  #bengoshiTab ul li a .arrow {
    width: 20px;
    height: 20px;
  }
  #bengoshiTab ul li a .arrow svg {
    width: 12px;
    height: 12px;
    fill: #737373;
    transform: rotate(-90deg);
  }
  #bengoshiTab ul li a.active {
    border-color: #088e98;
    border-width: 2px;
    border-bottom: 3px solid #fff;
  }
  #bengoshiTab ul li a.active:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    position: absolute;
    bottom: -5px;
    left: 0;
  }
  #bengoshiTab ul li a.active, #bengoshiTab ul li a:hover {
    z-index: 5;
  }
  #bengoshiTab ul li a.active .icon, #bengoshiTab ul li a:hover .icon {
    background-color: #088e98;
  }
  #bengoshiTab ul li a.active .title, #bengoshiTab ul li a:hover .title {
    color: #088e98;
  }
  .ctrlPanel {
    width: 100%;
    padding: 60px 0;
  }
  #ctrlBtns {
    background-color: #eff9f7;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 60px;
    gap: 20px;
    margin-top: 20px;
  }
  #ctrlBtns a {
    text-decoration: none;
    padding: 0 60px;
  }
  #ctrlBtns .item {
    cursor: pointer;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px #cbebe4;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex: 1;
    min-height: 88px;
    transition: all 0.3s;
  }
  #ctrlBtns .item:hover {
    background-color: rgba(8, 142, 152, 0.1);
  }
  #ctrlBtns .item .icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: calc(50% - 15px);
    left: 20px;
  }
  #ctrlBtns .item .icon svg {
    width: 100%;
    height: 100%;
    fill: #088e98;
  }
  #ctrlBtns .item .title {
    font-size: 20px;
    color: #088e98;
  }
  #ctrlBtns .item.disabled {
    background-color: #e6e6e6;
    pointer-events: none;
    box-shadow: none;
  }
  #ctrlBtns .item.disabled .icon svg {
    fill: #737373;
  }
  #ctrlBtns .item.disabled .title {
    color: #737373;
  }
  .ctrlMess {
    background-color: #eff9f7;
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
  }
  .ctrlMess .title {
    font-size: 18px;
    font-weight: 700;
  }
  .ctrlMess .memo {
    font-size: 16px;
    margin-top: 20px;
  }
  .ctrlMess#errer {
    background-color: #fff0f0;
  }
  .ctrlMess#errer .title,
  .ctrlMess#errer .memo {
    color: #a72525;
  }
  .loading {
    width: 480px;
    height: 26px;
    border-radius: 13px;
    background-color: #d1eaec;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
  }
  .loading .bar {
    width: 50%;
    background: radial-gradient(circle, rgb(0, 185, 198) 0%, rgb(8, 142, 152) 100%);
    background-size: 200% 100%;
    background-position: -100% 0;
    height: 26px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
    animation: barAni 3s infinite linear;
  }
  @keyframes barAni {
    50% {
      background-position: 0 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
  .button {
    width: 220px;
    height: 48px;
    border: 1px solid #707070;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    color: #707070;
    font-size: 16px;
    margin-top: 20px;
    transition: all 0.3s;
    border-radius: 5px;
  }
  .button .icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: calc(50% - 10px);
    left: 15px;
    margin: 0;
    display: block;
  }
  .button .icon svg {
    width: 16px;
    height: 16px;
    fill: #707070;
  }
  .button:hover {
    background-color: rgba(8, 142, 152, 0.2);
  }
  .backBtn {
    margin-top: 60px;
  }
  .backBtn .button {
    width: 280px;
    text-decoration: none;
    color: #707070;
    margin: 0 auto;
  }
  #log {
    margin-top: 30px;
  }
  #log .logTable {
    list-style: none;
    margin-top: 20px;
  }
  #log .logTable li {
    border-bottom: 1px dotted #b8b8b8;
    display: flex;
    position: relative;
    font-size: 16px;
    padding-left: 1em;
    padding-bottom: 10px;
  }
  #log .logTable li + li {
    margin-top: 10px;
  }
  #log .logTable li.error {
    color: #a72525;
  }
  #log .logTable li:before {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #088e98;
    position: absolute;
    top: 10px;
    left: 2px;
  }
  #log .logTable li .date {
    width: 340px;
  }
  #log .logTable li .matter {
    flex: 1;
  }
  ul.formRow {
    list-style: none;
  }
  ul.formRow li {
    display: flex;
    gap: 15px;
  }
  ul.formRow li + li {
    margin-top: 20px;
  }
  ul.formRow .kokaiLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 42px;
    border-radius: 5px;
    border: 1px solid #b8b8b8;
    background-color: #fff;
    position: relative;
    cursor: pointer;
  }
  ul.formRow .kokaiLabel:before {
    content: "";
    display: block;
    width: 17px;
    height: 17px;
    background-color: #e0e0e0;
    position: absolute;
    left: 10px;
    top: calc(50% - 8px);
    border-radius: 50%;
  }
  ul.formRow .kokaiLabel.checked {
    background-color: #eefdfe;
    border: 2px solid #088e98;
    color: #000 grn;
  }
  ul.formRow .kokaiLabel.checked:before {
    width: 10px;
    height: 15px;
    border-right: 3px solid #088e98;
    border-bottom: 3px solid #088e98;
    transform: rotate(45deg);
    border-radius: 0;
    background-color: transparent;
    top: 6px;
  }
  .inputCal {
    display: flex;
    width: 183px;
    height: 42px;
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    overflow: hidden;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
  }
  .inputCal.disabled {
    background-color: #e6e6e6;
    cursor: default;
  }
  .inputCal.disabled input {
    background-color: #e6e6e6;
    cursor: default;
  }
  .inputCal.error {
    background-color: #fff0f0;
  }
  .inputCal.error input {
    background-color: transparent;
  }
  .inputCal svg {
    width: 20px;
    height: 20px;
    fill: #737373;
  }
  .inputCal input {
    width: 140px;
    padding: 0 10px;
    cursor: pointer;
  }
  .timeSelect {
    position: relative;
    z-index: 5;
  }
  .timeSelect .timeBtn {
    display: flex;
    width: 150px;
    height: 42px;
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    overflow: hidden;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
  }
  .timeSelect .timeBtn.disabled {
    background-color: #e6e6e6;
    cursor: default;
  }
  .timeSelect .timeBtn.disabled input {
    background-color: #e6e6e6;
    cursor: default;
  }
  .timeSelect .timeBtn svg {
    width: 20px;
    height: 20px;
    fill: #737373;
  }
  .timeSelect .timeBtn input {
    width: 100px;
    padding: 0 10px;
    cursor: pointer;
  }
  .timeSelectArea {
    display: none;
  }
  .timeSelectArea .timeSelectInner {
    border: 1px solid #d2d2d2;
    background-color: #fff;
    padding: 20px;
    display: flex;
    gap: 20px;
    position: absolute;
    top: 20px;
    left: 0;
  }
  .timeSelectArea .timeSelectInner .title {
    text-align: center;
    border-bottom: 1px solid #d2d2d2;
  }
  .timeSelectArea #hour .H {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    background-color: #d2d2d2;
    gap: 1px;
    padding: 1px;
    margin-top: 10px;
  }
  .timeSelectArea #hour .H + .H {
    margin-top: 20px;
  }
  .timeSelectArea #hour .H span {
    grid-row: 1/span 2;
    grid-column: 1;
    line-height: 1;
    width: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F7F6F4;
  }
  .timeSelectArea #hour .H label {
    background-color: #fff;
    line-height: 1;
    width: 2em;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .timeSelectArea #hour .H label.checked {
    background-color: #bef1e6;
  }
  .timeSelectArea #min .M {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    background-color: #d2d2d2;
    gap: 1px;
    padding: 1px;
    margin-top: 10px;
  }
  .timeSelectArea #min .M label {
    background-color: #fff;
    line-height: 1;
    width: 2em;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .timeSelectArea #min .M label.checked {
    background-color: #bef1e6;
    cursor: pointer;
  }
  .underBtns {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .underBtns button {
    width: 220px;
    height: 48px;
    border-radius: 5px;
    color: #737373;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .underBtns button.back {
    position: relative;
  }
  .underBtns button.back svg {
    width: 20px;
    height: 20px;
    fill: #737373;
    position: absolute;
    top: calc(50% - 10px);
    left: 15px;
  }
  .underBtns button.back.white {
    min-width: 220px;
    width: auto;
    padding: 0 15px 0 40px;
    background-color: #fff;
    border: 1px solid #737373;
  }
  .underBtns button.regist {
    background-color: #e6e6e6;
  }
  .underBtns button.regist.noErrer {
    background-color: #088e98;
    color: #fff;
  }
  .completionArea {
    background-color: #f7f6f4;
    width: 100%;
    padding: 40px;
    text-align: center;
    margin-top: 60px;
  }
  .completionArea .title {
    font-size: 22px;
    font-weight: 700;
  }
  .completionArea p {
    margin-top: 40px;
  }
  .colorGreen .searchMenu + .registErrer {
    margin-top: 40px;
  }
  .colorGreen .compBox + .registErrer {
    margin-top: 20px;
  }
  .colorGreen .registErrer {
    border: none;
    padding-top: 6px;
    padding-bottom: 20px;
  }
  .colorGreen .errorText {
    margin-right: auto;
    margin-left: auto;
    color: #A72525;
    background: #fff;
    width: calc(100% - 40px);
    padding: 20px 30px;
  }
  .colorGreen * + .errorList {
    margin-top: 12px;
  }
  .registErrer {
    border: 2px solid #a72525;
    background-color: #fff0f0;
    margin-top: 60px;
    padding-top: 40px;
  }
  .registErrer .title {
    font-size: 22px;
    font-weight: 700;
    color: #a72525;
    display: flex;
    justify-content: center;
    align-items: center;
    background: inherit !important;
  }
  .registErrer .title svg {
    width: 22px;
    height: 22px;
    fill: #a72525;
    margin-right: 10px;
  }
  .registErrer .errerInfo {
    background-color: #fff;
    display: block;
    margin: 40px;
    padding: 30px;
    color: #a72525;
  }
  .ui-state-highlight,
  .ui-widget-content .ui-state-highlight,
  .ui-widget-header .ui-state-highlight {
    background-color: #bef1e6 !important;
  }
  .modal {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
  }
  .modal .modalInner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal .modalInner.subContent .modalBody {
    position: relative;
    padding-bottom: 128px !important;
  }
  .modal .modalInner.subContent .modalBody .modBodyIn:not(.scrollNonePC) {
    overflow-y: auto;
    max-height: calc(100vh - 300px);
  }
  .modal .modalInner.subContent .modalBody .modBodyIn * + .modBodyInScrollPC {
    margin-top: 30px;
  }
  .modal .modalInner.subContent .modalBody .modBodyIn .modBodyInScrollPC {
    overflow-y: auto;
    max-height: calc(100vh - 380px);
  }
  .modal .modalInner.subContent .modalBody .modalBtns {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    bottom: 40px;
  }
  .modal .modalInner.subContent * + .inputWrap {
    margin-top: 30px;
  }
  .modal .modalInner.subContent .inputWrap .inputSearchBtnWrap {
    width: 100%;
    max-width: 464px;
    margin-left: 0;
  }
  .modal .modalInner.subContent .inputWrap .inputSearchBtnWrap .inputSearchInput {
    width: 100%;
  }
  .modal .modalInner.subContent .inputWrap + .errorMsg {
    margin-top: 6px;
  }
  .modal .modalInner.subContent .modTableNote + .modTable {
    margin-top: 17px !important;
  }
  .modal .modalInner .modalBody {
    background-color: #fff;
  }
  .modal .modalInner .modalBody.alert {
    width: 580px;
    padding: 30px;
  }
  .modal .modalInner .modalBody.alert .icon {
    width: 40px;
    margin: 0 auto;
  }
  .modal .modalInner .modalBody.alert .icon svg {
    width: 40px;
    height: 40px;
    fill: #d2d2d2;
  }
  .modal .modalInner .modalBody.alert .title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }
  .modal .modalInner .modalBody.alert p {
    font-size: 16px;
    text-align: center;
  }
  .modal .modalInner .modalBody.alert .loading + p {
    margin-top: 20px;
  }
  .modal .modalInner .modalBody.operation {
    max-width: 1220px;
    width: 95%;
    padding-bottom: 80px;
  }
  .modal .modalInner .modalBody.operation .title {
    background-color: #bef1e6;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    padding: 24px 10px;
  }
  .modal .modalInner .modalBody.operation .modBodyIn {
    padding: 30px 50px 0;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable {
    border: 1px solid #d2d2d2;
    width: 100%;
    margin-top: 30px;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable th {
    text-align: left;
    background-color: #FBFAF9;
    padding: 10px;
    font-size: 14px;
    border-top: 1px solid #d2d2d2;
    width: 180px;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable th[scope=row] {
    width: inherit;
    text-align: center;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable td {
    padding: 10px;
    border-top: 1px solid #d2d2d2;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable td .errorMess {
    display: block;
    font-size: 14px;
    text-align: left;
    color: #a72525;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable td .errorMess + * {
    margin-top: 8px;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable .iconRequired {
    display: inline-block;
    padding: 2px 5px;
    margin-right: 11px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    font-style: normal;
    vertical-align: baseline;
    font-weight: 400;
    line-height: 1;
    background: #A72525;
    box-sizing: border-box;
  }
  .modal .modalInner .modalBody.operation .modBodyIn p.error {
    background-color: #fff0f0;
    display: block;
    padding: 5px;
    color: #a72525;
  }
  .modal .modalInner .modalBody.operation .greenArrow {
    margin-top: 30px;
    width: 100%;
  }
  .modal .modalInner .modalBody.operation .greenArrow:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 25px solid #088e98;
    border-right: 35px solid transparent;
    border-left: 35px solid transparent;
    margin: 0 auto;
  }
  .modal .modalInner .modalBody .compBox {
    background-color: #f7f6f4;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .modal .modalInner .modalBody .compBox .title {
    background-color: transparent;
  }
  .modal .modalInner .modalBody .compBox .title a {
    color: #707070;
    border: 1px solid #707070;
    width: 200px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    margin: 30px auto 0;
    background-color: #fff;
  }
  .modal .modalInner .modalBody .compBox .title a:hover {
    text-decoration: none;
  }
  .modal .modalInner .modalBody .compBox p {
    text-align: center;
  }
  .modal .modalInner .modalBody .compBox .custom_hover {
    width: 200px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    color: #707070;
    border: 1px solid #707070;
    text-decoration: none;
    background-color: #fff;
    margin: 20px auto 0;
  }
  .modal .modalInner .modalBody .compBox.errorBox {
    background-color: #fff0f0;
    color: #a72525;
  }
  .modal .modalInner .modalBody .compBox table.modTable td {
    background: #fff;
  }
  .modal .modalInner .modalBody .modalBtns {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .modal .modalInner .modalBody .modalBtns .btn {
    width: 200px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    cursor: pointer;
  }
  .modal .modalInner .modalBody .modalBtns .btn.cancel {
    color: #707070;
    border: 1px solid #707070;
  }
  .modal .modalInner .modalBody .modalBtns .btn.goOn {
    background-color: #088e98;
    color: #fff;
  }
  .modal .modalInner .modalBody .modalBtns .btn .btnIcon {
    width: 11px;
    height: 11px;
    position: absolute;
    top: calc(50% - 6px);
    left: 15px;
    fill: #707070;
  }
  .modal .modalInner .searchMenu {
    margin-top: 0;
  }
  .modal .modalInner .searchMenu + .compBox {
    margin-top: 40px;
  }
  .modal .modalInner #upFileWrap {
    display: block;
    padding: 33px 40px;
    background: #F7F6F4;
  }
  .modal .modalInner #upFileWrap #inputFile + .errorMsg {
    margin-top: 10px;
  }
  .modal .modalInner #upFileWrap #inputFile + .btnWrap {
    margin-top: 8px;
  }
  .modal .modalInner #upFileWrap * + .btnWrap {
    margin-top: 30px;
  }
  #targetfile_name {
    position: relative;
    margin-top: 1em;
  }
  .formList {
    background-color: #F7F6F4;
    margin-top: 30px;
  }
  .formList .flex {
    display: flex;
    background-color: #fff;
    margin-top: 20px;
    border: 1px solid #d2d2d2;
    padding: 15px;
  }
  .formList .flex + .flex {
    margin-top: -1px;
  }
  .formList.teiki {
    background-color: #e6effd;
  }
  .formList.teiki #listFileWrap ul li.listHead {
    background: #a8cfff;
  }
  .formList.teiki #listFileWrap p span.kubun {
    color: #fff;
    background: #1560bb;
    padding: 2px 10px 4px;
    margin-right: 10px;
  }
  .formList.rinji {
    background-color: #fff2e3;
  }
  .formList.rinji #listFileWrap ul li.listHead {
    background: #ffd09a;
  }
  .formList.rinji #listFileWrap p span.kubun {
    color: #fff;
    background: #e48314;
    padding: 2px 10px 4px;
    margin-right: 10px;
  }
  .formList dt {
    position: relative;
  }
  .formList dt .clearBtn {
    width: 170px;
    height: 42px;
    border: 1px solid #b8b8b8;
    background-color: #fff;
    position: absolute;
    top: calc(50% - 21px);
    right: 0;
    font-size: 14px;
    color: #707070;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  .formList dt .clearBtn svg {
    width: 11px;
    height: 11px;
    fill: #707070;
  }
  .formList dt .clearBtn:hover {
    color: #000;
  }
  .formList dt .clearBtn:hover svg {
    fill: #000;
  }
  * + #upFileWrap {
    margin-top: 20px;
  }
  #inputFile {
    position: relative;
    padding: 170px 0 70px;
    text-align: center;
    height: 310px;
    cursor: pointer;
  }
  #dropArea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 90px 0 0;
    border: 2px dashed #b8b8b8;
    color: #b8b8b8;
    font-size: 1.2em;
    background: #fff;
  }
  #dropArea.dragover {
    border: 5px dashed #088e93;
  }
  #uploadFile {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: block;
    width: 220px;
    height: 48px;
    opacity: 0;
    cursor: pointer;
  }
  #inputFileWrap {
    position: relative;
    display: inline-block;
    border: 2px solid #088e98;
    color: #088e98;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  #selectFile {
    border-color: #088e93;
    color: #088e93;
    cursor: pointer;
  }
  #selectFile span {
    cursor: pointer;
  }
  #listFileWrap {
    position: relative;
    font-size: 0.8em;
  }
  #listFileWrap p {
    margin: 15px 0;
  }
  #listFileWrap ul {
    list-style: none;
    border: 1px solid #e3e3e3;
    background: #fff;
  }
  #listFileWrap ul li {
    border-bottom: 1px solid #e3e3e3;
  }
  #listFileWrap ul li span {
    padding: 10px;
    display: inline-block;
  }
  #listFileWrap ul li span.csvname {
    width: 285px;
  }
  .btn.btnGreen {
    color: #fff;
    border-color: #088e93;
    background: #088e93;
  }
  .btn.btnGreen:disabled {
    color: #818181;
    border-color: #e6e6e6;
    background: #e6e6e6;
  }
  .subContent .modalBody.operation .modBodyIn {
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
  }
  .subContent .formList {
    padding: 30px;
  }
  .subContent .formList > div > dt {
    width: 220px;
  }
  .errorMsg {
    color: #a72525;
    font-weight: bold;
  }
  .seisei_date {
    margin-right: 20px;
  }
  .base_date {
    margin-right: 20px;
  }
  .errorRed {
    color: #a72525;
  }
  #overlay {
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #content {
    z-index: 6;
    width: 540px;
    padding: 30px;
    background: #fff;
    text-align: center;
  }
  #date-update {
    z-index: 6;
    width: 540px;
    padding: 30px;
    background: #fff;
    text-align: center;
  }
  #content .iconAttention {
    width: 40px;
    height: 40px;
    filter: grayscale(100%);
    background-color: rgba(255, 255, 255, 0.8);
    background-blend-mode: lighten;
  }
  .subContent .inputCal.inputSearch:before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22currentColor%22%20class%3D%22bi%20bi-calendar%22%20viewBox%3D%220%200%2016%2016%22%3E%20%3Cpath%20d%3D%22M3.5%200a.5.5%200%200%201%20.5.5V1h8V.5a.5.5%200%200%201%201%200V1h1a2%202%200%200%201%202%202v11a2%202%200%200%201-2%202H2a2%202%200%200%201-2-2V3a2%202%200%200%201%202-2h1V.5a.5.5%200%200%201%20.5-.5zM1%204v10a1%201%200%200%200%201%201h12a1%201%200%200%200%201-1V4H1z%22%2F%3E%3C%2Fsvg%3E") !important;
    background-color: rgba(255, 255, 255, 0.8);
    background-blend-mode: lighten;
  }
  #date-update .title {
    font-size: 1.2em;
    font-weight: bold;
  }
  #date-update .caption {
    font-size: 0.8em;
  }
  #date-update .step-progress-wrap {
    margin: 30px;
    background-color: #cde8e9;
    border-radius: 100px;
  }
  #date-update .step-progress {
    display: flex;
    width: 100%;
    height: 15px;
    background-color: #088e93;
    transition: 0.5s;
    border-radius: 100px;
  }
  .vshow-enter-active,
  .vshow-leave-active {
    transition: opacity 0.3s;
  }
  .vshow-enter,
  vshow-leave-to {
    opacity: 0;
  }
  .vld-overlay {
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-out;
  }
  [v-cloak] {
    visibility: hidden;
  }
  .logTable.gradient {
    -webkit-mask-image: linear-gradient(#fff, rgba(255, 255, 255, 0));
  }
  .logTable.maxHeight {
    max-height: 400px;
  }
  .d-none {
    display: none;
  }
  #btnShow {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #btnShow .button {
    color: #438E98;
    border: 1px solid #438E98;
  }
  #btnShow .arrow-down {
    transform: rotate(-90deg);
  }
  .custom-error {
    text-decoration-line: none !important;
  }
  div.message-error {
    position: relative;
  }
  div.clearBtn {
    width: 170px;
    height: 42px;
    border: 1px solid #b8b8b8;
    background-color: #fff;
    position: absolute;
    top: calc(50% - 21px);
    right: 0;
    font-size: 14px;
    color: #707070;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  div.clearBtn svg {
    width: 11px;
    height: 11px;
    fill: #707070;
  }
  div.clearBtn:hover {
    color: #000;
  }
  div.clearBtn:hover svg {
    fill: #000;
  }
  div#errorMsgZip .errorMsg {
    margin-left: 0px;
  }
  .color_a72525 {
    color: #a72525;
  }
  .file-item {
    display: flex;
    align-items: center;
  }
  img.imgIconFile {
    padding-top: 2px;
  }
  #listFileWrap .listHead {
    display: grid;
    grid-template-columns: 20% 30% 8% 10% 32%;
  }
  #listFileWrap .file-item {
    display: grid;
    grid-template-columns: 20% 30% 8% 10% 32%;
  }
  .errorList > li:before {
    content: "・";
    color: #A72525;
  }
  .calendarList {
    display: flex;
    align-items: center;
    font-size: 13px;
  }
  .calendarList.row {
    flex-direction: column;
    align-items: flex-start;
  }
  .calendarList.row > li + li {
    margin-top: 8px;
    margin-left: 0;
  }
  .calendarList > li + li {
    margin-left: 14px;
  }
  .calendarList .inputSearch.inputCal {
    max-width: 188px;
  }
  .calendarList .inputSearch.inputCal .input[type=text], .calendarList .inputSearch.inputCal .input::placeholder {
    font-size: 16px;
  }
  * + .newsBox {
    margin-top: 40px;
  }
  .newsBox {
    display: flex;
    align-items: center;
    padding: 28px 42px 21px 0;
  }
  .newsBox > dt {
    width: 170px;
    padding: 0 10px;
    font-size: 20px;
    text-align: center;
  }
  .newsBox + * {
    margin-top: 73px;
  }
  * + .inputArea {
    margin-top: 20px;
  }
  .inputArea {
    padding: 42px 40px;
  }
  .inputArea * + .commonDlTitle {
    margin-top: 28px;
  }
  .inputArea .commonDl + .commonDlTitle {
    margin-top: 45px;
  }
  .inputArea .commonDlTitle + .commonDl {
    margin-top: 12px;
  }
  .progBar {
    width: 102px;
    height: 17px;
    margin-top: 5px;
  }
  .colorGreen .linkGreen i {
    margin-left: 8px;
  }
  * + .listBoxContent {
    margin-top: 10px;
  }
  .formlistBoxTitle {
    font-size: 14px;
  }
  .formlistBoxTitle + .formlistBox {
    margin-top: 10px;
  }
  .formlistBox .listBoxContent > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 15px;
  }
  .formlistBox .listBoxContent > li > .listBoxFixed {
    flex: none;
  }
  .formWrap {
    flex-wrap: wrap;
  }
  .formWrap .btnWrap {
    margin-top: 0;
    margin-left: 28px;
  }
  .formWrap .btn {
    margin-top: 0;
  }
  .formWrap > * + p {
    margin-top: 0;
  }
  .formTitle {
    display: flex;
    align-items: center;
  }
  .formTitle .formTitleSub {
    padding: 9px 7px;
    font-size: 14px;
  }
  .formTitle strong {
    margin-left: 13px;
    font-size: 20px;
  }
  * + .fileSelectRow {
    margin-top: 21px;
  }
  .fileSelectRow {
    padding: 17px 20px;
  }
  .fileSelectRow .fileSectLead {
    font-size: 21px;
  }
  .fileSelectRow * + .btnWrap {
    margin-top: 0;
    margin-left: 36px;
  }
  .fileSelectRow .btnWrap .btn:hover {
    opacity: 1;
  }
  .fileSelectRow + .errorMsg {
    margin-top: 10px;
  }
  * + .fileSelect {
    margin-top: 33px;
  }
  .fileSelect {
    padding: 111px 20px 70px;
  }
  .fileSelect .fileSectLead {
    font-size: 21px;
  }
  .fileSelect * + .btnWrap {
    margin-top: 34px;
  }
  .fileSelect .btnWrap .btn:hover {
    opacity: 1;
  }
  .fileSelect + .errorMsg {
    margin-top: 10px;
  }
  .inputSearchBtnIn .inputWrap .inputSearchBtnWrap .inputSearchInput + label {
    width: 50px;
    height: 42px;
    border-radius: 0 4px 4px 0;
  }
  .inputSearchBtnIn .inputWrap .inputSearchBtnWrap .inputSearchInput + label .inputSearchBtn + span:before {
    width: 20px;
    height: 20px;
    margin-right: 0;
  }
  .quasiTable .quasiTableBody .quasiTableTr > * {
    min-height: 80px;
  }
  .quasiTable .quasiTableTr > * {
    display: flex;
    align-items: center;
    padding: 15px 20px;
  }
  .quasiTable .quasiTableTr .quasiTableLabel {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .quasiTable .quasiTableTr .quasiTableInfo .textInfo {
    font-size: 16px;
  }
  .quasiTable a.quasiTableTr {
    transition: 0.2s opacity;
  }
  .quasiTable a.quasiTableTr:hover {
    opacity: 0.7;
  }
  .quasiTable a.quasiTableTr:after {
    width: 10px;
    height: 10px;
    right: 18px;
  }
  .modalInner .quasiTable .quasiTableBody .quasiTableTr > * {
    min-height: 68px;
  }
  .modalInner .quasiTable .quasiTableTr > * {
    display: flex;
    align-items: center;
    padding: 9px 20px;
  }
  * + .titleSupplement {
    margin-top: 28px;
  }
  .titleSupplement + * {
    margin-top: 16px;
  }
  * + .textAreaWrap {
    margin-top: 16px;
  }
  .textAreaWrap {
    padding: 25px 20px 17px;
  }
  .textAreaWrap textarea {
    padding: 10px;
  }
  .textAreaWrap textarea.inputM {
    min-height: 150px !important;
  }
  .textAreaWrap textarea + p {
    margin-top: 6px;
  }
  .textAreaWrap .textAreaResult {
    padding: 29px 28px;
  }
  #log .btnMore {
    padding-right: 33px;
  }
  #log .btnMore:not(:has(.iconSearch)):before {
    width: 8px;
    height: 8px;
    border-right: 1px solid #1560BB;
    border-bottom: 1px solid #1560BB;
    right: 16px;
  }
  #log .btnMore:after {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 16px;
    border-radius: 4px 0 0 4px;
    width: calc(100% - 33px);
    line-height: 29px;
  }
  #log .btnMore.btnGreen {
    min-width: 175px;
  }
  #log .btnMore.btnGreen:not(:has(.iconSearch)):before {
    border-width: 7px 6.5px 0 6.5px;
  }
  body.bgLogin .subContent:not(.loginBox) {
    width: 95%;
    max-width: 1220px;
    padding: 48px 0 105px;
    margin-top: 10px;
    margin-bottom: 0;
    margin-inline: auto;
  }
  body.bgLogin .subContent:not(.loginBox) .inner {
    max-width: 960px;
    margin-inline: auto;
  }
  body.bgLogin .subContent.loginBox {
    width: 95%;
    max-width: 500px;
    padding: 40px 40px 45px;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-inline: auto;
  }
  body.bgLogin .subContent.loginBox .inner {
    width: 100%;
    margin-inline: auto;
  }
  body.bgLogin .subContent.loginBox .loginBoxHeader {
    margin-bottom: 20px;
  }
  body.bgLogin .subContent.loginBox .loginBoxHeader .lHInner #site_logo .site_logo_text {
    margin-top: 13px;
    font-size: 20px;
  }
  .loginBoxContent + .loginBoxContent {
    padding-top: 28px;
    margin-top: 37px;
  }
  .loginBoxContent .loginBoxInner {
    max-width: 270px;
    margin-inline: auto;
  }
  .loginBoxContent .loginBoxInner * + * {
    margin-top: 6px;
  }
  .loginBoxContent .loginBoxInner .btnWrap {
    margin-top: 15px;
  }
  .loginBoxContent .input {
    padding: 11px 14px;
  }
  .loginHeader {
    padding-top: 40px;
  }
  .loginHeader .lHInner {
    width: 95%;
    max-width: 1220px;
    margin-inline: auto;
  }
  .loginHeader .lHInner #site_logo a {
    max-width: 241px;
  }
  .loginFooter {
    width: 95%;
    max-width: 1220px;
    margin-top: 14px;
  }
  .loginFooter p.copy {
    max-width: inherit;
    width: 100%;
    padding: 0 10px;
  }
  .loginFooter p.copyError {
    margin-bottom: 10px;
    font-size: 12px;
  }
  .loginFooter p.copyError + * {
    margin-top: 7px;
  }
  .loginFooter.small {
    width: 100%;
    max-width: 500px;
    text-align: center;
  }
  .loginFooter.small .lFImage {
    padding: 0;
    top: -112px;
    left: -55px;
  }
  .subContent .titleCenter {
    font-size: 28px;
  }
  .subContent .titleCenter + * {
    margin-top: 85px;
  }
  .subContent * + .stepNumWrap {
    margin-top: 85px;
  }
  .subContent .stepNumWrap + * {
    margin-top: 14px;
  }
  .subContent .stepNumWrap > * + * {
    margin-top: 0;
  }
  .subContent .stepNumWrap .stepNum {
    padding: 4px 16px;
    font-size: 13px;
  }
  .subContent .stepNumWrap .stepNumTitle {
    margin-left: 6px;
    font-size: 18px;
  }
  .subContent * + .logoutContent {
    margin-top: 45px;
  }
  .subContent .infoContentNote {
    margin-top: 11px;
  }
  .subContent .infoContentNote:before {
    width: 19px;
    height: 19px;
    margin-top: 3px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 600px) {
  /* Magnific Popup CSS */
  .mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8;
  }
  .mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
  }
  .mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box;
  }
  .mfp-container:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .mfp-align-top .mfp-container:before {
    display: none;
  }
  .mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
  }
  .mfp-inline-holder .mfp-content,
  .mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
  }
  .mfp-ajax-cur {
    cursor: progress;
  }
  .mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
  }
  .mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
  }
  .mfp-auto-cursor .mfp-content {
    cursor: auto;
  }
  .mfp-close,
  .mfp-arrow,
  .mfp-preloader,
  .mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  .mfp-loading.mfp-figure {
    display: none;
  }
  .mfp-hide {
    display: none !important;
  }
  .mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
  }
  .mfp-preloader a {
    color: #CCC;
  }
  .mfp-preloader a:hover {
    color: #FFF;
  }
  .mfp-s-ready .mfp-preloader {
    display: none;
  }
  .mfp-s-error .mfp-content {
    display: none;
  }
  button.mfp-close,
  button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation;
  }
  button::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  .mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
  }
  .mfp-close:hover,
  .mfp-close:focus {
    opacity: 1;
  }
  .mfp-close-btn-in .mfp-close {
    color: #333;
  }
  .mfp-image-holder .mfp-close,
  .mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
  }
  .mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
  }
  .mfp-arrow {
    position: absolute;
    opacity: 0.65;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent;
  }
  .mfp-arrow:active {
    margin-top: -54px;
  }
  .mfp-arrow:hover,
  .mfp-arrow:focus {
    opacity: 1;
  }
  .mfp-arrow:before,
  .mfp-arrow:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
  }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
  }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7;
  }
  .mfp-arrow-left {
    left: 0;
  }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px;
  }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F;
  }
  .mfp-arrow-right {
    right: 0;
  }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px;
  }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F;
  }
  .mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
  }
  .mfp-iframe-holder .mfp-close {
    top: -40px;
  }
  .mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
  }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
  }
  /* Main image in popup */
  img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
  }
  /* The shadow behind the image */
  .mfp-figure {
    line-height: 0;
  }
  .mfp-figure:after {
    content: "";
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444;
  }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px;
  }
  .mfp-figure figure {
    margin: 0;
  }
  .mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
  }
  .mfp-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px;
  }
  .mfp-image-holder .mfp-content {
    max-width: 100%;
  }
  .mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
  }
  /* css button clear file maintenance */
}
@media screen and (max-width: 1000px) and (min-width: 600px) and (max-width: 800px) and (orientation: landscape), screen and (max-width: 1000px) and (min-width: 600px) and (max-height: 300px) {
  /**
  * Remove all paddings around the image on small screen
  */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media screen and (max-width: 1000px) and (min-width: 600px) and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 600px) {
  .breadcrumb nav {
    overflow: hidden;
    background: #fff;
    position: relative;
    top: 0;
    height: 100%;
    z-index: 1;
  }
  .breadcrumb nav ol {
    float: left;
  }
  .breadcrumb nav ol li {
    list-style: none;
    float: left;
    padding-right: 20px;
    position: relative;
  }
  .breadcrumb nav ol li a {
    color: #000;
    font-size: 14px;
  }
  .breadcrumb nav ol li a:hover {
    text-decoration: none;
    color: #088E98;
  }
  .breadcrumb nav ol li .active {
    color: #088E98;
  }
  .breadcrumb nav ol li .breadcrumb-home-a::after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    transform: rotate(45deg) translateY(-50%);
    position: absolute;
    top: 50%;
    right: 10px;
    border-top: 1px solid #CFCFCF;
    border-right: 1px solid #CFCFCF;
  }
  .breadcrumb h1 {
    position: relative;
    padding-left: 22px;
    line-height: 44px;
  }
  .breadcrumb h1:after {
    content: "";
    display: block;
    width: 0;
    height: 44px;
    position: absolute;
    top: 0;
    left: 0;
    border-right: 6px solid #088E98;
  }
  .section {
    padding-bottom: 0;
  }
  .subContent .btnIcon,
  .mfp-content .btnIcon {
    width: 11px;
    height: 11px;
    position: absolute;
    top: calc(50% - 6px);
    left: 15px;
    fill: #707070;
  }
  .subContent ul.commonList > li:before,
  .mfp-content ul.commonList > li:before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: auto;
    content: "";
    border-radius: 50%;
    background-color: #1560BB;
    position: absolute;
    left: 0;
    top: 0.55em;
  }
  .subContent .modalBody .scrollTable table,
  .mfp-content .modalBody .scrollTable table {
    min-width: 983px;
  }
  .subContent * + .scrollTable,
  .mfp-content * + .scrollTable {
    margin-top: 12px;
  }
  .subContent .scrollTable,
  .mfp-content .scrollTable {
    overflow: auto;
  }
  .subContent .scrollTable table,
  .mfp-content .scrollTable table {
    min-width: 1220px;
  }
  .subContent .scrolltext,
  .mfp-content .scrolltext {
    margin-bottom: 12px;
    font-size: 14px;
    text-align: right;
    display: block !important;
  }
  .subContent .commonTable,
  .mfp-content .commonTable {
    border-bottom: 1px solid #D2D2D2;
  }
  .subContent .commonTable > thead > tr > th:not(:last-child),
  .subContent .commonTable > thead > tr > td:not(:last-child),
  .subContent .commonTable > tbody > tr > th:not(:last-child),
  .subContent .commonTable > tbody > tr > td:not(:last-child),
  .subContent .commonTable > tr > th:not(:last-child),
  .subContent .commonTable > tr > td:not(:last-child),
  .mfp-content .commonTable > thead > tr > th:not(:last-child),
  .mfp-content .commonTable > thead > tr > td:not(:last-child),
  .mfp-content .commonTable > tbody > tr > th:not(:last-child),
  .mfp-content .commonTable > tbody > tr > td:not(:last-child),
  .mfp-content .commonTable > tr > th:not(:last-child),
  .mfp-content .commonTable > tr > td:not(:last-child) {
    border-bottom: none;
  }
  .subContent .commonTable > thead > tr,
  .subContent .commonTable > tbody > tr,
  .subContent .commonTable > tr,
  .mfp-content .commonTable > thead > tr,
  .mfp-content .commonTable > tbody > tr,
  .mfp-content .commonTable > tr {
    display: flex;
    flex-wrap: wrap;
  }
  .subContent .commonTable > thead > tr > td,
  .subContent .commonTable > tbody > tr > td,
  .subContent .commonTable > tr > td,
  .mfp-content .commonTable > thead > tr > td,
  .mfp-content .commonTable > tbody > tr > td,
  .mfp-content .commonTable > tr > td {
    border-bottom: none;
  }
  .subContent .commonTable > thead > tr > th,
  .subContent .commonTable > tbody > tr > th,
  .subContent .commonTable > tr > th,
  .mfp-content .commonTable > thead > tr > th,
  .mfp-content .commonTable > tbody > tr > th,
  .mfp-content .commonTable > tr > th {
    display: block;
  }
  .subContent .commonTable > thead > tr > td,
  .subContent .commonTable > tbody > tr > td,
  .subContent .commonTable > tr > td,
  .mfp-content .commonTable > thead > tr > td,
  .mfp-content .commonTable > tbody > tr > td,
  .mfp-content .commonTable > tr > td {
    display: block;
    width: calc(100% - 120px) !important;
  }
  .subContent .sortTable,
  .mfp-content .sortTable {
    border-bottom: 1px solid #D2D2D2;
  }
  .subContent .sortTable > thead > tr > th:not(:last-child),
  .subContent .sortTable > thead > tr > td:not(:last-child),
  .subContent .sortTable > tbody > tr > th:not(:last-child),
  .subContent .sortTable > tbody > tr > td:not(:last-child),
  .subContent .sortTable > tr > th:not(:last-child),
  .subContent .sortTable > tr > td:not(:last-child),
  .mfp-content .sortTable > thead > tr > th:not(:last-child),
  .mfp-content .sortTable > thead > tr > td:not(:last-child),
  .mfp-content .sortTable > tbody > tr > th:not(:last-child),
  .mfp-content .sortTable > tbody > tr > td:not(:last-child),
  .mfp-content .sortTable > tr > th:not(:last-child),
  .mfp-content .sortTable > tr > td:not(:last-child) {
    border-bottom: none;
  }
  .subContent .sortTable.tdSmall > thead > tr > td,
  .subContent .sortTable.tdSmall > tbody > tr > td,
  .subContent .sortTable.tdSmall > tr > td,
  .mfp-content .sortTable.tdSmall > thead > tr > td,
  .mfp-content .sortTable.tdSmall > tbody > tr > td,
  .mfp-content .sortTable.tdSmall > tr > td {
    font-size: 14px;
  }
  .subContent .sortTable > thead > tr > th:not([scope=col]) .sortBtn,
  .subContent .sortTable > tbody > tr > th:not([scope=col]) .sortBtn,
  .subContent .sortTable > tr > th:not([scope=col]) .sortBtn,
  .mfp-content .sortTable > thead > tr > th:not([scope=col]) .sortBtn,
  .mfp-content .sortTable > tbody > tr > th:not([scope=col]) .sortBtn,
  .mfp-content .sortTable > tr > th:not([scope=col]) .sortBtn {
    height: 100%;
  }
  .subContent .separateTable,
  .mfp-content .separateTable {
    border-bottom: 1px solid #D2D2D2;
  }
  .subContent .separateTable > thead > tr > th,
  .subContent .separateTable > thead > tr > td,
  .subContent .separateTable > tbody > tr > th,
  .subContent .separateTable > tbody > tr > td,
  .subContent .separateTable > tr > th,
  .subContent .separateTable > tr > td,
  .mfp-content .separateTable > thead > tr > th,
  .mfp-content .separateTable > thead > tr > td,
  .mfp-content .separateTable > tbody > tr > th,
  .mfp-content .separateTable > tbody > tr > td,
  .mfp-content .separateTable > tr > th,
  .mfp-content .separateTable > tr > td {
    text-align: center;
  }
  .subContent .colorGreen .searchMenu,
  .mfp-content .colorGreen .searchMenu {
    flex-direction: column;
  }
  .subContent .colorGreen .searchMenu > ul,
  .mfp-content .colorGreen .searchMenu > ul {
    width: 100%;
  }
  .subContent .colorGreen .searchMenu > ul > li > * br,
  .mfp-content .colorGreen .searchMenu > ul > li > * br {
    display: none;
  }
  .subContent .colorGreen .searchMenu.notBtn > ul,
  .mfp-content .colorGreen .searchMenu.notBtn > ul {
    padding-right: 0;
  }
  .subContent .colorGreen .searchMenu > .searchMenuBtnArea,
  .mfp-content .colorGreen .searchMenu > .searchMenuBtnArea {
    width: inherit;
    margin-top: 20px;
    border-bottom: none;
  }
  .subContent .colorGreen .sortTable > tbody > tr > th:not([scope=col]).isAsc .sortBtn .sortSelect > li:nth-child(odd),
  .mfp-content .colorGreen .sortTable > tbody > tr > th:not([scope=col]).isAsc .sortBtn .sortSelect > li:nth-child(odd) {
    border-color: transparent transparent #088E98 transparent !important;
  }
  .subContent .colorGreen .sortTable > tbody > tr > th:not([scope=col]).isDesc .sortBtn .sortSelect > li:nth-child(even),
  .mfp-content .colorGreen .sortTable > tbody > tr > th:not([scope=col]).isDesc .sortBtn .sortSelect > li:nth-child(even) {
    border-color: #088E98 transparent transparent transparent !important;
  }
  .subContent .colorGreen .flexItem,
  .mfp-content .colorGreen .flexItem {
    flex-direction: column;
    align-items: flex-start;
  }
  .subContent .colorGreen .flexItem > * + *,
  .mfp-content .colorGreen .flexItem > * + * {
    margin-top: 8px;
    margin-left: 0;
  }
  .subContent .colorGreen .flexItem > .infoList,
  .mfp-content .colorGreen .flexItem > .infoList {
    flex-direction: column;
    align-items: flex-end;
  }
  .subContent .colorGreen .commonDlCol,
  .mfp-content .colorGreen .commonDlCol {
    flex-direction: column;
  }
  .subContent .colorGreen .commonDlCol > * + *,
  .mfp-content .colorGreen .commonDlCol > * + * {
    margin-top: 12px;
    margin-left: 0;
  }
  .subContent .colorGreen table.thSticky tbody tr:first-child td,
  .mfp-content .colorGreen table.thSticky tbody tr:first-child td {
    border-top: none !important;
  }
  .subContent .downBlock .downLead,
  .mfp-content .downBlock .downLead {
    flex-direction: column;
    align-items: flex-start;
  }
  .subContent .downBlock .downLead > * + *,
  .mfp-content .downBlock .downLead > * + * {
    margin-top: 8px !important;
  }
  .subContent .downBlock .downLead .btnWrap,
  .mfp-content .downBlock .downLead .btnWrap {
    margin-left: auto;
  }
  .subContent .downBlock .downBox2,
  .mfp-content .downBlock .downBox2 {
    display: none;
    margin-top: 8px;
  }
  .subContent .downBlock .downBox2 ul,
  .mfp-content .downBlock .downBox2 ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    border-top: 1px solid #707070;
    border-left: 1px solid #707070;
  }
  .subContent .downBlock .downBox2 ul li,
  .mfp-content .downBlock .downBox2 ul li {
    border-right: 1px solid #707070;
    border-bottom: 1px solid #707070;
  }
  .subContent .downBlock .downBox2 ul button,
  .mfp-content .downBlock .downBox2 ul button {
    width: 100%;
    height: 50px;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    background-color: #fff;
    position: relative;
  }
  .subContent .sortBlock .sortBlockHeader,
  .mfp-content .sortBlock .sortBlockHeader {
    flex-wrap: wrap;
  }
  .subContent .sortBlock .sortBlockHeader .sortBlockHeaderLead,
  .mfp-content .sortBlock .sortBlockHeader .sortBlockHeaderLead {
    width: 100%;
    justify-content: space-between;
  }
  .subContent .sortBlock .sortBlockHeader .paging,
  .mfp-content .sortBlock .sortBlockHeader .paging {
    width: 100%;
  }
  .subContent * + .paging,
  .mfp-content * + .paging {
    margin-top: 20px;
  }
  .subContent .sortBlock .scrolltext,
  .mfp-content .sortBlock .scrolltext {
    padding: 0 20px;
  }
  .subContent .formBlock .btnWrap,
  .mfp-content .formBlock .btnWrap {
    margin-top: 22px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 600px) and (min-width: 600px) and (max-width: 1230px) {
  .subContent .colorGreen .formList > div.formListDt2.variable > dt + dd,
  .mfp-content .colorGreen .formList > div.formListDt2.variable > dt + dd {
    margin-top: 10px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 600px) and (min-width: 600px) and (max-width: 1230px) {
  .subContent .colorGreen .formList > div.formListDt2.variable > dd,
  .mfp-content .colorGreen .formList > div.formListDt2.variable > dd {
    width: calc(100% - 180px);
    margin-right: 0;
  }
}
@media screen and (max-width: 1000px) and (min-width: 600px) {
  .subContent .formList > div.formListDt2,
  .mfp-content .formList > div.formListDt2 {
    flex-wrap: wrap;
  }
  .subContent .formList > div.formListDt2 dd + dt,
  .mfp-content .formList > div.formListDt2 dd + dt {
    width: 180px;
    margin-top: 10px;
  }
  .subContent .formList > div.formListDt2 dd + dt + dd,
  .mfp-content .formList > div.formListDt2 dd + dt + dd {
    margin-top: 10px;
  }
  .subContent .formList > div.formListDt2 dd,
  .mfp-content .formList > div.formListDt2 dd {
    width: calc(100% - 160px);
    padding-right: 0;
    max-width: inherit;
    padding-right: inherit;
  }
  .subContent .input.inputL,
  .mfp-content .input.inputL {
    min-width: inherit;
  }
  .subContent ::placeholder,
  .mfp-content ::placeholder {
    color: #B8B8B8;
  }
  .subContent select.error,
  .subContent input.error,
  .subContent textarea.error,
  .mfp-content select.error,
  .mfp-content input.error,
  .mfp-content textarea.error {
    background: #FFF0F0 !important;
  }
  @keyframes onAutoFillStart {}
  .subContent .modalInner,
  .mfp-content .modalInner {
    padding-right: 15px;
  }
  .subContent .modalInner::-webkit-scrollbar,
  .mfp-content .modalInner::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 30px;
    background: #E6E6E6;
    cursor: pointer;
  }
  .subContent .modalInner::-webkit-scrollbar-thumb,
  .mfp-content .modalInner::-webkit-scrollbar-thumb {
    width: 5px;
    height: 5px;
    border-radius: 30px;
    background: #1560BB;
    cursor: pointer;
  }
  .subContent .radioList > li .radiListContent,
  .mfp-content .radioList > li .radiListContent {
    flex-direction: column;
  }
  .subContent .selectWrap.disabled:before,
  .mfp-content .selectWrap.disabled:before {
    border-color: #B8B8B8 transparent transparent transparent;
  }
  .subContent .selectWrap.error:before,
  .mfp-content .selectWrap.error:before {
    border-color: #A72525 transparent transparent transparent !important;
  }
  .subContent .selectWrap.error .select,
  .mfp-content .selectWrap.error .select {
    background: #FFF0F0 !important;
  }
}
@media screen and (max-width: 1000px) and (min-width: 600px) and (min-width: 600px) and (max-width: 1000px) {
  #areaWrapper1 {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 1000px) and (min-width: 600px) and (min-width: 600px) and (max-width: 1000px) {
  .flexBtn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 50px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 600px) and (min-width: 600px) and (max-width: 1000px) {
  .flexBtn a {
    display: block;
    padding: 0.5em 1em;
    background-color: #bef1e6;
    color: #088E98;
    text-decoration: none;
    margin: 1em 0.5em;
  }
}
@media screen and (max-width: 1000px) and (min-width: 600px) and (min-width: 600px) and (max-width: 1000px) {
  #mainArea #backBtn {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    margin-top: 40px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 600px) and (min-width: 600px) and (max-width: 1000px) {
  #mainArea #backBtn a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    padding: 0 20px 0 10px;
    font-size: 14px;
    position: relative;
  }
}
@media screen and (max-width: 1000px) and (min-width: 600px) and (min-width: 600px) and (max-width: 1000px) {
  #mainArea #backBtn a:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #ccc;
    border-left: 2px solid #ccc;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 15px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 600px) {
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .rotate-90 {
    transform: rotate(-90deg);
  }
  .section.subContent {
    margin-bottom: 100px;
  }
  .breadcrumb nav {
    overflow: hidden;
    background: #fff;
    position: relative;
    top: 0;
    height: 100%;
    z-index: 1;
    font-size: 14px;
  }
  .breadcrumb nav ol {
    float: left;
  }
  .breadcrumb nav ol li {
    list-style: none;
    float: left;
    padding-right: 20px;
    position: relative;
  }
  .breadcrumb nav ol li a {
    color: #000;
    font-size: 14px;
  }
  .breadcrumb nav ol li a:hover {
    text-decoration: none;
    color: #088E98;
  }
  .breadcrumb nav ol li .active {
    color: #088E98;
  }
  .breadcrumb nav ol li .breadcrumb-home-a::after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    transform: rotate(45deg) translateY(-50%);
    position: absolute;
    top: 50%;
    right: 10px;
    border-top: 1px solid #CFCFCF;
    border-right: 1px solid #CFCFCF;
  }
  .breadcrumb h1 {
    position: relative;
    padding-left: 22px;
    line-height: 44px;
  }
  .breadcrumb h1:after {
    content: "";
    display: block;
    width: 0;
    height: 44px;
    position: absolute;
    top: 0;
    left: 0;
    border-right: 6px solid #088E98;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 1;
    padding-left: 0.6em;
    position: relative;
  }
  h2:before {
    content: "";
    display: block;
    width: 6px;
    height: 1.2em;
    background-color: #088e98;
    position: absolute;
    left: 0;
    top: calc(50% - 0.6em);
  }
  h3 {
    font-size: 16px;
    font-weight: 700;
    border-bottom: 2px solid #088e98;
    padding-bottom: 10px;
  }
  .menteMenu {
    display: flex;
    flex-wrap: wrap;
    background-color: #eff9f7;
    margin-top: 40px;
    padding: 40px 10.65%;
    width: 100%;
  }
  .menteMenu a {
    display: block;
    display: flex;
    width: calc(25% - 21px);
    flex-direction: column;
    align-items: center;
    color: #000;
    text-decoration: none;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px #cbebe4;
    padding: 32px 0;
    position: relative;
    max-width: 230px;
  }
  .menteMenu a:not(:nth-child(4n)) {
    margin-right: 27px;
  }
  .menteMenu a:nth-child(n+5) {
    margin-top: 2.8%;
  }
  .menteMenu a:hover .icon {
    opacity: 0.8;
  }
  .menteMenu a:hover .icon svg, .menteMenu a:hover .icon img {
    transform: scale(1.5);
  }
  .menteMenu a .blank {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .menteMenu a .blank svg {
    width: 16px;
    height: 16px;
    fill: #088e98;
  }
  .menteMenu a .icon {
    aspect-ratio: 1/1;
    width: 45%;
    border-radius: 50%;
    background-color: #088e98;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
  }
  .menteMenu a .icon svg, .menteMenu a .icon img {
    fill: #fff;
    transition: all 0.3s;
  }
  .menteMenu a .icon svg {
    aspect-ratio: 1/1;
    width: 30%;
  }
  .menteMenu a .icon img {
    width: 40%;
  }
  .menteMenu a .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    width: 90%;
    height: 42px;
  }
  .menteMenu a .title .name {
    flex: 1;
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
  }
  .menteMenu a .title .name span {
    font-size: 13px;
    display: block;
  }
  .menteMenu a .title svg {
    width: 16px;
    height: 16px;
    fill: #088e98;
    margin-left: 5px;
    transform: rotate(180deg);
  }
  #bengoshiTab {
    margin-top: 20px;
  }
  #bengoshiTab ul {
    list-style: none;
    display: flex;
    gap: 10px;
    border-bottom: 2px solid #088e98;
    background-color: #fff;
  }
  #bengoshiTab ul li a {
    height: 100%;
    position: relative;
    background-color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    border-top: 1px solid #737373;
    border-right: 1px solid #737373;
    border-left: 1px solid #737373;
    padding: 10px;
  }
  #bengoshiTab ul li a .icon {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: #b8b8b8;
  }
  #bengoshiTab ul li a .icon svg {
    width: 15px;
    height: 15px;
    fill: #fff;
  }
  #bengoshiTab ul li a .icon img {
    width: 20px;
    height: 20px;
  }
  #bengoshiTab ul li a .title {
    font-size: 18px;
    font-weight: 500;
    color: #737373;
    text-align: center;
    margin: 0 15px;
  }
  #bengoshiTab ul li a .arrow {
    width: 20px;
    height: 20px;
  }
  #bengoshiTab ul li a .arrow svg {
    width: 12px;
    height: 12px;
    fill: #737373;
    transform: rotate(-90deg);
  }
  #bengoshiTab ul li a.active {
    border-color: #088e98;
    border-width: 2px;
    border-bottom: 3px solid #fff;
  }
  #bengoshiTab ul li a.active:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    position: absolute;
    bottom: -5px;
    left: 0;
  }
  #bengoshiTab ul li a.active, #bengoshiTab ul li a:hover {
    z-index: 5;
  }
  #bengoshiTab ul li a.active .icon, #bengoshiTab ul li a:hover .icon {
    background-color: #088e98;
  }
  #bengoshiTab ul li a.active .title, #bengoshiTab ul li a:hover .title {
    color: #088e98;
  }
  .ctrlPanel {
    width: 100%;
    padding: 60px 0;
  }
  #ctrlBtns {
    background-color: #eff9f7;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 60px;
    gap: 20px;
    margin-top: 20px;
  }
  #ctrlBtns a {
    text-decoration: none;
  }
  #ctrlBtns .item {
    cursor: pointer;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 10px #cbebe4;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex: 1;
    min-height: 88px;
    transition: all 0.3s;
  }
  #ctrlBtns .item:hover {
    background-color: rgba(8, 142, 152, 0.1);
  }
  #ctrlBtns .item .icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: calc(50% - 15px);
    left: 20px;
  }
  #ctrlBtns .item .icon svg {
    width: 100%;
    height: 100%;
    fill: #088e98;
  }
  #ctrlBtns .item .title {
    font-size: 20px;
    color: #088e98;
  }
  #ctrlBtns .item.disabled {
    background-color: #e6e6e6;
    pointer-events: none;
    box-shadow: none;
  }
  #ctrlBtns .item.disabled .icon svg {
    fill: #737373;
  }
  #ctrlBtns .item.disabled .title {
    color: #737373;
  }
  .ctrlMess {
    background-color: #eff9f7;
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
  }
  .ctrlMess .title {
    font-size: 18px;
    font-weight: 700;
  }
  .ctrlMess .memo {
    font-size: 16px;
    margin-top: 20px;
  }
  .ctrlMess#errer {
    background-color: #fff0f0;
  }
  .ctrlMess#errer .title,
  .ctrlMess#errer .memo {
    color: #a72525;
  }
  .loading {
    width: 480px;
    height: 26px;
    border-radius: 13px;
    background-color: #d1eaec;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
  }
  .loading .bar {
    width: 50%;
    background: radial-gradient(circle, rgb(0, 185, 198) 0%, rgb(8, 142, 152) 100%);
    background-size: 200% 100%;
    background-position: -100% 0;
    height: 26px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
    animation: barAni 3s infinite linear;
  }
  @keyframes barAni {
    50% {
      background-position: 0 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
  .button {
    width: 220px;
    height: 48px;
    border: 1px solid #707070;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    color: #707070;
    font-size: 16px;
    margin-top: 20px;
    transition: all 0.3s;
    border-radius: 5px;
  }
  .button .icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: calc(50% - 10px);
    left: 15px;
    margin: 0;
    display: block;
  }
  .button .icon svg {
    width: 16px;
    height: 16px;
    fill: #707070;
  }
  .button:hover {
    background-color: rgba(8, 142, 152, 0.2);
  }
  .backBtn {
    margin-top: 60px;
  }
  .backBtn .button {
    width: 280px;
    text-decoration: none;
    color: #707070;
    margin: 0 auto;
  }
  #log {
    margin-top: 30px;
  }
  #log .logTable {
    list-style: none;
    margin-top: 20px;
  }
  #log .logTable li {
    border-bottom: 1px dotted #b8b8b8;
    display: flex;
    position: relative;
    font-size: 16px;
    padding-left: 1em;
    padding-bottom: 10px;
  }
  #log .logTable li + li {
    margin-top: 10px;
  }
  #log .logTable li.error {
    color: #a72525;
  }
  #log .logTable li:before {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #088e98;
    position: absolute;
    top: 10px;
    left: 2px;
  }
  #log .logTable li .date {
    width: 340px;
  }
  #log .logTable li .matter {
    flex: 1;
  }
  ul.formRow {
    list-style: none;
  }
  ul.formRow li {
    display: flex;
    gap: 15px;
  }
  ul.formRow li + li {
    margin-top: 20px;
  }
  ul.formRow .kokaiLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 42px;
    border-radius: 5px;
    border: 1px solid #b8b8b8;
    background-color: #fff;
    position: relative;
    cursor: pointer;
  }
  ul.formRow .kokaiLabel:before {
    content: "";
    display: block;
    width: 17px;
    height: 17px;
    background-color: #e0e0e0;
    position: absolute;
    left: 10px;
    top: calc(50% - 8px);
    border-radius: 50%;
  }
  ul.formRow .kokaiLabel.checked {
    background-color: #eefdfe;
    border: 2px solid #088e98;
    color: #000 grn;
  }
  ul.formRow .kokaiLabel.checked:before {
    width: 10px;
    height: 15px;
    border-right: 3px solid #088e98;
    border-bottom: 3px solid #088e98;
    transform: rotate(45deg);
    border-radius: 0;
    background-color: transparent;
    top: 6px;
  }
  .inputCal {
    display: flex;
    width: 183px;
    height: 42px;
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    overflow: hidden;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
  }
  .inputCal.disabled {
    background-color: #e6e6e6;
    cursor: default;
  }
  .inputCal.disabled input {
    background-color: #e6e6e6;
    cursor: default;
  }
  .inputCal.error {
    background-color: #fff0f0;
  }
  .inputCal.error input {
    background-color: transparent;
  }
  .inputCal svg {
    width: 20px;
    height: 20px;
    fill: #737373;
  }
  .inputCal input {
    width: 140px;
    padding: 0 10px;
    cursor: pointer;
  }
  .timeSelect {
    position: relative;
    z-index: 5;
  }
  .timeSelect .timeBtn {
    display: flex;
    width: 150px;
    height: 42px;
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    overflow: hidden;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
  }
  .timeSelect .timeBtn.disabled {
    background-color: #e6e6e6;
    cursor: default;
  }
  .timeSelect .timeBtn.disabled input {
    background-color: #e6e6e6;
    cursor: default;
  }
  .timeSelect .timeBtn svg {
    width: 20px;
    height: 20px;
    fill: #737373;
  }
  .timeSelect .timeBtn input {
    width: 100px;
    padding: 0 10px;
    cursor: pointer;
  }
  .timeSelectArea {
    display: none;
  }
  .timeSelectArea .timeSelectInner {
    border: 1px solid #d2d2d2;
    background-color: #fff;
    padding: 20px;
    display: flex;
    gap: 20px;
    position: absolute;
    top: 20px;
    left: 0;
  }
  .timeSelectArea .timeSelectInner .title {
    text-align: center;
    border-bottom: 1px solid #d2d2d2;
  }
  .timeSelectArea #hour .H {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    background-color: #d2d2d2;
    gap: 1px;
    padding: 1px;
    margin-top: 10px;
  }
  .timeSelectArea #hour .H + .H {
    margin-top: 20px;
  }
  .timeSelectArea #hour .H span {
    grid-row: 1/span 2;
    grid-column: 1;
    line-height: 1;
    width: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F7F6F4;
  }
  .timeSelectArea #hour .H label {
    background-color: #fff;
    line-height: 1;
    width: 2em;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .timeSelectArea #hour .H label.checked {
    background-color: #bef1e6;
  }
  .timeSelectArea #min .M {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    background-color: #d2d2d2;
    gap: 1px;
    padding: 1px;
    margin-top: 10px;
  }
  .timeSelectArea #min .M label {
    background-color: #fff;
    line-height: 1;
    width: 2em;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .timeSelectArea #min .M label.checked {
    background-color: #bef1e6;
    cursor: pointer;
  }
  .underBtns {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .underBtns button {
    width: 220px;
    height: 48px;
    border-radius: 5px;
    color: #737373;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .underBtns button.back {
    position: relative;
  }
  .underBtns button.back svg {
    width: 20px;
    height: 20px;
    fill: #737373;
    position: absolute;
    top: calc(50% - 10px);
    left: 15px;
  }
  .underBtns button.back.white {
    min-width: 220px;
    width: auto;
    padding: 0 15px 0 40px;
    background-color: #fff;
    border: 1px solid #737373;
  }
  .underBtns button.regist {
    background-color: #e6e6e6;
  }
  .underBtns button.regist.noErrer {
    background-color: #088e98;
    color: #fff;
  }
  .completionArea {
    background-color: #f7f6f4;
    width: 100%;
    padding: 40px;
    text-align: center;
    margin-top: 60px;
  }
  .completionArea .title {
    font-size: 22px;
    font-weight: 700;
  }
  .completionArea p {
    margin-top: 40px;
  }
  .colorGreen .registErrer {
    border: none;
  }
  .colorGreen .errorText {
    margin-right: auto;
    margin-left: auto;
    color: #A72525;
    background: #fff;
  }
  .registErrer {
    border: 2px solid #a72525;
    background-color: #fff0f0;
    margin-top: 60px;
    padding-top: 40px;
  }
  .registErrer .title {
    font-size: 22px;
    font-weight: 700;
    color: #a72525;
    display: flex;
    justify-content: center;
    align-items: center;
    background: inherit !important;
  }
  .registErrer .title svg {
    width: 22px;
    height: 22px;
    fill: #a72525;
    margin-right: 10px;
  }
  .registErrer .errerInfo {
    background-color: #fff;
    display: block;
    margin: 40px;
    padding: 30px;
    color: #a72525;
  }
  .ui-state-highlight,
  .ui-widget-content .ui-state-highlight,
  .ui-widget-header .ui-state-highlight {
    background-color: #bef1e6 !important;
  }
  .modal {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
  }
  .modal .modalInner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal .modalInner.subContent .modalBody {
    position: relative;
  }
  .modal .modalInner .modalBody {
    background-color: #fff;
  }
  .modal .modalInner .modalBody.alert {
    width: 580px;
    padding: 30px;
  }
  .modal .modalInner .modalBody.alert .icon {
    width: 40px;
    margin: 0 auto;
  }
  .modal .modalInner .modalBody.alert .icon svg {
    width: 40px;
    height: 40px;
    fill: #d2d2d2;
  }
  .modal .modalInner .modalBody.alert .title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }
  .modal .modalInner .modalBody.alert p {
    font-size: 16px;
    text-align: center;
  }
  .modal .modalInner .modalBody.alert .loading + p {
    margin-top: 20px;
  }
  .modal .modalInner .modalBody.operation {
    max-width: 1220px;
    width: 95%;
    padding-bottom: 80px;
  }
  .modal .modalInner .modalBody.operation .title {
    background-color: #bef1e6;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable {
    border: 1px solid #d2d2d2;
    width: 100%;
    margin-top: 30px;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable th {
    text-align: left;
    background-color: #FBFAF9;
    padding: 10px;
    font-size: 14px;
    border-top: 1px solid #d2d2d2;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable th[scope=row] {
    width: inherit;
    text-align: center;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable td {
    padding: 10px;
    border-top: 1px solid #d2d2d2;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable td .errorMess {
    display: block;
    font-size: 14px;
    text-align: left;
    color: #a72525;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable td .errorMess + * {
    margin-top: 8px;
  }
  .modal .modalInner .modalBody.operation .modBodyIn table.modTable .iconRequired {
    display: inline-block;
    padding: 2px 5px;
    margin-right: 11px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    font-style: normal;
    vertical-align: baseline;
    font-weight: 400;
    line-height: 1;
    background: #A72525;
    box-sizing: border-box;
  }
  .modal .modalInner .modalBody.operation .modBodyIn p.error {
    background-color: #fff0f0;
    display: block;
    padding: 5px;
    color: #a72525;
  }
  .modal .modalInner .modalBody.operation .greenArrow {
    margin-top: 30px;
    width: 100%;
  }
  .modal .modalInner .modalBody.operation .greenArrow:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 25px solid #088e98;
    border-right: 35px solid transparent;
    border-left: 35px solid transparent;
    margin: 0 auto;
  }
  .modal .modalInner .modalBody .compBox {
    background-color: #f7f6f4;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .modal .modalInner .modalBody .compBox .title {
    background-color: transparent;
  }
  .modal .modalInner .modalBody .compBox .title a {
    color: #707070;
    border: 1px solid #707070;
    width: 200px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    margin: 30px auto 0;
    background-color: #fff;
  }
  .modal .modalInner .modalBody .compBox .title a:hover {
    text-decoration: none;
  }
  .modal .modalInner .modalBody .compBox p {
    text-align: center;
  }
  .modal .modalInner .modalBody .compBox .custom_hover {
    width: 200px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    color: #707070;
    border: 1px solid #707070;
    text-decoration: none;
    background-color: #fff;
    margin: 20px auto 0;
  }
  .modal .modalInner .modalBody .compBox.errorBox {
    background-color: #fff0f0;
    color: #a72525;
  }
  .modal .modalInner .modalBody .compBox table.modTable td {
    background: #fff;
  }
  .modal .modalInner .modalBody .modalBtns {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .modal .modalInner .modalBody .modalBtns .btn {
    width: 200px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    cursor: pointer;
  }
  .modal .modalInner .modalBody .modalBtns .btn.cancel {
    color: #707070;
    border: 1px solid #707070;
  }
  .modal .modalInner .modalBody .modalBtns .btn.goOn {
    background-color: #088e98;
    color: #fff;
  }
  .modal .modalInner .modalBody .modalBtns .btn .btnIcon {
    width: 11px;
    height: 11px;
    position: absolute;
    top: calc(50% - 6px);
    left: 15px;
    fill: #707070;
  }
  .formList {
    background-color: #F7F6F4;
    margin-top: 30px;
  }
  .formList .flex {
    display: flex;
    background-color: #fff;
    margin-top: 20px;
    border: 1px solid #d2d2d2;
    padding: 15px;
  }
  .formList .flex + .flex {
    margin-top: -1px;
  }
  .formList.teiki {
    background-color: #e6effd;
  }
  .formList.teiki #listFileWrap ul li.listHead {
    background: #a8cfff;
  }
  .formList.teiki #listFileWrap p span.kubun {
    color: #fff;
    background: #1560bb;
    padding: 2px 10px 4px;
    margin-right: 10px;
  }
  .formList.rinji {
    background-color: #fff2e3;
  }
  .formList.rinji #listFileWrap ul li.listHead {
    background: #ffd09a;
  }
  .formList.rinji #listFileWrap p span.kubun {
    color: #fff;
    background: #e48314;
    padding: 2px 10px 4px;
    margin-right: 10px;
  }
  .formList dt {
    position: relative;
  }
  .formList dt .clearBtn {
    width: 170px;
    height: 42px;
    border: 1px solid #b8b8b8;
    background-color: #fff;
    position: absolute;
    top: calc(50% - 21px);
    right: 0;
    font-size: 14px;
    color: #707070;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  .formList dt .clearBtn svg {
    width: 11px;
    height: 11px;
    fill: #707070;
  }
  .formList dt .clearBtn:hover {
    color: #000;
  }
  .formList dt .clearBtn:hover svg {
    fill: #000;
  }
  * + #upFileWrap {
    margin-top: 20px;
  }
  #inputFile {
    position: relative;
    padding: 170px 0 70px;
    text-align: center;
    height: 310px;
    cursor: pointer;
  }
  #dropArea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 90px 0 0;
    border: 2px dashed #b8b8b8;
    color: #b8b8b8;
    font-size: 1.2em;
    background: #fff;
  }
  #dropArea.dragover {
    border: 5px dashed #088e93;
  }
  #uploadFile {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: block;
    width: 220px;
    height: 48px;
    opacity: 0;
    cursor: pointer;
  }
  #inputFileWrap {
    position: relative;
    display: inline-block;
    border: 2px solid #088e98;
    color: #088e98;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  #selectFile {
    border-color: #088e93;
    color: #088e93;
    cursor: pointer;
  }
  #selectFile span {
    cursor: pointer;
  }
  #listFileWrap {
    position: relative;
    font-size: 0.8em;
  }
  #listFileWrap p {
    margin: 15px 0;
  }
  #listFileWrap ul {
    list-style: none;
    border: 1px solid #e3e3e3;
    background: #fff;
  }
  #listFileWrap ul li {
    border-bottom: 1px solid #e3e3e3;
  }
  #listFileWrap ul li span {
    padding: 10px;
    display: inline-block;
  }
  #listFileWrap ul li span.csvname {
    width: 285px;
  }
  .btn.btnGreen {
    color: #fff;
    border-color: #088e93;
    background: #088e93;
  }
  .btn.btnGreen:disabled {
    color: #818181;
    border-color: #e6e6e6;
    background: #e6e6e6;
  }
  .subContent .modalBody.operation .modBodyIn .scrollTable .modTable.thSticky {
    min-width: 1000px;
  }
  .subContent .formList {
    padding: 30px;
  }
  .subContent .formList > div > dt {
    width: 220px;
  }
  .errorMsg {
    color: #a72525;
    font-weight: bold;
  }
  .seisei_date {
    margin-right: 20px;
  }
  .base_date {
    margin-right: 20px;
  }
  .errorRed {
    color: #a72525;
  }
  #overlay {
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #content {
    z-index: 6;
    width: 540px;
    padding: 30px;
    background: #fff;
    text-align: center;
  }
  #date-update {
    z-index: 6;
    width: 540px;
    padding: 30px;
    background: #fff;
    text-align: center;
  }
  #content .iconAttention {
    width: 40px;
    height: 40px;
    filter: grayscale(100%);
    background-color: rgba(255, 255, 255, 0.8);
    background-blend-mode: lighten;
  }
  .subContent .inputCal.inputSearch:before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22currentColor%22%20class%3D%22bi%20bi-calendar%22%20viewBox%3D%220%200%2016%2016%22%3E%20%3Cpath%20d%3D%22M3.5%200a.5.5%200%200%201%20.5.5V1h8V.5a.5.5%200%200%201%201%200V1h1a2%202%200%200%201%202%202v11a2%202%200%200%201-2%202H2a2%202%200%200%201-2-2V3a2%202%200%200%201%202-2h1V.5a.5.5%200%200%201%20.5-.5zM1%204v10a1%201%200%200%200%201%201h12a1%201%200%200%200%201-1V4H1z%22%2F%3E%3C%2Fsvg%3E") !important;
    background-color: rgba(255, 255, 255, 0.8);
    background-blend-mode: lighten;
  }
  #date-update .title {
    font-size: 1.2em;
    font-weight: bold;
  }
  #date-update .caption {
    font-size: 0.8em;
  }
  #date-update .step-progress-wrap {
    margin: 30px;
    background-color: #cde8e9;
    border-radius: 100px;
  }
  #date-update .step-progress {
    display: flex;
    width: 100%;
    height: 15px;
    background-color: #088e93;
    transition: 0.5s;
    border-radius: 100px;
  }
  .vshow-enter-active,
  .vshow-leave-active {
    transition: opacity 0.3s;
  }
  .vshow-enter,
  vshow-leave-to {
    opacity: 0;
  }
  .vld-overlay {
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-out;
  }
  [v-cloak] {
    visibility: hidden;
  }
  .logTable.gradient {
    -webkit-mask-image: linear-gradient(#fff, rgba(255, 255, 255, 0));
  }
  .logTable.maxHeight {
    max-height: 400px;
  }
  .d-none {
    display: none;
  }
  #btnShow {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #btnShow .button {
    color: #438E98;
    border: 1px solid #438E98;
  }
  #btnShow .arrow-down {
    transform: rotate(-90deg);
  }
  .custom-error {
    text-decoration-line: none !important;
  }
  div.message-error {
    position: relative;
  }
  div.clearBtn {
    width: 170px;
    height: 42px;
    border: 1px solid #b8b8b8;
    background-color: #fff;
    position: absolute;
    top: calc(50% - 21px);
    right: 0;
    font-size: 14px;
    color: #707070;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  div.clearBtn svg {
    width: 11px;
    height: 11px;
    fill: #707070;
  }
  div.clearBtn:hover {
    color: #000;
  }
  div.clearBtn:hover svg {
    fill: #000;
  }
  div#errorMsgZip .errorMsg {
    margin-left: 0px;
  }
  .color_a72525 {
    color: #a72525;
  }
  .file-item {
    display: flex;
    align-items: center;
  }
  img.imgIconFile {
    padding-top: 2px;
  }
  #listFileWrap .listHead {
    display: grid;
    grid-template-columns: 20% 30% 8% 10% 32%;
  }
  #listFileWrap .file-item {
    display: grid;
    grid-template-columns: 20% 30% 8% 10% 32%;
  }
  .loginFooter.small {
    margin-top: 0;
  }
  .loginFooter.small .lFImage {
    background: transparent;
  }
}